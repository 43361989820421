import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import SideDrawerModal from '../../../../aem-core-components/components/SideDrawerModal/SideDrawerModal';
import { SIDE_DRAWER } from '../../../../aem-core-components/context/Modal/constants';
import { ModalContext } from '../../../../aem-core-components/context/ModalContext';
import Button from '../../../global/atoms/button/button';
import { useToastMessageState } from '../../../global/modules/toastMessage/toastContext';
import { logError } from '../../../global/utils/logger';
import {
    SET_CLEAR_WISHLISTS,
    SET_IS_COPY_FAVORITE_BTN_CLICKED,
    SET_IS_FAVORITE,
    SET_IS_MOVE_FAVORITE_BTN_CLICKED,
    SET_SELECTED_WISHLISTS,
    SET_SHOW_SAVE_FAVORITE_MODAL,
    SET_WISHLIST_UPDATING
} from '../../actionTypes/actionTypes';
import { ACTION_NAMES, TOAST_ANIMATION_TYPE, actionBarDropdownConstants } from '../../constants';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';
import { useFavouritesState } from '../../context';
import { favDataLocators } from '../../dataLocators';
import { EVENT_P2P_NAMES_CONFIG } from '../../../../constants/analyticsConstants/ProcureToPay';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import useFavourites from '../../hooks/useFavorites';
import useFailureToastNotification from '../../hooks/useFailureToastNotification';
import CreateWishListContent from '../../modules/createWishlist/components/CreateWishListContent';
import CreateWishListFooter from '../../modules/createWishlist/components/CreateWishListFooter';
import CreateWishListHeader from '../../modules/createWishlist/components/CreateWishListHeader';
import SaveToFavorites from '../../modules/saveToFavorites';
import '../../modules/createWishlist/createWishList.scss';
import '../../modules/saveToFavorites/saveToFavorites.scss';

const SideBarGlobal = () => {
    const intl = useIntl();
    const [{ actionName, selectedWishlists, favProduct }, favDispatch] = useFavouritesState();
    const showToast = useToastMessageState();
    const { addOrRemoveProductFromWishList, checkCategoryClassIsWishlist } = useFavourites();
    const [nesting, setNesting] = useState(1);
    const [submit, setSubmit] = useState(false);
    const [selectedFavLists, setSelectedFavLists] = useState([]);
    const { sendEventsForP2PCtaClick } = useAnalyticsContext();

    const useModalState = () => useContext(ModalContext);
    const { openModal, closeModal } = useModalState();
    const showFailureToastNotification = useFailureToastNotification();

    useEffect(() => {
        if (favProduct?.sku) {
            checkWishlistCategory();
        }
    }, [favProduct?.sku]);

    const checkWishlistCategory = async () => {
        try {
            const { data } = await checkCategoryClassIsWishlist({
                sku: [favProduct?.sku]
            });
            const response = data?.productDetail?.items;

            if (response?.length > 0) {
                const { wishlistIds, isFavorite } = response[0];

                if (isFavorite && wishlistIds?.length > 0) {
                    const ids = wishlistIds.map(id => +id);
                    setSelectedFavLists(ids);
                    favDispatch({ type: SET_SELECTED_WISHLISTS, payload: ids });
                }
            }
        } catch (error) {
            logError(error, false, 'checkWishlistCategory', [favProduct]);
        }
    };

    const handleSuccessfullCreateWishlist = async () => {
        setNesting(1);
        showToast({
            message: intl.formatMessage({ id: 'p2p-list-created-successfully' }),
            toastContainerClass: 'alert_save_favorites',
            toastDirection: TOAST_ANIMATION_TYPE.BOTTOM
        });
    };

    const handleNext = () => {
        setNesting(nesting => nesting + 1);
    };

    const handleBack = () => {
        setNesting(nesting => nesting - 1);
    };

    const onSubmitCreateList = () => {
        setSubmit(true);
        sendEventsForP2PCtaClick(
            EVENT_P2P_NAMES_CONFIG.P2P_CTA_CLICKED,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_NAME_CREATE_FAVORITE_LIST,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_TYPE_BUTTON,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_LOCATION_BODY,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_DESTINATION_CREATE_FAVORITE_LIST
        );
    };

    const getHeaderMessageId = () => {
        if (actionName === ACTION_NAMES.MOVE) {
            return 'p2p-move-items-to-another-list';
        } else if (actionName === ACTION_NAMES.COPY) {
            return 'p2p-copy-items-to-another-list';
        } else {
            return 'p2p-pdp-save-to-favourites';
        }
    };

    const renderHeader = () => {
        switch (nesting) {
            case 1:
                return (
                    <h2 className="create_list_fav_title" tabIndex={'0'}>
                        {intl.formatMessage({ id: getHeaderMessageId() })}
                    </h2>
                );
            case 2:
                return <CreateWishListHeader handleBack={handleBack} />;
            default:
                return (
                    <h2 className="create_list_fav_title" tabIndex={'0'}>
                        {intl.formatMessage({ id: getHeaderMessageId() })}
                    </h2>
                );
        }
    };
    const saveOnClick = () => {
        const corpAccDetails = JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.CORP_ACCOUNT_DETAILS) || '{}');
        favDispatch({ type: SET_WISHLIST_UPDATING, payload: true });
        addOrRemoveProductFromWishList({
            wishlistId: selectedWishlists,
            sku: favProduct.sku,
            qty: favProduct.qty,
            accountId: corpAccDetails?.id || ''
        })
            .then(response => {
                closeModal(SIDE_DRAWER);
                if (!response?.error) {
                    showToast({
                        message: intl.formatMessage({ id: 'p2p-item-saved-successfully-to-list' }),
                        toastContainerClass: 'alert_Save_favorites_pdp'
                    });
                } else {
                    showFailureToastNotification({
                        action: actionBarDropdownConstants.SAVE,
                        toastContainerClass: 'alert_position_bottom_2x',
                        toastDirection: TOAST_ANIMATION_TYPE.BOTTOM
                    });
                }

                const wishlistObj = response?.data?.addOrRemoveProductsFromWishlist;
                const transformedResponse = [
                    {
                        sku: favProduct.sku,
                        isFavorite: wishlistObj?.isFavorite,
                        wishlistIds: wishlistObj?.wishlistsList
                    }
                ];

                favDispatch({
                    type: SET_IS_FAVORITE,
                    payload: transformedResponse || []
                });
                favDispatch({ type: SET_CLEAR_WISHLISTS });
            })
            .catch(() => {
                logError(error, false, 'saveOnClick');
                showFailureToastNotification({
                    action: actionBarDropdownConstants.SAVE,
                    toastContainerClass: 'alert_position_bottom_2x',
                    toastDirection: TOAST_ANIMATION_TYPE.BOTTOM
                });
            })
            .finally(() => {
                favDispatch({ type: SET_WISHLIST_UPDATING, payload: false });
            });
    };
    const isSelectedWishlistIdExist = (array1, array2) => {
        if (array1.length === array2.length) {
            return array1.every(element => array2.includes(element));
        }
        return false;
    };

    const dispatchFnBasedOnActionName = () => {
        let actionType = '';
        if ([ACTION_NAMES.MOVE, ACTION_NAMES.COPY].includes(actionName) && selectedWishlists.length === 0) {
            favDispatch({ type: SET_SHOW_SAVE_FAVORITE_MODAL, payload: false });
            showToast(); /* empty callback function for disappearing the toast **/
            return;
        }
        if (actionName === ACTION_NAMES.MOVE) {
            actionType = SET_IS_MOVE_FAVORITE_BTN_CLICKED;
        } else if (actionName === ACTION_NAMES.COPY) {
            actionType = SET_IS_COPY_FAVORITE_BTN_CLICKED;
        } else if (actionName === ACTION_NAMES.SAVE) {
            sendEventsForP2PCtaClick(
                EVENT_P2P_NAMES_CONFIG.P2P_CTA_CLICKED,
                EVENT_P2P_NAMES_CONFIG.P2P_LINK_NAME_SAVE_FAVORITE,
                EVENT_P2P_NAMES_CONFIG.P2P_LINK_TYPE_BUTTON,
                EVENT_P2P_NAMES_CONFIG.P2P_LINK_LOCATION_BODY,
                EVENT_P2P_NAMES_CONFIG.P2P_LINK_DESTINATION_SAVE_FAVORITE,
                {"cat_class": favProduct?.sku}
            );
            if (isSelectedWishlistIdExist(selectedWishlists, selectedFavLists)) {
                handleFavModalToggle();
            } else {
                saveOnClick();
            }
        } else {
            handleFavModalToggle();
        }
        favDispatch({ type: actionType, payload: true });
    };

    const getFooterMessageId = () => {
        const isMoveOrCopy = [ACTION_NAMES.MOVE, ACTION_NAMES.COPY].includes(actionName);
        if (isMoveOrCopy) {
            return selectedWishlists?.length === 0 ? 'common:close' : 'common:save';
        } else {
            return isSelectedWishlistIdExist(selectedWishlists, selectedFavLists) ? 'common:close' : 'common:save';
        }
    };

    const renderfooter = () => {
        switch (nesting) {
            case 1:
                return (
                    <Button
                        type="button"
                        onClick={dispatchFnBasedOnActionName}
                        className={'button button-primary save_fav_btn'}
                        customButtonAriaLabel={intl.formatMessage({ id: getFooterMessageId() })}
                        tabindex="0">
                        {intl.formatMessage({ id: getFooterMessageId() })}
                    </Button>
                );
            case 2:
                return <CreateWishListFooter handleBack={handleBack} handleCreateList={onSubmitCreateList} />;
            default:
                return (
                    <Button
                        type="button"
                        onClick={dispatchFnBasedOnActionName}
                        className={'button button-primary save_fav_btn'}
                        dataTestid={favDataLocators.wishlist_modal_footer}
                        customButtonAriaLabel={intl.formatMessage({ id: getArialLabelId() })}
                        tabindex="0">
                        {intl.formatMessage({ id: getMessageId() })}
                    </Button>
                );
        }
    };

    const renderContent = () => {
        switch (nesting) {
            case 1:
                return <SaveToFavorites handleNextScreen={handleNext} />;
            case 2:
                return (
                    <CreateWishListContent
                        submit={submit}
                        setSubmit={setSubmit}
                        onSuccessfullCreateWishlist={handleSuccessfullCreateWishlist}
                    />
                );
            default:
                return <SaveToFavorites handleNextScreen={handleNext} />;
        }
    };

    const handleFavModalToggle = () => {
        favDispatch({ type: SET_CLEAR_WISHLISTS });
        favDispatch({ type: SET_SHOW_SAVE_FAVORITE_MODAL, payload: false });
        closeModal();
        showToast(); /* empty callback function for disappearing the toast **/
    };

    return (
        <SideDrawerModal
            modalHeaderClass={nesting === 2 && 'create-modal-header'}
            modalContentClass={nesting === 2 ? 'modal-createlist-content' : ''}
            content={renderContent()}
            header={renderHeader()}
            footer={renderfooter()}
            isModalOpen={true}
            handleModalToggle={handleFavModalToggle}
            nestIndex={nesting}
            setNestIndex={setNesting}
            showModalHeader={nesting === 1 ? true : false}
            closeButtonClass={nesting === 1 && 'cross-black'}
        />
    );
};

export default SideBarGlobal;
