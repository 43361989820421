import axiosInstance from '../main/api/axiosDotComInstance';
import { logError } from '../main/components/global/utils/logger';
import { API_CONFIG } from '../main/constants/apiConfig';

const { MKTO_ENDPOINT, MKTO_LOGIN_ENDPOINT } = API_CONFIG;

export const mktoLeadCreation = payload => {
    try {
        if (!payload) {
            return;
        }
        axiosInstance.post(MKTO_ENDPOINT, payload, {
            headers: {
                isSilentOnError: true
            }
        });
    } catch (error) {
        logError(error, false, 'mktoLeadCreation', [payload]);
        return error;
    }
};

export const mktoLogin = async payload => {
    try {
        const { data, error } = await axiosInstance.post(MKTO_LOGIN_ENDPOINT, payload, {
            headers: {
                isSilentOnError: true
            }
        });
        return { data, error };
    } catch (error) {
        logError(error, false, 'mktoLogin', [payload]);
        return error;
    }
};
export const getMktoCustomerData = async email => {
    try {
        const { data, error } = await axiosInstance.get(MKTO_LOGIN_ENDPOINT, {
            params: {
                email: `${email}`
            },
            headers: {
                isSilentOnError: true
            }
        });
        return { data, error };
    } catch (error) {
        logError(error, false, 'getMktoCustomerData', [email]);
        return error;
    }
};
