import { gql } from '@apollo/client';

const DELETE_WISHLIST = gql`
    mutation deleteWishlist (
        $wishlistId: ID!,
        $productSortBy: WishlistProductSortingEnum!, 
        $productPageNo: Int,
        $productsSize: Int,
        $accountId: Int!
    ){
        deleteWishlist(
            wishlistId: $wishlistId
            accountId: $accountId
        ) {
            status
            wishlists {
            id
            name
            visibility
            items_count
            items_v2(
                currentPage: $productPageNo
                pageSize: $productsSize
                sortBy: $productSortBy
            ) {
                items{
                    id
                    product {
                        sku
                        name
                    }
                }
                page_info {
                    current_page
                    page_size
                    total_pages
                }
            }
            }
        }
        }
`;
export default DELETE_WISHLIST;