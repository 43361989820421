import { bool, func, number, oneOfType, shape, string } from 'prop-types';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useAtp } from '../../../../cap/hooks/useATP';
import ProductTile from '../ProductTile';
import { PRODUCT_TILE_MOUNT_REACT } from '../../../utils/commonUtils';
import { useCheckAuthorityType } from '../../../../../hooks/useCheckUser';

const AddToCartProductTileReact = props => {
    const {
        cardData,
        handleClick,
        trackAnalytics,
        handleAddToCartClickAnalytics,
        isCoachmarkEnabled,
        hideUnavailable
    } = props;
    const currentNode = useRef();
    const { handleATP } = useAtp();
    const [isLoading, setIsLoading] = useState(false);
    const authorityType = useCheckAuthorityType();

    useEffect(() => {
        if (currentNode?.current) {
            currentNode.current.dataset.productdata = JSON.stringify({ catclass: cardData?.catclass });
        }
    }, [cardData?.catclass]);

    useEffect(() => {
        if (tileStatus === 'UNAVAILABLE' && hideUnavailable)
            if (currentNode?.current) {
                currentNode.current.parentElement.classList.add('hidetilewrapper');
            }
    }, [tileStatus]);

    const tileStatus = handleATP({
        catsku: cardData?.catsku,
        inventoryDataObj: cardData?.inventory,
        showonlinecatalog: cardData?.showonlinecatalog,
        disableaddtocartoption: cardData?.disableaddtocartoption,
        pcAvailability: cardData?.pc_availability
    });
    return (
        <div ref={currentNode} className={isLoading ? 'pdp__root-isloading' : PRODUCT_TILE_MOUNT_REACT}>
            <ProductTile
                tileDetails={cardData}
                tileState={tileStatus}
                handleClick={handleClick}
                trackAnalytics={trackAnalytics}
                handleAddToCartClickAnalytics={handleAddToCartClickAnalytics}
                variant={authorityType.toUpperCase()}
                isCoachmarkEnabled={isCoachmarkEnabled}
            />
        </div>
    );
};

AddToCartProductTileReact.defaultProps = {
    cardData: {
        catclass: '',
        showonlinecatalog: 'YES',
        disableaddtocartoption: 'NO',
        catsku: '',
        inventory: ''
    },
    handleClick: () => {},
    trackAnalytics: () => {},
    handleAddToCartClickAnalytics: () => {},
    isCoachmarkEnabled: true
};

AddToCartProductTileReact.propTypes = {
    cardData: shape({
        catclass: oneOfType([number, string]),
        showonlinecatalog: string,
        disableaddtocartoption: string,
        catsku: oneOfType([number, string]),
        inventory: string
    }),
    handleClick: func,
    trackAnalytics: func,
    handleAddToCartClickAnalytics: func,
    isCoachmarkEnabled: bool
};

export default memo(AddToCartProductTileReact);
