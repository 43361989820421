import { ApolloLink } from '@apollo/client';
import { cookieValue } from './cookieUtils';
import { generateCorrelationId } from '../../components/global/utils/logger';
import { getProjectsFromCookie } from '../../components/global/utils/commonUtils';
import { useCheckAuthorityType } from '../../hooks/useCheckUser';
import { AUTHORITY_TYPE } from '../../components/global/constants';
import { useAuthorityToken } from './hooks';

const authLink = new ApolloLink((operation, forward) => {
    const authorityType = useCheckAuthorityType();

    let magentoToken = cookieValue('cif.magentoToken');
    let [accessToken] = useAuthorityToken('accesstoken');

    const projectInfoCookies = getProjectsFromCookie();

    if ([AUTHORITY_TYPE.P2P, AUTHORITY_TYPE.PUNCHOUT].includes(authorityType) && !magentoToken) {
        // fallback condition for punchout
        magentoToken = sessionStorage.getItem('cif.magentoToken');
    }

    operation.setContext(({ headers }) => ({
        headers: {
            'x-correlation-Id': generateCorrelationId(),
            Authorization: `Bearer ${magentoToken}`,
            accesstoken: accessToken,
            'company-id':
                parseInt(localStorage.getItem('companyID') || projectInfoCookies?.CurrentWynneAccountCID) || 1,
            ...headers
        }
    }));

    return forward(operation);
});

export { authLink as graphqlAuthLink };
