import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CartCounter from './cartCounter';
import classes from './cartTrigger.css';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { logError } from '../../../components/global/utils/logger';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import useAnalytics from '../../../hooks/useAnalytics';
import { useCheckUser } from '../../../hooks/useCheckUser';
import { EVENT_PAGE_NAMES_CONFIG } from '../../../constants/analyticsConstants/Page';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import { useUserContext } from '../../context/UserContext';
import config from '../../../components/App/config';

const Trigger = () => {
    const { sendEventsForEcommercePage, sendEventsForPageUser } = useAnalyticsContext();
    const userType = useCheckUser();
    const [{ isProfileLoaded }] = useUserContext();
    const [{ payloadEcommerceLocationActionAnalytics }] = useAnalytics();
    const { miniCart = '' } = document.querySelector('.pdp__select__location')?.dataset || {};
    let accIcon = document.querySelector('.dropdown-account-icon') || '';
    let cartQuantity =
        (isProfileLoaded && parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.CARTTOTALQUANTITY))) || 0;

    useEffect(() => {
        if (isProfileLoaded && userType !== USER_TYPE.GUEST && accIcon) {
            accIcon.style.borderRadius = '50%';
            accIcon.style.backgroundColor = '#F2FCF5';
            accIcon.style.color = 'var(--sbrgreen-500)';
        }
    }, [userType, isProfileLoaded]);

    const sendEcommerceEvents = () => {
        try {
            sendEventsForEcommercePage(
                VARIABLE_CONFIG.ECOMMERCE_PAGE.MINICART,
                localStorage.getItem('companyID') == 2
                    ? VARIABLE_CONFIG.CURRENCY_CODE.CANADA
                    : VARIABLE_CONFIG.CURRENCY_CODE.USA
            );
            sendEventsForPageUser(EVENT_PAGE_NAMES_CONFIG.PAGE_USER, payloadEcommerceLocationActionAnalytics());
        } catch (error) {
            logError(error, false, 'sendEcommerceEvents');
        }
    };

    const handleCartIconClick = () => {
        window.location.href = window.location.origin + config?.pagePaths?.cartPage;
        try {
            sendEcommerceEvents();
        } catch (error) {
            logError(error, false, 'handleCartIconClick');
        }
    };

    return (
        <button
            className={classes.root}
            data-testid={'cart-counter'}
            aria-label={`${cartQuantity} ${cartQuantity == 1 ? 'item' : 'items'} present in the cart`}
            onClick={handleCartIconClick}>
            {miniCart ? (
                <img src={miniCart} alt="cart-icon"></img>
            ) : (
                <i
                    aria-hidden="true"
                    className={`icon icon-shopping-cart-icon ${
                        classes.icon
                    }`}></i>
            )}
            <CartCounter counter={cartQuantity} />
        </button>
    );
};

Trigger.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.shape({
        root: PropTypes.string
    })
};

export default Trigger;
