/**
 * Retrieves the product items based on filter
 * @param {Object} payload a parameters object with the following structure:
 *      getProductList - the query object to execute
 *      dispatch - the dispatch callback for the cart context
 *      skuList - array of sku items
 */

import { TILE_STATES } from '../../../components/cap/constants';
import { logError } from '../../../components/global/utils/logger';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';

export const getProductList = async payload => {
    const { productListQuery, skuList, pageSize = 20, handleATPCart } = payload;
    try {
        if (!skuList) {
            return;
        }
        const { data, error } = await productListQuery({
            variables: {
                sku: { in: skuList },
                pageSize
            },
            fetchPolicy: 'network-only',
            context: {
                headers: {
                    'company-id': parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1
                }
            }
        });
        let items = data?.products?.items || [];
        items = items.map(ele => {
            const tileStatus = handleATPCart({
                catsku: ele?.sku,
                inventoryDataObj: ele?.ec_pc_inventory,
                showonlinecatalog: ele?.showonlinecatalog,
                disableaddtocartoption: ele?.disableaddtocartoption
            });
            return {
                ...ele,
                pc_availability: tileStatus !== TILE_STATES.UNAVAILABLE
            };
        });
        if (error) {
            throw new Error(error);
        }
        return { productDetails: items, error };
    } catch (error) {
        logError(error, false, 'getProductList', [payload])
        return { productDetails: null, error };
    }
};

export const getFacetsAPI = async payload => {
    const { getFacetsMutation, url_key } = payload;
    try {
        const { data, error } = await getFacetsMutation({
            variables: {
                url_key
            },
            fetchPolicy: 'network-only'
        });

        return { data, error };
    } catch (error) {}
};
