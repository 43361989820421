import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { useUserContext } from '../../../../aem-core-components/context/UserContext';
import ArrowRight from '../../../../resources/images/arrow-right.svg';
import classes from './accountSelector.css';
import { accountDetailsDataLocator } from './dataLocators';
import { EMPLOYEE } from '../../../checkout/constants';
import { func, string } from 'prop-types';

const AccountSelector = props => {
    const { buttonAriaLabel, screenName, accountName, accountNumber, className, accountClick, accountSelectorTitle } =
        props;
    const [userState] = useUserContext();
    const { userProfile } = userState;
    const intl = useIntl();

    return (
        <button
            className={`${screenName ? classes.modalAccountSelector : classes.AccountSelector} ${className}`}
            onClick={accountClick}
            aria-label={`${intl.formatMessage({ id: 'account:ac-common-click-here-msg' })} ${buttonAriaLabel}`}
            data-testid={accountDetailsDataLocator.accountSelector_test_id}>
            <div className={classes.accountSelectorWrapper}>
                {!props?.accountSelectorTitle ? (
                    <>
                        <p
                            className={
                                userProfile?.type != EMPLOYEE
                                    ? classes.bodyp
                                    : `${classes.bodyp} ${classes.employeeAccount}`
                            }>
                            {accountName}
                        </p>
                        <small className={userProfile?.type != EMPLOYEE ? classes.bodysmallp : classes.employeeAccount}>
                            Account number: {accountNumber}
                        </small>
                    </>
                ) : (
                    accountSelectorTitle
                )}
            </div>
            <div className={classes.bodyIcon}>{userProfile?.type != EMPLOYEE && <ArrowRight />}</div>
        </button>
    );
};

AccountSelector.propTypes = {
    buttonAriaLabel: string,
    screenName: string,
    accountName: string,
    accountNumber: string,
    className: string,
    accountSelectorTitle: string,
    accountClick: func
};

AccountSelector.defaultProps = {
    buttonAriaLabel: '',
    screenName: '',
    accountName: '',
    accountNumber: '',
    className: '',
    accountSelectorTitle: '',
    accountClick: () => {}
};

export default memo(AccountSelector);
