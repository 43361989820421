import React from 'react';
import PropTypes from 'prop-types';
import ArrowLeftSvg from '../../../../resources/images/arrow-ios-left.svg';
import { mobileViewDataLocators } from './dataLocators';

export default function Header(props) {
    const { handleBtnClick, title } = props;
    return (
       
            <button className="button" onClick={handleBtnClick} data-testid={mobileViewDataLocators.mobile_view_back_link}>
                <ArrowLeftSvg />
                {title}
            </button>
       
    );
}

Header.propTypes = {
    title: PropTypes.string,
    handleBtnClick: PropTypes.func
};

Header.defaultProps = {
    title: 'Back',
    handleBtnClick: () => {}
};
