import { gql } from '@apollo/client';

export const GET_WISHLISTS = gql`
    query getWishlists(
        $wishlistPageNo: Int
        $wishlistPageSize: Int
        $productPageNo: Int
        $productsSize: Int
        $sortBy: WishlistSortingEnum!
        $productSortBy: WishlistProductSortingEnum!
        $accountId: Int!
    ) {
        customer {
            wishlists(currentPage: $wishlistPageNo, pageSize: $wishlistPageSize, sortBy: $sortBy, accountId: $accountId) {
                id
                name
                visibility
                items_count
                updated_at
                items_v2(currentPage: $productPageNo, pageSize: $productsSize, sortBy: $productSortBy) {
                    items {
                        id
                        product {
                            sku
                            name
                            thumbnail {
                                url
                            }
                        }
                    }
                    page_info {
                        current_page
                        page_size
                        total_pages
                    }
                }
            }
        }
    }
`;

export const GET_WISHLIST_NAMES = gql`
    query getWishlists($wishlistPageNo: Int, $wishlistPageSize: Int, $sortBy: WishlistSortingEnum!, $accountId: Int!) {
        customer {
            wishlists(currentPage: $wishlistPageNo, pageSize: $wishlistPageSize, sortBy: $sortBy, accountId: $accountId) {
                id
                name
            }
        }
    }
`;
