import React, { memo } from 'react';
import { array, string } from 'prop-types';
import { useIntl } from 'react-intl';
import ExpandableAlert from '../expandableAlert';
import CheckmarkCircle from '../../../../resources/images/checkmark-circle-2.svg';
import './itemAvailabilityAtStoreAlert.scss';
import { itemAvailabilityDataLocator } from './dataLocator';

const ItemAvailabilityAtStoreAlert = ({ unavailableItems, className }) => {
    const intl = useIntl();

    const getUnavailableItemList = () => (
        <ul data-testid={itemAvailabilityDataLocator.itemavailability_items_unavailable_alert_expanded}>
            {unavailableItems?.map(item => (
                <li key={item?.name}>{item?.name}</li>
            ))}
        </ul>
    );

    const getAlertTitle = () =>
        `${unavailableItems?.length} ${
            unavailableItems?.length === 1
                ? intl.formatMessage({ id: 'common:item-is' })
                : intl.formatMessage({ id: 'common:items-are' })
        } ${intl.formatMessage({ id: 'items-availability:unavailable' })}`;

    const renderUnavailableItems = () => {
        return (
            <ExpandableAlert
                role="none"
                title={getAlertTitle}
                expandableContent={getUnavailableItemList}
                dataTestId={itemAvailabilityDataLocator.itemavailability_items_unavailable_alert}
            />
        );
    };

    const renderAllItemsAvailable = () => (
        <div
            className={`items-available-alert ${className}`}
            data-testid={itemAvailabilityDataLocator.itemavailability_items_available}>
            <CheckmarkCircle />
            {intl.formatMessage({ id: 'items-availability:all-items-available' })}
        </div>
    );

    return unavailableItems?.length > 0 ? renderUnavailableItems() : renderAllItemsAvailable();
};

ItemAvailabilityAtStoreAlert.propTypes = {
    unavailableItems: array,
    className: string
};

ItemAvailabilityAtStoreAlert.defaultProps = {
    unavailableItems: [],
    className: ''
};

export default memo(ItemAvailabilityAtStoreAlert);
