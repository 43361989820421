import React, { memo, useEffect, useState } from 'react';
import './ldpAlert.scss';
import { getStoreDistances } from './api/getLocationDistanceApi';
import { USER_TYPE } from '../../constants/userDetailsConstants';
import { useCheckUser } from '../../hooks/useCheckUser';
import { useFilterState } from '../cap';
import AlertTriangleYellow from '../../resources/images/alert-triangle-yellow-small.svg';
import { ENV_CONFIG } from '../../constants/envConfig';

export const LdpAlert = () => {
    const [{ projectDetails }] = useFilterState();
    const [distance, setDistance] = useState(0);
    const userType = useCheckUser();
    const isCreditCustomer = userType === USER_TYPE.CREDIT;
    const maxDistance = Number(ENV_CONFIG.LDP_ALERT_MAX_DISTANCE);
    const alertMessage = ENV_CONFIG.LDP_ALERT_MESSAGE;
    useEffect(() => {
        const callDistanceApi = async () => {
            if (isCreditCustomer && projectDetails?.selectedProjectLatititude) {
                const destinationLatitudeElement = document?.getElementById('destinationLatitude');
                const destinationLongitudeElement = document?.getElementById('destinationLongitude');
                const destinationLatitude = destinationLatitudeElement?.value ? parseFloat(destinationLatitudeElement?.value) : null;
                const destinationLongitude = destinationLongitudeElement?.value ? parseFloat(destinationLongitudeElement?.value) : null;
                const destLatLong = { lat: destinationLatitude, long: destinationLongitude };
                const sourceLatLong = {
                    lat: projectDetails?.selectedProjectLatititude,
                    long: projectDetails?.selectedProjectLongitude
                };
                if (sourceLatLong?.lat && destLatLong?.lat) {
                    const { data, error } = await getStoreDistances(sourceLatLong, destLatLong);
                    setDistance(Number(data?.[0]?.distance));
                }
            }
        };
        callDistanceApi();
    }, [projectDetails?.selectedProjectLatititude, isCreditCustomer]);
    if (distance > maxDistance) {
        return (
            <div class="location__branch-alert" tabindex="0" data-testid="ldp_alert_message">
                <AlertTriangleYellow />
                {alertMessage}
            </div>
        );
    }
};

export default memo(LdpAlert);
