import { getProductId } from "../../components/pdp/utils/pdpUtils";

export const initialPdpState = {
    rates: {},
    item: {
        qty: 1
    },
    isRatesLoading: getProductId() ? true : false,
    alternateInventory: [],
    parentLink: ''
};