import React, { memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import './punchoutBanner.scss';
import { isP2PWithoutIframe } from '../../../../hooks/useCheckUser';

const PunchoutBanner = () => {
    const intl = useIntl();
    const checkIsP2PWithoutIframe = isP2PWithoutIframe();
    useEffect(() => {
        const bodyTag = document.querySelector('body');
        if (checkIsP2PWithoutIframe) {
            bodyTag.classList.add('body_p2p');
        } else {
            bodyTag.classList.remove('body_p2p');
        }
    }, []);


    return (checkIsP2PWithoutIframe && 
        <div className="punchout_banner">
            <div className="container">
                <div className="punchout_banner_header">
                    <div className="punchout_banner_header_text">
                        <p>
                            {intl.formatMessage({ id: 'punchout:punchout-banner-text' })}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(PunchoutBanner);
