import React, { createContext, useContext, useMemo, useRef, useState } from 'react';
import { TOAST_ANIMATION_CLASSES, TOAST_ANIMATION_TYPE } from '../../../favorites/constants';
import Alert from '../../atoms/alert/alert';
import '../../atoms/alert/alert.scss';
import { ALERT_SUCCESS } from '../../constants';

const FADEIN_TIMEOUT = 500;
const FADEOUT_TIMEOUT = 300;

export const ToastContext = createContext();

const initialState = {
    message: '',
    type: 'success',
    showCloseButton: true,
    showLink: false,
    customClass: 'alert_success alert_large',
    icon: ALERT_SUCCESS,
    linkLabel: '',
    toastContainerClass: '',
    toastDirection: TOAST_ANIMATION_TYPE.RIGHT,
    linkUrl: ''
};

const getAnimationClasses = (toastDirection) => {
    if(toastDirection === TOAST_ANIMATION_TYPE.RIGHT) { //when animation is at page level it should slide in from right
        const { PAGE_TOAST_SLIDE_IN_CLASS, PAGE_TOAST_SLIDE_OUT_CLASS } = TOAST_ANIMATION_CLASSES;
        return {
            slideInClass: [PAGE_TOAST_SLIDE_IN_CLASS],
            slideOutClass: [PAGE_TOAST_SLIDE_OUT_CLASS]
        }
    }
    else {//when animation is at Drawer level it should slide in from bottom
        const { DRAWER_TOAST_SLIDE_IN_CLASS, DRAWER_TOAST_SLIDE_OUT_CLASS } = TOAST_ANIMATION_CLASSES;
        return {
            slideInClass: [DRAWER_TOAST_SLIDE_IN_CLASS, "alert_in_drawer"],
            slideOutClass: [DRAWER_TOAST_SLIDE_OUT_CLASS, "alert_in_drawer"]
        }
    }
}

export const ToastProvider = ({ children }) => {
    const [toast, setToast] = useState();
    const toastRef = useRef();
    const timer = useMemo(() => {
        if (toast) {
            const { slideInClass, slideOutClass } = getAnimationClasses(toast.toastDirection);
            const additionalClasses = toast.toastContainerClass ? toast.toastContainerClass.split(" ") : [];

            toastRef.current.classList.add(...slideInClass, ...additionalClasses);
            return setTimeout(() => {
                toastRef.current.classList.remove(...slideInClass, ...additionalClasses);
                toastRef.current.classList.add(...slideOutClass, ...additionalClasses);
                setTimeout(() => {
                    setToast(null);
                    toastRef.current.classList.remove(...slideOutClass, ...additionalClasses);
                }, FADEOUT_TIMEOUT);
            }, toast.duration || 7000);
        }
        else {
            toastRef.current && (toastRef.current.className = "alert_position");
        }
    }, [toast]);

    const showToast = data => {
        if (data && typeof data === 'object') {
            data = {
                ...initialState,
                ...data
            };
        }
        const modal = document.querySelector(".modal_content");
        if(modal) {
            //remove any inline width style if previously added
            toastRef.current.style.removeProperty("width");
            if(data?.toastDirection === TOAST_ANIMATION_TYPE.BOTTOM){
                //since we have to keep 24 px gap on both sides of toast
                const newWidth = modal.offsetWidth - (2*24); 
                toastRef.current.style.width = `${newWidth}px`;
            }
        }
        if (timer) {
            toastRef.current.classList.add('animate__fadeOut');
            clearTimeout(timer);
            setTimeout(() => {
                setToast(null);
                toastRef.current.classList.remove('animate__fadeOut');
            }, FADEOUT_TIMEOUT);
        }
        setTimeout(() => {
            setToast(data);
        }, FADEIN_TIMEOUT);
    };
    const handleClose = () => {
        setToast(null);
    };

    return (
        <ToastContext.Provider value={showToast}>
            <div
                ref={toastRef}
                className={`alert_position`}>
                {toast && (
                    <Alert
                        customClass={toast.customClass}
                        icon={toast.icon}
                        type={toast.type}
                        message={toast.message}
                        showCloseButton={toast.showCloseButton}
                        onClose={handleClose}
                        showLink={toast.showLink}
                        linkLabel={toast.linkLabel}
                        linkUrl={toast.linkUrl}
                    />
                )}
            </div>

            {children}
        </ToastContext.Provider>
    );
};

export const useToastMessageState = () => useContext(ToastContext);
