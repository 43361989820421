import { callGtm } from "../../GTM/";
var isGTMHidden = document.querySelector('meta[name="hideGTM"]')?.content;
if(isGTMHidden === "false"){
	var marketingComponents = document.querySelectorAll(".marketingComponent .cmp-button"); 
	marketingComponents.forEach((marketingComponent) => {    
		marketingComponent.addEventListener("click", function (e) { 
			var eventAction = marketingComponent.innerText;
			var eventLabel = e.target.closest('.marketingComponent').querySelector(".block-market__title").innerText;
			callGtm('uaevent', 'undefined', 'MC_banner', eventAction, eventLabel);     
		});   
	});
}
