export const checkoutDatalocator = {
    checkout_heading_testid: 'checkout_heading',
    orderdetails_Label_testid: 'oderdetials_Label',
    checkout_instorePickUpLink_testid: 'checkout_instorePickUpLink',
    checkout_roundtrip_link_testid: 'checkout_roundtrip_link',
    checkout_startDate_link_testid: 'checkout_startDate_link',
    checkout_endDate_link_testid: 'checkout_endDate_link',
    checkout_addressline_label_testid: 'checkout_address_label',
    checkout_addressline_txtfield_testid: 'checkout_address_txtfield',
    checkout_addressline2_label_testid: 'checkout_addressline2_label',
    checkout_addressline2_txtfield_testid: 'checkout_addressline2_txtfield',
    checkout_city_label_testid: 'checkout_city_label',
    checkout_city_txtfield_testid: 'checkout_city_txtfield',
    checkout_state_label_testid: 'checkout_state_label',
    checkout_state_drpdwn_testid: 'checkout_state_drpdwn',
    checkout_zipcode_label_testid: 'checkout_zipcode_label',
    checkout_zipcode_txtfield_testid: 'checkout_zipcode_txtfield',
    checkout_continue_cta_testid: 'checkout_continue_cta',
    checkout_cross_icon: 'checkout_crossIcon_CTA',
    checkout_howtogetyourorder_link_testid: 'checkout_howtogetyourorder_link',
    checkout_optionplans_link_testid: 'checkout_optionplans_link',
    checkout_payment_link_testid: 'checkout_payment_link',
    checkout_payment_heading: 'checkout_payment_heading_label',
    checkout_new_payment: 'checkout_newPayment_radiobutton',
    checkout_payment_edit: 'checkout_payment_Edit',
    checkout_OrderSummary_heading_testid: 'checkout_OrderSummary_heading',
    checkout_itemscounts_label_testid: 'checkout_itemscounts_label',
    checkout_rentals_heading_testid: 'checkout_rentals_heading',
    checkout_product_img_testid: 'checkout_product_img',
    checkout_product_title_testid: 'checkout_product_title',
    checkout_qty_label_testid: 'checkout_qty_label',
    checkout_price_label_testid: 'checkout_price_label',
    checkout_orderdetails_editlink_testid: 'checkout_orderdetails_editlink',
    checkout_instorePickUp_label_testid: 'checkout_instorePickUp_label',
    checkout_InstorePickUp_Address_label_testid: 'checkout_InstorePickUp_Address_label',
    checkout_instoreStartandEndDate_label_testid: 'checkout_instoreStartandEndDate_label',
    checkout_chooseastore_label_testid: 'checkout_chooseastore_label',
    checkout_makeaselection_drpdown_testid: 'checkout_makeaselection_drpdown',
    checkout_PickUpTimedropdown_label_testid: 'checkout_PickUpTimedropdown',
    checkout_ReturnTimedropdown_label_testid: 'checkout_ReturnTimedropdown',
    checkout_whowillpickupitems_labels_testid: 'checkout_whowillpickupitems_labels',
    checkout_iwillpickitup_radiobutton_testid: 'checkout_iwillpickitup_radiobutton',
    checkout_someoneelsewill_radiobutton_testid: 'checkout_someoneelsewill_radiobutton',
    checkout_howtogetyourorder_Continue_cta_testid: 'checkout_howtogetyourorder_Continue_cta',
    checkout_PickUpAndReturnTime_label_testid: 'checkout_PickUpAndReturnTime_label',
    checkout_rentalsstart_label_testid: 'checkout_rentalsstart_label',
    checkout_rentalsend_label_testid: 'checkout_rentalsend_label',
    checkout_pickUptime_field_testid: 'checkout_pickUptime_field',
    checkout_returnTime_field_testid: 'checkout_returnTime_field',
    checkout_firstNameofpickUpcontact_txtfield_testid: 'checkout_firstNameofpickUpcontact_txtfield',
    checkout_lastnameofpickupcontact_txtfield_testid: 'checkout_lastnameofpickupcontact_txtfield',
    checkout_phoneNumber_txtfield_testid: 'checkout_phoneNumber_txtfield',
    checkout_emailAddress_txtfield_testid: 'checkout_emailAddress_txtfield',
    checkout_doyouwanttomakechanges_heading_testid: 'checkout_doyouwanttomakechanges_heading',
    checkout_crossicon_testid: 'checkout_crossicon',
    checkout_makechangesmodal_tooltip_testid: 'checkout_makechangesmodal_tooltip',
    checkout_changesrequiredyourinformartion_labels_testid: 'checkout_changesrequiredyourinformartion_labels',
    checkout_makechanges_CTA_testid: 'checkout_makechanges_CTA',
    checkout_cancel_CTA_testid: 'checkout_cancel_CTA',
    checkout_cannotfulfillselecteddates_label_testid: 'checkout_cannotfulfillselecteddates_label',
    checkout_choose_from_availabe_dates_below_or_pick_another_store: 'checkout_pickanotherstore_label',
    checkout_choose_a_store: 'checkout_chooseastore_link',
    checkout_bestMatch: 'checkout_bestmatch_link',
    checkout_store_near_me: 'checkout_storenearme_link',
    checkout_store_radiobutton: 'checkout_store_radiobutton',
    checkout_store_name: 'checkout_storename_label',
    checkout_store_distance: 'checkout_storedistance_label',
    checkout_store_distance_label: 'checkout_storedistance_label_text',
    checkout_store_openHours: 'checkout_storeOpenHours_label',
    checkout_store_openDate: 'checkout_storeOpenDate_label',
    checkout_store_address: 'checkout_storeaddress_label',
    checkout_store_details_link: 'checkout_storedetails_link',
    checkout_store_phoneNumber: 'checkout_storephonenumber_label',
    checkout_closet_store_near_to_you_CTA: 'checkout_closetstoreneartoyou_CTA',
    checkout_choose_this_store: 'checkout_choosethisstore_CTA',
    checkout_crossIcon: 'checkout_chooseastore_crossicon',
    checkout_closet_store_to_you_label: 'checkout_closestoretoyou_label',
    checkout_accordion_rentals_name: 'checkout_accordion_rentals_name',
    checkout_accordion_rentals_qty: 'checkout_accordion_rentals_qty',
    checkout_accordion_rentals_price: 'checkout_accordion_rentals_price',

    checkout_yourdetails_link_testid: 'checkout_yourdetails_link',
    checkout_yourdetails_firstName_txtfield_testid: 'checkout_yourdetails_firstName_txtfield_testid',
    checkout_yourdetails_lastname_txtfield_testid: 'checkout_yourdetails_lastName_txtfield_testid',
    checkout_yourdetails_phoneNumber_txtfield_testid: 'checkout_yourdetails_phoneNumber_txtfield_testid',
    checkout_yourdetails_emailAddress_txtfield_testid: 'checkout_yourdetails_emailAddress_txtfield_testid',
    checkout_yourdetails_name_label_testid: 'checkout_yourdetails_name_label_testid',
    checkout_yourdetails_phoneNumber_label_testid: 'checkout_yourdetails_phoneNumber_label_testid',
    checkout_yourdetails_emailAddress_label_testid: 'checkout_yourdetails_emailAddress_label_testid',
    checkout_yourdetails_signin_your_account: 'checkout_yourdetails_signin_your_account',

    checkout_projectName_label_testid: 'checkout_projectName_label',
    checkout_accountName_label_testid: 'checkout_accountName_label',
    checkout_create_a_new_project: 'checkout_createanewproject_link',
    checkout_continue_CTA: 'checkout_continue_CTA',
    checkout_select_a_project_to_continue: 'checkout_selectaprojecttocontinue_label',
    checkout_location_overlay: 'checkout_location_heading',
    checkout_this_store_cant_fillful: 'checkout_cannotfulfillselecteddates_label',
    checkout_choose_a_store_from_available_dates: 'checkout_pickanotherstore_label',
    checkout_choose_a_project: 'checkout_chooseaproject_label',

    checkout_projectName_label_testid: 'checkout_projectName_label',
    checkout_accountName_label_testid: 'checkout_accountName_label',
    checkout_create_a_new_project: 'checkout_createanewproject_link',
    checkout_continue_CTA: 'checkout_continue_CTA',
    checkout_select_a_project_to_continue: 'checkout_selectaprojecttocontinue_label',
    checkout_location_overlay: 'checkout_location_heading',
    checkout_accessories_and_addons_title: 'checkout_accessoriesAndAddOns_heading',
    checkout_accessories_and_addons_makesureyouhave_label: 'checkout_makesureyouhave_label',
    checkout_accessories_and_addons_needhelp_label: 'checkout_needhelp_label',
    checkout_accessories_and_addons_productimage: 'checkout_consumables_productImage',
    checkout_accessories_and_addons_producttitle: 'checkout_consumables_productTitle',
    checkout_accessories_and_addons_productquantity: 'checkout_consumables_ProductQty',
    checkout_accessories_and_addons_productprice: 'checkout_consumables_productPrice',
    checkout_accessories_and_addons_itemsavailable_link: 'checkout_consumables_itemsAvailable_link',
    checkout_accessories_and_addons_skip_addingitems_cta: 'checkout_consumables_addingItemCTA',
    checkout_rental_equipment_submit: 'checkout_rental_equipment_submit',
    checkout_rental_equipment_accessories_heading: 'checkout_rental_equipment_accessories_heading',
    checkout_rental_equipment_addons_heading: 'checkout_rental_equipment_addons_heading',
    checkout_rental_equipment_heading: 'checkout_rental_equipment_heading',
    checkout_rental_equipment_close: 'checkout_rental_equipment_close',
    checkout_accessories_closeIcon: 'checkout_accessories_closeIcon',
    checkout_IUnderstand_link: 'checkout_IUnderstand_link',
    checkout_goback_link: 'checkout_goback_link',
    checkout_accessoreis_skipModal: 'checkout_accessoreis_skipModal',
    checkout_equipmentAndAccesories_link: 'checkout_equipmentAndAccesories_link',

    avs_confirmyouraddress_heading: 'avs_confirmyouraddress_heading',
    avs_crossicon_link: 'avs_crossicon_link',
    avs_subheading_text: 'avs_subheading_text',
    avs_address_label: 'avs_address_label',
    avs_edit_link: 'avs_edit_link',
    avs_addressdetails_label: 'avs_addressdetails_label',
    avs_suggestedaddress_label: 'avs_suggestedaddress_label',
    avs_addressoptionradiobutton: 'avs_addressoptionradiobutton',
    avs_updateandContinuebutton: 'avs_updateandContinuebutton',
    avs_streetAddress_text: 'avs_streetAddress_text',
    avs_address2_text: 'avs_address2_text',
    avs_city_state_text: 'avs_city_state_text',
    guest_backtocart_link_testid: 'guest_backtocart_link',
    guest_sbrlogo_testid: 'guest_sbrlogo',
    guest_back_to_cart_modal_close_testid: 'guest_back_to_cart_modal_close',
    guest_return_to_cart_btn_testid: 'guest_return_to_cart_btn',
    guest_back_to_cart_modal_cancel_testid: 'guest_back_to_cart_modal_cancel',
    guest_back_to_homepage_modal_close_testid: 'guest_back_to_homepage_modal_close',
    guest_return_to_homepage_btn_testid: 'guest_return_to_homepage_btn',
    guest_back_to_homepage_modal_cancel_testid: 'guest_back_to_homepage_modal_cancel',
    guest_deliveryavailable_label_testid: 'guest_deliveryavailable_label',
    guest_createFreeAccount_cta_testid: 'guest_createFreeAccount_cta',
    checkout_accessories_returnToCheckout_CTA_testid: 'checkout_accessories_returnToCheckout_CTA',
    checkout_accessories_cancel_CTA_testid: 'checkout_accessories_cancel_CTA',
    checkout_accessories_cross_icon_testid: 'checkout_accessories_cross_icon',
    checkout_optional_rpp_checkbox_testid: 'checkout_optional_rpp_checkbox',
    checkout_optional_fuel_checkbox_testid: 'checkout_optional_fuel_checkbox',
    checkout_continue_optional_cta_testid: 'checkout_continue_optional_cta',
    checkout_optional_stage_edit_testid: 'checkout_optional_stage_edit',
    checkout_transmit_now_cta_testid: 'checkout_transmit_now_cta',
    checkout_save_quote_cta_testid:'checkout_save_quote_cta',
    checkout_retry_save_cta_testid: 'checkout_retry_save_cta',
    reservation_view_rentals_cta_testid: 'reservation_RentalDetails',
    reservation_continue_exploring_cta_testid: 'reservation_ContinueExploring',
    reservation_question_call: 'reservation_QuestionCall',
    reservation_phonenumber: 'reservation_PhoneNumber',
    reservation_crossbtn: 'reservation_crossButton',
    checkout_elapsed_time_title: 'checkout_rentalStartTime_label',
    checkout_elapsed_time_text: 'checkout_chooseAnotherTime_label',
    checkout_elapsed_date_title: 'checkout_rentalStartDate_label',
    checkout_elapsed_date_text: 'checkout_chooseAnotherDate_label',
    reservation_result_icon_testid: 'reservation_resultIcon',
    reservation_resultLabel_testid: 'reservation_resultLabel',
    reservation_Number_testid: 'reservation_Number',
    reservation_failureSummary_msg_testid: 'reservation_DueToInventoryMessage',
    reservation_successSummary_msg_testid: 'reservation_successSummaryConfirmationMsg',
    reservation_warningSummary_msg_testid: 'reservation_GatheringInformation',
    checkout_tbd_i_understand_cta: 'checkout_tbd_i_understand_cta',
    checkout_tbd_modal_title: 'checkout_tbd_modal_title',
    checkout_tbd_modal_content: 'checkout_tbd_modal_content',
    checkout_tbd_modal_tooltip: 'checkout_tbd_modal_tooltip',
    submit_reservation: 'submitReservation',
    avs_updateandContinuebutton_text: 'avs_updateandContinuebutton_text',
    marketing_opt_in_message: 'marketing_opt_in_message',
    guest_signIn_cta_testid: 'guest_signIn_cta_testid',
    mobile_close_button: 'mobile_close_button',
    checkout_delivery_time_dropdown: "checkout_delivery_time_dropdown",
    checkout_pickup_time_dropdown: "checkout_pickup_time_dropdown",
    checkout_view_saved_quote_cta_testid: 'checkout_saved_quote_cta',
	checkout_go_to_home_page_cta_testid: 'checkout_go_to_home_page_cta',
    checkout_retry_cta_testid: 'checkout_retry_cta',
    checkout_helipline_number_testid: 'checkout_helipline_number',
    
};
