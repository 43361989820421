import { gql } from '@apollo/client';

export const GET_JOBSITE_FAVORITES = gql`
query getCustomerFavoriteJobsites($account_id: String!) {
  getCustomerFavoriteJobsites(account_id: $account_id) {
      accountName
      equipmentCount
      projectKey
      salesRepId
      projectId
      jobsiteId
      deliveryInstructions1
      deliveryInstructions2
      deliveryInstructions3
      deliveryInstructions4
      Location
      companyId
      isActive
      customerPO
      CJDLVY
      contact{
          name
          phone
          id
      }
      address{
          attn
          city
          line1
          line2
          state
          zip
          latitude
          longitude
          locationPC
          isGeoCoded
      }
  }
}
`;

export default GET_JOBSITE_FAVORITES;