import { logError } from "../../../components/global/utils/logger";
import { STORAGE_CONFIG } from "../../../constants/storageConfig";

export const generateLocalCartItems = (items = []) => {
    try {
        let assets = [];
        items?.forEach((item, index) => {
            const { sku = '' } = item?.product;
            const quantity = item?.quantity;
            assets.push({
                productId: sku,
                quantity,
                sequenceNumber: index
            });
        });
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.LOCAL_ASSETS_DATA, JSON.stringify(assets))
    } catch (e) {
        logError(e, false, 'generateLocalCartItems', [items])
    }
}