import { useCheckUser } from '../../../../hooks/useCheckUser';

window.addEventListener('load', event => {
    try {
        const userTypeCookie = useCheckUser();
    const createAccountSection = document.querySelector("section.ca-section");
    const userType = userTypeCookie;
    if (userType === 'guest') {
        createAccountSection?.classList.remove("hide__content");
    } else {
        createAccountSection?.classList.add("hide__content");
    }
    } catch (error) {
        console.error('An error occurred while initializing the Jquery:', error);
    } 
})