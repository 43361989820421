import moment from 'moment';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { STORE_TYPE } from '../constants';
import { logError } from '../../global/utils/logger';

/**
 * Function to get CI Tier 1 PCs
 * @param {Array} nearbypcs - An array of nearby PCs
 * @param {string} inventory - A JSON string of inventory data
 * @param {string} type - A type of store from which PC should be fetch
 * @returns {Array} ciTier1Pcs - An array of CI Tier 1 PCs
 */

/**
 * ATP structure
 * old  {\"01_0001\":{\"Inventory\":19,\"AdjATP\":9,\"ATP\":10}}

new structure:

{
  01_0001: "19|9|10",

  ...
}
below function refactored to handle below structure
 */
const getCITier1Pcs = (nearbypcs = [], inventory = '{}', type = STORE_TYPE.INVENTORY) => {
    const ciTier1Pcs = [];
    const inventoryDataObj = JSON.parse(inventory) || {};
    try {
        for (let i = 0; i < nearbypcs?.length; i++) {
            const currPc = nearbypcs[i];
            const inventoryPipeSeperatedVal = inventoryDataObj?.[currPc]?.split('|');
            if (inventoryPipeSeperatedVal?.length > 0) {
                const typeInventoryQty = getInventoryByType(inventoryPipeSeperatedVal, type);
                if (typeInventoryQty !== 0) {
                    ciTier1Pcs.push(currPc);
                }
            }
        }
        return ciTier1Pcs;
    } catch (e) {
        logError(e, false, 'getCITier1Pcs', ciTier1Pcs);
        return ciTier1Pcs;
    }
};

/**
 * Function to check if a PC is present in CI
 * @param {string} pc - The PC to check
 * @param {string} inventory - A JSON string of inventory data
 * @param {string} type - A type of store from which PC should be fetch
 * @returns {boolean} - True if the PC is present in CI, false otherwise
 */

const checkIfPcPresentCI = (pc = '', inventory = '{}', type = STORE_TYPE.INVENTORY) => {
    const inventoryDataObj = JSON.parse(inventory) || {};
    try {
        const inventoryPipeSeperatedVal = inventoryDataObj?.[pc] && inventoryDataObj?.[pc].split('|');
        if (inventoryPipeSeperatedVal && inventoryPipeSeperatedVal?.length > 0) {
            //inventoryDataObj[pc]?.[type] !== 0
            const typeInventoryQty = getInventoryByType(inventoryPipeSeperatedVal, type);
            return typeInventoryQty !== 0;
        }
        return false;
    } catch (e) {
        logError(e, false, 'checkIfPcPresentCI', inventoryDataObj?.[pc]);
        return false;
    }
};

/**
 * Function to get the difference between two dates in hours
 * @param {string} date1 - The first date
 * @param {string} date2 - The second date
 * @returns {number} - The difference between the two dates in hours
 */
const getDateDiffInHrs = (date1, date2) => {
    try {
        var currentTime = moment();
        const moment1 = moment(date1).set({
            hour: currentTime.hour(),
            minute: currentTime.minute(),
            second: currentTime.second(),
            millisecond: currentTime.millisecond()
        }); // expected date format 2023-09-01T12:00:00'
        const moment2 = moment(date2).set({
            hour: currentTime.hour(),
            minute: currentTime.minute(),
            second: currentTime.second(),
            millisecond: currentTime.millisecond()
        });
        // Calculate the time difference in milliseconds
        const timeDifference = moment1.diff(moment2, 'days');
        // Convert the time difference to days
        switch (true) {
            case timeDifference >= 3:
                return 72;
            case timeDifference >= 2:
                return 48;
            case timeDifference >= 1:
                return 24;
            default:
                return 0;
        }
    } catch (e) {
        return null;
    }
};

const getNearByPcList = () => {
    try {
        var nearbyPcs = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ATP_PC_LIST)) || [];
        return nearbyPcs;
    } catch (e) {
        return [];
    }
};

/**
 * Function to save ATP stores list
 * @param {string} catsku - The catsku to save
 * @param {Object} listDataObj - The list data object to save
 */

const saveAtpStoresList = (catsku, listDataObj = {}) => {
    const atpSessionKey = STORAGE_CONFIG.SESSION_STORAGE.ATP_STORES_CATSKU;
    const item = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ATP_STORES_CATSKU);
    try {
        const { key = '', value = [] } = listDataObj;
        const parsedItem = item ? JSON.parse(item) : {};
        parsedItem[catsku] = {
            ...parsedItem.catsku,
            [key]: value
        };
        sessionStorage.setItem(atpSessionKey, JSON.stringify(parsedItem));
    } catch (e) {
        logError(e?.message, false, 'saveAtpStoresList');
    }
};

const getInventoryByType = (inventoryArr = [], type = STORE_TYPE.INVENTORY) => {
    switch (type) {
        case STORE_TYPE.ATP:
            return Number(inventoryArr[2]);
        case STORE_TYPE.ADJATP:
            return Number(inventoryArr[1]);
        default:
            return Number(inventoryArr[0]);
    }
};

const getInventoryPcs = item => {
    try {
        const data = JSON.parse(item || '{}');
        // if ec_pc_inventory is true, this function will return empty array
        return Object.keys(data)?.filter(key => data[key][0] != 0);
    } catch (error) {
        logError(error, false, 'getInventoryPcs', item);
        return [];
    }
};

export {
    getDateDiffInHrs,
    checkIfPcPresentCI,
    getCITier1Pcs,
    getNearByPcList,
    saveAtpStoresList,
    getInventoryByType,
    getInventoryPcs
};
