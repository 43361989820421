import React, { useEffect, useRef } from 'react';
import { array, bool, func, node, string, instanceOf, oneOfType } from 'prop-types';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import { MEDIA_TYPE } from '../../../../constants/screenConstants';
import DatePickerInput from './DatePickerInput';
import RangePickerOverlay from './RangePickerOverlay';

export default function RangePickerInput({
    isOpen,
    setIsOpen,
    selectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    setSelectedStartDate,
    checkIfDayDisabled,
    startDateError,
    endDateError,
    setStartDateError,
    setEndDateError,
    minDate,
    customOnModalOpen,
    saveOnSelectDate,
    showWarning,
    setShowWarning,
    renderWarningComponent,
    isLabelVisibleInModal,
    className,
    endDateLabel,
    showOnDrawerForTablet,
    hideBackButton,
    showIconWithLabel,
    pickerOverlayClass,
    startDateLabel,
    dynamicWidth,
    isWrapperClickable,
    handleDatesOnDoneClick,
    handleResetClick,
    sendDatePickerViewAnalytics,
    sendDatePickerModalEvents,
    editViewSource,
    onEditViewSourceMatch,
    calendarDateInteraction,
    isEditView,
    handleDateEventsInteraction,
    mediaType,
    onRangePickerToggle,
    handleToggle,
    calenderTitle,
    isEndDateEmpty,
    setIsEndDateEmpty,
    isStartDateEarly,
    setIsStartDateEarly,
    rangeState,
    setRangeState
}) {
    const datePickerRef = useRef(null);

    useEffect(() => {
        if (editViewSource === VARIABLE_CONFIG.CAP_VIEW_MODE.DATE_VIEW && mediaType === MEDIA_TYPE.DESKTOP) {
            datePickerRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            handleToggle();
            onEditViewSourceMatch();
        }
    }, [editViewSource]);


    const handleSelectedDatesOnDone = () => {
       return handleDatesOnDoneClick(rangeState[0]?.startDate, rangeState[0]?.endDate);
    };
    const onResetClick = () => {
        handleResetClick();
    };

    return (
        <div className={className} ref={datePickerRef}>
            {rangeState.map(item => (
                <div key={item?.key}>
                    <DatePickerInput
                        key={item.key}
                        startDate={
                            selectedStartDate
                                ? isNaN(new Date(selectedStartDate).getDate())
                                    ? ''
                                    : new Date(selectedStartDate).toLocaleDateString('en-US', {
                                          month: 'numeric',
                                          day: 'numeric',
                                          year: '2-digit'
                                      })
                                : ''
                        }
                        endDate={
                            selectedEndDate
                                ? isNaN(new Date(selectedEndDate).getDate())
                                    ? ''
                                    : new Date(selectedEndDate).toLocaleDateString('en-US', {
                                          month: 'numeric',
                                          day: 'numeric',
                                          year: '2-digit'
                                      })
                                : ''
                        }
                        handleToggle={handleToggle}
                        isVisibleInModal={isLabelVisibleInModal}
                        inputCustomClass={'startInputCustomMargin'}
                        startDateError={startDateError}
                        endDateError={endDateError}
                        showWarning={showWarning}
                        isOpen={isOpen}
                        endDateLabel={endDateLabel}
                        showIconWithLabel={showIconWithLabel}
                        startDateLabel={startDateLabel}
                        dynamicWidth={dynamicWidth}
                        isWrapperClickable={isWrapperClickable}
                    />
                </div>
            ))}
            <RangePickerOverlay
                isOpen={isOpen}
                handleToggle={handleToggle}
                rangeState={rangeState}
                setRangeState={setRangeState}
                selectedStartDate={selectedStartDate}
                selectedEndDate={selectedEndDate}
                setSelectedEndDate={setSelectedEndDate}
                setSelectedStartDate={setSelectedStartDate}
                checkIfDayDisabled={checkIfDayDisabled}
                setStartDateError={setStartDateError}
                setEndDateError={setEndDateError}
                minDate={minDate}
                customOnModalOpen={customOnModalOpen}
                saveOnSelectDate={saveOnSelectDate}
                showWarning={showWarning}
                setShowWarning={setShowWarning}
                setIsEndDateEmpty={setIsEndDateEmpty}
                setIsStartDateEarly={setIsStartDateEarly}
                onResetClick={onResetClick}
                handleSelectedDatesOnDone={handleSelectedDatesOnDone}
                handleDateEventsInteraction={handleDateEventsInteraction}
                sendDatePickerModalEvents={sendDatePickerModalEvents}
                mediaType={mediaType}
                renderWarningComponent={renderWarningComponent}
                endDateLabel={endDateLabel}
                startDateLabel={startDateLabel}
                showOnDrawerForTablet={showOnDrawerForTablet}
                hideBackButton={hideBackButton}
                pickerOverlayClass={pickerOverlayClass}
                calendarDateInteraction={calendarDateInteraction}
                isEditView={isEditView}
                calenderTitle={calenderTitle}
            />
        </div>
    );
}

RangePickerInput.propTypes = {
    isOpen: bool,
    setIsOpen: func,
    selectedStartDate: oneOfType([instanceOf(Date), string]),
    selectedEndDate: oneOfType([instanceOf(Date), string]),
    startDateError: string,
    endDateError: string,
    setSelectedEndDate: func,
    setSelectedStartDate: func,
    setStartDateError: func,
    setEndDateError: func,
    checkIfDayDisabled: func,
    customOnModalOpen: func,
    handleDateEventsInteraction: func,
    setShowWarning: func,
    showWarning: bool,
    isLabelVisibleInModal: bool,
    className: string,
    endDateLabel: string,
    startDateLabel: string,
    showOnDrawerForTablet: bool,
    hideBackButton: bool,
    showIconWithLabel: bool,
    saveOnSelectDate: bool,
    pickerOverlayClass: string,
    dynamicWidth: bool,
    isWrapperClickable: bool,
    editViewSource: string,
    onEditViewSourceMatch: func,
    handleDatesOnDoneClick: func,
    handleResetClick: func,
    sendDatePickerViewAnalytics: func,
    sendDatePickerModalEvents: func,
    calendarDateInteraction: bool,
    isEditView: bool,
    mediaType: string,
    onRangePickerToggle: func,
    calenderTitle: string,
    isEndDateEmpty: bool,
    setIsEndDateEmpty: func,
    isStartDateEarly: bool,
    setIsStartDateEarly: func,
    rangeState: array,
    setRangeState: func,
    renderWarningComponent: node,
    handleToggle: func
};

RangePickerInput.defaultProps = {
    isOpen: false,
    setIsOpen: () => {},
    selectedStartDate: '',
    selectedEndDate: '',
    startDateError: '',
    endDateError: '',
    setSelectedEndDate: () => {},
    setSelectedStartDate: () => {},
    setStartDateError: () => {},
    setEndDateError: () => {},
    checkIfDayDisabled: () => {},
    customOnModalOpen: () => {},
    minDate: new Date(),
    handleDateEventsInteraction: () => {},
    setShowWarning: () => {},
    showWarning: false,
    isLabelVisibleInModal: false,
    className: '',
    endDateLabel: '',
    startDateLabel: '',
    showOnDrawerForTablet: false,
    hideBackButton: false,
    showIconWithLabel: false,
    saveOnSelectDate: true,
    pickerOverlayClass: '',
    dynamicWidth: false,
    isWrapperClickable: false,
    editViewSource: '',
    onEditViewSourceMatch: () => {},
    handleDatesOnDoneClick: () => {},
    handleResetClick: () => {},
    sendDatePickerViewAnalytics: () => {},
    sendDatePickerModalEvents: () => {},
    calendarDateInteraction: false,
    isEditView: false,
    mediaType: '',
    onRangePickerToggle: () => {},
    calenderTitle: '',
    iisEndDateEmpty: false,
    setIsEndDateEmpty: () => {},
    isStartDateEarly: false,
    setIsStartDateEarly: () => {},
    rangeState: array,
    setRangeState: () => {},
    renderWarningComponent: null,
    handleToggle: () => {}
};