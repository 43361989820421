import { gql } from '@apollo/client';

const VIEW_QUOTE_DETAILS = gql`
    mutation viewQuote($sfGuiQuoteId: String!) {
        viewQuote(input: { sfGuiQuoteId: $sfGuiQuoteId }) {
            status
            message
            cart {
                id
                email
                items {
                    id
                    uid
                    prices {
                        total_item_discount {
                            value
                        }
                        price {
                            value
                        }
                        discounts {
                            label
                            amount {
                                value
                            }
                        }
                    }
                    product {
                        name
                        sku
                        pc_availability
                        thumbnail {
                            url
                        }
                    }
                    quantity
                }
                prices {
                    grand_total {
                        value
                        currency
                    }
                }
            }
            data {
                companyId
                createdDate
                lastModifiedDate
                estimatedCost
                deliveryMethod
                pickUpCharge
                deliveryCharge
                status
                transmitStatus
                sfGuiQuoteId
                sfQuoteId
                rentalmanQuoteId
                branchLocationNumber
                deliveryInstructions
                currencyCode
                isEditable
                displayQuoteId
                fulfillmentType
                rentalStartDate
                estimatedEndDate
                optOutRPP
                optOutFuelCharge
                sellingChannel
                initiatingChannel
                recordType
                poNumber
                rppFees
                quoteTotalAmount
                rentalSubTotal
                purchasesSubTotal
                otherFees
                taxes
                isDelivery
                salesRepId
                distanceUnits
                distanceFromJobSite
                additionalcharges {
                    companyid
                    contractline
                    cost
                    description
                    supplierid
                }
                lastModifiedByData {
                    id
                    firstName
                    lastName
                    name
                }
                createdByData {
                    id
                    firstName
                    lastName
                    name
                }
                jobsiteData {
                    jobsiteName
                    streetAddress
                    streetAddress2
                    city
                    state
                    country
                    phone
                    zipCode
                    branch
                    jobsiteId
                    jobNumber
                    jobsiteNumber
                    latitude
                    longitude
                    rmJobsiteId
                    accessNotes2
                    accessNotes1
                }
                orderedByData {
                    id
                    firstName
                    lastName
                    email
                    phone
                    name
                }
                accountData {
                    accountId
                    accountNumber
                    displayAccountNumber
                    accountName
                    accountStatus
                    isCorpLinkAccount
                }
                siteContactData {
                    name
                    phone
                }
                branchData {
                    jobsiteName
                    branchLocationNumber
                    streetAddress
                    streetAddress2
                    city
                    state
                    zipCode
                    branch
                    jobsiteId
                    email
                    phone
                    latitude
                    longitude
                    timeZoneId
                    timeZoneName
                    name
                    fax
                    analysisRegion
                    specialties
                    specialtyTypes
                }
                products {
                    productSKU
                    sfLineItemId
                    sfLineNumber
                    totalPrice
                    quantity
                    hourRate
                    minimumRate
                    dayRate
                    weeklyRate
                    monthlyRate
                    ratesOverriden
                    miscChargeType
                    miscCharge
                    lineItemType
                    rmQuoteLineNumber
                    branchLocationNumber
                    sellingPrice
                    discountPercentage
                    isTaxable
                    hourlyBookRate
                    minimumBookRate
                    monthlyBookRate
                    unitOfMeasure
                    globalLineNumber
                    kitNumber
                    shiftDifferential
                    dateRateSource
                    weekRateSourceCode
                    monthRateSource
                    forcedItem
                    productData {
                        productType
                        productName
                        productImageURL
                        stockClass
                        itemNumber
                        isRentalItem
                        product {
                            sku
                            name
                            thumbnail {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;
export default VIEW_QUOTE_DETAILS;
