import { gql } from '@apollo/client';

const MUTATION_GET_RECOMMENDED_JOBSITES = gql`
    mutation ($accountId: String!, $recommended: String!) {
        getRecommendedJobSites(accountId: $accountId, recommended: $recommended) {
            status
            message
            recommededJobsites {
                data {
                    accountName
                    deliveryInstructions1
                    deliveryInstructions2
                    deliveryInstructions3
                    deliveryInstructions4
                    Location
                    jobsiteId
                    companyId
                    isActive
                    customerPO
                    CJDLVY
                    Rtype
                    projectId
                    contact{
                        name
                        phone
                        id
                    }
                    address{
                        attn
                        city
                        line1
                        line2
                        state
                        zip
                        latitude
                        longitude
                        locationPC
                    }
                }
            }
            lastusedjobsites {
                data {
                    accountName
                    deliveryInstructions1
                    deliveryInstructions2
                    deliveryInstructions3
                    deliveryInstructions4
                    Location
                    companyId
                    isActive
                    customerPO
                    projectId
                    contact{
                        name
                        phone
                        id
                    }
                    address{
                        attn
                        city
                        line1
                        line2
                        state
                        zip
                        latitude
                        longitude
                        locationPC
                    }
                }
            }
        }
    }
`;

export default MUTATION_GET_RECOMMENDED_JOBSITES;
