import React from 'react';
import { useIntl } from 'react-intl';
import { func, number, oneOfType, string } from 'prop-types';
import { useFilterState } from '../../../../../cap';
import { useCapUtils } from '../../../../../cap/hooks/useCapUtils';
import useCheckLocationEmpty from '../../../../../../hooks/useCheckLocationEmpty';
import Button from '../../../../atoms/button/button';
import { logError } from '../../../../utils/logger';
import ArrowRight from '../../../../../../resources/images/chevron-right-16.svg';
import { formatStoreDataToObject } from '../../../../utils/commonUtils';
import { SET_PICKUP_STORES } from '../../../../../cap/constants';

const StoreSelector = ({
    availableStores,
    selectStores,
    className,
    changeStoreRef,
    productName,
    pageType,
    btnCustomClass,
    changeStoreLabel
}) => {
    const intl = useIntl();
    const [{ pickupStores }, dispatch] = useFilterState();
    const { fetchLocationCoordinates } = useCheckLocationEmpty();
    const { getStoresData } = useCapUtils();

    const getStoresLabel = availableStores => {
        if (availableStores && parseInt(availableStores) > 1) {
            return `${availableStores} ${
                pageType === 'cart'
                    ? intl.formatMessage({ id: 'cart:product-availability-stores-text-other' })
                    : intl.formatMessage({ id: 'stores-text' })
            }`;
        } else {
            return `${availableStores} ${
                pageType === 'cart'
                    ? intl.formatMessage({ id: 'cart:product-availability-store-text-other' })
                    : intl.formatMessage({ id: 'store-text' })
            }`;
        }
    };

    const selectStoresHandler = async () => {
        try {
            if (!pickupStores) {
                selectStores(null, true);
                const { localLat, localLong } = fetchLocationCoordinates();
                const { pickupStorePcs } = await getStoresData(localLat, localLong);
                const formattedStores = formatStoreDataToObject(pickupStorePcs) || {};
                dispatch({
                    type: SET_PICKUP_STORES,
                    pickupStores: formattedStores
                });
                selectStores(formattedStores, false);
            } else {
                selectStores();
            }
        } catch (error) {
            logError(error, false, 'selectStoresHandler');
        }
    };

    const getChangeStoreLabel = () => {
        return changeStoreLabel || intl.formatMessage({ id: 'change-store' });
    };

    return (
        <>
            <Button
                btnRef={changeStoreRef}
                className={`button button-block producttile__storeselectore producttile__action ${className} ${btnCustomClass}`}
                onClick={selectStoresHandler}
                dataTestid="change_store_cta"
                buttonAriaLabel={`${intl.formatMessage({ id: 'product-availability-store-text' })} ${getStoresLabel(
                    availableStores
                )} ${intl.formatMessage({ id: 'change-store' })} for ${productName}`}>
                <span className="producttile__abailable">
                    {intl.formatMessage({
                        id: 'product-availability-store-text'
                    })}{' '}
                    <strong>{getStoresLabel(availableStores)}</strong>
                </span>

                <span className="producttile__changestore">
                    {getChangeStoreLabel()}
                    <ArrowRight />
                </span>
            </Button>
        </>
    );
};
export default React.memo(StoreSelector);

StoreSelector.defaultProps = {
    availableStores: '',
    selectStores: () => {},
    className: '',
    changeStoreRef: () => {},
    productName: '',
    changeStoreLabel: ''
};

StoreSelector.propTypes = {
    availableStores: oneOfType([number, string]),
    selectStores: func,
    className: string,
    changeStoreRef: func,
    productName: string,
    changeStoreLabel: string
};
