import React from 'react';
import { useIntl } from 'react-intl';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { bool, number, string } from 'prop-types';
import Button from '../button';
import CloseIcon from '../../../../resources/images/cross_24x24.svg';

import 'react-tooltip/dist/react-tooltip.css';
import './tooltip.scss';

const Tooltip = props => {
    const intl = useIntl();
    const { isOpen, handleTooltipClose, children, zIndex } = props;

    const TOOLTIP_STYLE = {
        backgroundColor: '#000',
        borderRadius: '0.5rem',
        width: '20.5rem',
        padding: '1rem',
        zIndex
    };

    return (
        <>
            {isOpen && (
                <ReactTooltip className="tooltip" style={TOOLTIP_STYLE} isOpen={isOpen} clickable {...props}>
                    <div className="tooltip-container">
                        <div className="tooltip-children">{children}</div>
                        <Button
                            className="tooltip-close-btn"
                            dataTestid="tooltip-close-btn"
                            onClick={handleTooltipClose}
                            buttonAriaLabel={`${intl.formatMessage({ id: 'coachmark:close-btn' })} ${children}`}>
                            <CloseIcon tabIndex={-1} ariaHidden={true} />
                        </Button>
                    </div>
                </ReactTooltip>
            )}
        </>
    );
};

Tooltip.propTypes = {
    content: string,
    place: string,
    id: string,
    anchorSelect: string,
    variant: string,
    delayShow: number,
    delayhide: number,
    hidden: bool,
    noArrow: bool,
    clickable: bool,
    isOpen: bool,
    opactity: number,
    zIndex: number,
    offset: number
};

Tooltip.defaultProps = {
    place: 'bottom',
    delayShow: 1000,
    delayHide: 300,
    zIndex: 5,
    offset: 7
};
export default Tooltip;
