import { useEffect } from 'react';
import { addItemToCart, getCartDetails } from '../../../aem-core-components/actions/cart';
import { useCartState } from '../../../contexts/cart/cartContext';
import { useUserContext } from '../../../aem-core-components/context/UserContext';
import config from '../config';
import { useAtp } from '../../cap/hooks/useATP';
import useCheckLocationEmpty from '../../../hooks/useCheckLocationEmpty';

export default ({ queries }) => {
    const {
        createCartMutation,
        addToCartMutation,
        cartDetailsQuery,
        addVirtualItemMutation,
        addBundleItemMutation,
        addSimpleAndVirtualItemMutation
    } = queries;
    const [{ isProfileLoaded }] = useUserContext();
    const [, filterDispatch] = useCartState();
    const [{ cartId, cart, isOpen, isLoading, isEditing, errorMessage }, dispatch] = useCartState();
    const { handleATPCart } = useAtp();
    const { isRentalDetailsAvailable } = useCheckLocationEmpty();

    useEffect(() => {
        async function fn() {
            if (window.location.pathname === config.pagePaths.checkoutPage) {
                await getCartDetails({
                    cartDetailsQuery,
                    dispatch,
                    cartId,
                    filterDispatch,
                    handleATPCart,
                    isRentalDetailsAvailable: isRentalDetailsAvailable()
                });
            }
        }
        if (isProfileLoaded && cartId) {
            fn();
        }
    }, [cartId, isProfileLoaded]); // Not needed
    const addItem = async event => {
        if (!event.detail) return;

        const mapper = item => {
            return {
                data: {
                    sku: item.sku,
                    quantity: parseFloat(item.quantity)
                }
            };
        };

        const bundleMapper = item => {
            return {
                ...mapper(item),
                bundle_options: item.options
            };
        };

        let physicalCartItems = event.detail.filter(item => !item.virtual).map(mapper);
        let virtualCartItems = event.detail.filter(item => item.virtual).map(mapper);
        let bundleCartItems = event.detail.filter(item => item.bundle).map(bundleMapper);

        dispatch({ type: 'open' });
        dispatch({ type: 'beginLoading' });

        let addItemFn = addToCartMutation;
        if (bundleCartItems.length > 0) {
            addItemFn = addBundleItemMutation;
        } else if (physicalCartItems.length > 0 && virtualCartItems.length > 0) {
            addItemFn = addSimpleAndVirtualItemMutation;
        } else if (virtualCartItems.length > 0) {
            addItemFn = addVirtualItemMutation;
        }

        await addItemToCart({
            createCartMutation,
            addToCartMutation: addItemFn,
            cartDetailsQuery,
            cart,
            cartId,
            dispatch,
            physicalCartItems,
            virtualCartItems,
            bundleCartItems,
            filterDispatch
        });

        dispatch({ type: 'endLoading' });
    };

    const data = { cartId, cart, isOpen, isLoading, isEditing, errorMessage };
    const api = { addItem, dispatch };

    return [data, api];
};
