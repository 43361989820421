import { gql } from '@apollo/client';

const ADD_PRODUCT_TO_WISHLIST = gql`
    mutation addProductToWishlist(
        $wishlistId: [ID!]!
        $sku: String!
        $qty: Float!
        $productPageNo: Int
        $productsSize: Int
    ) {
        addProductsToWishlist(wishlistId: $wishlistId, wishlistItems: [{ sku: $sku, quantity: $qty }]) {
            wishlists {
                id
                name
                visibility
                items_count
                items_v2(currentPage: $productPageNo, pageSize: $productsSize) {
                    items {
                        id
                        product {
                            sku
                            name
                        }
                    }
                    page_info {
                        current_page
                        page_size
                        total_pages
                    }
                }
            }
        }
    }
`;
export default ADD_PRODUCT_TO_WISHLIST;
