import { gql } from '@apollo/client';

const CREATE_PUNCHOUT_ORDER = gql`
    mutation createPunchoutOrder(
        $cart_id: String!
        $storeId: Int!
        $customerType: String!
        $driverLicenseState: String
        $driverLicenseNumber: String
        $isDelivery: Boolean!
        $deliveryInstructions: String
        $startDateTime: String!
        $endDateTime: String!
        $earliestDateTime: String!
        $optOutRPP: String!
        $optOutFuelCharge: String!
        $deliveryCharge: Float
        $orderByEmail: String!
        $areaCode: String!
        $phoneNumber: String!
        $sessionID: String
        $tokenID: String
        $tokenPvID: String
        $tokenExpirationDate: String
        $allocateAsset: Boolean!
        $channel: String!
        $address1: String
        $city: String
        $state: String
        $zip: String
        $distance: Float
        $pickUpCharge: Float
        $orderedBy: String
        $sourceSystem: String!
        $salesItems: [SalesItems]
        $products: [PunchoutProducts]!
        $companyId: Int
        $customer: Int
        $jobNumber: String
    ) {
        createPunchoutOrder(
            cart_id: $cart_id
            storeId: $storeId
            customerType: $customerType
            driverLicenseState: $driverLicenseState
            driverLicenseNumber: $driverLicenseNumber
            isDelivery: $isDelivery
            deliveryInstructions: $deliveryInstructions
            startDateTime: $startDateTime
            endDateTime: $endDateTime
            earliestDateTime: $earliestDateTime
            optOutRPP: $optOutRPP
            optOutFuelCharge: $optOutFuelCharge
            deliveryCharge: $deliveryCharge
            orderByEmail: $orderByEmail
            areaCode: $areaCode
            phoneNumber: $phoneNumber
            sessionID: $sessionID
            tokenID: $tokenID
            tokenPvID: $tokenPvID
            tokenExpirationDate: $tokenExpirationDate
            allocateAsset: $allocateAsset
            channel: $channel
            address1: $address1
            city: $city
            state: $state
            zip: $zip
            distance: $distance
            pickUpCharge: $pickUpCharge
            orderedBy: $orderedBy
            sourceSystem: $sourceSystem
            salesItems: $salesItems
            products: $products
            companyId: $companyId
            customer: $customer
            jobNumber: $jobNumber
        ) {
            status
            message
            data {
                companyId
                orderId
                assetAllocated
                allocationStatus
                productItems {
                    lineItemNumber
                    lineItemType
                    equipmentNumber
                    description
                    cost
                    quantity
                    catId
                    classId
                }
            }
        }
    }
`;

export default CREATE_PUNCHOUT_ORDER;
