import React from 'react';
import { func, number, oneOfType, shape, string } from 'prop-types';
import { useIntl } from 'react-intl';
import DefaultViewNoImage from '../atoms/noImageView/NoImageView';
import Favorites from '../atoms/favorites/Favorites';
import { productTileDataLocator } from '../../addToCartTile/dataLocators';
import { formatCatClass } from '../../../utils/commonUtils';
import { DOTCOM, P2P } from '../constants';
import FavouritesButton from '../../../../favorites/modules/favouritesButton/FavouritesButton';

const Unavailable = ({ tileDetails, accountNumber, handleAnchorClick, handleRedirectClick, variant }) => {
    const intl = useIntl();
    return (
        <div className="producttile">
            <div
                data-testid={productTileDataLocator.productTile_productFavoriteIndicator}
                className="producttile__favourite">
                {variant === DOTCOM && (
                    <Favorites
                        sku={tileDetails?.catclass}
                        accountNumber={accountNumber}
                        showFavoriteTitle={false}
                        productName={tileDetails?.producttitle}
                    />
                )}
            </div>
            <a
                className="producttile__top"
                href={tileDetails?.producturl}
                onClick={handleAnchorClick}
                aria-label={`${intl.formatMessage({ id: 'product-tile-unavailable' })} ${intl.formatMessage({ id: 'common:for' })} ${tileDetails?.producttitle}`}>
                {tileDetails?.productimageurl ? (
                    <div className="producttile__img" onClick={handleRedirectClick}>
                        <img src={tileDetails?.productimageurl} alt={tileDetails?.producttitle} height="156" className="unavailable-img" />
                        <div className="producttile__unavailable">
                            {intl.formatMessage({ id: 'product-tile-unavailable' })}
                        </div>
                    </div>
                ) : (
                    <div className="producttile__img-unavailable">
                        <DefaultViewNoImage />
                        <div className="producttile__unavailable">
                            {intl.formatMessage({ id: 'product-tile-unavailable' })}
                        </div>
                    </div>
                )}

                <div>
                    <h3 className="producttile__catclass eyebrow">{`${intl.formatMessage({
                        id: 'homepage:cat-class'
                    })} ${formatCatClass(tileDetails?.catclass)}`}</h3>
                    <h4 className="producttile__productname">{tileDetails?.producttitle}</h4>
                </div>
            </a>
            {variant === P2P && <FavouritesButton sku={tileDetails?.catclass} qty={1} productName={tileDetails?.producttitle} />}
        </div>
    );
};

export default React.memo(Unavailable);

Unavailable.defaultProps = {
    tileDetails: {
        catclass: '',
        producttitle: '',
        productimageurl: '',
        producturl: '#',
        showonlinecatalog: 'YES',
        disableaddtocartoption: 'NO',
        categoryname: '',
        catsku: '',
        catid: '',
        metaTitle: '',
        metaDescription: '',
        productAltText: ''
    },
    accountNumber: '',
    handleAnchorClick: () => {},
    handleRedirectClick: () => {}
};

Unavailable.propTypes = {
    tileDetails: shape({
        catclass: oneOfType([number, string]),
        producttitle: string,
        productimageurl: string,
        producturl: string,
        showonlinecatalog: string,
        disableaddtocartoption: string,
        categoryname: string,
        catsku: oneOfType([number, string]),
        catid: oneOfType([number, string]),
        metaDescription: string,
        metaTitle: string,
        productAltText: string
    }),
    accountNumber: string,
    handleAnchorClick: func,
    handleRedirectClick: func
};
