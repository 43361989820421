import { useCartState } from '../contexts/cart/cartContext';
import { updateCartItem } from '../aem-core-components/actions/cart';
import { SET_UPDATED_EQUIPMENT } from '../aem-core-components/actions/constants';
import useCartEstimate from '../components/App/hooks/useCartEstimate';

const useCartOptions = ({ updateCartItemMutation, cartDetailsQuery, handleATPCart }) => {
    const [{ editItem, cartId, editQuote }, dispatch] = useCartState();
    const [{ getEstimates }] = useCartEstimate();
    const updateCart = async newQuantity => {
        dispatch({ type: 'beginLoading' });

        await updateCartItem({
            cartDetailsQuery,
            updateCartItemMutation,
            cartId,
            cartItemUid: editItem.uid,
            itemQuantity: newQuantity,
            handleATPCart,
            dispatch
        });
        dispatch({ type: 'endLoading' });
    };

    const updateCartFromMinicart = async cartItems => {
        dispatch({ type: 'beginLoading' });
        const updatedItems = await updateCartItem({
            cartDetailsQuery,
            updateCartItemMutation,
            cartId,
            cartItems,
            handleATPCart,
            dispatch
        });
        dispatch({ type: 'endLoading' });
        return updatedItems;
    };

    const updateCartOnEditQuote = async () => {
        dispatch({ type: 'beginLoading' });
        await updateCartItem({
            cartDetailsQuery,
            updateCartItemMutation,
            cartId,
            cartItems: editQuote?.updatedEquipmentData,
            dispatch
        });
        dispatch({ type: SET_UPDATED_EQUIPMENT, isUpdateAvailable: false, payload: {} });
        getEstimates();
        dispatch({ type: 'endLoading' });
    };

    const removeUnavailableItems = async (unavailableItems, showLoader = true) => {
        let items = unavailableItems?.map(item => {
            return { cart_item_uid: item?.uid, quantity: 0 };
        });
        await updateCartForUnavailableItems(items, showLoader);
    };

    const updateCartForUnavailableItems = async (cartItems, showLoader) => {
        showLoader && dispatch({ type: 'beginLoading' });
        await updateCartItem({
            cartDetailsQuery,
            updateCartItemMutation,
            cartId,
            cartItems,
            handleATPCart,
            dispatch
        });

        showLoader && dispatch({ type: 'endLoading' });
    };

    const data = { editItem, cartId };
    const api = {
        dispatch,
        updateCartItem: updateCart,
        updateCartFromMinicart,
        updateCartForUnavailableItems,
        updateCartOnEditQuote,
        removeUnavailableItems
    };
    return [data, api];
};

export default useCartOptions;
