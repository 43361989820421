/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 ~ Copyright 2020 Adobe
 ~
 ~ Licensed under the Apache License, Version 2.0 (the "License");
 ~ you may not use this file except in compliance with the License.
 ~ You may obtain a copy of the License at
 ~
 ~     http://www.apache.org/licenses/LICENSE-2.0
 ~
 ~ Unless required by applicable law or agreed to in writing, software
 ~ distributed under the License is distributed on an "AS IS" BASIS,
 ~ WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 ~ See the License for the specific language governing permissions and
 ~ limitations under the License.
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
import React from 'react';
import { string, bool, shape, func } from 'prop-types';

import usePassword from './usePassword';
import mergeClasses from '../../utils/mergeClasses';

import Button from '../../../components/global/atoms/button';
import Field from '../Field';
import TextInput from '../TextInput';
import { isRequired } from '../../utils/formValidators';
import EyeOff from '../../../resources/images/eye-off-2.svg';
import Eye from '../../../resources/images/eye.svg';
import defaultClasses from './password.css';
import { useIntl } from 'react-intl';

const Password = props => {
    const {
        classes: propClasses,
        label,
        fieldName,
        isToggleButtonHidden,
        autoComplete,
        validate,
        ...otherProps
    } = props;
    const intl = useIntl();
    const talonProps = usePassword();
    const { visible, togglePasswordVisibility } = talonProps;
    const classes = mergeClasses(defaultClasses, propClasses);

    const passwordButton = !isToggleButtonHidden ? (
        <Button
            tabIndex={0}
            onClick={togglePasswordVisibility}
            type="button"
            buttonAriaLabel={otherProps.buttonAriaLabel}>
            {visible ? <EyeOff tabIndex="-1" aria-hidden={true} /> : <Eye tabIndex="-1" aria-hidden={true}/>}
        </Button>
    ) : null;

    const fieldType = visible ? 'text' : 'password';

    return (
        <Field label={label} classes={{ root: classes.root }}>
            <TextInput
                after={passwordButton}
                autoComplete={autoComplete}
                field={fieldName}
                type={fieldType}
                validate={validate}
                tabIndex={0}
                ariaLabel={intl.formatMessage({ id: 'guest_submit_reservation_passwordInput_ariaLabel' })}
                {...otherProps}
            />
        </Field>
    );
};

Password.propTypes = {
    autoComplete: string,
    classes: shape({
        root: string
    }),
    label: string,
    fieldName: string,
    isToggleButtonHidden: bool,
    validate: func
};

Password.defaultProps = {
    isToggleButtonHidden: true,
    validate: isRequired
};

export default Password;
