import { logError } from '../../../components/global/utils/logger';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { ERROR_MSG } from '../../../constants/errorMappingCodes';

/**
 * Re-fetches a customer cart.
 *
 * @param {Object} payload a parameters object with the following structure:
 *     fetchCustomerCartQuery - the query object to execute to retrieve the cart details
 *     dispatch - the dispatch callback for the user context
 */
export const resetCustomerCart = async ({ dispatch, fetchCustomerCartQuery }) => {
    const { data } = await fetchCustomerCartQuery({
        fetchPolicy: 'network-only'
    });
    const cartId = data.customerCart.id;
    dispatch({ type: 'setCartId', cartId });
};

export const signOutUser = async ({ revokeCustomerToken, setCartCookie, setUserCookie, dispatch }) => {
    try {
        await revokeCustomerToken();
        setCartCookie('', 0);
        setUserCookie('', 0);
        dispatch({ type: 'signOut' });
    } catch (error) {
        console.error('An error occurred during sign-out', error);
        dispatch({ type: 'error', error: error.toString() });
    }
};

export const getReviewDuplicatesAction = async payload => {
    const { reviewDuplicatesGQL, payloadForReviewDuplicates, accountNumber = '' } = payload;
    try {
        const { data, errors } = await reviewDuplicatesGQL({
            variables: payloadForReviewDuplicates,
            fetchPolicy: 'network-only',
            context: {
                headers: {
                    'account-id': accountNumber,
                    limit: ENV_CONFIG.JOBSITESLIMIT || 1000
                }
            }
        });

        if (data?.checkJobsiteInMagentoCache?.success && data?.checkJobsiteInMagentoCache?.data) {
            return data?.checkJobsiteInMagentoCache?.data;
        } else if (errors) {
            logError(errors?.[0]?.message || ERROR_MSG?.INTERNAL_SERVER_ERROR, false, 'getReviewDuplicatesAction', [
                payload
            ]);
        } else {
            logError(
                data?.checkJobsiteInMagentoCache?.message || 'Something went wrong in consumables API',
                false,
                'getReviewDuplicatesAction',
                [payload]
            );
        }
    } catch (error) {
        logError(error || ERROR_MSG?.INTERNAL_SERVER_ERROR, true, 'getReviewDuplicatesAction', [payload]);
    }
};
