import { bool, node, number, shape, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { useIntl } from 'react-intl';
import ArrowLeftSvg from '../../../../../resources/images/arrow-left-green.svg';
import ArrowRightSvg from '../../../../../resources/images/arrow-right-green.svg';
import Button from '../../../atoms/button/button';
import './carousel.scss';

const Carousel = props => {
    const { children, mobileResponsive, disableDotsControls, carouselWrapClasses, totalItem } = props;

    const intl = useIntl();
    const carouselRef = useRef(null);
    const carouselWrapperRef = useRef(null);

    const [dotLength, setDotLength] = useState(0);
    const [itemsToSlide, setItemsToSlide] = useState(2);

    const responsive = {
        0: {
            items: mobileResponsive.mobile
        },
        640: {
            items: mobileResponsive.smalltablet
        },
        768: {
            items: mobileResponsive.tablet
        },
        1025: {
            items: mobileResponsive.smalldesktop
        },
        1281: {
            items: mobileResponsive.desktop
        }
    };

    useEffect(() => {
        const carouselWrapperElement = carouselWrapperRef?.current;
        const nextBtnWrapper = carouselWrapperElement?.querySelector('.alice-carousel__next-btn');

        const handleNextButtonClick = event => {
            const nextBtn = carouselWrapperElement?.querySelector('.alice-carousel__next-btn > button');

            if (nextBtn?.classList?.contains('carousel__action--disabled')) {
                event?.stopPropagation();
                event?.preventDefault();
            }
        };

        nextBtnWrapper?.addEventListener('click', handleNextButtonClick);

        return () => {
            nextBtnWrapper?.removeEventListener('click', handleNextButtonClick);
        };
    }, []);

    const getDotsLength = () => {
        const carouselSlideWrap = carouselWrapperRef?.current;
        const dotsLength = carouselSlideWrap?.querySelectorAll('.alice-carousel__dots-item')?.length;
        setDotLength(dotsLength);
    };

    const handleDots = itemsInSlide => {
        const carouselSlideWrap = carouselWrapperRef?.current;
        const carouselDots = carouselSlideWrap?.querySelector('.alice-carousel__dots');
        const carouselPrevBtn = carouselSlideWrap?.querySelector('.alice-carousel__prev-btn');
        const carouselNextBtn = carouselSlideWrap?.querySelector('.alice-carousel__next-btn');
        const itemsToHide = [carouselDots, carouselPrevBtn, carouselNextBtn];

        if (carouselDots && carouselPrevBtn && carouselNextBtn) {
            itemsToHide.forEach(item => (item.style.display = totalItem > itemsInSlide ? 'flex' : 'none'));
        }
    };

    const renderDotsItem = ({ isActive, activeIndex }) => {
        return (
            <button
                type="button"
                tabIndex={isActive ? '0' : '-1'}
                aria-selected={`${isActive}`}
                aria-label={`${activeIndex + 1} of ${dotLength}`}>
                {activeIndex + 1}
            </button>
        );
    };

    const renderPrevButton = ({ isDisabled }) => {
        return (
            <Button
                tabIndex={'0'}
                className={`carousel__action ${isDisabled ? 'carousel__action--disabled' : ''}`}
                aria-disabled={isDisabled}
                onClick={slidePrev}
                buttonAriaLabel={intl.formatMessage({ id: 'carousel-previous-text' })}>
                <ArrowLeftSvg aria-hidden={true} tabIndex={'-1'} />
            </Button>
        );
    };

    const renderNextButton = ({ isDisabled }) => {
        return (
            <Button
                tabIndex={'0'}
                className={`carousel__action ${isDisabled ? 'carousel__action--disabled' : ''}`}
                aria-disabled={isDisabled}
                onClick={slideNext}
                buttonAriaLabel={intl.formatMessage({ id: 'carousel-next-text' })}>
                <ArrowRightSvg aria-hidden={true} tabIndex={'-1'} />
            </Button>
        );
    };

    const onResized = e => {
        getDotsLength();
        handleDots(e.itemsInSlide);
        setItemsToSlide(e.itemsInSlide);
    };

    const onInitialized = e => {
        getDotsLength();
        handleDots(e.itemsInSlide);
        setItemsToSlide(e.itemsInSlide);
    };

    const slidePrev = () => {
        if (carouselRef?.current) {
            const currentIndex = carouselRef?.current?.state?.activeIndex;
            let prevIndex = currentIndex - itemsToSlide;
            if (prevIndex < 0) {
                prevIndex = 0;
            }
            carouselRef.current?.slideTo(prevIndex);
        }
    };

    const slideNext = () => {
        if (carouselRef?.current) {
            const currentIndex = carouselRef?.current?.state?.activeIndex;
            const itemsInSlide = carouselRef?.current?.state?.itemsInSlide;
            let nextIndex = currentIndex + itemsToSlide;
            if (nextIndex >= totalItem) {
                nextIndex = totalItem - itemsInSlide;
            }
            carouselRef.current?.slideTo(nextIndex);
        }
    };

    return (
        <div className={`carousel__slide carousel ${carouselWrapClasses} `} ref={carouselWrapperRef}>
            <AliceCarousel
                mouseTracking
                ref={carouselRef}
                renderDotsItem={renderDotsItem}
                responsive={responsive}
                keyboardNavigation={true}
                renderPrevButton={renderPrevButton}
                renderNextButton={renderNextButton}
                disableDotsControls={disableDotsControls}
                onInitialized={onInitialized}
                onResized={onResized}>
                {children}
            </AliceCarousel>
        </div>
    );
};

Carousel.propTypes = {
    children: node,
    disableDotsControls: bool,
    carouselWrapClasses: string,
    mobileResponsive: shape({
        mobile: number,
        smalltablet: number,
        tablet: number,
        smalldesktop: number,
        desktop: number
    }),
    totalItem: number
};

Carousel.defaultProps = {
    mobileResponsive: {},
    disableDotsControls: false,
    carouselWrapClasses: ''
};

export default React.memo(Carousel);
