var productCategories = document?.querySelectorAll('.prodcat');

const toggleButtonText = (button, textBeforeClick, textAfterClick) => {
    button.textContent = button.textContent === textBeforeClick ? textAfterClick : textBeforeClick;
};

const toggleButtonAriaLabel = (button, showmorearialabel, showlessarialabel) => {
    button.ariaLabel = button.ariaLabel === showmorearialabel ? showlessarialabel : showmorearialabel;
};

productCategories.forEach(productCategory => {
    //set dataset for each productCategory
    var productCategoryDataSet = productCategory?.dataset;
    var productCategoryAction = productCategory?.querySelector('.prodcat__action');
    var { showmore = '', showless = '', showmorearialabel = '', showlessarialabel = '' } = productCategoryDataSet || {};
    productCategoryAction?.addEventListener('click', function () {
        var productCategoryActionText = productCategoryAction?.querySelector('.show-more-text');
        var productCategoryActionClosest = productCategoryAction?.closest('.prodcat__row');
        productCategoryActionClosest?.classList?.toggle('prodcat__row-show');
        toggleButtonText(productCategoryActionText, showmore, showless);
        toggleButtonAriaLabel(productCategoryAction, showmorearialabel, showlessarialabel);
    });
});
