import { useEffect, useState } from 'react';

const MEDIA_TYPE = ['MOBILE', 'TABLET', 'DESKTOP'];
const useMedia = () => {
    const queries = ['(max-width :639px)', '(max-width: 1024px)', '(min-width : 1025px)'];
    const mediaQueryLists = queries.map(q => window.matchMedia(q));

    const getValue = () => {
        const index = mediaQueryLists.findIndex(mql => mql.matches);
        return index;
    };

    const [value, setValue] = useState(getValue);
    useEffect(() => {
        const handler = () => setValue(getValue);
        try {
            mediaQueryLists?.forEach(mql => {
                if (mql.addEventListener) {
                    mql.addEventListener('change', handler);
                }
            });

            return () =>
                mediaQueryLists?.forEach(mql => {
                    if (mql.removeEventListener) {
                        mql.removeEventListener('change', handler);
                    }
                });
        } catch (e1) {
            console.error(e1);
        }
        try {
            // Safari
            mediaQueryLists?.forEach(mql => mql.addListener(handler));

            return () => mediaQueryLists?.forEach(mql => mql.removeListener(handler));
        } catch (e2) {
            console.error(e2);
        }
    }, []);

    return MEDIA_TYPE[value];
};
export default useMedia;
