window.addEventListener('load', event => {
    var seasonalCarousel = $('.seasonal__carousel');
    var aemproductCarousels = $('.aemproduct__carousel');
    try {
        seasonalCarousel?.each(function (index, element) {
            var slider = $(this);
            var prevArrowButton = slider.closest('.block--seasonal').find('.seasonal__action-prev');
            var nextArrowButton = slider.closest('.block--seasonal').find('.seasonal__action-next');
            slider?.slick({
                dots: true,
                infinite: false,
                slidesToShow: 4,
                slidesToScroll: 4,
                prevArrow: prevArrowButton,
                nextArrow: nextArrowButton,
                accessibility: false,
                responsive: [
                    {
                        breakpoint: 767.98,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    },
                    {
                        breakpoint: 639.98,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    }
                ]
            });
        });

        var seasonalCarouselItems = document?.querySelectorAll('.seasonal__carousel-item');
        var handleTabindex = function (elements) {
            [...elements]?.forEach(seasonalCarouselItem => {
                seasonalCarouselItem.tabIndex = '0';
                seasonalCarouselItem.ariaHidden = 'false';
            });
        };
        handleTabindex(seasonalCarouselItems);

        if (aemproductCarousels) {
            function toggleDotsAndActions(slick, sliderActions, slider) {
                var dotsLength = slider?.find('ul.slick-dots li').length;
                var dotsElement = $(slick.$dots);
                if (dotsLength <= 1) {
                    dotsElement.hide(); // Hide dots
                    sliderActions.hide();
                } else {
                    dotsElement.show(); // Show dots
                    sliderActions.css('display', 'flex');
                }
            }

            aemproductCarousels?.each(function (index, element) {
                var aemproductCarousel = $(this);

                var aemproductPrevArrowButton = aemproductCarousel
                    .closest('.block--aemproduct')
                    .find('.aemproduct__action-prev');

                var aemproductNextArrowButton = aemproductCarousel
                    .closest('.block--aemproduct')
                    .find('.aemproduct__action-next');

                var aemproductSliderActions = aemproductCarousel
                    .closest('.block--aemproduct')
                    .find('.aemproduct__carousel-actions');

                aemproductCarousel.on('init', function (event, slick) {
                    toggleDotsAndActions(slick, aemproductSliderActions, aemproductCarousel);
                });

                aemproductCarousel?.slick({
                    dots: true,
                    infinite: false,
                    slidesToShow: 6,
                    slidesToScroll: 6,
                    prevArrow: aemproductPrevArrowButton,
                    nextArrow: aemproductNextArrowButton,
                    responsive: [
                        {
                            breakpoint: 1024.98,
                            settings: {
                                slidesToShow: 4,
                                slidesToScroll: 4
                            }
                        },
                        {
                            breakpoint: 767.98,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3
                            }
                        },
                        {
                            breakpoint: 639.98,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2
                            }
                        }
                    ]
                });
                aemproductCarousel.on('afterChange', function (event, slick, currentSlide, nextSlide) {
                    toggleDotsAndActions(slick, aemproductSliderActions, aemproductCarousel);
                });
            });
        }
    } catch (error) {
        console.error('An error occurred while initializing the slick slider:', error);
    }
});
