import { bool, element, func, number, string } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import ReactModal from 'react-modal';
import Button from '../../../components/global/atoms/button/button';
import CloseIcon from '../../../resources/images/close.svg';
import './sideDrawerModal.scss';

const SideDrawerModal = ({
    showModalHeader,
    header,
    footer,
    content,
    isModalOpen,
    handleModalToggle,
    nestIndex,
    setNestIndex,
    closeButtonClass,
    modalContentClass,
    modalFooterClass,
    modalHeaderClass,
    modalWrapperClass,
    isOpenRight,
    closeBtnTestId,
    ariaLabel,
    hideModalFooter
}) => {
    const intl = useIntl();

    const handleNesting = () => {
        setNestIndex(nestIndex - 1);
    };

    if (!isModalOpen) return <></>;

    return (
        <>
            <ReactModal
                isOpen={isModalOpen}
                onClose={handleModalToggle}
                onRequestClose={handleModalToggle}
                className={`modal modal_sidedrawer ${modalWrapperClass} ${isOpenRight ? 'open_right' : 'open_left'}`}>
                <div className={`modal_header ${modalHeaderClass}`}>
                    {header}
                    {showModalHeader && (
                        <div>
                            {nestIndex == 2 ? (
                                <Button
                                    onClick={handleNesting}
                                    customButtonAriaLabel={intl.formatMessage({
                                        id: 'p2p-favorites:side-drawer-goback'
                                    })}></Button>
                            ) : (
                                <Button
                                    className={`popup__crossicon ${closeButtonClass}`}
                                    onClick={handleModalToggle}
                                    dataTestid={closeBtnTestId}
                                    customButtonAriaLabel={ariaLabel}>
                                    <CloseIcon aria-hidden={true} />
                                </Button>
                            )}
                        </div>
                    )}
                </div>
                <div
                    className={`modal_content modal_createlist_content ${modalContentClass} ${
                        hideModalFooter ? 'no_footer' : ''
                    }`}>
                    {content}
                </div>
                {!hideModalFooter && <div className={`modal_footer ${modalFooterClass}`}>{footer}</div>}
            </ReactModal>
        </>
    );
};

SideDrawerModal.propTypes = {
    header: element,
    footer: element,
    content: element,
    isModalOpen: bool,
    handleModalToggle: func,
    nestIndex: number,
    setNestIndex: func,
    showModalHeader: bool,
    modalContentClass: string,
    modalHeaderClass: string,
    modalWrapperClass: string,
    modalFooterClass: string,
    isOpenRight: bool,
    closeBtnTestId: string,
    ariaLabel: string,
    hideModalFooter: bool
};
SideDrawerModal.defaultProps = {
    header: <></>,
    footer: <></>,
    content: <></>,
    isModalOpen: false,
    handleModalToggle: () => {},
    nestIndex: 0,
    setNestIndex: () => {},
    showModalHeader: false,
    modalContentClass: '',
    modalHeaderClass: '',
    modalWrapperClass: '',
    modalFooterClass: '',
    isOpenRight: true,
    closeBtnTestId: '',
    ariaLabel: 'click here to close dialog',
    hideModalFooter: false
};

export default React.memo(SideDrawerModal);
