import { callGtm } from "../../GTM/";
var herosectionSlider = $(".herosection__slider");
herosectionSlider.each(function (index, element) {
    var HomeSlider = $(this);
    var HomePrevArrowButton = HomeSlider.closest(
        ".block--herosection"
    ).find(".herosection__action-prev");
    var HomwNextArrowButton = HomeSlider.closest(
        ".block--herosection"
    ).find(".herosection__action-next");
    var homeDots = HomeSlider.closest(".block--herosection").find(
        ".slick-slider-dots"
    );
    HomeSlider.slick({
        slidesToShow: 1,
        infinite: false,
        dots: true,
        appendDots: homeDots,
        prevArrow: HomePrevArrowButton,
        nextArrow: HomwNextArrowButton,
        adaptiveHeight: true
    });
    var isGTMHidden = document.querySelector('meta[name="hideGTM"]')?.content;
    if(isGTMHidden === "false"){
        var eventAction = "";
        HomwNextArrowButton.on('click', function(e){
            eventAction = "next";
        });
        
        HomePrevArrowButton.on('click', function(e){
            eventAction = "previous";
        });
        
        homeDots.on('click', function(e){
            eventAction = "dot";
        });
        
        HomeSlider.on('afterChange', function(event, slick, currentSlide){
            var eventLabel = currentSlide+1; 
            callGtm('uaevent', 'undefined', 'carousel action', eventAction, eventLabel, false);
        });
    }
});

var allHeroActions = document.querySelectorAll(".hero-slide-action");
allHeroActions.forEach((button) => {
    const { innerText } = button;
    button.addEventListener("click", function () {
      var heroSectionRow = button.closest(".herosection__row");
      var heroTitle = heroSectionRow.querySelector(".herosection__title");
      callGtm('uaevent', 'undefined', 'carousel action', innerText, heroTitle.innerText); 
    });
});