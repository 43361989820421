import { number, string } from 'prop-types';
import React from 'react';
import { shimmerDataLocators } from './dataLocator';
import './shimmer.scss';

const Shimmer = ({ heightWidthClass, numberOfRows }) => {
    const shimmerRows = Array.from({ length: numberOfRows }, (_, index) => (
        <div key={`${heightWidthClass}__${index}`} className={`shimmer ${heightWidthClass}`} aria-busy="true"></div>
    ));

    return <div data-testid={shimmerDataLocators.shimmer_row_container_test_id}>{shimmerRows}</div>;
};

Shimmer.propTypes = {
    heightWidthClass: string,
    numberOfRows: number.isRequired
};
Shimmer.defaultProps = {
    heightWidthClass: 'default_shimmer',
    numberOfRows: 1
};

export default Shimmer;
