import { func, object } from 'prop-types';
import React, { createContext, useContext, useReducer } from 'react';
import { PurchaseOrderListInitialState, purchaseOrderListReducer } from './reducers/purchaseOrderListReducer';

export const PurchaseOrderListContext = createContext();

export const PurchaseOrderListProvider = props => {
    const reducer = props.reducer || purchaseOrderListReducer;
    const initialState = props.initialState || PurchaseOrderListInitialState;

    return (
        <PurchaseOrderListContext.Provider value={useReducer(reducer, initialState)}>
            {props.children}
        </PurchaseOrderListContext.Provider>
    );
};

PurchaseOrderListProvider.propTypes = {
    reducer: func,
    initialState: object
};

export const usePurchaseOrderState = () => useContext(PurchaseOrderListContext);
