import { useMutation, useQuery } from '@apollo/client';
import { useAwaitQuery } from '../../../aem-core-components/utils/hooks';
import { SET_FAVOURITES_ERROR, SET_IS_FAVORITE, SET_WISHLIST_LIST } from '../actionTypes/actionTypes';
import { useFavouritesState } from '../context';
import ADD_REMOVE_PRODUCT_FROM_WISHLIST from '../mutations/mutation_add_or_remove_products_from_wishlist';
import ADD_PRODUCT_TO_WISHLIST from '../mutations/mutation_add_product_to_wishlist';
import ADD_WISHLIST from '../mutations/mutation_add_wishlist';
import ADD_WISHLIST_ITEM_TO_CART from '../mutations/mutation_add_wishlist_items_to_cart';
import COPY_PRODUCT_BETWEEN_WISHLIST from '../mutations/mutation_copy_products_between_wishlist';
import DELETE_WISHLIST from '../mutations/mutation_delete_wishlist';
import MOVE_PRODUCT_BETWEEN_WISHLIST from '../mutations/mutation_move_products_between_wishlists';
import REMOVE_PRODUCT_FROM_WISHLIST from '../mutations/mutation_remove_products_from_whishlist';
import UPDATE_PRODUCTS_IN_WISHLIST from '../mutations/mutation_update_products_in_wishlist';
import UPDATE_WISHLIST from '../mutations/mutation_update_wishlist';
import CHECK_IF_CAT_CLASS_FAVORITE from '../mutations/query_check_cat_class_favourite';
import GET_WISHLIST_BY_ID from '../mutations/query_get_wishlist_by_id';
import { GET_WISHLISTS, GET_WISHLIST_NAMES } from '../mutations/query_get_wishlist_list';
import { logError } from '../../global/utils/logger';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { useCartState } from '../../../contexts/cart';
import { SET_IS_CART_LOADING } from '../../../aem-core-components/actions/constants';

const useFavourites = () => {
    const getWishlistsQuery = useAwaitQuery(GET_WISHLISTS);
    const getWishlistByIdQuery = useAwaitQuery(GET_WISHLIST_BY_ID);
    // const getWishlistNamesQuery = useQuery(GET_WISHLIST_NAMES);
    const checkCategoryClassIsWishlistQuery = useAwaitQuery(CHECK_IF_CAT_CLASS_FAVORITE);

    const [addWishlistMutation] = useMutation(ADD_WISHLIST);
    const [deleteWishlistMutation] = useMutation(DELETE_WISHLIST);
    const [removeProductFromWishlistMutation] = useMutation(REMOVE_PRODUCT_FROM_WISHLIST);
    const [updateWishlistMutation] = useMutation(UPDATE_WISHLIST);
    const [addOrRemoveProductFromWishListMutation] = useMutation(ADD_REMOVE_PRODUCT_FROM_WISHLIST);
    const [updateProductsInWishlistMutation] = useMutation(UPDATE_PRODUCTS_IN_WISHLIST);
    const [addWishlistItemTocartMutation] = useMutation(ADD_WISHLIST_ITEM_TO_CART);
    const [copyProductsBetweenWishlistMutation] = useMutation(COPY_PRODUCT_BETWEEN_WISHLIST);
    const [moveProductsBetweenWishlistMutation] = useMutation(MOVE_PRODUCT_BETWEEN_WISHLIST);

    const [, dispatch] = useFavouritesState();
    const [{}, cartDispatch] = useCartState();

    const resetFavsDataInStorage = () => {
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.MY_FAVORITES_DROPDOWN_DATA, '[]');
    };

    // this function is used to get the wishlist list items
    const getWishListListItems = async payload => {
        try {
            const { data, loading, error } = await getWishlistsQuery({
                variables: payload,
                fetchPolicy: 'network-only'
            });
            dispatch({ type: SET_WISHLIST_LIST, payload: data?.customer?.wishlists || [] });
            sessionStorage.setItem(
                STORAGE_CONFIG.SESSION_STORAGE.MY_FAVORITES_DROPDOWN_DATA,
                JSON.stringify(data?.customer?.wishlists)
            );
            return { data, loading, error };
        } catch (error) {
            // Handle the error here
            logError(error, false, 'getWishListListItems');
            dispatch({ type: SET_FAVOURITES_ERROR, payload: error });
            return { data: null, loading: false, error };
        }
    };

    const getWishListById = async payload => {
        try {
            const { data, loading, error } = await getWishlistByIdQuery({
                variables: payload,
                fetchPolicy: 'network-only'
            });

            return { data, loading, error };
        } catch (error) {
            // Handle the error here
            logError(error, false, 'getWishListById');
            dispatch({ type: SET_FAVOURITES_ERROR, payload: error });
            return { data: null, loading: false, error };
        }
    };

    const checkCategoryClassIsWishlist = async payload => {
        try {
            const corpAccDetails = JSON.parse(
                localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.CORP_ACCOUNT_DETAILS) || '{}'
            );
            const { data, loading, error } = await checkCategoryClassIsWishlistQuery({
                variables: payload,
                fetchPolicy: 'network-only',
                context: {
                    headers: {
                        accountId: corpAccDetails?.id || ''
                    }
                }
            });

            return { data, loading, error };
        } catch (error) {
            // Handle the error here
            logError(error, false, 'checkCategoryClassIsWishlist');
            dispatch({ type: SET_FAVOURITES_ERROR, payload: error });
            return { data: null, loading: false, error };
        }
    };

    const checkIfProductIsFavorite = async productIdArray => {
        try {
            const corpAccDetails = JSON.parse(
                localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.CORP_ACCOUNT_DETAILS) || '{}'
            );
            const { data } = await checkCategoryClassIsWishlistQuery({
                variables: productIdArray,
                fetchPolicy: 'network-only',
                context: {
                    headers: {
                        accountId: corpAccDetails?.id || ''
                    }
                }
            });

            dispatch({
                type: SET_IS_FAVORITE,
                payload: data?.productDetail?.items || []
            });
        } catch (error) {
            logError(error, false, 'checkIfProductIsFavorite');
            return { data: null, error };
        }
    };

    // Add Wishlist or create new list
    const addWishlist = async payload => {
        try {
            const { data } = await addWishlistMutation({
                variables: payload || {},
                fetchPolicy: 'network-only'
            });
            resetFavsDataInStorage();
            return { data };
        } catch (error) {
            // Handle the error here
            logError(error, false, 'addWishlist');
            dispatch({ type: SET_FAVOURITES_ERROR, payload: error });
            return { data: null, loading: false, error };
        }
    };

    // Delete Wishlist
    const deleteWishlist = async payload => {
        try {
            const { data } = await deleteWishlistMutation({
                variables: payload || {},
                fetchPolicy: 'network-only'
            });
            resetFavsDataInStorage();
            return { data };
        } catch (error) {
            // Handle the error here
            logError(error, false, 'deleteWishlist');
            dispatch({ type: SET_FAVOURITES_ERROR, payload: error });
            return { data: null, loading: false, error };
        }
    };

    // //Rename Wishlist
    const renameList = async payload => {
        try {
            const { data } = await updateWishlistMutation({
                variables: payload || {},
                fetchPolicy: 'network-only'
            });
            resetFavsDataInStorage();
            return { data };
        } catch (error) {
            // Handle the error here
            logError(error, false, 'renameList');
            dispatch({ type: SET_FAVOURITES_ERROR, payload: error });
            return { data: null, loading: false, error };
        }
    };

    // Remove Product from wishlist
    const removeProductFromWishlist = async payload => {
        try {
            const { data } = await removeProductFromWishlistMutation({
                variables: payload || {},
                fetchPolicy: 'network-only'
            });
            resetFavsDataInStorage();
            return { data };
        } catch (error) {
            // Handle the error here
            logError(error, false, 'removeProductFromWishlist');
            dispatch({ type: SET_FAVOURITES_ERROR, payload: error });
            return { data: null, loading: false, error };
        }
    };

    const addOrRemoveProductFromWishList = async payload => {
        try {
            const { data } = await addOrRemoveProductFromWishListMutation({
                variables: payload || {},
                fetchPolicy: 'network-only'
            });
            resetFavsDataInStorage();
            return { data };
        } catch (error) {
            // Handle the error here
            logError(error, false, 'addOrRemoveProductFromWishList');
            dispatch({ type: SET_FAVOURITES_ERROR, payload: error });
            return { data: null, loading: false, error };
        }
    };

    const updateProductsInWishlist = async payload => {
        try {
            const { data } = await updateProductsInWishlistMutation({
                variables: payload || {},
                fetchPolicy: 'network-only'
            });
            resetFavsDataInStorage();
            return { data };
        } catch (error) {
            // Handle the error here
            logError(error, false, 'updateProductsInWishlist');
            dispatch({ type: SET_FAVOURITES_ERROR, payload: error });
            return { data: null, loading: false, error };
        }
    };

    const addWishlistItemTocart = async payload => {
        try {
            cartDispatch({ type: SET_IS_CART_LOADING, isCartLoading: true });
            const { data } = await addWishlistItemTocartMutation({
                variables: payload || {},
                fetchPolicy: 'network-only'
            });
            cartDispatch({ type: SET_IS_CART_LOADING, isCartLoading: false });
            return { data };
        } catch (error) {
            // Handle the error here
            logError(error, false, 'addWishlistItemTocart');
            dispatch({ type: SET_FAVOURITES_ERROR, payload: error });
            cartDispatch({ type: SET_IS_CART_LOADING, isCartLoading: false });
            return { data: null, loading: false, error };
        }
    };

    const copyProductsBetweenWishlist = async payload => {
        try {
            const { data } = await copyProductsBetweenWishlistMutation({
                variables: payload || {},
                fetchPolicy: 'network-only'
            });
            resetFavsDataInStorage();
            return { data };
        } catch (error) {
            // Handle the error here
            logError(error, false, 'copyProductsBetweenWishlist');
            dispatch({ type: SET_FAVOURITES_ERROR, payload: error });
            return { data: null, loading: false, error };
        }
    };

    const moveProductsBetweenWishlist = async payload => {
        try {
            const { data } = await moveProductsBetweenWishlistMutation({
                variables: payload || {},
                fetchPolicy: 'network-only'
            });
            resetFavsDataInStorage();
            return { data };
        } catch (error) {
            // Handle the error here
            logError(error, false, 'moveProductsBetweenWishlist');
            dispatch({ type: SET_FAVOURITES_ERROR, payload: error });
            return { data: null, loading: false, error };
        }
    };

    return {
        getWishListListItems,
        getWishListById,
        checkCategoryClassIsWishlist,
        checkIfProductIsFavorite,
        addWishlist,
        deleteWishlist,
        renameList,
        removeProductFromWishlist,
        addOrRemoveProductFromWishList,
        updateProductsInWishlist,
        addWishlistItemTocart,
        copyProductsBetweenWishlist,
        moveProductsBetweenWishlist
    };
};

export default useFavourites;
