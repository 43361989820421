import { callGtm } from "../../GTM/";
var isGTMHidden = document.querySelector('meta[name="hideGTM"]')?.content;
if(isGTMHidden === "false"){
    var contentListTeaser = document.querySelectorAll(".block--resources .cmp-button__text");
    contentListTeaser?.forEach((contentListTeaserComponent) => {
        contentListTeaserComponent?.addEventListener("click", function(e) {
            var eventAction = contentListTeaserComponent.innerText;
            var eventLabel = e.target.closest('.block--resources').querySelector(".resources-subheading").innerText; 
            callGtm('uaevent', 'undefined', 'banner title', eventAction, eventLabel);
        });
    });
}