import React, { memo, useState } from 'react';
import { func, node, string } from 'prop-types';
import AlertIcon from '../../../../resources/images/alert-triangle-red.svg';
import './expandableAlert.scss';

const ExpandableAlert = ({ role, icon, title, expandableContent, downChevronIcon, className, dataTestId }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleAlert = event => {
        event?.stopPropagation();
        setIsExpanded(!isExpanded);
    };

    return (
        <div
            role={role}
            className={`expandable-alert ${className}`}
            onClick={toggleAlert}
            data-testid={dataTestId}
            tabIndex={0}
            aria-label={title()}
            aria-expanded={isExpanded}>
            {icon || <AlertIcon className="expandable-alert__alert-icon" />}
            <div className="expandable-alert__content">
                <div>{title()}</div>
                {isExpanded && <div>{expandableContent()}</div>}
            </div>
            <div className={`expandable-alert__down-chevron-icon ${isExpanded ? 'chevron-expand' : ''}`}>
                {downChevronIcon || <i className="icon icon-chevron-down-bold" />}
            </div>
        </div>
    );
};

ExpandableAlert.propTypes = {
    role: string,
    icon: node,
    title: func,
    expandableContent: func,
    downChevronIcon: node,
    dataTestId: string,
    className: string
};

ExpandableAlert.defaultProps = {
    role: 'alert',
    icon: null,
    title: () => {},
    expandableContent: () => {},
    downChevronIcon: null,
    dataTestId: '',
    className: ''
};

export default memo(ExpandableAlert);
