import React from 'react';

const OktaBodyShimmer = () => {
    return (
        <div className="okta__signin-iframe" data-testid="oktaLoader">
            <div className="shimmer okta__content--title-shimmer"></div>
            <div className="shimmer okta__content--label-shimmer"></div>
            <div className="shimmer okta__content--input-shimmer"></div>
            <div className="shimmer okta__content--label-shimmer"></div>
            <div className="shimmer okta__content--input-shimmer"></div>
            <div className="shimmer okta__content--checkbox-shimmer"></div>
            <div className="shimmer okta__content--button-shimmer"></div>
            <div className="shimmer okta__content--action-shimmer"></div>
            <div className="shimmer okta__content--action-shimmer"></div>
            <div className="okta__content--bottom">
                <div className="shimmer okta__content--bottom-shimmer"></div>
            </div>
        </div>
    );
};

export default React.memo(OktaBodyShimmer);
