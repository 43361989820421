import moment from 'moment';
import { HOW_TO_GET_YOUR_ORDER } from '../../../../constants/cartConstants';

const useFulfillmentHooks = () => {
    const isIn24Hrs = (selectedStartDate, currentOffSet) => {
        let formattedCurrentOffSet = moment(currentOffSet);
        let formattedSelectedStartDate = moment(selectedStartDate);
        formattedSelectedStartDate = formattedSelectedStartDate.set({
            hour: formattedCurrentOffSet.get('hour'),
            minute: formattedCurrentOffSet.get('minute'),
            second: formattedCurrentOffSet.get('second'),
            millisecond: 0
        });
        const timeDiff = formattedSelectedStartDate.diff(formattedCurrentOffSet, 'seconds');
        if (timeDiff <= HOW_TO_GET_YOUR_ORDER.ONE_DAY_DIFFERENCE_IN_SECONDS) {
            return true;
        } else return false;
    };
    const isPCfulfillmentVoid = (selectedStartDate, currentOffSet, storesData) => {
        if (
            isIn24Hrs(selectedStartDate, currentOffSet) &&
            !storesData?.data?.data?.[storesData?.deliveryStoreIndex]?.isOpenOnDateOut
        ) {
            return true;
        } else if (
            isIn24Hrs(selectedStartDate, currentOffSet) &&
            storesData?.data?.data?.[storesData?.deliveryStoreIndex]?.isLastResortBranch === true
        ) {
            return true;
        } else if (
            isIn24Hrs(selectedStartDate, currentOffSet) &&
            storesData?.data?.data?.[storesData?.deliveryStoreIndex]?.fulfillmentPercent > 0 &&
            storesData?.data?.data?.[storesData?.deliveryStoreIndex]?.fulfillmentPercent < 1 &&
            !storesData?.data?.data?.[storesData?.deliveryStoreIndex]?.isLastResortBranch === true
        ) {
            return true;
        } else if (
            !isIn24Hrs(selectedStartDate, currentOffSet) &&
            !storesData?.data?.data?.[storesData?.deliveryStoreIndex]?.isOpenOnDateOut
        ) {
            return true;
        } else return false;
    };
    return { isIn24Hrs, isPCfulfillmentVoid };
};
export default useFulfillmentHooks;
