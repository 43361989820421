// ModalContext.js
import React, { createContext, useEffect, useReducer, useRef } from 'react';
import { useIntl } from 'react-intl';
import Modal from 'react-modal';
import SideBarFavLanding from '../../components/favorites/components/sideBarFavLanding/SideBarFavLanding';
import SideBarGlobal from '../../components/favorites/components/sideBarGlobal/SideBarGlobal';
import TooltipModal from '../../components/global/atoms/modal';
import AlertModal from '../../components/global/modules/modals/alertModal';
import AddedToCart from '../../components/global/modules/productTile/atoms/addedToCart/AddedToCart';
import ChooseStoreOverlay from '../../components/global/modules/productTile/atoms/chooseStoreOverlay/ChooseStoreOverlay';
import RangePickerOverlayWrapper from '../../components/global/modules/productTile/atoms/rangePickerOverlayWrapper';
import { pdpDataLocators } from '../../components/pdp/dataLocators';
import LocationOverlayModal from '../../components/pdp/location/LocationOverlayModal';
import { modalReducer } from './Modal/actions';
import {
    ADD_TO_CART,
    CHOOSE_STORE,
    CLOSE_MODAL,
    DATE_PICKER,
    LOCATION,
    OPEN_MODAL,
    SIDE_DRAWER,
    SIDE_DRAWER_CREATE_FLOW,
    TOOLTIP_MODAL,
    RENTAL_CHANGE_CONFIRMATION_MODAL
} from './Modal/constants';
import { INITIAL_STATE } from './Modal/state';
import RentalChangeConfirmationModal from '../../components/global/modules/rentalChangeConfirmationModal/RentalChangeConfirmationModal';

const ProductTileModalSelector = props => {
    const intl = useIntl();
    const { modalType, modalProps, isModalOpen } = props;

    switch (modalType) {
        case ADD_TO_CART:
            return (
                <AlertModal
                    title={intl.formatMessage({ id: 'pdp-alert-added-to-cart' })}
                    content={
                        <AddedToCart
                            handleCloseModal={modalProps?.closeModal}
                            localItemObj={modalProps?.localItemObj}
                            productId={modalProps?.productId}
                            error={modalProps?.error}
                        />
                    }
                    handleCloseModal={modalProps?.closeModal}
                    isAddToCart={true}
                    isModalOpen={isModalOpen}
                    titleDataLocator={pdpDataLocators.added_to_cart_product_title}
                    closeIconDataLocator={pdpDataLocators.added_to_cart_close_icon}
                />
            );
        case LOCATION:
            return (
                <LocationOverlayModal
                    showLocationModal={isModalOpen}
                    handleLocationOverlay={props?.handleLocationOverlay}
                />
            );
        case SIDE_DRAWER:
            return <SideBarGlobal />;
        case SIDE_DRAWER_CREATE_FLOW:
            return <SideBarFavLanding />;
        case DATE_PICKER:
            return (
                <RangePickerOverlayWrapper
                    isOpen={isModalOpen}
                    minAvailableDate={modalProps?.minAvailableDate}
                    handleCloseModal={modalProps?.closeModal}
                    showOnDrawerForTablet={modalProps?.showOnDrawerForTablet}
                    hideBackButton={modalProps?.hideBackButton}
                    pickerOverlayClass={modalProps?.pickerOverlayClass}
                    showAlertModal={modalProps?.showAlertModal}
                    isDateModalOpen={modalProps?.isDateModalOpen}
                />
            );
        case CHOOSE_STORE:
            return (
                <ChooseStoreOverlay
                    isOpen={isModalOpen}
                    availableStores={modalProps?.availableStores}
                    closeStoreModal={modalProps?.closeModal}
                    startingDate={modalProps?.startingDate}
                    showAvailableStartDate={modalProps?.showAvailableStartDate}
                    showInDrawerForTablet={modalProps?.showInDrawerForTablet}
                    showDateModal={modalProps?.showDateModal}
                    showMonochromaticMap={modalProps?.showMonochromaticMap}
                    showAlertModal={modalProps?.showAlertModal}
                    isDateModalOpen={modalProps?.isDateModalOpen}
                    showShimmer={modalProps?.showShimmer}
                />
            );
        case RENTAL_CHANGE_CONFIRMATION_MODAL:
            const handleRentalConfirmationClose = (isAccepted, isCloseClicked, isBackClicked) => {
                /* To close the modal when user clicks on back or close button */
                modalProps?.closeModal();
                modalProps?.handleConfirmation?.(isAccepted, isCloseClicked, isBackClicked);
            };
            return (
                <RentalChangeConfirmationModal
                    isOpen={isModalOpen}
                    productList={modalProps?.productList}
                    handleModalClose={() => handleRentalConfirmationClose(false, true)}
                    handleBackNavigation={() => handleRentalConfirmationClose(false, false, true)}
                    handleConfirmation={handleRentalConfirmationClose}
                />
            );
        case TOOLTIP_MODAL:
            return <TooltipModal {...modalProps} />;
        default:
            null;
    }
};

const ModalContext = createContext();

const ModalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(modalReducer, INITIAL_STATE);
    const modalContentRef = useRef(null);

    useEffect(() => {
        Modal.setAppElement('.root');
    }, []);

    const openModal = (modalType, modalProps) => {
        dispatch({ type: OPEN_MODAL, modalType, modalProps: { ...modalProps, closeModal } });
    };

    const closeModal = () => {
        dispatch({ type: CLOSE_MODAL });
        document.body.classList.remove('overflow-hidden');
    };

    const handleLocationOverlay = close => {
        if (close) {
            openModal(LOCATION, {});
        } else {
            closeModal();
        }
    };

    return (
        <ModalContext.Provider value={{ ...state, openModal, closeModal, modalContentRef }}>
            {children}
            <ProductTileModalSelector {...state} handleLocationOverlay={handleLocationOverlay} />
        </ModalContext.Provider>
    );
};

export { ModalContext, ModalProvider };
