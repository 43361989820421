import React from 'react';
import { bool, node, oneOfType, shape, string, any } from 'prop-types';

import classes from './field.css';

const Field = props => {
    const { required, children, htmlFor, label, lbltestid, errorMsg, fieldCustomClass } = props;
    return (
        <div className={`${classes.root} ${fieldCustomClass && fieldCustomClass}`}>
            {label && (
                <label htmlFor={htmlFor}>
                    <span className={errorMsg ? classes.errorLabel : classes.label} data-testid={lbltestid}>
                        {label}
                        {required && <span className={classes.requiredSymbol} />}
                    </span>
                </label>
            )}
            {children}
        </div>
    );
};

Field.propTypes = {
    children: node,
    classes: oneOfType([
        shape({
            label: string,
            root: string
        }),
        any
    ]),
    label: node,
    required: bool,
    htmlFor: string,
    fieldCustomClass: string
};

Field.defaultProps = {
    children: '',
    classes: shape({
        label: '',
        root: ''
    }),
    label: '',
    required: false,
    htmlFor: '',
    fieldCustomClass: ''
};
export default Field;
