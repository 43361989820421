import { gql } from '@apollo/client';

const FETCH_JOBSITES_FOR_SEARCH = gql`
    mutation getSbrJobSitesForSearch(
        $accountId: String!
    ) {
        getSbrJobSitesForSearch(
            accountId: $accountId
        ) {
            status
        }
    }
`;

export default FETCH_JOBSITES_FOR_SEARCH;

