import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ENV_CONFIG } from '../../../../constants/envConfig';
import AlertTriangle from '../../../../resources/images/alert-triangle.svg';
import AlertModal from '../../modules/modals/alertModal/AlertModal';
import Alert from '../alert/alert';
import './HigherFeeWarningAlert.scss';

const HigherFeeWarningAlert = ({ className, productName }) => {
    const intl = useIntl();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const title = ENV_CONFIG.HIGHER_FEE_WARNING || intl.formatMessage({ id: 'higher-fee-warning' });
    const description =
        ENV_CONFIG.HIGHER_FEE_WARNING_DESCRIPTION || intl.formatMessage({ id: 'higher-fee-tooltip-description' });

    const handleClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const getMessageContent = () => {
        return (
            <p className="higher_fee_warning_learn_more">
                {title}
                <button 
                    className='higher_fee_warning_learn_more_button' 
                    aria-label={`Learn more as Fees may be higher for ${productName || ''}`} 
                    onClick={handleClick}
                    >
                        {intl.formatMessage({ id: 'higher-fee-warning-learn-more' })}
                    </button>
            </p>
        );
    };
    return (
        <div className={`higher_fee_warning ${className || ''}`}>
            <AlertModal
                isModalOpen={isModalOpen}
                handleCloseModal={handleCloseModal}
                title={intl.formatMessage({ id: 'higher-fee-tooltip-title' })}
                content={description}
            />
            <Alert
                noRole
                tabIndex="-1"
                icon={<AlertTriangle aria-hidden={true} tabIndex={'-1'} />}
                type={'warning'}
                message={getMessageContent()}
            />
        </div>
    );
};

export default HigherFeeWarningAlert;
