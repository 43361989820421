import { func, number, oneOfType, shape, string } from 'prop-types';
import React from 'react';
import BaseProductTile from '../BaseProductTile';
import StoreSelector from '../atoms/storeSelector/StoreSelector';

const ChangeDatesAndStoreGrt72Hrs = ({
    tileDetails,
    dailyProductRate,
    accountNumber,
    handleAnchorClick,
    handleRedirectClick,
    showAvailableStores,
    ...restProps
}) => {
    const { availableStores, selectStores } = showAvailableStores || {};

    const footerContent = () => {
        return (
            <>
                <StoreSelector
                    availableStores={availableStores}
                    selectStores={selectStores}
                    productName={tileDetails?.producttitle}
                    btnCustomClass={'productTile-hover'}></StoreSelector>
            </>
        );
    };
    return (
        <BaseProductTile
            productUrl={tileDetails?.producturl}
            productImg={tileDetails?.productimageurl}
            catClass={tileDetails?.catclass}
            productName={tileDetails?.producttitle}
            dailyProductRate={dailyProductRate}
            accountNumber={accountNumber}
            footerContent={footerContent}
            handleAnchorClick={handleAnchorClick}
            handleRedirectClick={handleRedirectClick}
            productAltText={tileDetails.productAltText}
            {...restProps}></BaseProductTile>
    );
};

export default React.memo(ChangeDatesAndStoreGrt72Hrs);

ChangeDatesAndStoreGrt72Hrs.defaultProps = {
    tileDetails: {
        catclass: '',
        producttitle: '',
        productimageurl: '',
        producturl: '#',
        showonlinecatalog: 'YES',
        disableaddtocartoption: 'NO',
        categoryname: '',
        catsku: '',
        catid: '',
        metaTitle: '',
        metaDescription: ''
    },
    dailyProductRate: '',
    handleAnchorClick: () => {},
    handleRedirectClick: () => {}
};

ChangeDatesAndStoreGrt72Hrs.propTypes = {
    tileDetails: shape({
        catclass: oneOfType([number, string]),
        producttitle: string,
        productimageurl: string,
        producturl: string,
        showonlinecatalog: string,
        disableaddtocartoption: string,
        categoryname: string,
        catsku: oneOfType([number, string]),
        catid: oneOfType([number, string]),
        metaDescription: string,
        metaTitle: string
    }),
    dailyProductRate: string,
    handleAnchorClick: func,
    handleRedirectClick: func
};
