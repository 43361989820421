import { gql } from '@apollo/client';

const GET_FAVORITES = gql`
    mutation getFavorites($accountNumber: Int!) {
        getFavorites(accountNumber: $accountNumber) {
            status
            message
            items_count
            items {
                favoriteID
                product {
                    sku
                    meta_title
                    meta_description
                }
            }
        }
    }
`;
export default GET_FAVORITES;