import { gql } from '@apollo/client';

const GET_BUYER_ACCOUNT = gql`
    mutation getBuyerAccount($accessToken: String!) {
        getBuyerAccount(accessToken: $accessToken) {
            status
            data {
                email
                firstName
                lastName
                magentoToken
            }
        }
    }
`;
export default GET_BUYER_ACCOUNT;
