export const capDataLocator = {
    button_cta_testid: 'cap_button_cta',
    edit_cta_testid: 'cap_edit_cta',
    set_rental_location_testid: 'cap_set_rental_location',
    set_rental_date_testid: 'cap_set_rental_date',
    renting_button_cta_testid: 'renting_button_cta_testid',
    apply_button_cta_testid: 'apply_button_cta_testid',
    rental_location_input_testid: 'cap_rental_location_input_testid',
    rental_bar_testid: 'cap_rental_bar_testid',
    location_input_btn_testid: 'cap_location_input_btn_testid',
    back_btn_testid: 'cap_back_btn_testid',
    cap_fulfillment_type_btn: 'cap_fulfillment_type_btn',
    continue_with_delivery: 'continue_with_delivery',
    pickupStore_alert: 'pickupStore_alert',
    cap_bottom_sticky_btn: 'cap_bottom_sticky_btn',
    cap_fulfillment_dropdown_testid: 'cap_fulfillment_dropdown'
};
