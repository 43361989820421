import React, { memo } from 'react';
import { string } from 'prop-types';
import classes from './plainText.css';

const PlainText = props => {
    return (
        <div className={classes.plainText}>
            <p className={classes.bodyp}>{props.accountName}</p>
            <p className={classes.bodysmallp}>{props.accountNumber}</p>
        </div>
    );
};

PlainText.propTypes = {
    accountName: string,
    accountNumber: string
};

PlainText.defaultProps = {
    accountNumber: '',
    accountName: ''
};

export default memo(PlainText);
