import axios from 'axios';
import axiosInstance from '../../../api/axiosDotComInstance';
import { API_CONFIG } from '../../../constants/apiConfig';

const { RATES_URL_OVERRIDEPC, RATES_URL_OWNED_PC } = API_CONFIG;

const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    clientKey: '709367c6eb824328a76f4af92ad60aa5',
    clientSecret: '1bf2Db58D82f41b5a9874608D6d2fbf2',
    'Request-Id': '12323sunbelt',
    channel: 'b2b',
    companyId: parseInt(localStorage.getItem('companyID')) || 1,
    loginToken: 'e21bb91b-b3da-48c5-b2c9-4b10c6fbd37e',
    Authorization: localStorage.getItem('user-login')
        ? `Bearer ${JSON.parse(localStorage.getItem('user-login'))?.accessToken}`
        : ''
};

const currentCountry = 'us'; //TODO:----
//TODO hardcoded until PDP page is integrated
const getRates = async (pc, customerNumber = '0', jobSite = '', items = []) => {
    try {
        if (pc) {
            const { data, error } = await axiosInstance.post(RATES_URL_OVERRIDEPC, { pc, customerNumber, jobSite, items });
            return { data, error };
        } else {
            return { error: 'PC not present' };
        }
    } catch (error) {
        return { error };
    }
};
const getRatesByOverridePc = async (pc, customerNumber = '0', jobSite = '', items = []) => {
    try {
        if (pc) {
            const { data, error } = await axiosInstance.post(RATES_URL_OVERRIDEPC, { pc, customerNumber, jobSite, items });
            return { data, error };
        } else {
            return { error: 'PC not present' };
        }
    } catch (error) {
        return { error };
    }
};

const getRatesByOwnedPc = async (customerNumber = '0', jobSite = '', items = []) => {
    try {
        if (items?.length > 0) {
            const { data, error } = await axiosInstance.post(RATES_URL_OWNED_PC, { customerNumber, jobSite, items });
            return { data, error };
        } else {
            return { error: 'Items with PC not present' };
        }
    } catch (error) {
        return { error };
    }
};

const GET_PC = `https://qa-api.sunbeltrentals.com/sbr-location-api-v2-qa2/api/v2/location?`;

const getStoresData = async (lat = 0, long = 0) => {
    try {
        const { data, error } = await axios.get(GET_PC, {
            headers,
            params: {
                latitude: lat,
                longitude: long,
                distance: 200
            }
        });
        return { data, error };
    } catch (error) {
        return { error };
    }
};
export { getRates, getRatesByOverridePc, getRatesByOwnedPc, getStoresData };
