const toggleButtonText = (button, textBeforeClick, textAfterClick) => {
    button.textContent = button.textContent === textBeforeClick ? textAfterClick : textBeforeClick;
};

const toggleButtonAriaLabel = (button, showmorearialabel, showlessarialabel) => {
    button.ariaLabel = button.ariaLabel === showmorearialabel ? showlessarialabel : showmorearialabel;
};

var columncontainerActions = document?.querySelectorAll('.tile__item__action');

columncontainerActions?.forEach(columncontainerAction => {
    var columncontainerActionDataset = columncontainerAction?.dataset;
    var {
        showmore = '',
        showless = '',
        showmorearialabel = '',
        showlessarialabel = ''
    } = columncontainerActionDataset || {};
        columncontainerAction?.addEventListener('click', function () {
        var columncontainerActionText = columncontainerAction?.querySelector('.show-more-text');
        var columncontainerActionClosest = columncontainerAction?.closest('.tile__row');
        columncontainerActionClosest?.classList?.toggle('tile__row--show');
        toggleButtonText(columncontainerActionText, showmore, showless);
        toggleButtonAriaLabel(columncontainerAction, showmorearialabel, showlessarialabel);
    });
});