import { bool, string, func } from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Alert from '../../atoms/alert/alert';
import LocationAutocomplete from '../location-autocomplete/LocationAutocomplete';
import { locationOverlayDataLocators } from '../../../pdp/location/LocationOverlayModal/dataLocators';
import classes from './newAddress.css';
import { useFilterState } from '../../../cap';
import { Field } from '../../../../aem-core-components';
import { newAddressDataLocator } from './dataLocators';

const NewAddress = props => {
    const intl = useIntl();
    const [{ viewCart }, filterDispatch] = useFilterState();
    const [streetAddress, setStreetAddress] = useState('');
    const { source, setAddressChangedByGeocode, addressChangedByGeocode, manualAddress } = props;
    useEffect(() => {
        if (!addressChangedByGeocode) {
            let str = `${viewCart.location ? viewCart.location : ''}`;

            if (viewCart.lat && viewCart.long) {
                setStreetAddress(str?.replace(/,\s*$/, ''));
            }
            props.setManualAddress(str?.replace(/,\s*$/, ''));
        }
    }, []);

    const handleLocationChange = (address = '') => {
        props.setAutoCompleteErrorMsg('');
        if (props.setManualAddress) {
            props.setIsManualAddress && props.setIsManualAddress(true);
            props.setManualAddress(address);
            setAddressChangedByGeocode(false);
            props.setAutoCompleteAddressSelected('');
        }
    };

    useEffect(() => {
        setStreetAddress(manualAddress);
    }, [manualAddress]);

    const handleSelectAddress = placeId => {
        props.setLocationByPlaces(placeId);
        setAddressChangedByGeocode(false);
        props.setIsManualAddress && props.setIsManualAddress(false);

        //TODO: ANALYTICS CHANGE FOR selectedAddress?.formattedAddress BY nikhil
    };

    /**
     * Parse locality, state, country and postal code from response
     */

    const handleZipOrPostalCodePlaceholder = () => {
        return localStorage.getItem('companyID') == 2
            ? intl.formatMessage({ id: 'new-address:search-by-zip-placeholder-ca' })
            : intl.formatMessage({ id: 'new-address:search-by-zip-placeholder' });
    };

    return (
        <>
            {props.screenName ? (
                <div className={props.canadaRestrictError ? classes.noBottomPadding : classes.modalNewAddress}>
                    <h6
                        className={classes.locationOverlayTitle}
                        data-testid={locationOverlayDataLocators.locationoverlay_enterlocation_searchlabel_testid}>
                        {intl.formatMessage({ id: 'new-address:search-by-full-address' })}
                    </h6>
                    <LocationAutocomplete
                        handleLocationChange={handleLocationChange}
                        onSelectAddress={selectedAddress => handleSelectAddress(selectedAddress)}
                        selectedAddress={val => {
                            setStreetAddress(val);
                            props.setAutoCompleteAddressSelected(val);
                        }}
                        placeholderText={handleZipOrPostalCodePlaceholder()}
                        prefilledAddress={streetAddress}
                        showResetBtn={false}
                        inputHeading={intl.formatMessage({ id: 'new-address:location-text' })}
                        labelTestId={locationOverlayDataLocators.locationoverlay_enterlocation_locationlabel_testid}
                        inputTestId={newAddressDataLocator.locationOverlay_locationfield_txtfield}
                        buttonTestID={newAddressDataLocator.locationOverlay_apiSuggestions_txt}
                        errorTestID={newAddressDataLocator.locationOverlay_locationerrormessage_txt}
                        inputlabel={newAddressDataLocator.search_location_label}
                        errorMsg={props.autoCompleteErrorMsg && !props.canadaRestrictError}
                        showAddressLineOneOnly={false}
                        source={source}
                    />
                    {props.autoCompleteErrorMsg && !props.canadaRestrictError ? (
                        <span className="error_input" tabindex="0" aria-live="assertive" role="alert">
                            {props.autoCompleteErrorMsg}
                        </span>
                    ) : (
                        ''
                    )}
                    {props.canadaRestrictError && (
                        <Alert
                            localStyle={`alert ${classes.alertPadding}`}
                            type={'error'}
                            message={props.canadaRestrictError}
                        />
                    )}
                    {addressChangedByGeocode && (
                        <Alert
                            localStyle={`alert ${classes.alertPadding}`}
                            type={'warning'}
                            message={intl.formatMessage({
                                id: 'order-details:address-changed-notification-label'
                            })}
                        />
                    )}
                </div>
            ) : (
                <>
                    <h6
                        className={classes.locationOverlayTitle}
                        data-testid={newAddressDataLocator.locationOverlay_searchByZip_txt}>
                        {intl.formatMessage({ id: 'new-address:search-by-full-address' })}
                    </h6>
                    <p
                        className={classes.bodyp}
                        data-testid={newAddressDataLocator.locationOverlay_sharingYourAddress_txt}>
                        {intl.formatMessage({ id: 'new-address:share-location-text-minicart' })}
                    </p>
                    <Field
                        label={intl.formatMessage({ id: 'rental-location-overlay:title' })}
                        htmlFor="rental-location">
                        <LocationAutocomplete
                            handleLocationChange={handleLocationChange}
                            onSelectAddress={selectedAddress => handleSelectAddress(selectedAddress)}
                            selectedAddress={val => {
                                setStreetAddress(val);
                                props.setAutoCompleteAddressSelected(val);
                            }}
                            placeholderText={''}
                            prefilledAddress={streetAddress}
                            showResetBtn={false}
                            inputHeading={intl.formatMessage({ id: 'new-address:location-text' })}
                            labelTestId={newAddressDataLocator.locationOverlay_location_lbl}
                            inputTestId={newAddressDataLocator.locationOverlay_locationfield_txtfield}
                            buttonTestID={newAddressDataLocator.locationOverlay_apiSuggestions_txt}
                            errorTestID={newAddressDataLocator.locationOverlay_locationerrormessage_txt}
                            inputlabel={newAddressDataLocator.search_location_label}
                            errorMsg={props.autoCompleteErrorMsg && !props.canadaRestrictError}
                            showAddressLineOneOnly={false}
                            source={source}
                            isFullAddress={true}
                            ariaLabel={intl.formatMessage({ id: 'rental-location-overlay:title' })}
                        />
                        {props.autoCompleteErrorMsg && !props.canadaRestrictError ? (
                            <span className="error_input" tabindex="0" aria-live="assertive" role="alert">
                                {props.autoCompleteErrorMsg}
                            </span>
                        ) : (
                            ''
                        )}
                        {props.canadaRestrictError && (
                            <Alert
                                localStyle={`alert ${classes.alertPadding}`}
                                type={'error'}
                                message={props.canadaRestrictError}
                            />
                        )}
                        {addressChangedByGeocode && (
                            <Alert
                                localStyle={`alert ${classes.alertPadding}`}
                                type={'warning'}
                                message={intl.formatMessage({
                                    id: 'order-details:address-changed-notification-label'
                                })}
                            />
                        )}
                    </Field>
                </>
            )}
        </>
    );
};

NewAddress.propTypes = {
    source: string,
    setAddressChangedByGeocode: func,
    addressChangedByGeocode: bool
};

NewAddress.defaultProps = {
    source: '',
    setAddressChangedByGeocode: () => {},
    addressChangedByGeocode: false
};

export default memo(NewAddress);
