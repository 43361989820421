import { gql } from '@apollo/client';

const TRANSMIT_QUOTE_DETAILS = gql`
    mutation transmitQuote($sfGuiQuoteId: String!, $tcsessionId: String!) {
        transmitQuote(input: { sfGuiQuoteId: $sfGuiQuoteId, tcsessionId: $tcsessionId }) {
            status
            message
            data {
                status
                rentalmanQuoteId
                displayQuoteId
                recordType
                sfGuiQuoteId
                sfQuoteId
                transmitStatus
            }
        }
    }
`;
export default TRANSMIT_QUOTE_DETAILS;
