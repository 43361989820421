import { ALERT_ERROR } from '../../global/constants';
import { useToastMessageState } from '../../global/modules/toastMessage/toastContext';
import { useIntl } from 'react-intl';

const errorMessages = {
    copy: 'p2p-items-did-not-copy-to-list',
    move: 'p2p-items-did-not-move-to-list',
    create: 'p2p-items-did-not-create-a-list',
    save: 'p2p-item-did-not-save-to-a-list',
    default: 'An error occurred.'
};
const useFailureToastNotification = () => {
    const intl = useIntl();
    const showToast = useToastMessageState();
    const showFailureToastNotification = ({ action, toastContainerClass = '', toastDirection }) => {
        const message = errorMessages[action] || errorMessages.default;
        showToast({
            message: intl.formatMessage({ id: message }),
            type: 'error',
            icon: ALERT_ERROR,
            customClass: 'alert_error alert_large',
            toastContainerClass: toastContainerClass,
            toastDirection
        });
    };
    return showFailureToastNotification;
};

export default useFailureToastNotification;
