import {
    SET_ALL_PCS,
    SET_ALL_TRAINERS,
    SET_NUMBER_OF_FILTERS,
    SET_SEARCHED_PC_LIST,
    SET_SEARCHED_TRAINER_LIST,
    SET_SEARCHED_TRAINER_LIST_BEFORE_FILTERS,
    SET_TRAINER_LIST,
    SET_TRAINER_LIST_BEFORE_FILTERS
} from './constants';

export const dstActions = (state, action) => {
    switch (action.type) {
        case SET_ALL_PCS:
            return {
                ...state,
                allPCs: action.allPCs
            };
        case SET_ALL_TRAINERS:
            return {
                ...state,
                allTrainers: action.allTrainers
            };
        case SET_TRAINER_LIST:
            return {
                ...state,
                trainerList: action.trainerList
            };
        case SET_TRAINER_LIST_BEFORE_FILTERS:
            return {
                ...state,
                trainerListBeforeFilters: action.trainerListBeforeFilters
            };
        case SET_NUMBER_OF_FILTERS:
            return {
                ...state,
                numberOfFilters: action.numberOfFilters
            };
        case SET_SEARCHED_PC_LIST:
            return {
                ...state,
                searchedPcList: action.searchedPcList
            };
        case SET_SEARCHED_TRAINER_LIST:
            return {
                ...state,
                searchedTrainerList: action.searchedTrainerList
            };
        case SET_SEARCHED_TRAINER_LIST_BEFORE_FILTERS:
            return {
                ...state,
                searchedTrainerListBeforeFilters: action.searchedTrainerListBeforeFilters
            };
        default:
            return state;
    }
};
