import axiosInstance from '../../../../../../main/api/axiosDotComInstance';
import { API_CONFIG } from '../../../../../constants/apiConfig';
import { ENV_CONFIG } from '../../../../../constants/envConfig';
import { ERROR_MSG } from '../../../../../constants/errorMappingCodes';
import { STORAGE_CONFIG } from '../../../../../constants/storageConfig';
const {
    GET_LOCATIONS_URL,
    GET_LOCATIONS_URL_FOR_BOTH_COUNTRIES,
    GET_PLACES_API,
    GET_LAT_LONG,
    GET_LAT_LONG_BOTH_COUNTRIES,
    GET_LAT_LONG_NO_COUNTRY
} = API_CONFIG;
//TODO:----

const getLocations = async (
    input = '',
    customCountryCode = '',
    isLocationAvailable = true,
    isUSCountry,
    locationCoordinates,
    addTypeCheck = false
) => {
    let currentCountry = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) === '2' ? 'ca' : 'us';
    let currentCountryId = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) === '2' ? 2 : 1;
    if (isUSCountry !== undefined) {
        currentCountry = isUSCountry ? 'us' : 'ca';
        currentCountryId = isUSCountry ? 1 : 2;
    }
    try {
        if (!isLocationAvailable) {
            const { data, error } = await axiosInstance.get(`${GET_LOCATIONS_URL_FOR_BOTH_COUNTRIES}`, {
                params: {
                    input,
                    ...(locationCoordinates?.lat
                        ? {
                              location: `${locationCoordinates?.lat},${locationCoordinates?.long}`,
                              radius: ENV_CONFIG.DEFAULT_AUTOSUGGEST_RADIUS * 1609.34 //miles to meters
                          }
                        : {}),
                    ...(addTypeCheck ? { types: 'address' } : {})
                },
                headers: { companyId: 0 }
            });
            return { data: data?.data, error };
        } else {
            const { data, error } = await axiosInstance.get(
                `${GET_LOCATIONS_URL}${customCountryCode ? customCountryCode : currentCountry}`,
                {
                    params: {
                        input,
                        ...(addTypeCheck ? { types: 'address' } : {})
                    },
                    headers: { companyId: currentCountryId }
                }
            );
            return { data: data?.data, error };
        }
    } catch (error) {
        return { error };
    }
};

const getLocationsDetailsByPlaceId = async (placeId = '', companyID) => {
    try {
        const companyId = companyID || localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) || 1;
        if (placeId) {
            const { data, error } = await axiosInstance.get(GET_PLACES_API, {
                params: {
                    placeId
                },
                headers: { companyId: companyId }
            });
            return { data: data?.data, error };
        } else {
            return { error: 'placeId is not present' };
        }
    } catch (error) {
        return { error };
    }
};

const getlatLongByAddress = async (companyID = '1', address = '', countryShortName, isLocationAvailable = true) => {
    if (isLocationAvailable && address === ERROR_MSG.INVALID_ADDRESS) {
        return { error: ERROR_MSG.LOCATION_NOT_PRESENT };
    } else {
        const currentCountry = companyID == '2' ? 'ca' : 'us';
        const url = isLocationAvailable
            ? `${GET_LAT_LONG}${countryShortName || currentCountry}`
            : GET_LAT_LONG_BOTH_COUNTRIES;
        try {
            const { data, error } = await axiosInstance.get(url, {
                params: {
                    address
                },
                headers: { companyId: companyID }
            });
            return { data: data?.data, error };
        } catch (error) {
            return { error };
        }
    }
};

const getAddressBylatLong = async (lat = '', long = '') => {
    try {
        const { data, error } = await axiosInstance.get(GET_LAT_LONG, {
            params: {
                latlng: `${lat},${long}`
            }
        });
        return { data: data?.data, error };
    } catch (error) {
        return { error };
    }
};

const geoCodeByLatLong = async (latlng = '') => {
    const url = `${GET_LAT_LONG_NO_COUNTRY}`;
    try {
        const { data, error } = await axiosInstance.get(url, {
            params: {
                latlng
            }
        });
        return { data: data?.data, error };
    } catch (error) {
        return { error };
    }
};

export { getLocations, getLocationsDetailsByPlaceId, getlatLongByAddress, getAddressBylatLong, geoCodeByLatLong };
