import axiosInstance from '../../../api/axiosDotComInstance';
import { API_CONFIG } from '../../../constants/apiConfig';
import { logError } from '../../global/utils/logger';

const { GET_ACCOUNTS, GET_JOBSITES, GET_QUOTE_LIST, GET_ORDERED_BY, POST_STORE_DISTANCES_URL } = API_CONFIG;

export const getQuotes = async (searchQuery, accountNumber, offset, limit) => {
    const payload = {
        searchString: searchQuery?.trim(),
        accountNumber: accountNumber,
        offset: offset.toString(),
        limit: limit.toString()
    };

    try {
        const { data, error } = await axiosInstance.post(GET_QUOTE_LIST, payload);
        return { data, error };
    } catch (error) {
        logError(error, false, 'getQuotes');
        return { error };
    }
};

export const getAccounts = async (searchQuery, accountNumber, quoteSearchTerm) => {
    const payload = {
        searchString: searchQuery?.trim(),
        accountNumber: accountNumber,
        quoteSearchString: quoteSearchTerm
    };

    try {
        const data = await axiosInstance.post(GET_ACCOUNTS, payload);
        return data;
    } catch (error) {
        logError(error, false, 'getAccounts');
        return error;
    }
};

export const getJobsites = async (searchQuery, accountNumber, childAccounts, quoteSearchTerm) => {
    const payload = {
        searchString: searchQuery?.trim(),
        accountNumber: accountNumber,
        childAccounts: childAccounts,
        quoteSearchString: quoteSearchTerm
    };

    try {
        const data = await axiosInstance.post(GET_JOBSITES, payload);
        return data;
    } catch (error) {
        logError(error, false, 'getJobsites');
        return error;
    }
};
export const getOrderedBy = async (accountNumber, childAccounts, listOfJobsites, quoteSearchTerm) => {
    const payload = {
        accountNumber,
        childAccounts: childAccounts,
        listOfJobsites: listOfJobsites,
        quoteSearchString: quoteSearchTerm
    };
    try {
        const data = await axiosInstance.post(GET_ORDERED_BY, payload);
        return data;
    } catch (error) {
        logError(error, false, 'getOrderedBy');
        return error;
    }
};

export const calculateStoreDistance = async payload => {
    try {
        const { data, error } = await axiosInstance.post(POST_STORE_DISTANCES_URL, payload);
        return { data: data?.data, error };
    } catch (error) {
        logError(error, false, 'storeDistance', [payload]);
        return error;
    }
};
