import { useEffect } from 'react';
import { logError } from '../../../global/utils/logger';
import { QUOTE_ESTIMATES_LOADED, SET_QUOTE_SUMMARY } from '../../../quotes/actionTypes/actionTypes';
import { useCartState } from '../../../../contexts/cart';
import { PUNCHOUT, QUOTE_FILTER_STATUS_TRANSMITTED } from '../../../quotes/constants';
import { useQuotesState } from '../../../quotes/context';
import {
    COST_CATEGORIES,
    DELIVERY,
    ENVIRONMENTAL,
    FIELD_MAPPINGS,
    FUEL_CONVENIENCE_CHARGE,
    PICKUP,
    STOCK_CLASS_DELIVERY,
    STOCK_CLASS_FUEL,
    STOCK_CLASS_MISCELLANEOUS,
    TRANSPORTATION_SURCHARGE
} from '../constants/orderSummaryFactoryConstants';

const useQuoteSummary = () => {
    const [{ quoteOrderEstimates, quoteEstimatesLoaded, quoteDetailsData, quoteSummary }, quoteDispatch] =
        useQuotesState();
    const [{ cart, consumables }] = useCartState();
    const { selectedRentalQtyAndPrice } = consumables || {};
    useEffect(() => {
        if (quoteEstimatesLoaded) {
            calculateUntransmittedQuoteSummary();
        }
    }, [quoteEstimatesLoaded]);

    const calculateQuoteRentalAmount = () => {
        try {
            const orderEstimates = quoteOrderEstimates?.estimate;
            const { rentalAmount } = orderEstimates?.totals || {};
            let total = 0;

            if (rentalAmount) {
                total = rentalAmount;
            } else {
                if (orderEstimates?.itemizedCharges?.products) {
                    orderEstimates?.itemizedCharges?.products?.forEach(product => {
                        total += product?.rentalCost;
                    });
                } else {
                    cart?.items?.forEach(item => {
                        total += item.prices?.row_total.value;
                    });
                    total += selectedRentalQtyAndPrice?.totalRentalPrice;
                }
            }
            return Number(total.toFixed(2)) || 0;
        } catch (error) {
            logError(error, false, 'calculateRentalAmountWhenNoDates');
        }
    };

    const getQuoteAmount = type => {
        return parseFloat(quoteDetailsData?.data[type] || 0);
    };

    const handleQuoteMiscCharges = type => {
        return quoteOrderEstimates?.estimate?.miscCharges?.find(item => item.type.indexOf(type) > -1)?.charge || 0;
    };

    const handleQuoteSalesTax = salesTaxVal => {
        const optedForPickup = !quoteDetailsData?.data?.isDelivery;
        try {
            let salesTaxTotal = salesTaxVal;

            const transportFeeTaxToBeRemoved = quoteOrderEstimates?.estimate?.miscCharges?.find(
                item => item.type.indexOf(TRANSPORTATION_SURCHARGE) > -1
            );
            salesTaxTotal -= optedForPickup && transportFeeTaxToBeRemoved?.tax ? transportFeeTaxToBeRemoved?.tax : 0;

            const fuelFeeTaxToBeRemoved = quoteOrderEstimates?.estimate?.miscCharges?.find(
                item => item.type.indexOf(FUEL_CONVENIENCE_CHARGE) > -1
            );

            if (quoteDetailsData?.data?.optOutFuelCharge) {
                salesTaxTotal -= fuelFeeTaxToBeRemoved?.tax ? fuelFeeTaxToBeRemoved?.tax : 0;
            }

            let totalRppTax = 0;
            quoteOrderEstimates?.estimate?.itemizedCharges?.products?.forEach(product => {
                totalRppTax += product?.rppTax;
            });

            if (quoteDetailsData?.data?.optOutRPP) {
                salesTaxTotal -= totalRppTax;
            }

            const deliveryFeeObj = quoteOrderEstimates?.estimate?.miscCharges?.find(
                item => item.type.indexOf(DELIVERY) > -1
            );
            const pickupFeeObj = quoteOrderEstimates?.estimate?.miscCharges?.find(
                item => item.type.indexOf(PICKUP) > -1
            );
            if (optedForPickup) {
                salesTaxTotal -= deliveryFeeObj?.tax ? deliveryFeeObj?.tax : 0;
                salesTaxTotal -= pickupFeeObj?.tax ? pickupFeeObj?.tax : 0;
            }

            return Number(salesTaxTotal.toFixed(2)) || 0;
        } catch (error) {
            logError(error, false, 'handleQuoteSalesTax');
        }
    };

    const handleQuoteEnvAndFuelFees = () => {
        const orderEstimates = quoteOrderEstimates?.estimate;
        const { rentalAmount, deliveryPickUpCharges, fuelCharges, rppCharges, salesTax, allOtherCharges } =
            orderEstimates?.totals || {};
        try {
            let amountToBeSubtractedFromOtherCharges = Number(allOtherCharges?.toFixed(2));

            const envFeeObj = orderEstimates?.miscCharges?.find(item => item.type.indexOf(ENVIRONMENTAL) > -1);
            const { charge = 0 } = envFeeObj || {};

            amountToBeSubtractedFromOtherCharges =
                Number(amountToBeSubtractedFromOtherCharges?.toFixed(2)) - Number(charge?.toFixed(2));

            const fuelFee = fuelCharges ? Number(fuelCharges?.toFixed(2)) : 0;
            amountToBeSubtractedFromOtherCharges =
                Number(amountToBeSubtractedFromOtherCharges?.toFixed(2)) - Number(fuelFee?.toFixed(2));

            const transportFeeToBeRemoved = orderEstimates?.miscCharges?.find(
                item => item.type.indexOf(TRANSPORTATION_SURCHARGE) > -1
            );
            amountToBeSubtractedFromOtherCharges =
                Number(amountToBeSubtractedFromOtherCharges?.toFixed(2)) -
                (!quoteDetailsData?.data?.isDelivery && transportFeeToBeRemoved?.charge
                    ? Number(transportFeeToBeRemoved?.charge?.toFixed(2))
                    : 0);

            return amountToBeSubtractedFromOtherCharges;
        } catch (error) {
            logError(error, false, 'handleQuoteEnvAndFuelFees');
        }
    };

    const calculateUntransmittedQuoteSummary = () => {
        try {
            quoteDispatch({ type: QUOTE_ESTIMATES_LOADED, payload: false });
            const estimates = quoteOrderEstimates?.estimate;
            const quoteSummaryData = {
                checkoutSubtotal: 0,
                rentalSubtotal: calculateQuoteRentalAmount(),
                purchaseSubtotal: estimates?.totals?.salesAmount,
                deliveryCharges: handleQuoteMiscCharges(DELIVERY),
                pickupCharges: handleQuoteMiscCharges(PICKUP),
                deliveryPickUpCharges: quoteDetailsData?.data?.isDelivery
                    ? handleQuoteMiscCharges(DELIVERY) + handleQuoteMiscCharges(PICKUP)
                    : 0,
                rentalProtectionPlan: !quoteDetailsData?.data?.optOutRPP ? estimates?.totals?.rppCharges : 0,
                prepayFuelOption: !quoteDetailsData?.data?.optOutFuelCharge
                    ? handleQuoteMiscCharges(FUEL_CONVENIENCE_CHARGE)
                    : 0,
                environmentalServiceFee: handleQuoteMiscCharges(ENVIRONMENTAL),
                otherFees: Number(handleQuoteEnvAndFuelFees().toFixed(2)) || 0,
                taxes: handleQuoteSalesTax(estimates?.totals?.salesTax)
            };
            quoteSummaryData['checkoutSubtotal'] = COST_CATEGORIES.reduce(
                (sum, key) => sum + (quoteSummaryData[key] || 0),
                0
            );
            quoteSummaryData['estimatedSubtotal'] = quoteSummaryData['checkoutSubtotal'] + quoteSummaryData['taxes'];
            quoteDispatch({
                type: SET_QUOTE_SUMMARY,
                payload: quoteSummaryData
            });
        } catch (error) {
            logError(error, false, 'calculateUntransmittedQuoteSummary');
        }
    };

    const calculateTransmittedQuoteOtherFees = deliveryCharges => {
        if (quoteDetailsData?.data['sellingChannel']?.toUpperCase() === PUNCHOUT) {
            return getQuoteAmount(FIELD_MAPPINGS.OTHER_FEES);
        }
        return (parseFloat(quoteDetailsData?.data[FIELD_MAPPINGS.OTHER_FEES] || 0) - deliveryCharges || 0).toFixed(2);
    };

    const calculateTransmittedQuoteSummary = () => {
        try {
            const deliveryCharges = quoteDetailsData?.data?.products?.reduce(
                (total, prod) =>
                    total +
                    (prod?.productData?.stockClass === STOCK_CLASS_DELIVERY ? parseFloat(prod?.sellingPrice) || 0 : 0),
                0
            );
            const fuelCharges = quoteDetailsData?.data?.products?.reduce(
                (total, prod) =>
                    total +
                    (prod?.productData?.stockClass === STOCK_CLASS_FUEL &&
                    prod?.productData?.productName.toUpperCase() === FUEL_CONVENIENCE_CHARGE &&
                    !prod?.forcedItem // adding fuel charges if the product line item is not forcedItem and having stock class FUEL
                        ? parseFloat(prod?.sellingPrice) || 0
                        : 0),
                0
            );
            const envCharges = quoteDetailsData?.data?.products?.reduce(
                (total, prod) =>
                    total +
                    (prod?.productData?.stockClass === STOCK_CLASS_MISCELLANEOUS &&
                    prod?.productData?.itemNumber === ENVIRONMENTAL
                        ? parseFloat(prod?.sellingPrice) || 0
                        : 0),
                0
            );

            const quoteSummaryData = {
                checkoutSubtotal: 0,
                rentalSubtotal: getQuoteAmount(FIELD_MAPPINGS.RENTAL_SUBTOTAL),
                purchaseSubtotal: getQuoteAmount(FIELD_MAPPINGS.PURCHASE_SUBTOTAL),
                deliveryPickUpCharges: quoteDetailsData?.data?.isDelivery ? deliveryCharges : 0,
                rentalProtectionPlan: getQuoteAmount(FIELD_MAPPINGS.RPP_FEES),
                prepayFuelOption: !quoteDetailsData?.data?.optOutFuelCharge ? fuelCharges : 0,
                environmentalServiceFee: envCharges,
                otherFees: calculateTransmittedQuoteOtherFees(deliveryCharges),
                taxes: getQuoteAmount(FIELD_MAPPINGS.TAXES),
                estimatedSubtotal: getQuoteAmount(FIELD_MAPPINGS.QUOTE_TOTAL_AMOUNT)
            };
            //subtracting fuel option and env service fee if selling channel is not punchout
            if (quoteDetailsData?.data['sellingChannel']?.toUpperCase() != PUNCHOUT) {
                quoteSummaryData['otherFees'] -= quoteSummaryData['prepayFuelOption'];
                quoteSummaryData['otherFees'] -= quoteSummaryData['environmentalServiceFee'];
            }
            quoteSummaryData['checkoutSubtotal'] = COST_CATEGORIES.reduce(
                (sum, key) => sum + (quoteSummaryData[key] || 0),
                0
            );

            quoteDispatch({
                type: SET_QUOTE_SUMMARY,
                payload: quoteSummaryData
            });
        } catch (error) {
            logError(error, false, 'calculateTransmittedQuoteSummary');
        }
    };

    return {
        quoteSummary,
        calculateUntransmittedQuoteSummary,
        calculateTransmittedQuoteSummary
    };
};

export default useQuoteSummary;
