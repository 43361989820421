import { createIntl, createIntlCache } from 'react-intl';
import i18Messages from '../i18n/en.json';
import config from '../../App/config';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { FORCE_ITEM } from '../../checkout/constants';

export const getAriaLableForItem = (cartItem, index, cartLength) => {
    return `${cartItem?.product?.name} item ${index} of ${cartLength}`;
};

export const getCartAemAuthorableDetails = type => {
    return ENV_CONFIG.CART_CONFIGURATIONS?.[type];
};

const cache = createIntlCache();
export const cartIntl = createIntl({ locale: 'en', messages: i18Messages }, cache);

export const removeZeroPriceItems = (items, estimatesObj = {}) => {
    items?.forEach(item => {
        let rentablesLength = item?.rentables?.length;
        while (rentablesLength--) {
            const currRent = item?.rentables[rentablesLength];
            if (
                estimatesObj[`${generateSKU(currRent?.rentalCat, currRent?.rentalClass)}`] != 0 ||
                currRent.forceItem === FORCE_ITEM.YES
            ) {
                continue;
            } else {
                item?.rentables?.splice(rentablesLength, 1);
            }
        }
        item?.consumables?.forEach(obj => {
            let specGroupsLength = obj?.specGroups?.length;
            while (specGroupsLength--) {
                if (obj?.specGroups[specGroupsLength]?.maxPrice != 0) {
                    continue;
                } else {
                    obj?.specGroups?.splice(specGroupsLength, 1);
                }
            }
        });
    });
    return items;
};

const generateSKU = (catId, classId) => {
    const catRental = ('000' + catId).slice(-3);
    const classRental = ('0000' + classId).slice(-4);
    return catRental + classRental;
};

export const getAvailableConsumablesConfig = items => {
    let remainingAccessories = {};
    let remainingAddOns = {};
    let totalAddOns = {};
    items?.forEach(item => {
        for (let index1 = 0; index1 < item.rentables.length; index1++) {
            const currRent = item.rentables[index1];
            if (!remainingAccessories[`${generateSKU(currRent?.rentalCat, currRent?.rentalClass)}`]) {
                remainingAccessories[`${generateSKU(currRent?.rentalCat, currRent?.rentalClass)}`] = {
                    accessoriesAvailableQuantity: parseInt(currRent.availQuantity),
                    accessoriesRemainingQuantity: parseInt(currRent.availQuantity)
                };
            } else {
                continue;
            }
        }
        for (let index2 = 0; index2 < item.consumables.length; index2++) {
            for (let index3 = 0; index3 < item.consumables[index2]?.specGroups?.length; index3++) {
                totalAddOns[`${generateSKU(item?.cat, item?.class)}`] =
                    (totalAddOns[`${generateSKU(item?.cat, item?.class)}`] || 0) + 1;
                for (let index4 = 0; index4 < item.consumables[index2].specGroups[index3].items.length; index4++) {
                    const currRent = item.consumables[index2].specGroups[index3].items[index4];
                    if (!remainingAddOns[`${currRent?.itemNumber}${currRent?.stockClass}${currRent?.oemStockNumber}`]) {
                        remainingAddOns[`${currRent?.itemNumber}${currRent?.stockClass}${currRent?.oemStockNumber}`] = {
                            addOnsAvailableQuantity: parseInt(currRent.availQuantity),
                            addOnsRemainingQuantity: parseInt(currRent.availQuantity)
                        };
                    } else {
                        continue;
                    }
                }
            }
        }
    });
    return { remainingAccessories, remainingAddOns, totalAddOns };
};

export const getDistance = isInStorePickup => {
    let distance = '';
    const drivingDistance = JSON.parse(
        sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.SELECTEDSTOREDETAILS_FOR_ROUND_TRIP) || '{}'
    )?.drivingDistanceFromJobsite;
    const browseLocationOBJ = JSON.parse(
        sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ) || '{}'
    )?.distance;
    distance = isInStorePickup ? 0 : drivingDistance || browseLocationOBJ || -1; //-1 indicating distance empty in FE
    return distance?.toString();
};

export const onExploreRentalsHandler = () => {
    window.location.href = window.location.origin + config?.pagePaths?.equipmentRentalPage;
};

export const linkToSimilarRentals = item => {
    let categories = item?.product?.categories;
    let link = categories ? categories[categories?.length - 2]?.category_page_url : '';
    link = link ? config.pagePaths.originURL + link : '';
    return link;
};
