import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import classes from './accountSelector.css';
import ArrowRight from '../../../../resources/images/arrow-right.svg';
import { bool, func, node, string } from 'prop-types';

const AccountSelector = props => {
    const {
        buttonAriaLabel,
        customClassName,
        customButtonView,
        pickupDefaultIcon,
        isSelectStoreError,
        accountClick,
        testid,
        testProjectName,
        selectedProjectAddress1,
        projectTestId,
        accountTestId,
        projectName
    } = props;
    const capIntl = useIntl();

    const handleSelectorClass = () => {
        if (isSelectStoreError) {
            return `${classes.AccountSelectorError}`;
        } else {
            if (customButtonView) {
                return `${classes.AccountSelectorContainer}`;
            } else {
                return `${classes.AccountSelector}`;
            }
        }
    };

    return (
        <button
            className={handleSelectorClass()}
            onClick={accountClick}
            data-testid={testid}
            aria-label={`${capIntl.formatMessage({ id: 'cap:pickup-store' })} ${buttonAriaLabel?.props?.children?.join(
                ''
            )}`}>
            <div className={classes.accountSelectorWrapper} data-testid={testProjectName}>
                {selectedProjectAddress1 && (
                    <p className={classes.bodyp} data-testid={projectTestId}>
                        {selectedProjectAddress1}
                    </p>
                )}
                <small className={`${classes.bodysmallp} ${customClassName}`} data-testid={accountTestId}>
                    {projectName}
                </small>
            </div>
            <div
                className={
                    selectedProjectAddress1 === capIntl.formatMessage({ id: 'cap:no-store-nearby' })
                        ? 'pickupStoreInput__alert__icon'
                        : `${classes.bodyIcon} account-selector-icon`
                }>
                {pickupDefaultIcon}
            </div>
        </button>
    );
};

AccountSelector.propTypes = {
    buttonAriaLabel: string,
    customClassName: string,
    customButtonView: bool,
    pickupDefaultIcon: node,
    isSelectStoreError: bool,
    accountClick: func,
    testid: string,
    testProjectName: string,
    selectedProjectAddress1: string,
    projectTestId: string,
    accountTestId: string,
    projectName: string
};

AccountSelector.defaultProps = {
    buttonAriaLabel: '',
    customClassName: '',
    customButtonView: false,
    pickupDefaultIcon: <ArrowRight />,
    isSelectStoreError: false,
    accountClick: () => {},
    testid: '',
    testProjectName: '',
    selectedProjectAddress1: '',
    projectTestId: '',
    accountTestId: '',
    projectName: ''
};

export default memo(AccountSelector);
