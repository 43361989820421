import React from 'react';
import PropTypes, { bool, func, number, string } from 'prop-types';
import CheckedRadio from '../../../../resources/images/checked-square.svg';
import UncheckedRadio from '../../../../resources/images/unchecked-square.svg';
import classes from './checkbox.css';

const Checkbox = ({
    id,
    isChecked,
    customClassName,
    onClick,
    label,
    dataTestId,
    customAriaLabel,
    cardStyle,
    isFilters,
    tabIndex
}) => {
    const handleCheckboxClick = () => {
        onClick(label, !isChecked, id);
    };

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            handleCheckboxClick();
        }
    };

    const renderContent = () => {
        if (isFilters) {
            return cardStyle();
        } else {
            // Handle the case when label is an object
            if (typeof label === 'object' && Object.keys(label).length === 0) {
                return null; // or some default content
            }

            return label;
        }
    };

    return (
        <div className={`${classes.checkboxWrapper} ${customClassName && customClassName}`}>
            <div
                role="checkbox"
                onClick={handleCheckboxClick}
                onKeyDown={handleKeyDown}
                data-testid={dataTestId}
                aria-checked={isChecked ? true : false}
                aria-label={customAriaLabel ? customAriaLabel : label}
                aria-labelledby={customAriaLabel ? customAriaLabel : label}
                className={classes.checkboxSVG}
                tabIndex={tabIndex}>
                {isChecked ? (
                    <CheckedRadio tabIndex={'-1'} aria-hidden={true} />
                ) : (
                    <UncheckedRadio tabIndex={'-1'} aria-hidden={true} />
                )}
            </div>
            {renderContent()}
        </div>
    );
};

Checkbox.propTypes = {
    isChecked: bool,
    onClick: func,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    customClassName: string,
    dataTestId: string,
    customAriaLabel: string,
    id: string,
    tabIndex: number
};

Checkbox.defaultProps = {
    isChecked: false,
    onClick: () => {},
    customClassName: '',
    dataTestId: '',
    label: '' || {},
    customAriaLabel: '',
    id: '',
    tabIndex: 0
};

export default React.memo(Checkbox);
