import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import { useUserContext } from '../aem-core-components/context/UserContext';
import { useFilterState } from '../components/cap';
import { getCartDetails } from '../aem-core-components/actions/cart';
import {
    SET_CURRENT_OFFSET_VALUE,
    SET_HOME_ASSETS_RATES,
    SET_RATES_LOADING_FLAG,
    SET_TIMEZONE_ID,
    SET_VIEW_CART_FIELDS,
    RESET_ORDER_ESTIMATES,
    SET_CID_PC_LIST,
    SET_IS_CART_LOADING,
    RESET_GEOGRAPHY,
    SET_SHOW_UNAVAILABLE_ITEMS,
    SET_SHOW_NO_RESULTS_FOR_UNAVILABLE_ITEMS,
    SET_LOCATION_DATA,
    SET_IS_ESTIMATE_ON_CART_OPEN_REQUIRED,
    SET_LOCATION_LOADING,
    SET_ATP_PC_LIST,
    SET_RECOMPUTE_ITEM_AVAILABILITY
} from '../aem-core-components/actions/constants';
import { useCartState } from '../contexts/cart';
import { ADDRESS_COMPONENTS, ADDRESS_COMPONENT_TYPE } from '../constants/cartConstants';
import isObjectEmpty from '../aem-core-components/utils/isObjectEmpty';
import { getTimezoneAPI } from '../components/checkout/checkoutAndOrderSummary/api/getCheckoutAPIs';
import {
    getClosestStores,
    getStores
} from '../components/checkout/orderDetailsCheckout/utils/editOrderDetailsFunctions';
import {
    geoCodeByLatLong,
    getlatLongByAddress,
    getLocationsDetailsByPlaceId
} from '../components/global/modules/location-autocomplete/api/getLocations';
import {
    determineCityComponent,
    formatNearbyPC,
    formatStoreDataToObject,
    getProductAssetsList,
    getProjectsFromCookie,
    getTimeFromCity,
    isCheckoutPage,
    validateZip,
    storeTier2Radius
} from '../components/global/utils/commonUtils';
import {
    getAssetsFromPdpUrl,
    handleBranchSelectorCall,
    updateSelectedStoreDetailsForRoundTrip
} from '../components/global/utils/computeAddressUtil';
import { isValidString, logError } from '../components/global/utils/logger';
import { getBSRListAPI, getPCfromLocations } from '../components/location/API/getStoreLocations';
import { getRates } from '../components/pdp/api/getRates';
import { STORAGE_CONFIG } from '../constants/storageConfig';
import { USER_TYPE } from '../constants/userDetailsConstants';
import { useCheckUser } from './useCheckUser';
import { useAtp } from '../components/cap/hooks/useATP';
import useUserData from './useUserData';
import useCheckLocationEmpty from './useCheckLocationEmpty';
import CART_DETAILS_QUERY from '../aem-core-components/queries/query_cart_details.graphql';
import { useAwaitQuery } from '../aem-core-components/utils/hooks';
import { updateContextBasedOnCompanyId } from '../components/search/Engine';
import { ENV_CONFIG } from '../constants/envConfig';
import { CATEGORY_PAGE_TEMPLATE_STRING } from '../constants/screenConstants';
import { SET_BSR_LIST, SET_PICKUP_STORES, SET_SELECTED_STORE_DETAILS } from '../components/cap/constants';
import { GENERAL_EQUIPMENT_AND_TOOLS } from '../components/location/constants';

const useComputeLocation = () => {
    const [{ hasAccountChanged }] = useUserContext();
    const [{ userAccount, cart, howToGetYourOrderDetails, cartId }, dispatch] = useCartState();
    const [
        { viewCart, projectDetails, showUnavailableItems, startDate, endDate, pickupStores, selectedStoreDetails },
        filterDispatch
    ] = useFilterState();
    const { pc } = viewCart;
    const [canadaRestrictStateError, setCanadaRestrictStateError] = useState('');
    const intl = useIntl();
    const userType = useCheckUser();
    const { handleATPCart } = useAtp();
    const [{ getAssets, updateGeography }] = useUserData();
    const validStateList = ENV_CONFIG.VALID_STATE_LIST;
    const { tieroneinvradius, tiertwoinvradius } = ENV_CONFIG.INVENTORY_CHECK_CONFIGS || {};
    const tier1SearchRadius = tieroneinvradius || 100;
    const tier2SearchRadius = tiertwoinvradius || 500;
    const cartDetailsQuery = useAwaitQuery(CART_DETAILS_QUERY);
    const projectCookies = getProjectsFromCookie();
    const { fetchLocationCoordinates, isRentalDetailsAvailable } = useCheckLocationEmpty();
    const [assets, setAssets] = useState([]);

    useEffect(() => {
        setCanadaRestrictStateError(canadaRestrictStateError);
    }, [canadaRestrictStateError]);

    useEffect(() => {
        setAssets(getAssets());
    }, [cart?.total_quantity]);
    const determineTypeOfAddress = component => {
        const types = get(component, 'types', []);
        if (types.includes(ADDRESS_COMPONENTS.SUBLOCALITY)) {
            return ADDRESS_COMPONENT_TYPE.SUBLOCALITY;
        }
        if (
            types.includes(ADDRESS_COMPONENTS.LOCALITY) ||
            types.includes(ADDRESS_COMPONENTS.AL3) ||
            types.includes(ADDRESS_COMPONENTS.AL2)
        ) {
            return ADDRESS_COMPONENT_TYPE.CITY;
        }
        if (types.includes(ADDRESS_COMPONENTS.AL1)) {
            return ADDRESS_COMPONENT_TYPE.STATE;
        }
        if (types.includes(ADDRESS_COMPONENTS.POSTAL)) {
            return ADDRESS_COMPONENT_TYPE.POSTAL_CODE;
        }
        if (types.includes(ADDRESS_COMPONENTS.STREET_NO)) {
            return ADDRESS_COMPONENT_TYPE.STREET;
        }
        if (types.includes(ADDRESS_COMPONENTS.COUNTRY)) {
            return ADDRESS_COMPONENT_TYPE.COUNTRY;
        }
        if (types.includes(ADDRESS_COMPONENTS.ROUTE)) {
            return ADDRESS_COMPONENT_TYPE.ROUTE;
        }
        if (types.includes(ADDRESS_COMPONENTS.NEIGHBORHOOD)) {
            return ADDRESS_COMPONENT_TYPE.NEIGHBORHOOD;
        }
    };

    const extractAddressComponents = (details, isUpdateCountry = true) => {
        let computedAddress = {
            jobSiteAddr2: '',
            jobSiteCity: '',
            jobSiteState: '',
            jobSiteZip: '',
            country: parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1
        };
        const addressComponents = get(details, 'addressComponents', []);
        let citySelected = false;
        if (addressComponents?.length > 0) {
            addressComponents?.map(component => {
                const type = determineTypeOfAddress(component);
                switch (type) {
                    case ADDRESS_COMPONENT_TYPE.CITY: {
                        computedAddress.jobSiteCity = determineCityComponent(addressComponents);
                        citySelected = true;
                        break;
                    }

                    case ADDRESS_COMPONENT_TYPE.STATE: {
                        computedAddress.jobSiteState = component.shortName;
                        break;
                    }

                    case ADDRESS_COMPONENT_TYPE.POSTAL_CODE: {
                        computedAddress.jobSiteZip = component.longName;
                        break;
                    }
                    case ADDRESS_COMPONENT_TYPE.AREA_L2: {
                        computedAddress.jobSiteAddr2 = component.longName;
                        break;
                    }
                    case ADDRESS_COMPONENT_TYPE.SUBLOCALITY: {
                        if (!citySelected) {
                            computedAddress.jobSiteCity = component.longName;
                        }
                        break;
                    }
                    case ADDRESS_COMPONENT_TYPE.COUNTRY: {
                        if (isUpdateCountry) {
                            localStorage.setItem('companyID', component.shortName == 'CA' ? 2 : 1);
                        } else {
                            computedAddress.country = component.shortName == 'CA' ? 2 : 1;
                        }
                        break;
                    }
                    default:
                        break;
                }
            });
        }
        return computedAddress;
    };

    const getBSRList = async ({ lat, long, companyID, updateInContext = true }) => {
        let localLat = '';
        let localLong = '';
        if (lat && long) {
            localLat = lat;
            localLong = long;
        } else {
            ({ localLat, localLong } = fetchLocationCoordinates());
        }
        try {
            if (localLat && localLong) {
                const { data, error } = await getBSRListAPI(localLat, localLong, companyID);
                if (error) {
                    logError(error, false, 'getBSRList', []);
                } else if (updateInContext) {
                    filterDispatch({ type: SET_BSR_LIST, bsrList: data || [] });
                }
                return { data: data, error: error };
            }
        } catch (error) {
            logError(error, false, 'getBSRList', []);
        }
    };

    const setSelectedStoreInContext = (distance, pcList, localLat, localLong, viewCart, pcObj) => {
        storeTier2Radius(distance);
        const selectedStorePC = pcList?.find(ele => ele?.pc === selectedStoreDetails?.pc);
        const specialities = pcList?.find(
            item =>
                !item?.specialties ||
                item?.specialties?.length === 0 ||
                item?.specialties?.[0] === GENERAL_EQUIPMENT_AND_TOOLS
        );
        filterDispatch({
            type: SET_PICKUP_STORES,
            pickupStores: formatStoreDataToObject(pcList || []) || {}
        });
        /* Set selected stores details empty when tire2 radius is there */
        if (distance == tier2SearchRadius) {
            filterDispatch({
                type: SET_SELECTED_STORE_DETAILS,
                selectedStoreDetails: {},
                localLat,
                localLong,
                isNoStore: pcObj
            });
        } else if (!selectedStorePC || !viewCart?.isInStorePickup) {
            filterDispatch({
                type: SET_SELECTED_STORE_DETAILS,
                selectedStoreDetails: specialities || pcObj,
                localLat,
                localLong
            });
        } else {
            filterDispatch({
                type: SET_SELECTED_STORE_DETAILS,
                selectedStoreDetails: selectedStorePC,
                localLat,
                localLong
            });
        }
    };

    const fetchandUpdatePricingPCs = async () => {
        try {
            if (ENV_CONFIG.PAGE_TEMPLATE_STRING == CATEGORY_PAGE_TEMPLATE_STRING) {
                filterDispatch({ type: SET_SHOW_UNAVAILABLE_ITEMS, showUnavailableItems: true });
                filterDispatch({
                    type: SET_SHOW_NO_RESULTS_FOR_UNAVILABLE_ITEMS,
                    showNoResultsForUnavailableItems: false
                });
            }
            const overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
            const { localLat, localLong } = fetchLocationCoordinates();
            const { pcObj, pcList, distance, isIdenticalPcList, error } = await getLocationPCs(localLat, localLong);
            if (error) {
                filterDispatch({ type: SET_LOCATION_LOADING, isLocationsLoading: false });
                logError(error, false, 'fetchandUpdatePricingPCs', []);
            } else {
                if (pcObj?.pcVal != viewCart?.pc) {
                    if (
                        !(
                            startDate &&
                            endDate &&
                            isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.CARTTOTALQUANTITY)) > 0
                        )
                    ) {
                        updateGeography(
                            pcObj?.pcVal,
                            pcObj?.pcLat,
                            pcObj?.pcLong,
                            pcObj?.distance,
                            pcObj?.specialtyTypes,
                            '',
                            ''
                        );
                    }
                }
            }
            if (pcList) {
                // this to handle reload scenario, when locations api is not called, so pcList data will be not be present
                setSelectedStoreInContext(distance, pcList, localLat, localLong, viewCart, pcObj);
            }
            if (!isIdenticalPcList && !overridePC?.pc) {
                const cidPcList = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.CID_PC_LIST)) || [];
                const atpPcList = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ATP_PC_LIST)) || [];
                dispatch({ type: SET_CID_PC_LIST, cidPcList });
                dispatch({ type: SET_ATP_PC_LIST, atpPcList });
            }
            if (overridePC?.pc) {
                const pcs = [formatNearbyPC(overridePC?.pc)];
                sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.HOMEASSETSRATES);
                dispatch({ type: SET_HOME_ASSETS_RATES, homeAssetsRates: {} });
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.CID_PC_LIST, JSON.stringify(pcs));
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ATP_PC_LIST, JSON.stringify(pcs));
                dispatch({ type: SET_CID_PC_LIST, cidPcList: pcs });
                dispatch({ type: SET_ATP_PC_LIST, atpPcList: pcs });
            }
            // recomputing cart items for account change
            if (!isIdenticalPcList && hasAccountChanged) {
                sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.CART_ITEMS);
                dispatch({ type: SET_RECOMPUTE_ITEM_AVAILABILITY, recomputeItemsAvailability: true });
            }
            filterDispatch({ type: SET_LOCATION_LOADING, isLocationsLoading: false });
        } catch (error) {
            filterDispatch({ type: SET_LOCATION_LOADING, isLocationsLoading: false });
            logError(error, false, 'fetchandUpdatePricingPCs', []);
        }
    };

    const getLocationPCs = async (latitude, longitude, distance = tier1SearchRadius) => {
        try {
            const cidPcList = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.CID_PC_LIST) || '[]');
            let locationPCObj = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ)) || {};
            if (
                locationPCObj?.localLat == latitude &&
                locationPCObj?.localLong == longitude &&
                locationPCObj?.pc &&
                cidPcList?.length != 0
            ) {
                return {
                    pcObj: {
                        pcVal: locationPCObj?.pc,
                        pcLat: locationPCObj?.lat,
                        pcLong: locationPCObj?.long,
                        distance: locationPCObj?.distance,
                        specialtyTypes: locationPCObj?.specialtyTypes
                    },
                    isIdenticalPcList: true
                };
            } else {
                const {
                    pcObj,
                    pcList,
                    distance: pcDistance,
                    error
                } = await getPCfromLocations(latitude, longitude, distance, dispatch, showUnavailableItems);
                if (error) {
                    logError(error, false, 'getLocationPCs', [latitude, longitude, distance]);
                    return { error };
                } else {
                    return { pcObj, pcList, distance: pcDistance, error };
                }
            }
        } catch (error) {
            dispatch({ type: 'endLoading' });
            logError(error, false, 'getLocationPCs', [latitude, longitude, distance]);
            return { error };
        }
    };

    const getPC = async (
        localLat = '',
        localLong = '',
        localAssets = [],
        updateLocationInContext = true,
        pcPayload = {}
    ) => {
        try {
            let updatedAssets = assets;
            if (!cart && cartId) {
                const assetsFromStorage = JSON.parse(
                    sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCAL_ASSETS_DATA) || '[]'
                );
                if (assetsFromStorage?.length > 0) {
                    updatedAssets = assetsFromStorage;
                } else {
                    let cartData = await getCartDetails({
                        cartDetailsQuery,
                        dispatch,
                        cartId,
                        filterDispatch,
                        handleATPCart,
                        isRentalDetailsAvailable: isRentalDetailsAvailable()
                    });
                    updatedAssets = getAssets(cartData);
                }
            }

            let pcVal = 0;
            let pcLat = '';
            let pcLong = '';
            let lat = '';
            let long = '';
            let localStartDate = pcPayload?.startDate || startDate;
            let isDelivery = pcPayload?.isDelivery || !viewCart?.isInStorePickup;
            let localEndDate = pcPayload?.endDate || endDate;
            let distance = '';
            let specialtyTypes = [];
            const assetsFromPdpUrl = getAssetsFromPdpUrl();
            let storeData = {};
            let overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
            if (overridePC?.pc) {
                updateSelectedStoreDetailsForRoundTrip(overridePC);
                pcVal = overridePC?.pc;
                pcLat = overridePC?.latitude;
                pcLong = overridePC?.longitude;
                specialtyTypes = overridePC?.specialtyTypes;
                updateContextBasedOnCompanyId(
                    [],
                    [
                        {
                            key: 'nearbypcs',
                            val: []
                        }
                    ]
                );
            } else {
                dispatch({ type: 'beginLoading' });
                if (localLat && localLong) {
                    lat = localLat;
                    long = localLong;
                } else {
                    const coords = fetchLocationCoordinates();
                    lat = coords?.localLat;
                    long = coords?.localLong;
                }
                if (localAssets?.length > 0) {
                    updatedAssets = localAssets;
                } else if (assets?.length > 0) {
                    updatedAssets = assets;
                } else if (assetsFromPdpUrl.length > 0) {
                    updatedAssets = assetsFromPdpUrl;
                }
                if (localStartDate && localEndDate && updatedAssets?.length > 0) {
                    if (updateLocationInContext) {
                        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.SOURCEPCOBJ);
                    }
                    const currentStep = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.CHECKOUT_CURRENT_STEP);
                    ({ pcVal, pcLat, pcLong, distance, specialtyTypes, storeData } = await handleBranchSelectorCall(
                        pcPayload?.storeLat || lat,
                        pcPayload?.storeLong || long,
                        localStartDate,
                        localEndDate,
                        updatedAssets,
                        isDelivery,
                        pcPayload?.isLocationChanged || (isCheckoutPage() && currentStep == 1)
                    ));
                    if (!pcVal) {
                        dispatch({ type: 'endLoading' });
                        dispatch({ type: SET_IS_CART_LOADING, isCartLoading: false });
                        dispatch({
                            type: SET_IS_ESTIMATE_ON_CART_OPEN_REQUIRED,
                            key: 'isEstimateOnCartOpenRequired',
                            value: false
                        });
                    }
                } else {
                    const { pcObj } = await getLocationPCs(lat, long, tier1SearchRadius, dispatch);
                    ({ pcVal, pcLat, pcLong, distance, specialtyTypes } = pcObj);
                    dispatch({ type: 'endLoading' });
                    return { pcVal, pcLat, pcLong };
                }
            }
            dispatch({ type: 'endLoading' });
            if (updateLocationInContext) {
                updateGeography(pcVal, pcLat, pcLong, distance, specialtyTypes, lat, long, updatedAssets);
            }

            return { pcVal, pcLat, pcLong };
        } catch (e) {
            dispatch({ type: 'endLoading' });
        }
    };

    const isValidState = state => {
        if (validStateList?.includes(state)) {
            setCanadaRestrictStateError('');
            return true;
        } else {
            setCanadaRestrictStateError(intl.formatMessage({ id: 'error:canada-invalid-state' }));
            return false;
        }
    };

    const cityAndZipValidationAddressComponent = (details, companyID) => {
        const addressComponents = get(details, 'addressComponents', []);
        let isCityEmpty = true;
        let isZipEmpty = true;
        let isStateValid = true;
        let isStreetEmpty = true;
        let userCompanyId = companyID || localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID);
        if (addressComponents?.length > 0) {
            addressComponents?.map(component => {
                const type = determineTypeOfAddress(component);
                switch (type) {
                    case ADDRESS_COMPONENT_TYPE.STREET: {
                        isStreetEmpty = false;
                        break;
                    }
                    case ADDRESS_COMPONENT_TYPE.NEIGHBORHOOD: {
                        isStreetEmpty = false;
                        break;
                    }
                    case ADDRESS_COMPONENT_TYPE.ROUTE: {
                        isStreetEmpty = false;
                        break;
                    }
                    case ADDRESS_COMPONENT_TYPE.SUBLOCALITY: {
                        isCityEmpty = false;
                        break;
                    }
                    case ADDRESS_COMPONENT_TYPE.CITY: {
                        isCityEmpty = false;
                        break;
                    }
                    case ADDRESS_COMPONENT_TYPE.POSTAL_CODE: {
                        isZipEmpty = false;
                        break;
                    }
                    case ADDRESS_COMPONENT_TYPE.STATE: {
                        isStateValid = userCompanyId == 2 ? !isValidState(component.shortName) : false;
                        break;
                    }
                    default:
                        break;
                }
            });
        }
        if (isCityEmpty || isZipEmpty || isStateValid || isStreetEmpty) {
            return false;
        } else {
            return true;
        }
    };
    const cityAndZipWithoutStreetValidation = (details, companyID) => {
        const addressComponents = get(details, 'addressComponents', []);
        let isCityEmpty = true;
        let isStateValid = true;
        const companyId = companyID || localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) || 1;

        if (addressComponents?.length > 0) {
            addressComponents?.map(component => {
                const type = determineTypeOfAddress(component);
                switch (type) {
                    case ADDRESS_COMPONENT_TYPE.CITY: {
                        isCityEmpty = false;
                        break;
                    }
                    case ADDRESS_COMPONENT_TYPE.SUBLOCALITY: {
                        isCityEmpty = false;
                        break;
                    }
                    case ADDRESS_COMPONENT_TYPE.STATE: {
                        isStateValid = companyId == 2 ? !isValidState(component.shortName) : false;
                        break;
                    }
                    default:
                        break;
                }
            });
        }
        if (isCityEmpty || isStateValid) {
            return false;
        } else {
            return true;
        }
    };
    const cityStateValidationForLocationPage = details => {
        const addressComponents = get(details, 'addressComponents', []);
        let isCityEmpty = true;
        let isStateInvalid = true;

        if (addressComponents?.length > 0) {
            addressComponents?.map(component => {
                const type = determineTypeOfAddress(component);
                switch (type) {
                    case ADDRESS_COMPONENT_TYPE.CITY: {
                        isCityEmpty = false;
                        break;
                    }
                    case ADDRESS_COMPONENT_TYPE.STATE: {
                        isStateInvalid =
                            localStorage.getItem('companyID') == 2 ? !isValidState(component.shortName) : false;
                        break;
                    }
                    default:
                        break;
                }
            });
        }
        if (isCityEmpty || isStateInvalid) {
            return false;
        } else {
            return true;
        }
    };

    const getJobSite = () => {
        const isEnterAddress = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS)
            ? JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS))
            : false;
        if (userType === USER_TYPE.CREDIT && !isEnterAddress) {
            return projectDetails?.selectedProjectJobId || projectCookies?.CurrentJobSite;
        }
    };

    const globalRates = (items = [], pcVal = '') => {
        const isEnterAddress = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS)
            ? JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS))
            : false;
        const customerNumber =
            userType === USER_TYPE.CREDIT
                ? userAccount?.accountNumber?.toString() || projectCookies?.CurrentWynneAccount
                : viewCart?.customerNumber;
        const jobSite = getJobSite();
        let ratesAndProductid = {};
        if (userType === USER_TYPE.CREDIT && !isEnterAddress) {
            dispatch({
                type: SET_RATES_LOADING_FLAG,
                isRatesLoading: true
            });
            getRates(
                pcVal || pc || projectDetails?.locationPC || projectCookies?.locationPC,
                customerNumber,
                jobSite,
                items
            )
                .then(({ data, error }) => {
                    if (error) {
                        dispatch({ type: SET_HOME_ASSETS_RATES, homeAssetsRates: {} });
                        dispatch({
                            type: SET_RATES_LOADING_FLAG,
                            isRatesLoading: false
                        });
                        return;
                    }
                    data?.data?.items.map(ratesObject => {
                        const { daily, minimum, monthly, weekly } = ratesObject?.rates?.suggestedRates;
                        const rates = {
                            daily: Number(daily) || 0,
                            minimum: Number(minimum) || 0,
                            monthly: Number(monthly) || 0,
                            weekly: Number(weekly) || 0
                        };
                        let prodID = ratesObject?.productId;
                        ratesAndProductid[prodID] = rates;
                    });
                    let updatedAssets = {};
                    if (sessionStorage.getItem('homeAssetsRates')) {
                        updatedAssets = {
                            ...JSON.parse(sessionStorage.getItem('homeAssetsRates')),
                            ...ratesAndProductid
                        };
                    } else {
                        updatedAssets = ratesAndProductid;
                    }
                    dispatch({ type: SET_HOME_ASSETS_RATES, homeAssetsRates: updatedAssets });
                    sessionStorage.setItem('homeAssetsRates', JSON.stringify(updatedAssets));
                    dispatch({
                        type: SET_RATES_LOADING_FLAG,
                        isRatesLoading: false
                    });
                })
                .catch(e => {
                    dispatch({ type: SET_HOME_ASSETS_RATES, homeAssetsRates: {} });
                    dispatch({
                        type: SET_RATES_LOADING_FLAG,
                        isRatesLoading: false
                    });
                });
        } else if (pcVal || pc) {
            dispatch({
                type: SET_RATES_LOADING_FLAG,
                isRatesLoading: true
            });
            getRates(pcVal || pc, customerNumber, jobSite, items)
                .then(({ data, error }) => {
                    if (error) {
                        dispatch({ type: SET_HOME_ASSETS_RATES, homeAssetsRates: {} });
                        dispatch({
                            type: SET_RATES_LOADING_FLAG,
                            isRatesLoading: false
                        });
                        return;
                    }
                    data?.data?.items.map(ratesObject => {
                        const { daily, minimum, monthly, weekly } = ratesObject?.rates?.suggestedRates;
                        const rates = {
                            daily: Number(daily) || 0,
                            minimum: Number(minimum) || 0,
                            monthly: Number(monthly) || 0,
                            weekly: Number(weekly) || 0
                        };
                        let prodID = ratesObject?.productId;
                        ratesAndProductid[prodID] = rates;
                    });
                    let updatedAssets = {};
                    if (sessionStorage.getItem('homeAssetsRates')) {
                        updatedAssets = {
                            ...JSON.parse(sessionStorage.getItem('homeAssetsRates')),
                            ...ratesAndProductid
                        };
                    } else {
                        updatedAssets = ratesAndProductid;
                    }
                    dispatch({ type: SET_HOME_ASSETS_RATES, homeAssetsRates: updatedAssets });
                    sessionStorage.setItem('homeAssetsRates', JSON.stringify(updatedAssets));
                    dispatch({
                        type: SET_RATES_LOADING_FLAG,
                        isRatesLoading: false
                    });
                })
                .catch(e => {
                    dispatch({ type: SET_HOME_ASSETS_RATES, homeAssetsRates: {} });
                    dispatch({
                        type: SET_RATES_LOADING_FLAG,
                        isRatesLoading: false
                    });
                });
        } else {
            dispatch({ type: SET_HOME_ASSETS_RATES, homeAssetsRates: {} });
            dispatch({
                type: SET_RATES_LOADING_FLAG,
                isRatesLoading: false
            });
        }
    };

    const getAssetArray = assetsOnHomePage => {
        let assets = [];
        assetsOnHomePage?.forEach((item, index) => {
            const quantity = 1;
            assets.push({
                productId: item,
                quantity,
                sequenceNumber: index
            });
        });
        return assets;
    };

    const handleAutoAddress = async (selectedPlaceId, selectedAutoAddress, noStreetValidation = false) => {
        const responseFromPlaceDetails = await getLocationsDetailsByPlaceId(selectedPlaceId);
        if (responseFromPlaceDetails?.error || isObjectEmpty(responseFromPlaceDetails?.data?.result)) {
            return;
        }

        if (!cityAndZipValidationAddressComponent(responseFromPlaceDetails?.data?.result) && !noStreetValidation) {
            return;
        }
        if (!cityAndZipWithoutStreetValidation(responseFromPlaceDetails?.data?.result) && noStreetValidation) {
            return;
        }

        if (selectedAutoAddress) {
            filterDispatch({
                type: SET_VIEW_CART_FIELDS,
                key: 'location',
                value: selectedAutoAddress
            });
        }
        let lat = responseFromPlaceDetails?.data?.result?.geometry?.location?.lat || '';
        let long = responseFromPlaceDetails?.data?.result?.geometry?.location?.lng || '';

        let { jobSiteAddr2, jobSiteCity, jobSiteState, jobSiteZip } = extractAddressComponents(
            responseFromPlaceDetails?.data?.result
        );
        const { pcVal, pcLat, pcLong } = await getPC(lat, long);

        let assetsOnHomePage = getProductAssetsList();

        if (!jobSiteZip) {
            let latlng = `${lat},${long}`;
            let geocoderesponse = await geoCodeByLatLong(latlng);
            jobSiteZip = getZipFromLatLong(geocoderesponse?.data?.results[0]);
        }

        if (assetsOnHomePage?.length > 0 && pcVal === 0) {
            let localAssets = getAssetArray(assetsOnHomePage);
            const { pcVal, pcLat, pcLong } = await getPC(lat, long, localAssets);
            return {
                location: selectedAutoAddress,
                lat,
                long,
                jobSiteAddr2: '',
                jobSiteCity,
                jobSiteState,
                jobSiteZip,
                pc: pcVal,
                pcLat,
                pcLong
            };
        }

        return {
            location: selectedAutoAddress,
            lat,
            long,
            jobSiteAddr2: '',
            jobSiteCity,
            jobSiteState,
            jobSiteZip,
            pc: pcVal,
            pcLat,
            pcLong
        };
    };

    const handleManualInstoreAddress = async (
        manualAddress,
        noStreetValidation = false,
        updateLocationInContext = true
    ) => {
        const companyID = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) || '1';
        const responseFromGeocode = await getlatLongByAddress(companyID, manualAddress);

        if (
            responseFromGeocode?.error ||
            responseFromGeocode?.data?.results?.length == 0 ||
            isObjectEmpty(responseFromGeocode?.data)
        ) {
            return;
        }
        if (!cityAndZipValidationAddressComponent(responseFromGeocode?.data?.results[0]) && !noStreetValidation) {
            return;
        }
        if (!cityAndZipWithoutStreetValidation(responseFromGeocode?.data?.results[0]) && noStreetValidation) {
            return;
        }
        if (updateLocationInContext) {
            filterDispatch({
                type: SET_VIEW_CART_FIELDS,
                key: 'location',
                value: responseFromGeocode?.data?.results[0]?.formattedAddress
            });
        }

        let lat = responseFromGeocode?.data?.results[0]?.geometry?.location?.lat || '';
        let long = responseFromGeocode?.data?.results[0]?.geometry?.location?.lng || '';

        let { jobSiteAddr2, jobSiteCity, jobSiteState, jobSiteZip } = extractAddressComponents(
            responseFromGeocode?.data?.results[0]
        );

        if (!jobSiteZip) {
            let latlng = `${lat},${long}`;
            let geocoderesponse = await geoCodeByLatLong(latlng);
            jobSiteZip = getZipFromLatLong(geocoderesponse?.data?.results[0]);
        }

        const { pcVal, pcLat, pcLong } = await getPC(lat, long, [], false);

        let assetsOnHomePage = getProductAssetsList();
        if (assetsOnHomePage?.length > 0 && pcVal === 0) {
            let localAssets = getAssetArray(assetsOnHomePage);
            const { pcVal, pcLat, pcLong } = await getPC(lat, long, localAssets, [], false);
            return {
                location: responseFromGeocode?.data?.results[0]?.formattedAddress,
                lat,
                long,
                jobSiteAddr2: '',
                jobSiteCity,
                jobSiteState,
                jobSiteZip,
                pc: pcVal,
                pcLat,
                pcLong
            };
        }

        return {
            location: responseFromGeocode?.data?.results[0]?.formattedAddress,
            lat,
            long,
            jobSiteAddr2: '',
            jobSiteCity,
            jobSiteState,
            jobSiteZip,
            pc: pcVal,
            pcLat,
            pcLong
        };
    };

    const handleManualUpdatedInstoreAddress = async (manualAddress, noStreetValidation = false, companyID) => {
        const responseFromGeocode = await getlatLongByAddress(companyID, manualAddress);

        if (
            responseFromGeocode?.error ||
            responseFromGeocode?.data?.results?.length == 0 ||
            isObjectEmpty(responseFromGeocode?.data)
        ) {
            return;
        }
        if (
            !cityAndZipValidationAddressComponent(responseFromGeocode?.data?.results[0], companyID) &&
            !noStreetValidation
        ) {
            return;
        }
        if (
            !cityAndZipWithoutStreetValidation(responseFromGeocode?.data?.results[0], companyID) &&
            noStreetValidation
        ) {
            return;
        }

        let lat = responseFromGeocode?.data?.results[0]?.geometry?.location?.lat || '';
        let long = responseFromGeocode?.data?.results[0]?.geometry?.location?.lng || '';

        let { jobSiteAddr2, jobSiteCity, jobSiteState, jobSiteZip } = extractAddressComponents(
            responseFromGeocode?.data?.results[0],
            false
        );

        if (!jobSiteZip) {
            let latlng = `${lat},${long}`;
            let geocoderesponse = await geoCodeByLatLong(latlng);
            jobSiteZip = getZipFromLatLong(geocoderesponse?.data?.results[0]);
        }

        return {
            location: responseFromGeocode?.data?.results[0]?.formattedAddress,
            lat,
            long,
            jobSiteAddr2: '',
            jobSiteCity,
            jobSiteState,
            jobSiteZip
        };
    };

    const updateManualAddressInContext = async (lat, long) => {
        const { pcVal, pcLat, pcLong } = await getPC(lat, long, [], false);

        let assetsOnHomePage = getProductAssetsList();
        if (assetsOnHomePage?.length > 0 && pcVal === 0) {
            let localAssets = getAssetArray(assetsOnHomePage);
            const { pcVal, pcLat, pcLong } = await getPC(lat, long, localAssets, [], false);
            return {
                pc: pcVal,
                pcLat,
                pcLong
            };
        }

        return {
            pc: pcVal,
            pcLat,
            pcLong
        };
    };

    const computeInstore = async (
        selectedPlaceId = '',
        selectedAutoAddress = '',
        manualAddress = '',
        noStreetValidation = false,
        updateLocationInContext = true
    ) => {
        dispatch({ type: 'beginLoading' });
        let localLocation = {
            location: '',
            lat: '',
            long: '',
            jobSiteAddr2: '',
            jobSiteCity: '',
            jobSiteState: '',
            jobSiteZip: '',
            pc: '',
            pcLat: '',
            pcLong: ''
        };
        if (ENV_CONFIG.PAGE_TEMPLATE_STRING == CATEGORY_PAGE_TEMPLATE_STRING) {
            filterDispatch({ type: SET_SHOW_UNAVAILABLE_ITEMS, showUnavailableItems: true });
            filterDispatch({ type: SET_SHOW_NO_RESULTS_FOR_UNAVILABLE_ITEMS, showNoResultsForUnavailableItems: false });
        }
        let isCheckoutPage = window.location.href.includes('checkout-page');
        if (manualAddress) {
            localLocation = await handleManualInstoreAddress(
                manualAddress,
                noStreetValidation,
                updateLocationInContext
            );
        } else {
            localLocation = await handleAutoAddress(selectedPlaceId, selectedAutoAddress, noStreetValidation);
        }
        if (!localLocation && isCheckoutPage) {
            filterDispatch({
                type: RESET_GEOGRAPHY
            });
            dispatch({
                type: RESET_ORDER_ESTIMATES
            });
            dispatch({ type: 'endLoading' });
            return;
        } else if (!validateZip(localLocation?.jobSiteZip) && isCheckoutPage) {
            dispatch({ type: 'endLoading' });
            return localLocation;
        } else if (!localLocation || (!validateZip(localLocation?.jobSiteZip) && !noStreetValidation)) {
            dispatch({ type: 'endLoading' });
            return;
        } else if (localLocation?.pc === 0) {
            dispatch({ type: 'endLoading' });
            return localLocation;
        } else {
            dispatch({ type: 'endLoading' });
        }
        return localLocation;
    };

    async function fetchTimeZoneAPI(address) {
        let offset = '';
        dispatch({ type: 'beginLoading' });
        const responseData = await getTimezoneAPI(address);
        dispatch({ type: 'endLoading' });
        if (!responseData.error) {
            offset = getTimeFromCity(responseData?.data.data.timeZoneId);
            dispatch({
                type: SET_TIMEZONE_ID,
                timeZoneID: responseData?.data.data.timeZoneId
            });
            dispatch({
                type: SET_CURRENT_OFFSET_VALUE,
                currentOffset: offset
            });
        }
        return offset;
    }

    const storesCalls = async () => {
        dispatch({ type: 'beginLoading' });
        const viewCartFromStorage = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART)
            ? JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART))
            : viewCart;
        getStores(
            viewCartFromStorage,
            startDate,
            endDate,
            projectDetails,
            userType,
            dispatch,
            assets,
            howToGetYourOrderDetails,
            false
        );
        getClosestStores(
            viewCartFromStorage,
            startDate,
            endDate,
            projectDetails,
            userType,
            dispatch,
            assets,
            howToGetYourOrderDetails
        );
    };

    const computeContinueORCreditInstore = async (location, checkout, handleStepChange) => {
        if (checkout && handleStepChange) {
            let offset = '';
            if (location) {
                ({ offset } = await fetchTimeZoneAPI(location));
            }
            storesCalls();
        }
    };

    const getCanadaRestrictError = () => {
        return canadaRestrictStateError;
    };

    const getLocationPC = async (latitude, longitude, distance = tier1SearchRadius) => {
        try {
            const { pcObj, error } = await getPCfromLocations(
                latitude,
                longitude,
                distance,
                dispatch,
                showUnavailableItems
            );
            if (error) {
                logError(error, false, 'getLocationPC', [latitude, longitude, distance]);
                return { error };
            }
            if (pcObj) {
                return { pcObj, error };
            }
        } catch (error) {
            dispatch({ type: 'endLoading' });
            logError(error, false, 'getLocationPC', [latitude, longitude, distance]);
            return { error };
        }
    };

    const getZipFromLatLong = details => {
        let jobSiteZip = '';
        const addressComponents = get(details, 'addressComponents', []);
        if (addressComponents?.length > 0) {
            addressComponents?.map(component => {
                const type = determineTypeOfAddress(component);
                if (type == ADDRESS_COMPONENT_TYPE.POSTAL_CODE) {
                    jobSiteZip = component.longName;
                    return;
                }
            });
        }
        return jobSiteZip;
    };

    const updateManualLocationInContext = async localLocation => {
        const pcVal = await updateManualAddressInContext(localLocation?.lat, localLocation?.long);
        filterDispatch({
            type: SET_LOCATION_DATA,
            pc: pcVal?.pc,
            location: localLocation?.location,
            jobSiteCity: localLocation?.jobSiteCity,
            jobSiteState: localLocation?.jobSiteState,
            jobSiteZip: localLocation?.jobSiteZip,
            lat: localLocation?.lat,
            long: localLocation?.long,
            jobSiteAddr2: localLocation?.jobSiteAddr2,
            pcLat: pcVal?.pcLat,
            pcLong: pcVal?.pcLong
        });
        return pcVal;
    };

    return {
        getLocationPC,
        getPC,
        cityAndZipValidationAddressComponent,
        computeInstore,
        computeContinueORCreditInstore,
        extractAddressComponents,
        storesCalls,
        getCanadaRestrictError,
        canadaRestrictStateError,
        setCanadaRestrictStateError,
        cityStateValidationForLocationPage,
        cityAndZipWithoutStreetValidation,
        getZipFromLatLong,
        globalRates,
        fetchandUpdatePricingPCs,
        getLocationPCs,
        getBSRList,
        handleManualUpdatedInstoreAddress,
        updateManualAddressInContext,
        updateManualLocationInContext
    };
};
export default useComputeLocation;
