import React, { memo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { func, string } from 'prop-types';
import { useUserContext } from '../../../../aem-core-components/context/UserContext';
import { useCartState } from '../../../../contexts/cart/cartContext';
import { useFilterState } from '../../../cap';
import AccountDetails from '../accountDetails';
import ProjectListing from '../projectListing';
import CardRadioButton from '../../atoms/cardRadioButton';
import classes from './existingAccount.css';
import { locationOverlayDataLocators } from '../../../pdp/location/LocationOverlayModal/dataLocators';
import { existingAccountDataLocator } from './dataLocators';
import { SCREEN_NAME } from '../../../../constants/screenConstants';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';

const ExistingAccounts = props => {
    const { source, setSelectedProjectId, screenName, setShowAccountModal, handleAccountOverlay } = props;
    const intl = useIntl();
    const [{ userAccount }] = useCartState();
    const [{ projectDetails }] = useFilterState();
    const [userState, { getProjectsFromCookie, getAccountProjectsDotCom }] = useUserContext();
    const projectInfoCookies = getProjectsFromCookie();
    const { isProjectsLoading } = userState;
    const [searchErrorText, setSearchErrorText] = useState('');
    const [selectedValue, setSelectedValue] = useState(
        projectDetails?.selectedProjectJobId || projectInfoCookies?.CurrentJobSite || ''
    );

    const notFoundErrorMessagePrefix = intl.formatMessage({ id: 'projects:project-not-found-text-prefix' });
    const notFoundErrorMessageSuffix = intl.formatMessage({ id: 'projects:project-not-found-text-suffix' });

    useEffect(() => {
        if (!sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ISJOBSITESFETCHED)) {
            getAccountProjectsDotCom(projectInfoCookies?.CurrentWynneAccount);
        }
    }, []);

    useEffect(() => {
        if (projectDetails?.selectedProjectJobId) {
            setSelectedValue(projectDetails?.selectedProjectJobId);
        }
    }, [projectDetails?.selectedProjectJobId]);

    const updateProjectDetailsHandler = data => {
        setSelectedProjectId(data);
    };

    const cardStyle = data => {
        return (
            <div className={classes.accountCardRoot}>
                <span
                    className={classes.cardTitle}
                    data-testid={
                        screenName
                            ? locationOverlayDataLocators.locationoverlay_project_title_testid
                            : existingAccountDataLocator.project_name_testid
                    }>
                    {data?.jobName}
                </span>
                <small
                    className={classes.cardContent}
                    data-testid={
                        screenName
                            ? locationOverlayDataLocators.locationoverlay_project_address_testid
                            : existingAccountDataLocator.project_address_testid
                    }>
                    {data?.address1}
                </small>
                <small className={classes.cardContent}>
                    {data?.city}, {data?.state}, {data?.zip}
                </small>
            </div>
        );
    };
    const displaySearchResults = data => {
        return (
            data?.jobKey && (
                <div key={data?.jobKey} className="card-radio-button-wrapper">
                    <CardRadioButton
                        item={data}
                        cardStyle={cardStyle}
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                        onPressRadioButton={updateProjectDetailsHandler}
                        isAccount={false}
                        radioButtonAriaLabel={data?.jobName}
                        source={source}
                    />
                </div>
            )
        );
    };
    const handleSearchBarPlaceholder = () => {
        return localStorage.getItem('companyID') == 2
            ? intl.formatMessage({ id: 'accounts:Projectplaceholder-canada' })
            : intl.formatMessage({ id: 'accounts:Projectplaceholder' });
    };

    return (
        <>
            {screenName ? (
                <div>
                    <div className={classes.accountSelectorPadding}>
                        <h6
                            className={classes.locationOverlayTitle}
                            data-testid={locationOverlayDataLocators.locationoverlay_account_heading_testid}
                            tabIndex={0}>
                            {intl.formatMessage({ id: 'existing-account:account' })}
                        </h6>
                        <div className={classes.accountSelector}>
                            <AccountDetails
                                screenName={screenName}
                                setShowAccountModal={setShowAccountModal}
                                handleAccountOverlay={handleAccountOverlay}
                                accountLength="10"
                                accountName={userAccount.accountName}
                                accountNumber={userAccount.accountNumber}
                            />
                        </div>
                    </div>
                    <div className={classes.projectBg}>
                        <div className={classes.lineSeparator} />
                        <div
                            className={`
                                ${
                                    screenName === SCREEN_NAME.CHECKOUT_SCREEN
                                        ? classes.projectContainer
                                        : screenName === SCREEN_NAME.PDP_SCREEN
                                        ? classes.projectContainerPDP
                                        : classes.projectPadding
                                } ${searchErrorText ? `${classes.projectnotfound}` : ''} ${
                                isProjectsLoading ? 'projectLoaderWrapper' : ''
                            }
                                    `}>
                            <label
                                className={classes.locationOverlayTitle_projects}
                                data-testid={locationOverlayDataLocators.locationoverlay_chooseproject_heading_testid}
                                htmlFor={existingAccountDataLocator.choose_an_account_label}
                                tabIndex={0}>
                                {intl.formatMessage({ id: 'existing-account:choose-a-project' })}
                            </label>
                            <div
                                data-testid={locationOverlayDataLocators.locationoverlay_project_searchbar_testid}
                                className={classes.locationSearchBar}>
                                <ProjectListing
                                    placeholder={handleSearchBarPlaceholder()}
                                    displaySearchResults={displaySearchResults}
                                    isSearchIconVisible={true}
                                    screenName={screenName}
                                    handleInputBlur={true}
                                    setSearchErrorText={setSearchErrorText}
                                    source={source}
                                />
                                {searchErrorText && (
                                    <div className={`${classes.notFoundErrorContainer} notFoundError`}>
                                        <p>{notFoundErrorMessagePrefix}</p>
                                        <p className={classes.searchedText}>'{searchErrorText}'</p>
                                        <p>{notFoundErrorMessageSuffix}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={isProjectsLoading && classes.accountWrapper}>
                    <h6
                        className={classes.locationOverlayTitle}
                        data-testid={existingAccountDataLocator.select_project_text_testid}>
                        {intl.formatMessage({ id: 'existing-account:account' })}
                    </h6>
                    <div className={classes.accountSelector}>
                        <AccountDetails
                            setShowAccountModal={setShowAccountModal}
                            handleAccountOverlay={handleAccountOverlay}
                            accountLength="10"
                            accountName={userAccount.accountName}
                            accountNumber={userAccount.accountNumber}
                        />
                    </div>

                    <label
                        className={classes.locationOverlayTitle_projects}
                        data-testid={existingAccountDataLocator.select_project_text_testid}
                        htmlFor={existingAccountDataLocator.choose_an_account_label}>
                        {intl.formatMessage({ id: 'existing-account:choose-a-project' })}
                    </label>
                    <div className={classes.locationSearchBar}>
                        <ProjectListing
                            placeholder={handleSearchBarPlaceholder()}
                            displaySearchResults={displaySearchResults}
                            isSearchIconVisible={true}
                            handleInputBlur={true}
                            setSearchErrorText={setSearchErrorText}
                        />
                        {searchErrorText && (
                            <div className={`${classes.notFoundErrorContainer} notFoundError`}>
                                <p>{notFoundErrorMessagePrefix}</p>
                                <p className={classes.searchedText}>'{searchErrorText}'</p>
                                <p>{notFoundErrorMessageSuffix}</p>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

ExistingAccounts.propTypes = {
    source: string,
    screenName: string,
    setSelectedProjectId: func,
    setShowAccountModal: func,
    handleAccountOverlay: func
};

ExistingAccounts.defaultProps = {
    source: '',
    screenName: '',
    setSelectedProjectId: () => {},
    setShowAccountModal: () => {},
    handleAccountOverlay: () => {}
};

export default memo(ExistingAccounts);
