import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { useCheckUser } from '../../../../hooks/useCheckUser';
import { useCartState } from '../../../../contexts/cart/cartContext';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import { logError } from '../../utils/logger';
import ArrowLeftSvg from '../../../../resources/images/arrow-ios-left.svg';
import './backHeader.scss';
import { POP_CART_STACK } from '../../../../aem-core-components/actions/constants';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import { USER_TYPE } from '../../../../constants/userDetailsConstants';
import { CART_OVERLAYS } from '../../../../constants/cartConstants';
import { func } from 'prop-types';

function BackHeader(props) {
    const { closeModal } = props;
    const intl = useIntl();
    const [{ cartOverlay }, dispatch] = useCartState();
    const userType = useCheckUser();
    const { sendEventsForClick } = useAnalyticsContext();

    const handleBackLink = () => {
        closeModal ? closeModal(false) : dispatch({ type: POP_CART_STACK });
        if (
            ((cartOverlay === CART_OVERLAYS.LOCATION || cartOverlay === CART_OVERLAYS.ACCOUNT) &&
                userType === USER_TYPE.CREDIT) ||
            cartOverlay === CART_OVERLAYS.ROUND_TRIP
        ) {
            try {
                sendEventsForClick(
                    VARIABLE_CONFIG.EVENT.UAEVENT,
                    VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                    VARIABLE_CONFIG.EVENT_CATEGORY.MINI_CART,
                    `${userType} ${VARIABLE_CONFIG.EVENT_ACTION.SELECT} :: ${VARIABLE_CONFIG.EVENT_ACTION.ESTIMATE_DELIVERY}`,
                    VARIABLE_CONFIG.EVENT_LABEL.BACK
                );
            } catch (error) {
                logError(error, false, 'handleBackLink');
            }
        }
    };

    return (
        <div className="headerroot">
            <a
                className="left link center"
                data-testid="back-header__link"
                onClick={handleBackLink}
                aria-label={`${intl.formatMessage({ id: 'account:ac-common-click-here-msg' })} ${intl.formatMessage({
                    id: 'account:ac-minicart-back-btn-aria-label'
                })}`}
                tabIndex={0}>
                <ArrowLeftSvg aria-hidden={true} />
                <span className={'header__text__small'}>{intl.formatMessage({ id: 'cart:back-btn-text' })}</span>
            </a>
        </div>
    );
}

BackHeader.propTypes = {
    closeModal: func
};

BackHeader.defaultProps = {
    closeModal: null
};

export default memo(BackHeader);
