import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useCartState } from '../../../contexts/cart';
import { useUserContext } from '../../../aem-core-components/context/UserContext';
import { useAuthorityToken } from '../../../aem-core-components/utils/hooks';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import useMarketo from '../../../hooks/useMarketo';
import './contactus.scss';
import { useFilterState } from '../../cap';

const ContactUs = () => {
    const [confirmationStatus, setConfirmationStatus] = useState(0);
    const intl = useIntl();
    const [{ userAccount, isCreditNewAddress }, dispatch] = useCartState();
    const [{ viewCart, projectDetails }, filterDispatch] = useFilterState();
    const [{ userProfile }] = useUserContext();
    const formRef = useRef(null);
    const [formRendered, setFormRendered] = useState(false);
    const [accessToken] = useAuthorityToken('accesstoken');
    const { sendEventsForClick, sendEventsForMarketoForm } = useAnalyticsContext();
    const [{ MktoForms2WhenReady }] = useMarketo();

    const loadMarketoForm = () => {
        if (window.MktoForms2 && ((accessToken && userProfile?.oktaUserId) || !accessToken)) {
            MktoForms2?.loadForm('//pages.sunbeltrentals.com', '581-YTY-386', '3044', function (form) {
                setFormRendered(true);
                sendEventsForMarketoForm(
                    VARIABLE_CONFIG.EVENT.FORM_VIEWED,
                    VARIABLE_CONFIG.FORM_ID.CONTACT_US,
                    VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                    VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                    VARIABLE_CONFIG.ECOMMERCE.UNDEFINED
                );
                form?.addHiddenFields({
                    userInformation: JSON.stringify({
                        userType: !userProfile ? 'guest' : userProfile?.Type === 0 ? 'credit' : 'cash',
                        userGuid: userProfile?.userGuid || '',
                        oktaUserId: userProfile?.oktaUserId || '',
                        accountName: userProfile?.Type === 0 ? userAccount?.accountName : '',
                        accountId: userProfile?.Type === 0 ? userAccount?.accountNumber : '',
                        companyId: parseInt(localStorage.getItem('companyID')) || 1
                    }),
                    pageInformation: JSON.stringify({
                        pageURL: window.location.href,
                        formTitle: 'Contact Us',
                        referringURL: window.location.host
                    }),
                    productInformation: JSON.stringify({
                        categoryName: '',
                        categoryId: '',
                        catClassName: '',
                        catClassId: '',
                        rate: '',
                        specialtyId: ''
                    })
                });
                if (userProfile?.oktaUserId) {
                    form.setValues({
                        FirstName: userProfile?.firstName,
                        LastName: userProfile?.lastName,
                        Email: userProfile?.email,
                        Phone: userProfile?.phone ? userProfile.phone.replace(/[- )(]/g, '') : ''
                    });
                    let country = document.getElementById('Country');
                    country.parentElement.parentElement.style.display = 'none';
                }
                form.onSubmit(function (values) {
                    sendEventsForClick(
                        VARIABLE_CONFIG.EVENT.UAEVENT,
                        VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                        VARIABLE_CONFIG.EVENT_CATEGORY.CONTACT_US,
                        VARIABLE_CONFIG.EVENT_ACTION.SUBMIT,
                        VARIABLE_CONFIG.EVENT_LABEL.CONTACT_US_FORM_SUBMIT
                    );
                });
                form.onSuccess(function (values) {
                    const dropdownOptions = document.getElementById('mktoContactUsSubject');
                    const subject = dropdownOptions?.options?.[dropdownOptions?.selectedIndex]?.text;
                    sendEventsForMarketoForm(
                        VARIABLE_CONFIG.EVENT.FORM_COMPLETED,
                        VARIABLE_CONFIG.FORM_ID.CONTACT_US,
                        VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                        VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                        subject
                    );
                    setConfirmationStatus(1);

                    return false;
                });
            });
            MktoForms2?.whenReady(function (form) {
                MktoForms2WhenReady(form, false);
            });
        }
    };

    const changeLocation = () => {
        if (formRendered && (viewCart?.location || projectDetails?.projectAddress1)) {
            let form = MktoForms2?.getForm('3044');
            let address = '',
                city = '',
                state = '',
                zip = '';
            if (userProfile?.Type === 0) {
                address = isCreditNewAddress ? viewCart?.location : projectDetails?.projectAddress1;
                city = isCreditNewAddress ? viewCart?.jobSiteCity : projectDetails?.selectedProjectCity;
                state = isCreditNewAddress ? viewCart?.jobSiteState : projectDetails?.selectedProjectState;
                zip = isCreditNewAddress ? viewCart?.jobSiteZip : projectDetails?.selectedProjectZip;
            }
            if (viewCart?.location && userProfile?.Type !== 0) {
                address = viewCart?.location;
                city = viewCart?.jobSiteCity;
                state = viewCart?.jobSiteState;
                zip = viewCart?.jobSiteZip;
            }
            form?.setValues({
                Address: address,
                City: city,
                State: state,
                PostalCode: zip,
                Country: parseInt(localStorage.getItem('companyID')) == 2 ? 'Canada' : 'United States'
            });
            form?.addHiddenFields({
                userInformation: JSON.stringify({
                    userType: !userProfile ? 'guest' : userProfile?.Type === 0 ? 'credit' : 'cash',
                    userGuid: userProfile?.userGuid || '',
                    oktaUserId: userProfile?.oktaUserId || '',
                    accountName: userProfile?.Type === 0 ? userAccount?.accountName : '',
                    accountId: userProfile?.Type === 0 ? userAccount?.accountNumber : '',
                    companyId: parseInt(localStorage.getItem('companyID')) || 1
                })
            });
        }
    };

    useEffect(() => {
        loadMarketoForm();
    }, [userProfile?.oktaUserId]);

    useEffect(() => {
        changeLocation();
    }, [formRendered, viewCart?.location, projectDetails?.projectAddress1]);

    useEffect(() => {
        if (confirmationStatus === 1) {
            window.location.href = '/customer-support/forms/contact/contact-us-thankyou/';
        }
    }, [confirmationStatus]);

    return (
        <>
            <section className="block block--contactpage">
                <div className="contactpage__wrap">
                    <h4 className="contactpage__title">Contact us</h4>
                    <div className="contactpage__description">
                        Thank you for your interest in Sunbelt Rentals! Our customer care team is available to assist
                        with your equipment rental needs and answer any questions you may have.
                    </div>
                    <form id={'mktoForm_3044'} ref={formRef} />
                </div>
            </section>
        </>
    );
};

export default ContactUs;
