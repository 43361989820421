import { callGtm } from "../../GTM/";
var isGTMHidden = document.querySelector('meta[name="hideGTM"]')?.content;
if(isGTMHidden === "false"){
	var catList = document.querySelectorAll(".category__card"); 
	catList.forEach((categoryListcomponent) => {    
		categoryListcomponent.addEventListener('click', function (e) { 
				
			var eventAction = e.target.closest('.category__card').querySelector(".category__title").innerText;	
			var eventLabel = categoryListcomponent.innerText.toLowerCase();
			
			callGtm('uaevent', 'undefined', 'super category title', eventAction, eventLabel);
			
		});   
	});
}
