import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import config from './config';

const SentryFallback = ({ error, componentStack, resetError }) => {
    const isDevelopment = process.env.NODE_ENV === 'development';
    const errorObj = { error: error?.message, componentStack, isFallbackComponent: true }
    useEffect(() => {
        try {
            Sentry.configureScope(function (scope) {
                scope.setTag('isFallbackComponent', true);
            });
            Sentry.captureException({ error: JSON.stringify(errorObj) });
            Sentry.configureScope(function (scope) {
                scope.setTag('isFallbackComponent', false);
            });
            if (!isDevelopment) {
                if (window.location.pathname !== config?.pagePaths?.wiresCrossedPage) {
                    window.location.href = config.pagePaths.wiresCrossedPage;
                }
            }
        } catch (e) {
            Sentry.captureException("Exception occurred in fallback component");
        }
    }, []);
    return null;
};

export default SentryFallback;
