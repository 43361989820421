
import React from 'react';
import { string, node, object } from 'prop-types';

import classes from './fieldIcons.css';

const FieldIcons = props => {
    const { after, before, children } = props;

    const style = {
        '--iconsBefore': before ? 1 : 0,
        '--iconsAfter': after ? 1 : 0
    };

    return (
        <span className={classes.root} style={style}>
            <span className={classes.input}>{children}</span>
            <span className={classes.before}>{before}</span>
            <span className={classes.after}>{after}</span>
        </span>
    );
};

FieldIcons.propTypes = {
    after: object,
    before: string,
    root: string,
    children: node
};

export default FieldIcons;
