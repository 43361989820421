import React, { createContext, useContext, useReducer } from 'react';
import { favoritesInitialState, favoritesReducer } from './reducers/favoritesReducer';
import { func, object } from 'prop-types';

export const FavouritesContext = createContext();

export const FavouritesProvider = props => {
    const reducer = props.reducer || favoritesReducer;
    const initialState = props.initialState || favoritesInitialState;

    return (
        <FavouritesContext.Provider value={useReducer(reducer, initialState)}>
            {props.children}
        </FavouritesContext.Provider>
    );
};

FavouritesProvider.propTypes = {
    reducer: func,
    initialState: object
};

export const useFavouritesState = () => useContext(FavouritesContext);
