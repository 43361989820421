import { func, object } from 'prop-types';
import React, { createContext, useContext, useReducer } from 'react';
import { quotesInitialState, quotesReducer } from './reducers/quotesReducer';

export const QuotesContext = createContext();

export const QuotesProvider = props => {
    const reducer = props.reducer || quotesReducer;
    const initialState = props.initialState || quotesInitialState;

    return <QuotesContext.Provider value={useReducer(reducer, initialState)}>{props.children}</QuotesContext.Provider>;
};

QuotesProvider.propTypes = {
    reducer: func,
    initialState: object
};

export const useQuotesState = () => useContext(QuotesContext);
