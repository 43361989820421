import { bool, func, string } from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useUserContext } from '../../../../aem-core-components/context/UserContext';
import { useFilterState } from '../../../cap';
import { useCartState } from '../../../../contexts/cart/cartContext';
import { useCheckAuthorityType } from '../../../../hooks/useCheckUser';
import useUserData from '../../../../hooks/useUserData';
import SearchBar from '../SearchBar';
import Button from '../../atoms/button';
import CardRadioButton from '../../atoms/cardRadioButton';
import ViewCartFooter from '../../atoms/viewCartFooter';
import { BackHeader } from '../../atoms/backHeader';
import { updateDefaultAccount } from '../../api/CommonResponseHandler';
import { isAccountNotActive } from '../../utils/commonUtils';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';
import AlertIconRed from '../../../../resources/images/alert-circle-red.svg';
import classes from './chooseAccount.css';
import {
    POP_CART_STACK,
    RESET_PROJECT,
    SET_CREDIT_NEWADDR_FLAG,
    SET_LAST_USED_JOBSITE,
    UPDATE_ACCOUNT,
    SET_LOCATION_LOADING,
    RESET_GEOGRAPHY,
    RESET_LOCATION_DATA
} from '../../../../aem-core-components/actions/constants';
import { accountsDatalocator } from './dataLocators';
import { locationOverlayDataLocators } from '../../../pdp/location/LocationOverlayModal/dataLocators';
import { AUTHORITY_TYPE } from '../../constants';
import { SET_IS_JOBSITE_RECOMMENDATION_LOADING, SET_SELECTED_STORE_DETAILS } from '../../../cap/constants';

const ChooseAccount = props => {
    const { setShowAccountModal, handleAccountOverlay, screenName, footerClass, ctaTitle, hideFooter, source } = props;
    const intl = useIntl();
    const [{ userAccount, punchoutUserData }, dispatch] = useCartState();
    const [{}, filterDispatch] = useFilterState();
    const [userState, { getAccountProjectsDotCom, dispatch: userDispatch }] = useUserContext();
    const [{ clearProjectDetailCookies }] = useUserData();
    const { userProfile } = userState;
    const [accountList, setAccountList] = useState([]);
    userAccount?.accountNumber || userProfile?.accounts[0]?.account || '';
    const [selectedValue, setSelectedValue] = useState();
    const [selectedAccount, setSelectedAccount] = useState(
        userAccount?.accountNumber || userProfile?.accounts[0] || ''
    );
    const searchCriteria = ['name', 'account'];
    const notFoundErrorMessage = intl.formatMessage({ id: 'accounts:account-not-found' });
    const defaultCompanyId = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID));
    const isCanada = defaultCompanyId === 1 ? false : true;
    const authorityType = useCheckAuthorityType();

    useEffect(() => {
        let removedItem;
        let tempAccountList;
        if (selectedValue) {
            removedItem = userProfile?.accounts?.find(data => {
                return data?.account == selectedValue;
            });

            tempAccountList = userProfile?.accounts?.filter(data => {
                return data?.account != selectedValue;
            });
            tempAccountList.sort((prevAccount, currentAccount) => prevAccount.name.localeCompare(currentAccount.name));
            tempAccountList?.unshift(removedItem);
            setAccountList(tempAccountList);
        }
    }, []);

    useEffect(() => {
        if (authorityType === AUTHORITY_TYPE.P2P) {
            const activeAccounts = userProfile?.accounts.filter(account => !isAccountNotActive(account.accountStatus));
            setAccountList(activeAccounts);
        } else {
            setAccountList(userProfile?.accounts);
        }
    }, []);

    const updateAccountDetailsHandler = data => {
        setSelectedAccount(data);
    };

    const handleBackLink = () => {
        dispatch({ type: POP_CART_STACK });
    };

    const corpLinkStatusFromAPIResponse = accNumber => {
        const corpLinkStatus = userProfile?.accounts?.find(account => account?.id == accNumber)?.isCorpLinkAccount;
        return corpLinkStatus;
    };

    const userDetailHandler = async data => {
        if (!data || !data?.name) {
            if (authorityType !== AUTHORITY_TYPE.P2P) {
                updateDefaultAccount(userAccount?.accountNumber, isCanada, userAccount?.accountName);
            }
            dispatch({
                type: UPDATE_ACCOUNT,
                accountName: userAccount?.accountName,
                accountNumber: userAccount?.accountNumber,
                accountStatus: userAccount?.accountStatus,
                isCorpLinkAccount: corpLinkStatusFromAPIResponse(userAccount?.accountNumber)
            });
        } else if (userAccount?.accountNumber !== data.account) {
            dispatch({ type: 'beginProjectsLoading' });
            userDispatch({ type: 'setHasAccountChanged', value: true });
            filterDispatch({ type: RESET_LOCATION_DATA });
            filterDispatch({ type: RESET_GEOGRAPHY });
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.SELECTED_ACCOUNT_ID, data?.account);
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC);
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.BSR_PC_LIST);
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.IS_LOCATIONS_TIER_2_RADIUS);
            if (authorityType !== AUTHORITY_TYPE.P2P) {
                updateDefaultAccount(data?.account, isCanada, data?.name);
            }
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.IS_ALL_JOBSITE_FETCHED);
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.RECOMMENDED_JOBSITE);
            filterDispatch({ type: SET_LOCATION_LOADING, isLocationsLoading: true });
            dispatch({
                type: UPDATE_ACCOUNT,
                accountName: data?.name,
                accountNumber: data?.account,
                accountStatus: data?.accountStatus,
                isCorpLinkAccount: corpLinkStatusFromAPIResponse(data?.account),
                isValueUpdated: true
            });
            clearProjectDetailCookies();
            filterDispatch({ type: RESET_PROJECT });
            filterDispatch({ type: SET_IS_JOBSITE_RECOMMENDATION_LOADING, isLoading: true });
            filterDispatch({
                type: SET_LAST_USED_JOBSITE,
                lastUsedJobsite: {}
            });
            filterDispatch({ type: SET_SELECTED_STORE_DETAILS, selectedStoreDetails: {} });
            dispatch({ type: SET_CREDIT_NEWADDR_FLAG, isCreditNewAddress: false });
            localStorage.setItem('companyID', data?.companyID);
            localStorage.removeItem('projectDetails');

            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.ISJOBSITESFETCHED);
            getAccountProjectsDotCom(data.account);
        }
        closeChooseAccount();
    };

    const closeChooseAccount = () => {
        if (setShowAccountModal) {
            setShowAccountModal(false);
        } else if (handleAccountOverlay) {
            handleAccountOverlay(false);
        } else {
            handleBackLink();
        }
    };

    const handleIconInactive = status => {
        const statusLowerCase = status?.toLowerCase();
        if (statusLowerCase !== 'a') {
            return <AlertIconRed tabIndex={'-1'} aria-hidden={true} />;
        }
    };
    const handleTextInactive = status => {
        const statusLowerCase = status?.toLowerCase();
        if (statusLowerCase !== 'a') {
            return intl.formatMessage({ id: 'delinquent-account-text' });
        }
    };

    const cardStyle = data => {
        try {
            return (
                <div className={classes.accountCardRoot}>
                    <span
                        className={classes.cardTitle}
                        data-testid={
                            screenName
                                ? locationOverlayDataLocators.locationoverlay_accounts_name_testid
                                : accountsDatalocator.account_name_testid
                        }>
                        {data?.name}
                    </span>
                    <small
                        className={`${classes.cardContent} ${classes.cardContentWithIcon}`}
                        data-testid={
                            screenName
                                ? locationOverlayDataLocators.locationoverlay_accounts_number_testid
                                : accountsDatalocator.account_number_testid
                        }>
                        <span>
                            {intl.formatMessage({ id: 'accounts:account-number' })} {data?.account}
                        </span>
                        {handleIconInactive(data?.accountStatus)}
                    </small>
                </div>
            );
        } catch (exp) {
            alert('Account not present');
        }
    };

    const displaySearchResults = data => {
        return (
            <>
                <CardRadioButton
                    key={data?.account}
                    item={data}
                    cardStyle={cardStyle}
                    selectedValue={selectedValue}
                    setSelectedValue={setSelectedValue}
                    onPressRadioButton={updateAccountDetailsHandler}
                    isAccount={true}
                    btnclasses={true}
                    radioButtonAriaLabel={`${data?.name} ${intl.formatMessage({
                        id: 'account:ac-minicart-account-number-text'
                    })}${data?.account} ${handleTextInactive(data?.accountStatus)}`}
                />
            </>
        );
    };

    return (
        <>
            {!setShowAccountModal && <BackHeader closeModal={handleAccountOverlay} />}
            <div className={classes.chooseAccountbody}>
                <h5
                    className={classes.chooseAccountHeading}
                    data-testid={
                        screenName
                            ? locationOverlayDataLocators.locationoverlay_accounts_heading_testid
                            : accountsDatalocator.accounts_header_testid
                    }
                    tabIndex={0}>
                    {intl.formatMessage({ id: 'accounts:accounts' })}
                </h5>
                <label
                    data-testid={
                        screenName
                            ? locationOverlayDataLocators.locationoverlay_accounts_chooseaccountheading_testid
                            : ''
                    }
                    className={classes.chooseAccountSubHeading}
                    htmlFor={accountsDatalocator.choose_an_account_label}
                    tabIndex={0}>
                    {intl.formatMessage({ id: 'accounts:choose-an-account' })}
                </label>
                <div className={`${classes.scrollableSearchResults}`}>
                    <SearchBar
                        source={source}
                        dataSet={accountList}
                        placeholder={
                            punchoutUserData?.isPunchoutUser
                                ? intl.formatMessage({ id: 'accounts:placeholder-punchoutuser' })
                                : intl.formatMessage({ id: 'accounts:placeholder' })
                        }
                        displaySearchResults={displaySearchResults}
                        searchCriteria={searchCriteria}
                        notFoundErrorMessage={notFoundErrorMessage}
                        isSearchIconVisible={true}
                        data-testid={accountsDatalocator.accounts_searchbar_testid}
                        screenName={screenName}
                    />
                </div>
            </div>
            {hideFooter && (!accountList || accountList?.length <= 0) ? null : (
                <ViewCartFooter
                    className="minicart-footer-actions"
                    footerClass={`account_switch_actions ${footerClass}`}>
                    <Button
                        data-testid={
                            screenName ? locationOverlayDataLocators.locationoverlay_accounts_switchaccCTA_testid : ''
                        }
                        type="button"
                        className={'button button-primary button-block'}
                        onClick={() => userDetailHandler(selectedAccount)}
                        buttonAriaLabel={intl.formatMessage({
                            id: 'accounts:switch-account'
                        })}>
                        {ctaTitle ? ctaTitle : intl.formatMessage({ id: 'accounts:switch-account' })}
                    </Button>
                </ViewCartFooter>
            )}
        </>
    );
};
ChooseAccount.propTypes = {
    setShowAccountModal: func,
    handleAccountOverlay: func,
    screenName: string,
    footerClass: string,
    ctaTitle: string,
    hideFooter: bool,
    source: string
};

ChooseAccount.defaultProps = {
    setShowAccountModal: () => {},
    handleAccountOverlay: () => {},
    screenName: '',
    footerClass: '',
    ctaTitle: '',
    hideFooter: false,
    source: ''
};

export default memo(ChooseAccount);
