import React, { useEffect } from 'react';
import PropTypes, { string } from 'prop-types';
import Modal from 'react-modal';
import Header from './Header';
import Footer from './Footer';
import Body from './Body';
import './mobileView.scss';
import { CHECKOUT_CHOOSE_STORE_HEADER_ID } from '../../../checkout/constants';
import { trapFocus } from '../../utils/commonUtils';

export default function MobileView(props) {
    const {
        isOpen,
        rootClass,
        customHeader,
        showHeader,
        customHeaderWrapClass,
        children,
        handleClose,
        customHeaderClass,
        headerTitle,
        footerContent,
        mobileBodyClass,
        customFooterClass,
        isChooseStoreView
    } = props;

    useEffect(() => {
        const liveChatButton = document.querySelector('.chat-button');
        document?.body?.classList?.add('overflow-hidden');

        if (liveChatButton) {
            liveChatButton.ariaHidden = 'true';
            liveChatButton.tabIndex = '-1';
        }
        return () => {
            document?.body?.classList?.remove('overflow-hidden');
            if (liveChatButton) {
                liveChatButton.ariaHidden = 'false';
                liveChatButton.tabIndex = '0';
            }
        };
    }, []);

    const afterOpenModal = () => {
        if (isOpen) {
            trapFocus(document.querySelector('#mobileViewRoot'));
        }
    };

    const renderHeader = () => {
        return (
            <div
                className={`mobile-view-header ${customHeaderWrapClass}`}
                id={isChooseStoreView && CHECKOUT_CHOOSE_STORE_HEADER_ID}>
                {customHeader || (
                    <Header customHeaderClass={customHeaderClass} title={headerTitle} handleBtnClick={handleClose} />
                )}
            </div>
        );
    };

    const renderFooter = () => {
        return <Footer> {footerContent()}</Footer>;
    };

    return (
        <>
            <Modal isOpen={isOpen} className={`${rootClass}`} id="mobileViewRoot" onAfterOpen={afterOpenModal}>
                {showHeader && renderHeader()}
                <Body className={mobileBodyClass}>{children}</Body>
                {!!footerContent && renderFooter()}
            </Modal>
        </>
    );
}

MobileView.propTypes = {
    handleClose: PropTypes.func,
    showHeader: PropTypes.bool,
    children: PropTypes.node,
    customHeader: PropTypes.node,
    headerTitle: PropTypes.string,
    footerContent: PropTypes.func,
    customHeaderClass: PropTypes.string,
    mobileBodyClass: PropTypes.string,
    rootClass: PropTypes.string,
    customFooterClass: string,
    customHeaderWrapClass: string
};

MobileView.defaultProps = {
    handleClose: () => {},
    showHeader: true,
    children: null,
    customHeader: null,
    footerContent: () => {},
    headerTitle: 'Back',
    customHeaderClass: '',
    mobileBodyClass: '',
    rootClass: '',
    customFooterClass: '',
    isChooseStoreView: false,
    customHeaderWrapClass: ''
};
