import { callGtm } from "../../GTM/";
var isGTMHidden = document.querySelector('meta[name="hideGTM"]')?.content;
if(isGTMHidden === "false"){
	var catCards = document.querySelectorAll(".category__card"); 
	catCards.forEach((catCard) => {    
		catCard.addEventListener("click", function (e) { 
			var eventCategory = e?.target?.closest('.wrapper_container')?.querySelector(".cards__category__title")?.innerText;
			var eventLabel = catCard.innerText;
			callGtm('uaevent', 'undefined', eventCategory, 'link', eventLabel);     
		});   
	});
}