export const cookieValue = cookieName => {
    let name = cookieName + '=';
    let cookieVal = document.cookie;
    let ca = cookieVal.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};

export const deleteCookie = cookieName => {
    return (document.cookie =
        cookieName + '=; Path=/; SameSite=None; Secure; Max-Age=0; Expires=Thu, 01 Jan 1970 00:00:01 GMT;');
};
