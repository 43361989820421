import { CLOSE_MODAL, OPEN_MODAL } from './constants';

export const modalReducer = (state, action) => {
    switch (action.type) {
        case OPEN_MODAL:
            return {
                ...state,
                isModalOpen: true,
                modalType: action?.modalType,
                modalProps: action?.modalProps
            };
        case CLOSE_MODAL:
            return {
                ...state,
                isModalOpen: false,
                modalType: null,
                modalProps: null
            };
        default:
            return state;
    }
};
