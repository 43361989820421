import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useFilterState } from '../components/cap';
import { useUserContext } from '../aem-core-components/context/UserContext';
import { usePageType } from './usePageType';
import { useCheckUser } from './useCheckUser';
import { COACHMARK_TYPE } from '../components/cap/constants';
import { STORAGE_CONFIG } from '../constants/storageConfig';
import { USER_TYPE } from '../constants/userDetailsConstants';
import { isValidString } from '../components/global/utils/logger';
import { ENV_CONFIG } from '../constants/envConfig';
import { TILE_STATES } from '../components/global/modules/productTile/constants';

const {
    CHANGE_STORE,
    CHANGE_DATES_GRT24HRS,
    CHANGE_DATES_GRT72HRS,
    CHANGE_DATES_OR_STORE_GRT72HRS,
    CHANGE_DATES_OR_STORE_GRT24HRS
} = TILE_STATES;

const useCoachmark = () => {
    const intl = useIntl();
    const [, , { updateCoachmark }] = useFilterState();
    const [{ userProfile }] = useUserContext();
    const pageType = usePageType();
    const userType = useCheckUser();
    const coachmarkData = ENV_CONFIG.COACHMARK_STATUS;

    useEffect(() => {
        let coachmarkObj = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.COACHMARK_OBJ)
            ? JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.COACHMARK_OBJ))
            : undefined;
        if (!coachmarkObj) {
            const obj = {
                [COACHMARK_TYPE.LOCATION]: [],
                [COACHMARK_TYPE.PICK_STORE]: [],
                [COACHMARK_TYPE.PRODUCT_TILE]: []
            };
            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.COACHMARK_OBJ, JSON.stringify(obj));
        }
    }, []);

    const getCountFromSession = type => {
        let coachmarkObj = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.COACHMARK_OBJ) || {});
        return coachmarkObj?.[type]?.length || 0;
    };

    const handleGetLocalCount = type => {
        const count = getCountFromSession(type);
        return count ? Number(count) : 0;
    };

    const handleSetCount = coachMarkType => {
        let coachmarkObj = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.COACHMARK_OBJ) || '{}');
        if (coachMarkType) {
            if (coachmarkObj?.[coachMarkType]?.indexOf(pageType) === -1) {
                coachmarkObj?.[coachMarkType]?.push(pageType);
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.COACHMARK_OBJ, JSON.stringify(coachmarkObj));
                if (coachmarkObj?.[coachMarkType]?.length === 1 && userType !== USER_TYPE.GUEST) {
                    const magentoCount = getCountFromMagento(coachMarkType);
                    incrementCountInMagento(coachMarkType, magentoCount);
                }
            }
        }
    };

    const getCountFromMagento = type => {
        const magentoCount = userProfile?.coachmark;
        return magentoCount?.[type] || 0;
    };

    const incrementCountInMagento = (coachMarkType, count) => {
        let payload = null;
        switch (coachMarkType) {
            case COACHMARK_TYPE.LOCATION:
                payload = { location: count + 1 };
                break;

            case COACHMARK_TYPE.PICK_STORE:
                payload = { pickstore: count + 1 };
                break;

            case COACHMARK_TYPE.PRODUCT_TILE:
                payload = { producttile: count + 1 };
                break;
        }
        updateCoachmark(payload);
    };

    const isCoachMarkSessionVisitNotFulfilled = type => {
        const localCount = handleGetLocalCount(type);
        if (userType === USER_TYPE.GUEST) {
            return localCount < 2;
        }
        const sessionLimit = Number(coachmarkData.coachmarklimit ?? 5) || 0;
        const sessionCount = getCountFromMagento(type) || 0;
        return sessionCount < sessionLimit && localCount < 2;
    };

    const isCoachMarkNotVisitedOnSamePage = type => {
        const coachmarkObj = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.COACHMARK_OBJ) || '{}');
        return coachmarkObj[type]?.indexOf(pageType) === -1;
    };

    const isCoachmarkFlagOn = type => {
        switch (true) {
            case userType === USER_TYPE.GUEST && !isValidString(coachmarkData?.guestuserscoachmarkflag ?? true):
                return false;
            case type === COACHMARK_TYPE.LOCATION || type === COACHMARK_TYPE.PICK_STORE:
                return isValidString(coachmarkData?.showcoachmarkoncap ?? true);
            case type === COACHMARK_TYPE.PRODUCT_TILE:
                return isValidString(coachmarkData?.producttilecoachmarkflag ?? true);
            default:
                return false;
        }
    };

    const showCoachmark = type => {
        if (isCoachmarkFlagOn(type) && isCoachMarkNotVisitedOnSamePage(type)) {
            return isCoachMarkSessionVisitNotFulfilled(type);
        } else {
            return false;
        }
    };

    const getProductTileCoachmarkMsg = tileState => {
        switch (tileState) {
            case CHANGE_STORE:
                return coachmarkData?.changestoretooltip || intl.formatMessage({ id: 'coachmark:change-store' });
            case CHANGE_DATES_GRT24HRS:
            case CHANGE_DATES_GRT72HRS:
                return coachmarkData?.changedatetooltip || intl.formatMessage({ id: 'coachmark:change-dates' });
            case CHANGE_DATES_OR_STORE_GRT24HRS:
            case CHANGE_DATES_OR_STORE_GRT72HRS:
                return (
                    coachmarkData?.changestoreordatetooltip ||
                    intl.formatMessage({ id: 'coachmark:change-store-or-dates' })
                );
            default:
                return '';
        }
    };

    const handleProductTileCoachmark = (catClass, tileState, setShowTileCoachmark, setCoachmarkMsg) => {
        const coachmarkMsg = getProductTileCoachmarkMsg(tileState);
        if (coachmarkMsg && showCoachmark(COACHMARK_TYPE.PRODUCT_TILE)) {
            const isProductForCoachmackSelected = sessionStorage.getItem(
                STORAGE_CONFIG.SESSION_STORAGE.IS_PRODUCT_FOR_COACHMARK_SELECTED
            );
            if (!isProductForCoachmackSelected || isProductForCoachmackSelected === catClass) {
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.IS_PRODUCT_FOR_COACHMARK_SELECTED, catClass);
                setShowTileCoachmark(true);
            }
            setCoachmarkMsg(coachmarkMsg);
        }
    };

    return {
        setCoachmarkCount: handleSetCount,
        showCoachmark,
        handleProductTileCoachmark
    };
};

export default useCoachmark;
