import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { addItemToCart } from '../aem-core-components/actions/cart';
import { SET_IS_ITEM_PC_ADDED, SET_START_CLICK } from '../aem-core-components/actions/constants';
import { useCartState } from '../contexts/cart';
import useMinicart from '../components/App/hooks/useMinicart';
import { ADD_TO_CART, CHOOSE_STORE, DATE_PICKER } from '../aem-core-components/context/Modal/constants';
import { ModalContext } from '../aem-core-components/context/ModalContext';
import MUTATION_ADD_BUNDLE_TO_CART from '../aem-core-components/queries/mutation_add_bundle_to_cart.graphql';
import MUTATION_ADD_SIMPLE_AND_VIRTUAL_TO_CART from '../aem-core-components/queries/mutation_add_simple_and_virtual_to_cart.graphql';
import MUTATION_ADD_TO_CART from '../aem-core-components/queries/mutation_add_to_cart.graphql';
import MUTATION_ADD_VIRTUAL_TO_CART from '../aem-core-components/queries/mutation_add_virtual_to_cart.graphql';
import MUTATION_CREATE_CART from '../aem-core-components/queries/mutation_create_guest_cart.graphql';
import QUERY_CART_DETAILS from '../aem-core-components/queries/query_cart_details.graphql';
import { useAwaitQuery } from '../aem-core-components/utils/hooks';
import isObjectEmpty from '../aem-core-components/utils/isObjectEmpty';
import { useFilterState } from '../components/cap';
import { EDIT_VIEW_DETAILS, EDIT_VIEW_OPEN, TILE_STATES } from '../components/cap/constants';
import { AUTHORITY_TYPE } from '../components/global/constants';
import { getValidQty, isDateDisabled } from '../components/global/utils/commonUtils';
import { isValidString, logError } from '../components/global/utils/logger';
import { useAnalyticsContext } from '../config/GoogleTagManagerEvents';
import { VARIABLE_CONFIG } from '../constants/analyticsConstants/Variables';
import { MEDIA_TYPE } from '../constants/screenConstants';
import { STORAGE_CONFIG } from '../constants/storageConfig';
import useAnalytics from './useAnalytics';
import useCheckLocationEmpty from './useCheckLocationEmpty';
import { useCheckAuthorityType } from './useCheckUser';
import useComputeLocation from './useComputeLocation';
import useMedia from './useMedia';
import { usePageType } from './usePageType';
import useUserData from './useUserData';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../constants/analyticsConstants/Ecommerce';

const useProductTile = () => {
    const [createCartMutation] = useMutation(MUTATION_CREATE_CART);
    const [addToCartMutation] = useMutation(MUTATION_ADD_TO_CART);
    const [addVirtualItemMutation] = useMutation(MUTATION_ADD_VIRTUAL_TO_CART);
    const [addSimpleAndVirtualItemMutation] = useMutation(MUTATION_ADD_SIMPLE_AND_VIRTUAL_TO_CART);
    const [addBundleItemMutation] = useMutation(MUTATION_ADD_BUNDLE_TO_CART);
    const cartDetailsQuery = useAwaitQuery(QUERY_CART_DETAILS);
    const pageType = usePageType();
    const { sendEventsForEcommerceAdd, sendEventsForEcommerceProductClick } = useAnalyticsContext();
    const useModalState = () => useContext(ModalContext);
    const { openModal } = useModalState();
    const [{ cart }, { addItem }] = useMinicart({
        queries: {
            addToCartMutation,
            cartDetailsQuery,
            addVirtualItemMutation,
            addBundleItemMutation,
            addSimpleAndVirtualItemMutation
        }
    });
    const [{ cartId }, cartDispatch] = useCartState();
    const [{ viewCart, pickupStores, startDate }, filterDispatch] = useFilterState();
    const authorityType = useCheckAuthorityType();
    const { getPC } = useComputeLocation();
    const { isSelectedLocationEmpty } = useCheckLocationEmpty();
    const [{ getAssets }] = useUserData();
    const [{ handleChangeCtaAnalytics, sendAddToCartAnalytics }] = useAnalytics();
    const mediaType = useMedia();
    const isHolidayDate = isDateDisabled();
    const isP2P = authorityType === AUTHORITY_TYPE.P2P;
    const assetsFromStorage = JSON.parse(
        sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCAL_ASSETS_DATA) || '[]'
    );
    const cartItems = cart?.items || assetsFromStorage;
    const { CHANGE_DATES_OR_STORE_GRT72HRS, CHANGE_DATES_OR_STORE_GRT24HRS } = TILE_STATES;

    const formatProductDetailsAnalytics = (productDetails, dailyProductRate) => {
        let assets = [];
        let productObj = {
            id: productDetails?.catclass,
            name: productDetails?.producttitle,
            categories: productDetails?.category_path || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
            category: productDetails?.productcategoryname || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
            subcategory: productDetails?.subcategoryname || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
            dimension38: VARIABLE_CONFIG.EMPTY_STRING.PAGE_UNSPECIFIED,
            dimension31: dailyProductRate,
            dimension32: dailyProductRate * 7,
            dimension33: dailyProductRate * 31,
            sku: productDetails?.catclass,
            dimension41: -1,
            list: pageType,
            position: parseInt(productDetails?.position) || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
            quantity: 1,
            imageUrl: productDetails?.productimageurl || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED
        };
        const productPrice = parseFloat(dailyProductRate).toFixed(2);
        if (!isNaN(productPrice)) {
            productObj.price = productPrice || '0';
        }
        assets.push(productObj);
        return assets;
    };

    const handleAddToCartClick = async (
        tileDetails,
        dailyProductRate,
        setIsMaxQtyReachedModalOpen,
        handleAddToCartClickAnalytics
    ) => {
        try {
            if (!isObjectEmpty(tileDetails)) {
                const productId = tileDetails?.catclass;
                const { error } = getValidQty(cartItems, 1, productId);
                if (error) {
                    setIsMaxQtyReachedModalOpen(true);
                } else {
                    /* Dispaly the product details in the ATC modal */
                    await addToCart(tileDetails, handleAddToCartClickAnalytics, dailyProductRate);
                }
                sendEventsForEcommerceAdd(pageType, formatProductDetailsAnalytics(tileDetails, dailyProductRate));
                if (pageType === VARIABLE_CONFIG.PAGE_TYPE.EQUIPMENT_AND_TOOLS_PAGE) {
                    sendAddToCartAnalytics(tileDetails);
                }
            }
        } catch (error) {
            logError(error, false, 'handleAddToCartClick - addToCartProductTileReact');
        }
    };

    const addToCart = async (tileDetails, handleAddToCartClickAnalytics, dailyProductRate) => {
        /* this below code checks whether to make source call or not based on
         if item about to add in the cart is already present in cart items and also pcLat presence in the context */

        try {
            cartDispatch({ type: SET_IS_ITEM_PC_ADDED, isItemPCAdding: true });
            await addItemToCart({
                createCartMutation,
                addToCartMutation,
                cartDetailsQuery,
                cart,
                cartId,
                dispatch: cartDispatch,
                physicalCartItems: [{ data: { sku: tileDetails?.catclass, quantity: 1 } }],
                filterDispatch
            });
            cartDispatch({ type: SET_IS_ITEM_PC_ADDED, isItemPCAdding: false });
            openModal(ADD_TO_CART, {
                localItemObj: {
                    title: tileDetails?.producttitle,
                    img: tileDetails?.productimageurl,
                    price: dailyProductRate,
                    qty: 1
                },
                productId: tileDetails?.catclass
            });
            handleAddToCartClickAnalytics && handleAddToCartClickAnalytics(tileDetails);
        } catch (error) {
            // Handle errors
            logError(error, false, 'addItemToCartPromise inside useProductTile');
        }
    };

    const openEmptyLocationDateModal = () => {
        if (isSelectedLocationEmpty()) {
            filterDispatch({ type: EDIT_VIEW_OPEN });
            filterDispatch({ type: EDIT_VIEW_DETAILS, editViewSource: VARIABLE_CONFIG.CAP_VIEW_MODE.LOCATION_VIEW });
        } else {
            mediaType !== MEDIA_TYPE.DESKTOP && filterDispatch({ type: EDIT_VIEW_OPEN });
            filterDispatch({ type: EDIT_VIEW_DETAILS, editViewSource: VARIABLE_CONFIG.CAP_VIEW_MODE.DATE_VIEW });
            cartDispatch({ type: SET_START_CLICK });
        }
    };

    const fireEvent = (productDetails, dailyProductRate) => {
        try {
            sendEventsForEcommerceProductClick(
                pageType,
                formatProductDetailsAnalytics(productDetails, dailyProductRate)
            );
        } catch (error) {
            logError(error, false, 'fireEvent');
        }
    };
    const checkIfDayDisabled = date => {
        return isHolidayDate(date);
    };

    const getAvailableStoreDate = availableDate => {
        let storeStartDate = availableDate?.clone(); // Use clone to avoid mutating the original date
        let isSeletedDateDisabled = checkIfDayDisabled(storeStartDate?.toDate());

        while (isSeletedDateDisabled) {
            // If the current date is disabled, add one more day and check again
            storeStartDate.add(1, 'days');
            isSeletedDateDisabled = checkIfDayDisabled(storeStartDate?.toDate());
        }

        return storeStartDate;
    };

    const showAlertForP2PDeliveryType = () => {
        if (isP2P && !viewCart?.isInStorePickup) {
            return false;
        }
        return true;
    };

    const showAvailableDates = (type, isDateModalOpen = false, sku, position, index, listName) => {
        try {
            let availableDate, selectDates;
            const ifItemsInCart =
                isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.CARTTOTALQUANTITY)) > 0 ||
                cart?.total_quantity > 0;
            switch (type) {
                case CHANGE_DATES_OR_STORE_GRT24HRS:
                    availableDate = moment(startDate).add(1, 'days');
                    availableDate = getAvailableStoreDate(availableDate);
                    selectDates = () => {
                        openModal(DATE_PICKER, {
                            minAvailableDate: availableDate?.toDate(),
                            showAlertModal: ifItemsInCart && showAlertForP2PDeliveryType(),
                            isDateModalOpen
                        });
                        handleChangeCtaAnalytics(
                            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CTA_CHANGE_DATE,
                            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CTA_DATE_PICKER,
                            sku,
                            position,
                            index,
                            listName
                        );
                    };
                    return { availableDate, selectDates };
                case CHANGE_DATES_OR_STORE_GRT72HRS:
                    availableDate = moment(new Date()).add(3, 'days');
                    availableDate = getAvailableStoreDate(availableDate);
                    selectDates = () => {
                        openModal(DATE_PICKER, {
                            minAvailableDate: availableDate?.toDate(),
                            showOnDrawerForTablet: true,
                            hideBackButton: true,
                            pickerOverlayClass: 'picker-overlay',
                            showAlertModal: ifItemsInCart && showAlertForP2PDeliveryType(),
                            isDateModalOpen
                        });
                        handleChangeCtaAnalytics(
                            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CTA_CHANGE_DATE,
                            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CTA_DATE_PICKER,
                            sku,
                            position,
                            index,
                            listName
                        );
                    };
                    return { availableDate, selectDates };
                default:
                    null;
            }
        } catch (error) {
            logError(error, false, 'showAvailableDates');
        }
    };

    const getFilteredStores = (allStores, stores) => {
        let filteredStoreDetails = { data: [] };
        for (let storePc of allStores) {
            if (stores[storePc]) {
                filteredStoreDetails?.data?.push(stores[storePc]);
            }
        }
        return filteredStoreDetails;
    };

    const showAvailableStores = (
        sku,
        type,
        isDateModalOpen = false,
        showAvailableStartDate = true,
        position,
        index,
        listName
    ) => {
        try {
            let availableStorePcs, selectStores, startingDate;
            const ifItemsInCart =
                isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.CARTTOTALQUANTITY)) > 0 ||
                cart?.total_quantity > 0;
            const parsedStoresPcs =
                JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ATP_STORES_CATSKU)) || {};
            availableStorePcs = Object.values(parsedStoresPcs[sku] || {})?.[0] || [];
            if (type) {
                startingDate = showAvailableDates(type, isDateModalOpen)?.availableDate;
            }
            selectStores = (allStores, showShimmer) => {
                const stores = allStores || pickupStores;
                openModal(CHOOSE_STORE, {
                    availableStores: showShimmer ? null : getFilteredStores(availableStorePcs, stores),
                    startingDate,
                    showAvailableStartDate: showAvailableStartDate ? Boolean(startingDate) : false,
                    showInDrawerForTablet: true,
                    showMonochromaticMap: true,
                    showDateModal: isDateModalOpen ? showAvailableDates(type, isDateModalOpen)?.selectDates : () => {},
                    showAlertModal: !isDateModalOpen && ifItemsInCart && showAlertForP2PDeliveryType(),
                    isDateModalOpen: isDateModalOpen,
                    showShimmer
                });
                handleChangeCtaAnalytics(
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CTA_CHANGE_STORE,
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CTA_STORE_PICKER,
                    sku,
                    position,
                    index,
                    listName
                );
            };
            return { availableStores: availableStorePcs?.length, selectStores };
        } catch (error) {
            logError(error, false, 'showAvailableStores');
        }
    };

    return {
        handleAddToCartClick,
        openEmptyLocationDateModal,
        fireEvent,
        showAvailableDates,
        showAvailableStores
    };
};

export default useProductTile;
