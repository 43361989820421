import React from 'react';
import { bool, node } from 'prop-types';
import classes from './indicator.css';

const LoadingIndicator = props => {
    const className = props.global ? classes.global : classes.root;
    const { customLoaderClass, customLoaderIndicatorClass } = props;

    return (
        <div aria-busy={true} className={`${className} ${customLoaderClass}`}>
            <span className={`${classes.indicator} ${customLoaderIndicatorClass}`}></span>
            <span className={classes.message}>{props.children}</span>
        </div>
    );
};

LoadingIndicator.propTypes = {
    global: bool,
    children: node
};

export default LoadingIndicator;
