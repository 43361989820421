
import React from 'react';
import PropTypes from 'prop-types';

import classes from './cartCounter.css';

const CartCounter = props => {
    const { counter } = props;
    return counter > 0 ? (
        <span className={classes.root} data-testid="cart-counter">
            {counter}
        </span>
    ) : null;
};

CartCounter.propTypes = {
    counter: PropTypes.number.isRequired
};

export default CartCounter;
