import { gql } from '@apollo/client';

const GET_WISHLIST_BY_ID = gql`
    query getWishlistById(
        $wishlistId: ID!
        $productPageNo: Int
        $productsSize: Int
        $productSortBy: WishlistProductSortingEnum!
        $accountId: Int!
    ) {
        customer {
            wishlist_v2(id: $wishlistId, accountId: $accountId) {
                id
                name
                visibility
                items_count
                updated_at
                items_v2(currentPage: $productPageNo, pageSize: $productsSize, sortBy: $productSortBy) {
                    items {
                        id
                        quantity
                        product {
                            sku
                            name
                            product_page_url
                            thumbnail {
                                url
                            }
                        }
                    }
                    page_info {
                        current_page
                        page_size
                        total_pages
                    }
                }
            }
        }
    }
`;
export default GET_WISHLIST_BY_ID;
