import { gql } from '@apollo/client';

const COPY_PRODUCT_BETWEEN_WISHLIST = gql`
    mutation copyProductsBetweenWishlist(
        $sourceWishlistId: ID!
        $destinationWishlistId: [ID!]!
        $wishlistItems: [WishlistItemCopyInput!]!
        $productPageNo: Int
        $productsSize: Int
        $productSortBy: WishlistProductSortingEnum!
        $accountId: Int!
    ) {
        copyProductsBetweenWishlists(
            sourceWishlistUid: $sourceWishlistId
            destinationWishlistUid: $destinationWishlistId
            wishlistItems: $wishlistItems
            accountId: $accountId
        ) {
            source_wishlist {
                id
                name
                visibility
                items_count
                items_v2(currentPage: $productPageNo, pageSize: $productsSize, sortBy: $productSortBy) {
                    items {
                        id
                        product {
                            sku
                            name
                        }
                    }
                    page_info {
                        current_page
                        page_size
                        total_pages
                    }
                }
            }
            destination_wishlists {
                id
                name
                visibility
                items_count
                items_v2(currentPage: $productPageNo, pageSize: $productsSize, sortBy: $productSortBy) {
                    items {
                        id
                        product {
                            sku
                            name
                        }
                    }
                    page_info {
                        current_page
                        page_size
                        total_pages
                    }
                }
            }
            user_errors {
                code
                message
                data {
                    sku
                    quantity
                    wishlistName
                    wishlistId
                }
            }
        }
    }
`;
export default COPY_PRODUCT_BETWEEN_WISHLIST;
