import React, { memo, useState } from 'react';
import { useIntl } from 'react-intl';
import Modal from 'react-modal';
import { bool, func, string } from 'prop-types';
import { logError } from '../../utils/logger';
import OktaBodyContent from './OktaBodyContent';
import Button from '../../atoms/button/button';
import Close from '../../../../resources/images/close.svg';
import Logo from '../../../../resources/images/logo52.svg';
import './oktasigin.scss';
import { ENV_CONFIG } from '../../../../constants/envConfig';
import { REDIRECTING } from '../../constants';

const OktaModal = ({ isOpen, handleRequestClose, urlPath }) => {
    const intl = useIntl();
    const [isLoading, setIsLoading] = useState(true);
    const { signinlogo: oktaLogoSrc } = ENV_CONFIG.CAP_HEADER_AEM_RIGHT_CONTENT || {};
    const handleIframeLoad = () => {
        setIsLoading(!isLoading);
    };
    const handleOktaClose = () => {
        setIsLoading(!isLoading);
        handleRequestClose();
    };

    window.onmessage = function (e) {
        try {
            if (e?.data == REDIRECTING) {
                setIsLoading(true);
            }
        } catch (e) {
            logError(e, false, 'onMessage', [e?.data]);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleOktaClose}
            overlayClassName={'okta__overlay'}
            className={'okta__content-wrap'}>
            <div className="okta__header">
                {oktaLogoSrc ? (
                    <img src={oktaLogoSrc} alt="logo" className="okta__logo-img" />
                ) : (
                    <Logo className="okta__logo" />
                )}

                <Button
                    dataTestid={'oktaCloseButton'}
                    className="okta__header-close"
                    onClick={handleOktaClose}
                    buttonAriaLabel={intl.formatMessage({ id: 'time-out-session-modal:clickToClose' })}>
                    <Close />
                </Button>
            </div>
            <OktaBodyContent isLoading={isLoading} handleIframeLoad={handleIframeLoad} iFrameSrc={urlPath} />
        </Modal>
    );
};

OktaModal.propTypes = {
    handleRequestClose: func,
    isOpen: bool,
    urlPath: string
};

OktaModal.defaultProps = {
    isOpen: false,
    urlPath: '',
    handleRequestClose: () => {}
};
export default memo(OktaModal);
