window.addEventListener("load", (event) => {
    const faqItems = document.querySelectorAll('.faq .faq__title');
    faqItems.forEach(faqItem =>
        faqItem.addEventListener('click', () => {
            faqItem.parentNode.classList.toggle('faq--show');
            var getAriaExpanded = faqItem.getAttribute('aria-expanded');
            if (getAriaExpanded == 'true') {
                getAriaExpanded = 'false';
            } else {
                getAriaExpanded = 'true';
            }
            faqItem.setAttribute('aria-expanded', getAriaExpanded);
        })
    );
  });