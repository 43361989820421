import { googleApiKey } from '../../../../components/global/utils/commonUtils';
import { ENV_CONFIG } from '../../../../constants/envConfig';
import { GENERAL_EQUIPMENT_AND_TOOLS } from '../../../../components/location/constants';
import MapPinIconSelected from '../../../../resources/images/map-pin-ldp-selected.png';
import MapPinIconSelectedMobile from '../../../../resources/images/map-pin-ldp-selected-mobile.png';
import MapPinSpecialtyIconSelected from '../../../../resources/images/map-pin-specialty-ldp-selected.png';
import MapPinSpecialtyIconSelectedMobile from '../../../../resources/images/map-pin-specialty-ldp-selected-mobile.png';
import { getStreetViewData } from '../../../../components/location/API/getStoreLocations';
import { MAP_RADIUS, MILES_TO_METER_CONVERSION_FACTOR, ldpMapStyles } from '../constants';
import { logError } from '../../../../components/global/utils/logger';

var checkLocationUrl = window.location.pathname.includes('location');
var strictBounds = '';

const checkStreetViewAvailabilityAndPreviewImg = async (latitude, longitude) => {
    try {
        const { data, error } = await getStreetViewData(latitude, longitude);
        if (error) {
            return false;
        }
        return data;
    } catch (error) {
        logError(error, false, 'checkStreetViewAvailabilityAndPreviewImg', [latitude, longitude]);
    }
};

const getImageUrl = base64String => {
    return `data:image/jpeg;base64,${base64String}`;
};

document.addEventListener('readystatechange', event => {
    try {
        if (checkLocationUrl && document.readyState === 'complete') {
            var equipmentLength = document.querySelectorAll('.location-tools-row .equipment-location-details a').length;
            var locationShowLinkButton = document.querySelector('.location-show-link');
            if (equipmentLength > 12) {
                locationShowLinkButton.classList.toggle('location-show-link--show');
                locationShowLinkButton.addEventListener('click', function () {
                    locationShowLinkButton
                        .closest('.location-tools-row')
                        .classList.toggle('location-tools-row--expand');
                    locationShowLinkButton.classList.toggle('location-show-link--show');
                });
            }
        }
    } catch (error) {
        logError(error, false, 'document.readyStateChange', [event]);
    }
});

if (window.location.href.indexOf('/location') > -1) {
    try {
        const specialtyType = ENV_CONFIG.SPECIALTY_TYPE?.toLowerCase();
        let mql = window.matchMedia('(max-width: 639px)');
        const specialityMapIcon = mql.matches ? MapPinSpecialtyIconSelectedMobile : MapPinSpecialtyIconSelected;
        const markerMapIcon = mql.matches ? MapPinIconSelectedMobile : MapPinIconSelected;
        const setMapIcon =
            specialtyType === GENERAL_EQUIPMENT_AND_TOOLS?.toLowerCase() ? markerMapIcon : specialityMapIcon;
        document.getElementById('location-submit')?.addEventListener('click', getDirections, false);
        const mapLink = document.querySelector('#mapLink');
        mapLink.href = getDirections();

        function getDirections() {
            try {
                var current_address = localStorage.getItem('startingLocationForGetDirections') || '';
                var destinationAddress = document.querySelector('#destinationAddress')?.value || '';
                return `https://www.google.com/maps/dir/${current_address}/${destinationAddress}`;
            } catch (error) {
                logError(error, false, 'getDirections');
            }
        }

        const setStreetViewDiv = (map, streetViewData, latitude, longitude) => {
            try {
                const streetViewDiv = document.createElement('div');
                streetViewDiv.classList.add('street-view-div');
                streetViewDiv.setAttribute('aria-label', 'View street image');
                streetViewDiv.setAttribute('role', 'button');
                streetViewDiv.setAttribute('tabindex', '0'); // Make the div focusable
                streetViewDiv.addEventListener('keydown', event => {
                    if (event.key === 'Enter' || event.key === ' ') {
                        streetViewDiv.click(); // Trigger click on Enter or Space key press
                    }
                });
                streetViewDiv.addEventListener('click', () => {
                    var myLatlng = new google.maps.LatLng(latitude, longitude);
                    map.getStreetView().setOptions({ visible: true, position: myLatlng });
                });

                const imageUrl = getImageUrl(streetViewData?.streetViewImage);
                const img = document.createElement('img');
                img.src = imageUrl;
                img.alt = 'Preview of street view at the selected location';
                streetViewDiv.appendChild(img);
                map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(streetViewDiv);
            } catch (error) {
                logError(error, false, 'setStreetViewDiv', streetViewData);
            }
        };

        const getMapBounds = () => {
            try {
                var circle = new google.maps.Circle({
                    radius: MAP_RADIUS * MILES_TO_METER_CONVERSION_FACTOR,
                    center: pointA
                });
                strictBounds = new google.maps.LatLngBounds(
                    circle.getBounds().getSouthWest(),
                    circle.getBounds().getNorthEast()
                );
            } catch (error) {
                logError(error, false, 'getMapBounds');
            }
        };

        const checkAllowedRadius = (map) => {
            try {
                if (!strictBounds) {
                    getMapBounds();
                }
                if (strictBounds.contains(map.getCenter())) return;
                var c = map.getCenter(),
                    x = c.lng(),
                    y = c.lat(),
                    maxX = strictBounds.getNorthEast().lng(),
                    maxY = strictBounds.getNorthEast().lat(),
                    minX = strictBounds.getSouthWest().lng(),
                    minY = strictBounds.getSouthWest().lat();
                if (x < minX) x = minX;
                if (x > maxX) x = maxX;
                if (y < minY) y = minY;
                if (y > maxY) y = maxY;

                map.setCenter(new google.maps.LatLng(y, x));
            } catch (error) {
                logError(error, false, 'checkAllowedRadius');
            }
        };

        window.initMap = async () => {
            try {
                var latitude = document?.getElementById('destinationLatitude')?.value
                    ? parseFloat(document?.getElementById('destinationLatitude')?.value)
                    : 37.0902;
                var longitude = document.getElementById('destinationLongitude')?.value
                    ? parseFloat(document.getElementById('destinationLongitude')?.value)
                    : -95.7129;

                //map style
                var styledMapType = new google.maps.StyledMapType(ldpMapStyles);

                var pointA = new google.maps.LatLng(latitude, longitude),
                    myOptions = {
                        zoom: 15,
                        maxZoom: 15,
                        minZoom: 3,
                        center: pointA,
                        mapTypeControl: false,
                        streetViewControl: false,
                        fullscreenControl: false,
                        disableDefaultUI: false,
                        zoomControl: true
                    },
                    map = new google.maps.Map(document.getElementById('map-canvas'), myOptions),
                    markerA = new google.maps.Marker({
                        position: pointA,
                        title: 'center',
                        map: map
                    });

                markerA.setIcon(setMapIcon);
                map.mapTypes.set('styled_map', styledMapType);
                map.setMapTypeId('styled_map');

                google.maps.event.addListener(map, 'drag', function () {
                    checkAllowedRadius(map);
                });

                const streetViewData = await checkStreetViewAvailabilityAndPreviewImg(latitude, longitude);
                if (streetViewData?.status === 'OK') {
                    setStreetViewDiv(map, streetViewData, latitude, longitude);
                }
            } catch (error) {
                logError(error, false, 'initMap');
            }
        };

        const locationScript = document.createElement('script');
        locationScript.setAttribute(
            'src',
            `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&callback=initMap&libraries=places`
        );
        locationScript.setAttribute('async', '');
        locationScript.setAttribute('defer', '');
        document.body.appendChild(locationScript);
    } catch (error) {
        logError(error, false, 'mapSetup');
    }
}
