import React, { memo } from 'react';
import { Button } from '../../../aem-core-components';
import useMedia from '../../../hooks/useMedia';
import { useCapUtils } from '../hooks/useCapUtils';
import { MEDIA_TYPE } from '../../../constants/screenConstants';
import { ENV_CONFIG } from '../../../constants/envConfig';
import './capbottom.scss';
import { capDataLocator } from '../dataLocator';

const CapBottom = () => {
    const mediaType = useMedia();
    const { startRenting } = useCapUtils();
    const isDesktopView = mediaType == MEDIA_TYPE.DESKTOP;
    const { rentingctatext: startRentingCtaText } = ENV_CONFIG.CAP_HEADER || {};
    const letsGetsStartedText = ENV_CONFIG.LDP_CAP_BOTTOM_TEXT;

    return (
        !isDesktopView && (
            <div className="ldp_cap__bottom_sticky">
                <div className="ldp_cap__bottom_label">{letsGetsStartedText}</div>
                <Button
                    className="button button-primary button-block"
                    type="button"
                    onClick={startRenting}
                    buttonAriaLabel="start_renting"
                    data-testid={capDataLocator.cap_bottom_sticky_btn}>
                    {startRentingCtaText}
                </Button>
            </div>
        )
    );
};

export default memo(CapBottom);
