import { gql } from '@apollo/client';

const REMOVE_FROM_WISHLIST = gql`
    mutation removeFavorites($accountNumber: Int!, $favoriteID: String!) {
        removeFavorites(accountNumber: $accountNumber, favoriteID: $favoriteID) {
            status
            message
        }
    }
`;

export default REMOVE_FROM_WISHLIST;
