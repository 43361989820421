import React, { memo } from 'react';
import { bool, number, oneOf, shape, string } from 'prop-types';
import ProductStatusChangeAlert from '../productStatusChangeAlert/ProductStatusChangeAlert';
import ProductImage from '../../../atoms/productImage';
import './productStatusDetails.scss';
import { PRODUCT_CHANGE_STATUS } from '../constants';
import { TILE_STATES } from '../../productTile/constants';

const { UNAVAILABLE, PRICE_CHANGE } = PRODUCT_CHANGE_STATUS;

const ProductStatusDetails = ({ product }) => {
    let status = '';
    if (product?.tileStatus !== TILE_STATES.AVAILABLE) {
        status = UNAVAILABLE;
    }
    return (
        <div className="product-status-details__wrapper">
            <ProductImage
                url={product?.product?.thumbnail?.url}
                defaultViewCardType="minicart"
                name={product?.product?.name}
                className="product-status-details__image"
            />
            <div className="product-status-details">
                {product?.product?.name}
                <ProductStatusChangeAlert productInfo={product} status={status} />
            </div>
        </div>
    );
};

ProductStatusDetails.propTypes = {
    product: shape({
        tileStatus: oneOf([TILE_STATES.UNAVAILABLE, TILE_STATES.CHANGE_STORE, TILE_STATES.AVAILABLE]),
        name: string,
        thumbnail: string
    })
};

ProductStatusDetails.defaultProps = {
    product: {}
};

export default memo(ProductStatusDetails);
