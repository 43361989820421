import { createIntl, createIntlCache } from 'react-intl';
import i18Messages from '../i18n/en.json';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { FULFILLMENT_TYPE } from '../constants';

const cache = createIntlCache();
export const capIntl = createIntl({ locale: 'en', messages: i18Messages }, cache);

const { deliverytext, pickuptext } = ENV_CONFIG.CAP_HEADER || {};
const delivery_text = deliverytext || capIntl.formatMessage({ id: 'cap:dropdown-delivery' });
const pickup_text = pickuptext || capIntl.formatMessage({ id: 'cap:dropdown-pickup' });

const getExclusionPCList = excludePcString => {
    let excludePcs = [];
    if (excludePcString?.length > 2) {
        const excludedString = excludePcString?.substring(1, excludePcString?.length - 1);
        excludePcs = excludedString?.split(',')?.map(element => element?.trim());
    }
    return excludePcs || [];
};

export const getExcludedPCfromList = pcList => {
    const excludePricePcs = getExclusionPCList(ENV_CONFIG.EXCLUDED_SPECIALTY_LIST);
    // const excludePickupPcs = getExclusionPCList(ENV_CONFIG.PICKUP_STORE_EXCLUDED_SPECIALTY_LIST);
    const filteredPricingPcs = [];
    const excludedPricingPcs = [];
    // const pickupStorePcs = [];
    for (let i in pcList) {
        const specialities = pcList[i]?.specialties;
        if (!specialities || !excludePricePcs?.includes(specialities?.[0]?.trim())) {
            filteredPricingPcs.push(pcList[i]);
        } else {
            excludedPricingPcs.push(pcList[i]);
        }
        // if (!specialities || !excludePickupPcs?.includes(specialities?.[0]?.trim())) {
        //     pickupStorePcs.push(pcList[i]);
        // }
    }
    return {
        /**  adding exclusion pcs at last, so if nearby pcs are not found from the filtered list,
         *  it will search from exclusion list as well
         * */
        pricingPcs: [...filteredPricingPcs, ...excludedPricingPcs],
        // pickupStorePcs: pickupStorePcs?.length > 0 ? pickupStorePcs : pcList
        pickupStorePcs: pcList
    };
};

export const createFullAddress = (item, showZip = false) => {
    const companyID = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1;
    const country =
        companyID === 2
            ? capIntl.formatMessage({ id: 'location-overlay:canada' })
            : capIntl.formatMessage({ id: 'common:country-usa' });

    if (item?.projectAddress1) {
        return `${item?.projectAddress1}, ${item?.selectedProjectCity}, ${item?.selectedProjectState}, ${
            showZip ? item?.selectedProjectZip : country
        }`;
    }
    return '';
};

export const inputOptions = [
    {
        label: delivery_text,
        value: FULFILLMENT_TYPE.DELIVERY
    },
    {
        label: pickup_text,
        value: FULFILLMENT_TYPE.PICKUP
    }
];

export const fulfillmentOptions = [
    {
        value: delivery_text,
        label: FULFILLMENT_TYPE.ROUNDTRIPDELIVERY
    },
    {
        value: pickup_text,
        label: FULFILLMENT_TYPE.INSTOREPICKUP
    }
];
