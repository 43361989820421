import { USER_TYPE } from '../../../../constants/userDetailsConstants';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';

var guestUser = document.querySelector('.block--market.guest-user');
var cashUser = document.querySelector('.block--market.cash-user');

const userType = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.USERTYPE);
if (guestUser !== null && guestUser !== undefined && cashUser !== null && cashUser !== undefined) {
	switch (userType) {
		case USER_TYPE.CASH:
			guestUser?.classList.add("block--market-hide");
			cashUser?.classList.remove("block--market-hide");
			break;
		case USER_TYPE.CREDIT:
			guestUser?.classList.add("block--market-hide");
			cashUser?.classList.add("block--market-hide");
			break;
		default:
			guestUser?.classList.remove("block--market-hide");
			cashUser?.classList.add("block--market-hide");
			break;
	}
}
