import { bool, func, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useCartState } from '../../../../contexts/cart';
import { useUserContext } from '../../../../aem-core-components/context/UserContext';
import { useCookieValue } from '../../../../aem-core-components/utils/hooks';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import { ENV_CONFIG } from '../../../../constants/envConfig';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';
import { usePdpState } from '../../../../contexts/pdp/pdpContext';
import useMedia from '../../../../hooks/useMedia';
import { usePageType } from '../../../../hooks/usePageType';
import Close from '../../../../resources/images/close.svg';
import GlobalModal from '../globalModal/GlobalModal';
import { logError } from '../../utils/logger';
import Button from '../button/button';
import ConfirmationScreenButtons from '../confirmationScreenButtons/ConfirmationScreenButtons';
import ConfirmationStatusScreen from '../confirmationStatusScreen/ConfirmationStatusScreen';
import './requestQuote.scss';
import { useFilterState } from '../../../cap';
import { checkoutDatalocator } from '../../../checkoutv2/checkoutAndOrderSummary/dataLocators';

const RequestQuote = props => {
    const {
        isOpen,
        handleQuoteForm,
        categoryName,
        catId,
        catSku,
        productName,
        quotationDescription,
        quotationHeading,
        superCategory,
        metaTitle,
        metaDescription,
        specialtyTypes,
        specialtytypescode,
        analysisgroupcode
    } = props;
    const intl = useIntl();
    const mediaType = useMedia();
    const [{ userAccount, isCreditNewAddress }, dispatch] = useCartState();
    const [{viewCart, projectDetails}, filterDispatch] = useFilterState();
    const formRef = useRef(null);
    const [{ userProfile }] = useUserContext();
    const [{ rates }] = usePdpState();
    const [confirmationStatus, setConfirmationStatus] = useState(0);
    const [successEventCalled, setSuccessEventCalled] = useState(false);
    const [accessToken] = useCookieValue('accesstoken');
    const pageType = usePageType();
    const { sendEventsForClick, sendEventsForMarketoForm } = useAnalyticsContext();
    const { iframeflag = 'false', iframeurl = '' } = ENV_CONFIG?.FRONTEND_VARIABLESMETA || {};
    const pdpBlockElement = document?.querySelector('.block--pdp-hero');
    const [formid,setFormId] = useState('');


    const sendEvent = () => {
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.REQUEST_QUOTE,
                VARIABLE_CONFIG.EVENT_ACTION.SELECT,
                `${pageType} :: ${productName}`
            );
        } catch (error) {
            logError(error, false, 'sendEvents');
        }
    };

    const checkCookie = cookieName => {
        return document.cookie.split(';').filter(item => item.trim().startsWith(`${cookieName}=`)).length > 0;
    };
    const cookieValue = cookieName => {
        let name = cookieName + '=';
        let cookieVal = document.cookie;
        let ca = cookieVal.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    };
    useEffect(() => {
        const onMessage12 = event => {
            if(formid){
                if (event.data === 'FormSuccess') {
                    setConfirmationStatus(1);
                    sendEventsForMarketoForm(
                        VARIABLE_CONFIG.EVENT.FORM_COMPLETED,
                        formid,
                        catSku,
                        categoryName,
                        VARIABLE_CONFIG.FORM_SUBMIT_SUBJECT.RENT_EQUIPMENT
                    );
                }
            }
        };

        window.addEventListener('message', onMessage12, false);
    }, [formid]);
    useEffect(() => {
        if (isOpen && formid ) {
            sendEventsForMarketoForm(
                VARIABLE_CONFIG.EVENT.FORM_VIEWED,
                formid,
                catSku,
                categoryName,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED
            );
        }
    }, [isOpen,formid]);
    const getSearchQuery = () => {
        const params = new URLSearchParams(window.location.search);
        const search = params.get('q');
        if (search) {
            return `${search}`;
        } else {
            return '';
        }
    };

    useEffect(() => {
        if (successEventCalled) {
            sendEvent();
            setConfirmationStatus(1);
        }
    }, [successEventCalled]);

    const onRequestQuote = () => {
        handleQuoteForm(false);
    };

    const renderReservationDetail = () => {
        return (
            <div
                className="status-details-container"
                data-testid={checkoutDatalocator?.reservation_successSummary_msg_testid}>
                {intl.formatMessage({ id: 'marketo:success-confirmation-subheading' })}
            </div>
        );
    };

    const renderModalHeader = () => {
        return (
            <>
                <div className="raq-drawer__header">
                    <h5 className="raq-title" tabIndex={0}>
                        {quotationHeading || intl.formatMessage({ id: 'request-quote-title' })}
                    </h5>
                    <Button
                        onClick={onRequestQuote}
                        className={'raq-drawer__close'}
                        tabIndex={0}
                        dataTestid={checkoutDatalocator.animatedHeaderModalClose}
                        customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}>
                        <Close aria-hidden="true" tabIndex={'-1'} />
                    </Button>
                </div>
            </>
        );
    };

    const renderConfirmationButtons = () => {
        return (
            <ConfirmationScreenButtons
                primaryBtnText={intl.formatMessage({ id: 'reservation:continue-cta' })}
                primaryHandle={onRequestQuote}
            />
        );
    };

    const getSpecialtyId = () => {
        return sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ANALYSISREGION);
    };

    const getMetaTitleDescription = () => {
        if (pageType === VARIABLE_CONFIG.ECOMMERCE_PAGE.DETAIL) {
            const pdpMetaDescription = document?.querySelector('meta[name="description"]');
            const pdpMetaTitle = document?.title;
            return {
                metaTitle: pdpMetaTitle,
                metaDescription: pdpMetaDescription?.getAttribute('content')
            };
        } else {
            return {
                metaTitle: metaTitle,
                metaDescription: metaDescription
            };
        }
    };

    const getSpecialtyTypes = () => {
        /* Reading specialty types from data attribute*/
        if (pageType === VARIABLE_CONFIG.ECOMMERCE_PAGE.DETAIL) {
            return pdpBlockElement?.getAttribute('data-specialtytypes');
        }
        return specialtyTypes;
    };

    const getSpecialtyTypeCode = () => {
        if (pageType === VARIABLE_CONFIG.ECOMMERCE_PAGE.DETAIL) {
            return pdpBlockElement?.getAttribute('data-specialtytypescode');
        }
        return specialtytypescode;
    };

    const getAnalysisGroupCode = () => {
        if (pageType === VARIABLE_CONFIG.ECOMMERCE_PAGE.DETAIL) {
            return pdpBlockElement?.getAttribute('data-analysisgroupcode');
        }
        return analysisgroupcode;
    };

    const handleAemModalData = () => {
        const iFrameAEM = document.querySelector('.aem-form-react');
        const getRequestQuoteIframe = iFrameAEM?.contentWindow?.document?.querySelector('.requestquote-iframe');
        const getIframInnerScrollHeight = getRequestQuoteIframe?.querySelector('form')?.scrollHeight;
        let formidAEM = getRequestQuoteIframe?.querySelector("form input[name='formid']").value;
        setFormId(formidAEM)
        
        iFrameAEM.height = `${getIframInnerScrollHeight + 50}px`;
        const fields = [
            { name: 'Form_Fill_Super_Category__c', value: superCategory || '' },
            { name: 'formFillMetaDescription', value: getMetaTitleDescription()?.metaDescription || '' },
            { name: 'formFillMetaTitle', value: getMetaTitleDescription()?.metaTitle || '' },
            {
                name: 'Account_Type__c',
                value: userProfile?.Type === 0 ? 'credit' : userProfile?.Type === 1 ? 'cash' : 'guest'
            },
            { name: 'searchQuery', value: getSearchQuery() || '' },
            { name: 'SBR_Guid__c', value: userProfile?.userGuid || '' },
            { name: 'Profile_ID__c', value: userProfile?.oktaUserId || '' },
            { name: 'JigsawCompanyId', value: parseInt(localStorage?.getItem('companyID')) || 1 },
            { name: 'Company', value: userProfile?.Type === 0 ? userAccount?.AccountName : '' },
            { name: 'Account_ID__c', value: userProfile?.Type === 0 ? userAccount?.AccountNumber : '' },
            { name: 'Mkto_Referring_Page__c', value: window?.location?.href || '' },
            { name: 'Form_Source_Referrer_URL__c', value: window?.location?.href || '' },
            { name: 'Form_Fill_Category__c', value: categoryName || '' },
            { name: 'formFillCategoryID', value: catId || '' },
            { name: 'Form_Fill_Product__c', value: productName || '' },
            { name: 'Form_Fill_Product_ID__c', value: catSku || '' },
            {
                name: 'formFillSuggested',
                value: rates?.length > 0 ? JSON?.stringify(rates[0]?.rates?.suggestedRates) : ''
            },
            { name: 'sourcePageType', value: pageType || '' },
            { name: 'Form_Fill_Form_Name__c', value: quotationHeading || '' },
            { name: 'SBUID__c', value: checkCookie('sbuid') ? cookieValue('sbuid') : '' },
            { name: 'sBAID__c', value: checkCookie('sbaid') ? cookieValue('sbaid') : '' },
            { name: 'SBSID__c', value: sessionStorage?.getItem('sbsid') || '' },
            { name: 'SBMID__c', value: sessionStorage?.getItem('sbmid') || '' },
            { name: 'Branch_LOB_Type__c', value: getSpecialtyId() || '' },
            { name: 'Form_Fill_Specialty_Type__c', value: getSpecialtyTypes() || '' },
            { name: 'Analysis_Region_Key__c', value: getSpecialtyTypeCode() || '' },
            { name: 'Form_Fill_Analysis_Group__c', value: getAnalysisGroupCode() || '' }
        ];

        fields.forEach(({ name, value }) => {
            const field = iFrameAEM.contentWindow.document.querySelector(`[name="${name}"]`);
            if (field) {
                field.value = value;
            }
        });
    };

    const renderModalBody = () => {
        const iframURL =  mediaType === 'MOBILE' ?  iframeurl : `${iframeurl}?modal=true`
        return (
            <>
                {confirmationStatus === 1 ? (
                    <div className='raq-drawer__confirmation'>
                        <ConfirmationStatusScreen
                                successMessage={intl.formatMessage({ id: 'marketo:success-confirmation-message' })}
                                statusBottom={renderConfirmationButtons()}
                                confirmationStatus={confirmationStatus}>
                                {renderReservationDetail()}
                            </ConfirmationStatusScreen>
                    </div>
                ) : (
                    <>
                        <div className="raq-drawer__top">
                            <p className="eyebrow raq-drawer__eyebrow">{productName}</p>
                            <p>{quotationDescription || intl.formatMessage({ id: 'request-quote-body-text' })}</p>
                        </div>
                        <div className="raq-drawer__bottom">
                            <iframe
                                allowFullScreen
                                id="aemmodaliframe"
                                src={iframURL}
                                className="aem-form-react"
                                onLoad={handleAemModalData}
                            />
                        </div>
                    </>
                )}
            </>
        );
    };

    return (
        <GlobalModal
            isOpen={isOpen}
            sideDrawer={'right'}
            settitleFocus={false}
            portalClassName={'raq-drawer'}
            customTitle={renderModalHeader()}
            showCloseButton={false}
            content={renderModalBody()}
            className="raq-drawer__content"
            contentClasses={'raq-drawer__content-wrap'}
            closeButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}
            onRequestClose={onRequestQuote}
        />
    );
};

export default RequestQuote;

RequestQuote.propsType = {
    isOpen: bool,
    handleQuoteForm: func,
    categoryName: string,
    catId: string,
    catSku: string,
    productName: string,
    quotationDescription: string,
    quotationHeading: string
};

RequestQuote.defaultProps = {
    isOpen: false,
    handleQuoteForm: () => {},
    categoryName: '',
    catId: '',
    catSku: '',
    productName: '',
    quotationDescription: '',
    quotationHeading: ''
};