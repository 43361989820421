import { gql } from '@apollo/client';

const CHECK_IF_CAT_CLASS_FAVORITE = gql`
    query checkIfCatClassFavorite($sku: [String!]!, $pageSize: Int) {
        productDetail: products(pageSize: $pageSize, filter: { sku: { in: $sku } }) {
            items {
                sku
                isFavorite
                wishlistIds
            }
        }
    }
`;
export default CHECK_IF_CAT_CLASS_FAVORITE;
