import React, { useEffect, useState } from 'react';
import { func, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { useFilterState } from '../../../cap';
import { useCartState } from '../../../../contexts/cart/cartContext';
import useComputeLocation from '../../../../hooks/useComputeLocation';
import { useCheckUser } from '../../../../hooks/useCheckUser';
import useMedia from '../../../../hooks/useMedia';
import useAnalytics from '../../../../hooks/useAnalytics';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import ExistingAccounts from '../existingAccount';
import NewAddress from '../newAddress';
import ToggleTabs from '../ToggleTabs/ToggleTabs';
import ViewCartFooter from '../../atoms/viewCartFooter';
import Button from '../../atoms/button';
import { BackHeader } from '../../atoms/backHeader';
import { getlatLongByAddress } from '../location-autocomplete/api/getLocations';
import { isCCPage, isCheckoutPage, reloadOnAccountChange } from '../../utils/commonUtils';
import { logError } from '../../utils/logger';
import classes from './location-Overlay.css';
import { locationOverlayDataLocators } from '../../../pdp/location/LocationOverlayModal/dataLocators';
import { locationsDatalocator } from './dataLocators';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../../constants/analyticsConstants/Ecommerce';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import { ENTER_KEY, SCREEN_NAME } from '../../../../constants/screenConstants';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';
import { USER_TYPE } from '../../../../constants/userDetailsConstants';
import {
    POP_CART_STACK,
    SET_CREDIT_NEWADDR_FLAG,
    SET_IS_CART_LOADING,
    SET_IS_ESTIMATE_ON_CART_OPEN_REQUIRED,
    SET_LOCATION_DATA,
    SET_VIEW_CART_FIELDS,
    UPDATE_PROJECT
} from '../../../../aem-core-components/actions/constants';
import { VIEW_CART } from '../../../../constants/cartConstants';

function LocationOverlay(props) {
    const intl = useIntl();
    const mediaType = useMedia();
    const userType = useCheckUser();
    const {
        setShowProjectModal,
        setShowAccountModal,
        handleLocationOverlay,
        handleAccountOverlay,
        screenName,
        setIsCreditNewAddress,
        handleBlockedAccount
    } = props;
    const [{ isCreditNewAddress, isOpen, cart }, dispatch] = useCartState();
    const [{ viewCart, projectDetails }, filterDispatch] = useFilterState();
    const {
        sendEventsForAddressErrorAction,
        sendEventsForLocationFormSubmitComplete,
        sendEventsForLocationFormSubmit
    } = useAnalyticsContext();
    const [{ payloadEcommerceLocationActionAnalytics, payloadEcommerceLocationActionSubmitted }] = useAnalytics();

    const [selectedButton, setSelectedButton] = useState(!isCreditNewAddress);
    const [selectedProjectData, setSelectedProjectData] = useState({});
    const [manualAddress, setManualAddress] = useState(viewCart?.location || '');
    const [isManualAddress, setIsManualAddress] = useState(false);
    const [autoCompleteAddressSelected, setAutoCompleteAddressSelected] = useState('');
    const [locationByPlaces, setLocationByPlaces] = useState('');
    const [autoCompleteErrorMsg, setAutoCompleteErrorMsg] = useState('');
    const [addressChangedByGeocode, setAddressChangedByGeocode] = useState(false);
    const [globalLocation, setGlobalLocation] = useState({
        location: viewCart?.location || '',
        lat: viewCart?.lat || '',
        long: viewCart?.long || '',
        jobSiteAddr2: viewCart?.jobSiteAddr2 || '',
        jobSiteCity: viewCart?.jobSiteCity || '',
        jobSiteState: viewCart?.jobSiteState || '',
        jobSiteZip: viewCart?.jobSiteZip || '',
        pc: viewCart?.pc || '',
        pcLat: viewCart?.pcLat || '',
        pcLong: viewCart?.pcLong || ''
    });
    const { computeInstore, canadaRestrictStateError, setCanadaRestrictStateError } = useComputeLocation();
    const isEmpty = cart && Object.entries(cart)?.length > 0 ? cart?.items?.length === 0 : true;

    useEffect(() => {
        setGlobalLocation({
            location: viewCart?.location || '',
            lat: viewCart?.lat || '',
            long: viewCart?.long || '',
            jobSiteAddr2: viewCart?.jobSiteAddr2 || '',
            jobSiteCity: viewCart?.jobSiteCity || '',
            jobSiteState: viewCart?.jobSiteState || '',
            jobSiteZip: viewCart?.jobSiteZip || '',
            pc: viewCart?.pc || '',
            pcLat: viewCart?.pcLat || '',
            pcLong: viewCart?.pcLong || ''
        });
    }, [viewCart?.lat]);

    useEffect(() => {
        setCanadaRestrictStateError('');
    }, [manualAddress]);
    useEffect(() => {
        if (projectDetails.isValueUpdated) {
            reloadOnAccountChange(userType);
        }
    }, [projectDetails.isValueUpdated]);

    const handleToggle = currentSelected => {
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISNEWADDRESSONOVERLAY, !currentSelected);
        setSelectedButton(currentSelected);
    };

    const setEnterAddressFlag = value => {
        if (setIsCreditNewAddress) {
            setIsCreditNewAddress(value);
        } else {
            dispatch({
                type: SET_CREDIT_NEWADDR_FLAG,
                isCreditNewAddress: value
            });
        }
    };

    const handleBackLink = async (isCallEvents = false) => {
        if (!handleBlockedAccount()) {
            let error = false;
            let userAddressData;
            if (!selectedButton && manualAddress?.trim() === '' && autoCompleteAddressSelected === '') {
                setAutoCompleteErrorMsg(intl.formatMessage({ id: 'cart-header:location-autocomplete-error-mesage-2' }));
                error = true;
                handleSuccessErrorEvents(globalLocation, error, isCallEvents);
                return;
            } else if (!selectedButton && isManualAddress) {
                setAutoCompleteErrorMsg(intl.formatMessage({ id: 'cart-header:location-autocomplete-error-mesage-2' }));
                error = true;
                userAddressData = manualAddress;
                handleSuccessErrorEvents(userAddressData, error, isCallEvents);
                return;
            } else if (!selectedButton && autoCompleteAddressSelected) {
                setEnterAddressFlag(!selectedButton);
                const localLocation = await computeInstore(locationByPlaces, autoCompleteAddressSelected, '', true);
                if (!localLocation) {
                    setAutoCompleteErrorMsg(
                        intl.formatMessage({ id: 'cart-header:location-autocomplete-error-mesage-2' })
                    );
                    error = true;
                    userAddressData = localLocation;
                    handleSuccessErrorEvents(userAddressData, error, isCallEvents);
                    return;
                } else {
                    setAutoCompleteErrorMsg('');
                    filterDispatch({
                        type: SET_LOCATION_DATA,
                        pc: localLocation?.pc,
                        location: localLocation?.location,
                        jobSiteCity: localLocation?.jobSiteCity,
                        jobSiteState: localLocation?.jobSiteState,
                        jobSiteZip: localLocation?.jobSiteZip,
                        lat: localLocation?.lat,
                        long: localLocation?.long,
                        jobSiteAddr2: localLocation?.jobSiteAddr2
                    });
                    userAddressData = localLocation;
                }
            } else {
                const {
                    address1,
                    latitude,
                    longitude,
                    city,
                    state,
                    zip,
                    locationPC,
                    contactName,
                    contactPhone,
                    deliveryInstructions,
                    customerPO
                } = selectedProjectData || '';
                const addressTosend = address1 || `${city}, ${state}`; // If no address in addressList
                let projectLat = '';
                let projectLong = '';
                const companyID = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) || '1';
                setEnterAddressFlag(!selectedButton);
                if (Object.keys(selectedProjectData).length) {
                    if (!latitude || !longitude) {
                        const response = await getlatLongByAddress(companyID, `${address1}, ${city}, ${state}, ${zip}`);
                        if (response?.data?.results?.length > 0) {
                            projectLat = response?.data?.results[0]?.geometry?.location.lat;
                            projectLong = response?.data?.results[0]?.geometry?.location.lng;
                        }
                    } else {
                        projectLat = latitude;
                        projectLong = longitude;
                    }
                    if (
                        !isEmpty &&
                        projectLat !== projectDetails?.selectedProjectLatititude &&
                        projectLong !== projectDetails?.selectedProjectLongitude
                    ) {
                        dispatch({ type: SET_IS_CART_LOADING, isCartLoading: true });
                        dispatch({
                            type: SET_IS_ESTIMATE_ON_CART_OPEN_REQUIRED,
                            key: 'isEstimateOnCartOpenRequired',
                            value: true
                        });
                    }
                    filterDispatch({
                        type: UPDATE_PROJECT,
                        projectName: selectedProjectData?.jobName,
                        projectAddress1: addressTosend,
                        projectAddress2: selectedProjectData?.address2,
                        selectedProjectJobId: selectedProjectData?.jobNumber,
                        selectedRMJobId: selectedProjectData?.rmJobsiteId,
                        selectedProjectLatititude: projectLat,
                        selectedProjectLongitude: projectLong,
                        selectedProjectState: state,
                        selectedProjectCity: city,
                        selectedProjectZip: zip,
                        locationPC: locationPC,
                        primaryContactName: contactName,
                        phoneNumber: contactPhone,
                        accessNotes: deliveryInstructions,
                        poNumber: customerPO,
                        isValueUpdated: true,
                        CJDLVY: selectedProjectData?.CJDLVY
                    });
                    localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.CJDLVY, selectedProjectData?.CJDLVY);
                    userAddressData = selectedProjectData;
                } else {
                    if (projectDetails?.selectedProjectJobId) {
                        if (!projectDetails?.selectedProjectLatititude || !projectDetails?.selectedProjectLongitude) {
                            const response = await getlatLongByAddress(
                                companyID,
                                `${projectDetails?.projectAddress1}, ${projectDetails?.selectedProjectCity}, ${projectDetails?.selectedProjectState}, ${projectDetails?.selectedProjectZip}`
                            );
                            if (response?.data?.results?.length > 0) {
                                projectLat = response?.data?.results[0]?.geometry?.location.lat;
                                projectLong = response?.data?.results[0]?.geometry?.location.lng;
                            }
                        } else {
                            projectLat = projectDetails?.selectedProjectLatititude;
                            projectLong = projectDetails?.selectedProjectLongitude;
                        }
                        filterDispatch({
                            type: UPDATE_PROJECT,
                            projectName: projectDetails?.projectName,
                            projectAddress1: projectDetails?.projectAddress1,
                            projectAddress2: projectDetails?.projectAddress2,
                            selectedProjectJobId: projectDetails?.selectedProjectJobId,
                            selectedRMJobId: projectDetails?.selectedRMJobId,
                            selectedProjectLatititude: projectLat,
                            selectedProjectLongitude: projectLong,
                            selectedProjectState: projectDetails?.selectedProjectState,
                            selectedProjectCity: projectDetails?.selectedProjectCity,
                            selectedProjectZip: projectDetails?.selectedProjectZip,
                            locationPC: projectDetails?.locationPC,
                            primaryContactName: projectDetails?.contactName,
                            phoneNumber: projectDetails?.contactPhone,
                            accessNotes: projectDetails?.accessNotes,
                            poNumber: projectDetails?.poNumber,
                            isValueUpdated: true,
                            CJDLVY: projectDetails?.CJDLVY
                        });
                        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.CJDLVY, projectDetails?.CJDLVY);
                    }
                }
            }
            handleSuccessErrorEvents(userAddressData, error, isCallEvents);
            if (!error) {
                if (setShowProjectModal) {
                    setShowProjectModal(false);
                } else if (handleLocationOverlay) {
                    handleLocationOverlay(false);
                } else {
                    dispatch({ type: POP_CART_STACK });
                }
            }

            handleChange(VIEW_CART.SHOW_CREATE_PROJECT, projectDetails?.projectName ? !selectedButton : true);
            handleChange(VIEW_CART.CREATE_PROJECT_SOURCE, 'minicart');
        } else {
            handleLocationOverlay(false);
        }
        if (userType === USER_TYPE.CREDIT && isCCPage()) {
            if (isOpen) {
                sessionStorage.setItem('isOpen', true);
            }
            window.location.reload();
        }
    };

    const handleSuccessErrorEvents = (userAddressData, error, isCallEvents) => {
        try {
            if (!isCheckoutPage() && isCallEvents && (userAddressData || error)) {
                sendEventsForLocationFormSubmit(
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_FORM_SUBMITTED_EVENT,
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_LOCATION_MODAL_FORM_NAME,
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_FORM_SUBMITTED_LINK_NAME,
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_FORM_SUBMITTED_LINK_TYPE,
                    payloadEcommerceLocationActionSubmitted(userAddressData, error)
                );
                if (error) {
                    sendEventsForAddressErrorAction(
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_FORM_ADDRESS_EVENT,
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_LOCATION_MODAL_FORM_NAME,
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_FORM_ADDRESS_FIELD,
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_FORM_ADDRESS_ERROR,
                        payloadEcommerceLocationActionAnalytics(true)
                    );
                } else {
                    sendEventsForLocationFormSubmitComplete(
                        EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_LOCATION_SUBMITTED_EVENT,
                        payloadEcommerceLocationActionSubmitted(userAddressData)
                    );
                    sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ISLOCATIONFORMNOTDIRTY, false);
                }
            }
        } catch (error) {
            logError(error, false, 'handleSuccessErrorEvents');
        }
    };
    const handleKeyPress = e => {
        if (e.key === ENTER_KEY) {
            handleBackLink();
        }
    };

    const handleSetLocation = () => {
        handleBackLink(true);
    };

    const handleChange = (key, value) => {
        filterDispatch({ type: SET_VIEW_CART_FIELDS, key, value });
    };

    const setSelectedProjectId = data => {
        setSelectedProjectData(data);
    };
    const computeClass = () => {
        if (mediaType === 'MOBILE' && selectedButton) {
            return 'locationoverlay-creditmobile-projectfooter';
        } else if (mediaType === 'MOBILE' && !selectedButton) {
            return 'locationoverlay-creditmobile-newaddressfooter';
        } else {
            return 'locationoverlay-credit-projectfooter';
        }
    };

    return (
        <>
            {screenName ? (
                <>
                    {screenName === SCREEN_NAME.PDP_SCREEN && (
                        <div className={classes.locationOverlayModalTabs}>
                            <div className={classes.accountTabs}>
                                <ToggleTabs
                                    toggle1Title={intl.formatMessage({ id: 'location-overlay:existing-project' })}
                                    toggle2Title={intl.formatMessage({ id: 'location-overlay:enter-address' })}
                                    isSelected={selectedButton}
                                    toggleHandler={handleToggle}
                                    toggle1TestId={
                                        locationOverlayDataLocators.locationoverlay_toggleexisting_cta_testid
                                    }
                                    toggle2TestId={locationOverlayDataLocators.locationoverlay_togglenew_cta_testid}
                                />
                            </div>
                        </div>
                    )}

                    {selectedButton ? (
                        <ExistingAccounts
                            handleAccountOverlay={handleAccountOverlay}
                            setSelectedProjectId={setSelectedProjectId}
                            screenName={screenName}
                            source={VARIABLE_CONFIG.PLACEMENT.LOCATIONOVERLAY}
                        />
                    ) : (
                        <NewAddress
                            screenName={screenName}
                            setManualAddress={setManualAddress}
                            setIsManualAddress={setIsManualAddress}
                            autoCompleteErrorMsg={autoCompleteErrorMsg}
                            setAutoCompleteErrorMsg={setAutoCompleteErrorMsg}
                            setAutoCompleteAddressSelected={setAutoCompleteAddressSelected}
                            setAddressChangedByGeocode={setAddressChangedByGeocode}
                            addressChangedByGeocode={addressChangedByGeocode}
                            setLocationByPlaces={setLocationByPlaces}
                            canadaRestrictError={canadaRestrictStateError}
                            source={VARIABLE_CONFIG.PLACEMENT.LOCATIONOVERLAY}
                            manualAddress={manualAddress}
                        />
                    )}

                    <div className={computeClass()}>
                        <ViewCartFooter footerClass={`${!selectedButton ? 'set_Location' : ''}`}>
                            <Button
                                type="button"
                                className={'button button-primary button-block'}
                                onClick={() => {
                                    handleSetLocation();
                                }}
                                data-testid={locationOverlayDataLocators.locationoverlay_setlocation_cta_testid}
                                buttonAriaLabel={`${intl.formatMessage({
                                    id: 'location-overlay:set-location-cta'
                                })}`}>
                                {intl.formatMessage({ id: 'location-overlay:set-location-cta' })}
                            </Button>
                        </ViewCartFooter>
                    </div>
                </>
            ) : (
                <>
                    {!setShowAccountModal && <BackHeader />}
                    <div className={classes.locationOverlayModalPadding}>
                        <h5 className={classes.locationOverlayTitle}>
                            {intl.formatMessage({ id: 'location-overlay:title' })}
                        </h5>
                        {!setShowAccountModal && !setShowProjectModal && (
                            <div className={classes.accountTabs}>
                                <ToggleTabs
                                    toggle1Title={intl.formatMessage({ id: 'location-overlay:existing-project' })}
                                    toggle2Title={intl.formatMessage({ id: 'location-overlay:enter-address' })}
                                    isSelected={selectedButton}
                                    toggleHandler={handleToggle}
                                    toggle1TestId={locationsDatalocator.existing_project_cta_testid}
                                    toggle2TestId={locationsDatalocator.locationOverlay_address_cta}
                                    isBoxShadowRequired={true}
                                />
                            </div>
                        )}
                        {selectedButton ? (
                            <ExistingAccounts
                                setShowAccountModal={setShowAccountModal}
                                setSelectedProjectId={setSelectedProjectId}
                            />
                        ) : (
                            <NewAddress
                                setManualAddress={setManualAddress}
                                setIsManualAddress={setIsManualAddress}
                                autoCompleteErrorMsg={autoCompleteErrorMsg}
                                setAutoCompleteErrorMsg={setAutoCompleteErrorMsg}
                                setAddressChangedByGeocode={setAddressChangedByGeocode}
                                addressChangedByGeocode={addressChangedByGeocode}
                                setAutoCompleteAddressSelected={setAutoCompleteAddressSelected}
                                setLocationByPlaces={setLocationByPlaces}
                                canadaRestrictError={canadaRestrictStateError}
                                manualAddress={manualAddress}
                            />
                        )}
                    </div>
                    <ViewCartFooter className="minicart-footer-actions" footerClass={classes.rtDeliveryFooter}>
                        <Button
                            type="button"
                            className={'button button-primary button-block'}
                            onClick={() => handleBackLink()}
                            onKeyPress={handleKeyPress}
                            data-testid={locationsDatalocator.save_and_continue_cta_testid}
                            buttonAriaLabel={`${intl.formatMessage({
                                id: 'location-overlay:save-and-continue'
                            })}`}>
                            {intl.formatMessage({ id: 'location-overlay:save-and-continue' })}
                        </Button>
                    </ViewCartFooter>
                </>
            )}
        </>
    );
}

export default LocationOverlay;

LocationOverlay.propTypes = {
    setShowProjectModal: func,
    setShowAccountModal: func,
    handleLocationOverlay: func,
    handleAccountOverlay: func,
    screenName: string,
    setIsCreditNewAddress: func,
    handleBlockedAccount: func
};

LocationOverlay.defaultProps = {
    screenName: '',
    handleBlockedAccount: () => {},
    setShowProjectModal: () => {},
    setShowAccountModal: () => {},
    handleLocationOverlay: () => {},
    handleAccountOverlay: () => {},
    screenName: '',
    setIsCreditNewAddress: () => {},
    handleBlockedAccount: () => {}
};
