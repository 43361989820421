export const pdpDataLocators = {
    pdp_breadrcrumb_categoryName_testid: 'pdp_breadrcrumb_categoryName',
    pdp_breadcrumb_productName_testid: 'pdp_breadcrumb_productName',
    pdp_productImage_testid: 'pdp_productImage',
    pdp_catClass_label_testid: 'pdp_catClass_label',
    pdp_productTile_label_testid: 'pdp_productTile_label',
    pdp_productFourHours_price_testid: 'pdp_productFourHours_price',
    pdp_productOneDay_price_testid: 'pdp_productOneDay_price',
    pdp_productOneWeek_price_testid: 'pdp_productOneWeek_price',
    pdp_productFourWeek_price_testid: 'pdp_productFourWeek_price',
    pdp_rapidReturn_link_testid: 'pdp_rapidReturn_link',
    pdp_ratesForAddress_link_testid: 'pdp_ratesForAddress_link',
    pdp_displayedRates_label_testid: 'pdp_displayedRates_label',
    pdp_Qty_dropdown_testid: 'pdp_Qty_dropdown',
    pdp_addToCartOwner_testid: 'pdp_addToCartOwner',
    pdp_emailLink_testid: 'pdp_emailLink',
    pdp_printlLink_testid: 'pdp_printlLink',
    pdp_favoriteLink_testid: 'pdp_favoriteLink',
    added_to_cart_heading: 'pdp_addedToCart_Overlay',
    added_to_cart_close_icon: 'pdp_addedToCart_closeicon',
    added_to_cart_product_image: 'pdp_addedToCart_productImage',
    added_to_cart_product_title: 'pdp_addedToCart_productTitle',
    added_to_cart_product_qty: 'pdp_addedToCart_productQty',
    added_to_cart_product_price: 'pdp_addedToCart_productPrice',
    quote_product_card_product_title: 'quote_product_card_product_title',
    quote_product_card_product_qty: 'quote_product_card_product_qty',
    quote_product_card_cat_class: 'quote_product_card_cat_class',
    quote_product_card_product_price: 'quote_product_card_product_price',
    added_to_cart_rental_subtotal: 'pdp_addedToCart_rentalSubtotal_label',
    added_to_cart_rental_subtotal_price: 'pdp_addedToCart_rentalSubtotal_price',
    added_to_cart_view_your_cart_cta: 'pdp_viewYourCart_cta',
    added_to_cart_keep_shopping_cta: 'pdp_keepShopping_cta',
    pdp_add_to_cart_quantity_exceeded_error_label: 'pdp_add_to_cart_quantity_exceeded_error_label'
};
