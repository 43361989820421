import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import SideDrawer from '../../../../aem-core-components/components/SideDrawerModal/SideDrawerModal';
import { SET_SHOW_SAVE_FAVORITE_MODAL } from '../../actionTypes/actionTypes';
import { useFavouritesState } from '../../context';
import CreateWishListContent from '../../modules/createWishlist/components/CreateWishListContent';
import CreateWishListFooter from '../../modules/createWishlist/components/CreateWishListFooter';
import { useToastMessageState } from '../../../global/modules/toastMessage/toastContext';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import { EVENT_P2P_NAMES_CONFIG } from '../../../../constants/analyticsConstants/ProcureToPay';
import './sideBarFavLanding.scss';

const SideBarFavLanding = () => {
    const intl = useIntl();
    const [{ showSaveToFavModal }, favDispatch] = useFavouritesState();
    const [submit, setSubmit] = useState(false);
    const showToast = useToastMessageState();
    const { sendEventsForP2PCtaClick } = useAnalyticsContext();

    const handleModalToggle = () => {
        favDispatch({ type: SET_SHOW_SAVE_FAVORITE_MODAL, payload: false });
    };

    const onSubmitCreateList = () => {
        setSubmit(true);
        sendEventsForP2PCtaClick(
            EVENT_P2P_NAMES_CONFIG.P2P_CTA_CLICKED,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_NAME_CREATE_FAVORITE_LIST,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_TYPE_BUTTON,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_LOCATION_BODY,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_DESTINATION_CREATE_FAVORITE_LIST
        );
    };

    const handleSuccessfullCreateWishlist = () => {
        handleModalToggle();
        showToast({
            message: intl.formatMessage({ id: 'p2p-list-created-successfully' })
        });
    };

    const renderHeader = () => {
        return (
            <h2 className="create_fav_title">
                {intl.formatMessage({ id: 'p2p-create-wishlist-heading' })}
            </h2>
        );
    };

    const renderFooter = () => {
        return <CreateWishListFooter handleBack={handleModalToggle} handleCreateList={onSubmitCreateList} />;
    };

    const renderContent = () => {
        return (
            <CreateWishListContent
                showHeading={false}
                submit={submit}
                setSubmit={setSubmit}
                onSuccessfullCreateWishlist={handleSuccessfullCreateWishlist}
            />
        );
    };

    return (
        <SideDrawer
            modalContentClass={'modal-createlist-content'}
            content={renderContent()}
            header={renderHeader()}
            footer={renderFooter()}
            isModalOpen={showSaveToFavModal}
            handleModalToggle={handleModalToggle}
            showModalHeader={true}
            closeButtonClass={'cross-black'}
        />
    );
};

export default React.memo(SideBarFavLanding);
