import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Button from '../button/button';

function TabBtn(props) {
    const { id, selTabIndex, onTabClick, title, index, customButtonAriaLabel, ariaControls } = props;
    return (
        <Button
            id={id}
            role="tab"
            className={`tabs-results__buttons ${selTabIndex === index ? 'tabs-results__buttons--active' : ''}`}
            onClick={() => onTabClick(index)}
            customButtonAriaLabel={customButtonAriaLabel}
            aria-selected={selTabIndex === index ? true : false}
            aria-controls={ariaControls}
            tabIndex="0">
            {title}
        </Button>
    );
}

TabBtn.defaultProps = {
    selTabIndex: 0,
    onTabClick: () => {},
    title: '',
    index: 0
};

TabBtn.propTypes = {
    selTabIndex: PropTypes.number,
    onTabClick: PropTypes.func,
    title: PropTypes.string,
    index: PropTypes.number
};

export default memo(TabBtn);
