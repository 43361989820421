import { frontendConfig } from '../../global/utils/commonUtils';

export const API_CONFIG = {
    BASE_URL: 'https://qa-api.sunbeltrentals.com/',
    GET_AUTH_TOKEN: 'api/v1/oauth2/token',
    VERIFY_EMAIL: 'web/api/v1/customers/verify/email',
    SEND_OTP: 'web/api/v1/customers/email-verification',
    VERIFY_CODE: 'web/api/v1/customers/email-verification/validation',
    DUPLICATE_PROFILES: 'web/api/v1/customers/duplicate-profiles',
    CREATE_ACCOUNT: '/web/api/v1/customers',
    CCA_SUBMISSION_ID: 'web/api/v1/cca/initialization',
    CLIENT_IP: 'https://ipapi.co/json/'
};

export const getClientId = () => {
    return frontendConfig?.openidmuleclientid || '0oa3cjs4xbyricVYP1d7';
};

export const getClientSecret = () => {
    return frontendConfig?.openidmuleclientsecret || 'lkSpZKIfO-y4vPZSYyDlnDagwSOvWlTR6e8U4hbi';
};

export const getApiConfigByEnv = () => {
    const muleDomain = frontendConfig?.muledomain || API_CONFIG.BASE_URL;
    return {
        baseURL: muleDomain, // api domain
        timeout: 30000,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            client_secret: getClientSecret(),
            client_id: getClientId()
        }
    };
};
