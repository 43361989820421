const MUTATION_LOG_ERRORS_TO_SPLUNK = `
    mutation sendLogMessage(
        $message: String!
    ) {
        sendLogMessage(
            message: $message
        ) 
    }
 `;
export default MUTATION_LOG_ERRORS_TO_SPLUNK;
