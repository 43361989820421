import React, { useEffect } from 'react';
import PunchoutUser from './PunchoutUser';
import { useCookieValue } from '../../aem-core-components/utils/hooks';
import { useCartState } from '../../contexts/cart';
import { SET_PUNCHOUT_USER_DATA } from '../../aem-core-components/actions/constants';
import { STORAGE_CONFIG } from '../../constants/storageConfig';
import { isPunchoutPage } from '../global/utils/commonUtils';
import { useCheckAuthorityType } from '../../hooks/useCheckUser';
import { AUTHORITY_TYPE } from '../global/constants';

const Punchout = () => {
    const [{ punchoutUserData }, dispatch] = useCartState();
    const authorityType = useCheckAuthorityType();
    // punchout cookies start
    const [pos] = useCookieValue('pos');
    const [punchoutIdToken] = useCookieValue('id_token');
    const [punchoutCompanyId] = useCookieValue('companyId');
    const [punchoutTokenType] = useCookieValue('token_type');
    const [punchoutTokenExpiresIn] = useCookieValue('expires_in');
    const [punchoutReturnURL] = useCookieValue('punchout_return_url');
    const [punchoutRedirectURL] = useCookieValue('punchout_redirect_url');
    // punchout cookies end

    const openPunchout = isPunchoutPage();

    useEffect(() => {
        setPunchoutUserData();
    }, []);

    const setPunchoutUserData = () => {
        if (authorityType === AUTHORITY_TYPE.PUNCHOUT) {
            if (document.querySelector('.chat-button')) {
                document.querySelector('.chat-button').style.display = 'none';
            }
            if (punchoutCompanyId) {
                localStorage.setItem('companyID', punchoutCompanyId);
            }
            let punchoutData = {
                ...punchoutUserData,
                isPunchoutUser: true,
                showSelectAccountscreen: true,
                pos,
                punchoutCompanyId,
                punchoutIdToken,
                punchoutTokenType,
                punchoutTokenExpiresIn,
                punchoutRefreshToken,
                punchoutReturnURL,
                punchoutRedirectURL,
                punchOutAccessToken
            };
            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.PUNCHOUTUSERDATA, JSON.stringify(punchoutData));
            dispatch({
                type: SET_PUNCHOUT_USER_DATA,
                punchoutUserData: {
                    ...punchoutData
                }
            });
        }
    };

    return (
        <>
            {openPunchout && authorityType === AUTHORITY_TYPE.PUNCHOUT && punchoutUserData?.showSelectAccountscreen && (
                <PunchoutUser />
            )}
        </>
    );
};

export default Punchout;
