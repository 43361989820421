import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { LoadingIndicator } from '../../../../aem-core-components';
import Listing from '../projectListing/Listing';
import { MEDIA_TYPE } from '../../../../constants/screenConstants';
import { useCheckAuthorityType } from '../../../../hooks/useCheckUser';
import useMedia from '../../../../hooks/useMedia';
import CardRadioButton from '../../atoms/cardRadioButton/cardRadioButton';
import { AUTHORITY_TYPE } from '../../constants';
import { JOBSITE_SEARCH_LENGTH } from '../../utils/commonUtils';
import NoDataFound from '../noDataFound';
import './jobsitesListingModal.scss';

const JobsiteListView = (props) => {
    const { isProjectsLoading, accountProjects, searchQuery, searchedJobsite, showProjectSearch, recommendedJobsites, isJobSiteLoaded,
        selectedValue, setSelectedValue, setSelectedJobsite, cardStyle, jobsiteListLabel, isModalOpen, isFavoriteNoResult, jobsiteSearch } = props;
    const intl = useIntl();
    const hrRef = useRef(null);
    const mediaType = useMedia();
    const authorityType = useCheckAuthorityType();
    const isP2PUser = authorityType === AUTHORITY_TYPE.P2P;
    const [containerHeight, setContainerHeight] = useState(0);

    useEffect(() => {
        // This block is used to calculate the jobsiteList container height.
        if (isModalOpen) {
            //Wrap this block of code in setTimeOut to retrieve the element after rendering.
            setTimeout(() => {
                const header = document?.querySelector('.modal_header')?.offsetHeight || 0;
                const title = document?.querySelector('.jobsites-list-title-text')?.offsetHeight || 0;
                const tabs = document.querySelector('.jobsite-tabs')?.offsetHeight || 0;
                const search = document.querySelector('.search-jobsite')?.offsetHeight || 0;
                const footer = document.querySelector('.modal_footer')?.offsetHeight || 0;
                var totalHrHeight = 0;

                if (hrRef?.current) {
                    const hr = hrRef?.current;
                    const rect = hr?.getBoundingClientRect();
                    const marginTop = parseFloat(getComputedStyle(hr)?.marginTop);
                    totalHrHeight = rect?.height + marginTop;
                }

                const sum = header + title + tabs + search + footer + totalHrHeight;
                setContainerHeight(sum);
            }, 0)
        }


    }, [isModalOpen, showProjectSearch]);

    const displaySearchResults = data => {
        return (
            data?.jobName && (
                <div key={data?.jobName} className="card-radio-button-wrapper">
                    <CardRadioButton
                        item={data}
                        cardStyle={cardStyle}
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                        onPressRadioButton={item => setSelectedJobsite(item)}
                        radioButtonAriaLabel={data?.jobName}
                    />
                </div>
            )
        );
    };

    return (
        <>
            {isProjectsLoading && !accountProjects?.length && !searchQuery && (
                <div className="jobsite-drawer-content__loader-wrapper">
                    <LoadingIndicator />
                </div>
            )}
            {mediaType !== MEDIA_TYPE.DESKTOP && !isP2PUser && (
                <h5 className="jobsites-list-title-text">{intl.formatMessage({ id: 'cap:choose-a-jobsite' })}</h5>
            )}
            {showProjectSearch ? (
                <div className="search-jobsite">
                    {jobsiteSearch()}
                </div>
            ) : (
                <hr ref={hrRef} />
            )}


            <div className='jobsites-list-container' style={{ height: `calc(100vh - ${containerHeight}px)` }}>
                {!searchQuery && recommendedJobsites?.length > 0 && accountProjects?.length > JOBSITE_SEARCH_LENGTH && (
                    <section className="jobsites-list-section">
                        <h6>{intl.formatMessage({ id: 'cap:suggested-jobsites' })}</h6>
                        <Listing projectList={recommendedJobsites} displaySearchResults={displaySearchResults} />
                    </section>
                )}
                {(accountProjects?.length > 0 || searchQuery) && (
                    <section className="jobsites-list-section">
                        <h6>
                            {searchQuery
                                ? intl.formatMessage({ id: 'cap:matching-jobsites' })
                                : jobsiteListLabel}
                        </h6>
                        <Listing
                            projectList={searchedJobsite}
                            displaySearchResults={displaySearchResults}
                            isProjectsLoading={isProjectsLoading}
                        />
                        {searchedJobsite?.length === 0 && !isJobSiteLoaded && (
                            <p className="no-search">{intl.formatMessage({ id: 'cap:no-jobsite-found' })}</p>
                        )}
                    </section>
                )}

                {accountProjects?.length === 0 && !showProjectSearch && !isProjectsLoading && (
                    isFavoriteNoResult ?
                        <div className='no-results-container' style={{ height: `calc(100vh - ${containerHeight}px)` }}>
                            <NoDataFound
                                heading={intl.formatMessage({ id: 'account-empty-list-heading' })}
                                subheading={intl.formatMessage({ id: 'jobsite-empty-list-subheading' })}
                                noSearchResultsFoundIcon
                                ctaVisible={false}
                                className='favorite_no_result'
                            />
                        </div>
                        :
                        <section className="jobsites-list-section">
                            <p className="no-account-projects">
                                {intl.formatMessage({ id: 'cap:delinquent-account-no-jobsite-found' })}
                            </p>
                        </section>
                )}
            </div>
        </>
    )
}
export default React.memo(JobsiteListView);
