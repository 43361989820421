import React, { memo } from 'react';
import { array, func, node, number, string } from 'prop-types';
import TabBtn from './TabsBtn';

import './tabs.scss';

function Tabs(props) {
    const { onTabChange, selTabIndex, children, tabWrapClasses } = props;

    const handleTabChange = index => {
        onTabChange(index);
    };

    const renderTabs = () => {
        return (
            <div className={`tabs-results ${tabWrapClasses}`}>
                {children instanceof Array ? (
                    children.map((tab, index) => {
                        return (
                            <React.Fragment key={`${tab?.props?.title || ''} ${index}`}>
                                {tab.props?.title ? <TabBtn
                                    id={`tab-${index + 1}`}
                                    index={index}
                                    selTabIndex={selTabIndex}
                                    title={tab.props?.title}
                                    customButtonAriaLabel={
                                        selTabIndex === index
                                            ? `${tab.props?.title}`
                                            : `${tab.props?.title} unselected`
                                    }
                                    ariaControls={`panel-${index + 1}`}
                                    onTabClick={handleTabChange}
                                /> : ''}
                                
                            </React.Fragment>
                        );
                    })
                ) : (
                    <TabBtn
                        id={'tab-1'}
                        key={0}
                        index={0}
                        selTabIndex={selTabIndex}
                        title={children?.props?.title}
                        customButtonAriaLabel={`${children?.props?.title}`}
                        aria_controls={'panel-1'}
                        onTabClick={handleTabChange}
                    />
                )}
            </div>
        );
    };

    return (
        <>
            {renderTabs()}

            {children instanceof Array ? children[selTabIndex] : children}
        </>
    );
}

Tabs.defaultProps = {
    tabsList: [],
    onTabChange: () => {},
    selTabIndex: 0,
    renderTabContent: () => {},
    tabWrapClasses: ''
};

Tabs.propTypes = {
    tabsList: array,
    onTabChange: func,
    selTabIndex: number,
    children: node,
    tabWrapClasses: string
};

export default memo(Tabs);
