import React, { useEffect, useState } from 'react';
import { useCartState } from '../contexts/cart';
import { VARIABLE_CONFIG } from '../constants/analyticsConstants/Variables';
import { useFilterState } from '../components/cap';

export const useSunbeltLocation = () => {
    const [{ howToGetYourOrderDetails }] = useCartState();
    const [{viewCart}, filterDispatch] = useFilterState();
    const [sunbeltLocation, setSunbeltLocation] = useState(VARIABLE_CONFIG.SUNBELT_LOCATION.NONE);
    useEffect(() => {
        if (
            viewCart?.isInStorePickup &&
            howToGetYourOrderDetails?.selectedStoreDetails?.state &&
            howToGetYourOrderDetails?.selectedStoreDetails?.city &&
            howToGetYourOrderDetails?.selectedStoreDetails?.pc
        ) {
            setSunbeltLocation(
                howToGetYourOrderDetails?.selectedStoreDetails?.state +
                    '|' +
                    howToGetYourOrderDetails?.selectedStoreDetails?.city +
                    '|' +
                    howToGetYourOrderDetails?.selectedStoreDetails?.pc
            );
        } else {
            setSunbeltLocation(VARIABLE_CONFIG.SUNBELT_LOCATION.NONE);
        }
    }, [viewCart?.isInStorePickup, howToGetYourOrderDetails?.selectedStoreDetails]);

    return sunbeltLocation;
};
