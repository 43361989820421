import React from 'react';
import NoList from '../../../../resources/images/no-list.svg';
import NoDataFoundIcon from '../../../../resources/images/no-data-found.svg';
import Button from '../../atoms/button/button';
import './NoDataFound.scss';
import { bool, func, string } from 'prop-types';

const NoDataFound = props => {
    const {
        heading,
        subheading,
        returnButtonAriaLabel,
        backCtaText,
        onClick,
        backCtaDataTestId,
        noSearchResultsFoundIcon,
        noDataFoundIcon,
        ctaVisible,
        className
    } = props;

    return (
        <>
            <div className={`empty_state ${className}`}>
                <div className="empty_state_image">
                    {noSearchResultsFoundIcon && <NoList tabIndex={-1} aria-hidden="true" />}
                    {noDataFoundIcon && <NoDataFoundIcon tabIndex={-1} aria-hidden="true" />}
                </div>
                <div className="empty_state_content">
                    <h2 tabIndex="0" className="empty_state_content_header">
                        {heading}
                    </h2>
                    <p tabIndex="0" className="empty_state_content_description">
                        {subheading}
                    </p>
                </div>
                {ctaVisible &&
                    <div className="empty_state_create_cta_container">
                        <Button
                            className="button button-outline-primary back_home_cta"
                            onClick={onClick}
                            buttonAriaLabel={returnButtonAriaLabel}
                            dataTestid={backCtaDataTestId}>
                            {backCtaText}
                        </Button>
                    </div>
                }
            </div>
        </>
    );
};
export default NoDataFound;

NoDataFound.propTypes = {
    heading: string,
    subheading: string,
    returnButtonAriaLabel: string,
    backCtaText: string,
    onClick: func,
    backCtaDataTestId: string,
    noSearchResultsFoundIcon: bool,
    noDataFoundIcon: bool,
    ctaVisible: bool,
    className: string
};

NoDataFound.defaultProps = {
    heading: '',
    subheading: '',
    returnButtonAriaLabel: '',
    backCtaText: '',
    onClick: () => { },
    backCtaDataTestId: '',
    noSearchResultsFoundIcon: false,
    noDataFoundIcon: false,
    ctaVisible: true,
    className: ''
};
