//tabs section
var tabsAll = document.querySelectorAll('.tabsJs');
tabsAll.forEach(function (tabs) {
    var tabsBtnsolutionActive = tabs.querySelector('.tabs__btnsolution-active');
    var tabsBtnSolutions = tabs.querySelector('.tabs__btn-solutions');
    var tabsButtonActive = tabs.querySelector('.tabs__button--active');
    var btns = tabs.querySelectorAll('.tabs__button');
    var articles = tabs.querySelectorAll('.tabs__pane');
    tabs?.addEventListener('click', function (e) {
        var id = e.target.dataset.id;
        if (id) {
            btns.forEach(function (btn) {
                btn.classList.remove('tabs__button--active');
                btn.setAttribute('aria-selected', 'false');
            });
            e.target.classList.add('tabs__button--active');
            e.target.setAttribute('aria-selected', 'true');
            tabsBtnsolutionActive.querySelector('span').innerHTML = e.target.innerHTML;
            tabsBtnSolutions.classList.toggle('tabs-open');
            articles.forEach(function (article) {
                article.classList.remove('tabs__pane--active');
                article.setAttribute('aria-selected', 'false');
            });
            const element = tabs.querySelector(`[data-tab-panel-id = "${id}"]`);
            element.classList.add('tabs__pane--active');
        }
    });
    let tabFocus = 0;
    tabsBtnSolutions?.addEventListener('keydown', e => {
        if (e?.key === 'ArrowRight' || e?.key === 'ArrowLeft') {
            btns[tabFocus]?.setAttribute('tabindex', -1);
            if (e.key === 'ArrowRight') {
                tabFocus++;
                // If we're at the end, go to the start
                if (tabFocus >= tabs.length) {
                    tabFocus = 0;
                }
                // Move left
            } else if (e?.key === 'ArrowLeft') {
                tabFocus--;
                // If we're at the start, move to the end
                if (tabFocus < 0) {
                    tabFocus = tabs.length - 1;
                }
            }
            btns[tabFocus].setAttribute('tabindex', 0);
            btns[tabFocus].focus();
        }
    });
    tabsBtnsolutionActive?.addEventListener('click', function () {
        tabsBtnSolutions.classList.toggle('tabs-open');
    });

    if (tabsBtnsolutionActive) {
        tabsBtnsolutionActive.querySelector('span').innerHTML = tabsButtonActive.innerHTML;
    }
});
