import config from '../../App/config';
import { basePathForPunchout, getEnv } from '../../global/utils/commonUtils';

// coveo
export const getProductListUrl = () => {
    let url = '';
    if (getEnv() === 'localhost') {
        //www.sunbeltrentals.com/equipment-rental/air-compressors-and-air-tools/air-compressor-aftercoolers-filters-separators-dryers/
        https: url = '/equipment-rental/air-compressors-and-air-tools/air-compressors/';
    } else if (window.location.pathname.includes('equipment-rental/')) {
        const level = document.querySelector("meta[name='categoryLevel']")?.content;
        if (level == 4) {
            let linkArr = window.location.pathname.split('/');
            if (linkArr[linkArr.length - 1] === '') {
                linkArr.pop();
            }
            linkArr.pop();
            linkArr = linkArr.join('/');
            url = `${linkArr}/`;
        } else {
            url += window.location.pathname;
        }
    } else {
        //To handle local env
        url = '/equipment-rental/air-compressors-and-air-tools/air-compressors/';
    }
    return url;
};

export const updateQueryParam = val => {
    const url = new URL(window.location);
    url.searchParams.set('q', val);
    window.history.replaceState({}, '', url.href);
};

export const removeQueryParam = param => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete(param);
    window.history.replaceState({}, document.title, currentUrl.toString());
};

export const redirectToQuotesPageWithQuoteId = searchStr => {
    window.location = basePathForPunchout + config.pagePaths.p2pQuoteListPage + '?q=' + searchStr;
};
