import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import ReactModal from 'react-modal';
import { ModalContext } from '../../../../aem-core-components/context/ModalContext';
import { useEventListener } from '../../../../aem-core-components/utils/hooks';
import { ESCAPE_KEY, KEYDOWN_EVENT } from '../../../../constants/screenConstants';
import CheckmarkWavy from '../../../../resources/images/checkmark-wavy.svg';
import CloseIcon from '../../../../resources/images/close.svg';
import InstoreIcon from '../../../../resources/images/instore.svg';
import Truck from '../../../../resources/images/truck.svg';
import Button from '../../atoms/button';
import { trapFocus } from '../../utils/commonUtils';
import './modal.scss';

const Modal = ({ isOpen, title, content, hideIcon, customClass }) => {
    const orderSummaryIntl = useIntl();
    const titleRef = useRef();
    const modalRef = useRef();
    const useModalState = () => useContext(ModalContext);
    const { closeModal } = useModalState();

    const handleClose = () => {
        closeModal();
    };
    const escapeKeyHandler = useCallback(event => {
        if (event.key === ESCAPE_KEY) {
            handleClose();
        }
    }, []);
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        trapFocus(document.querySelector('#checkoutModelPopup'));
    }

    useEffect(() => {
        const body = document?.querySelector('body');
        const liveChatButton = document.querySelector('.chat-button');
        if (isOpen) {
            titleRef?.current?.focus();
            body.classList.add('overflow-hidden');

            if (liveChatButton) {
                liveChatButton.setAttribute('aria-hidden', 'true');
                liveChatButton.setAttribute('tabIndex', '-1');
            }
        } else {
            body.classList.remove('overflow-hidden');
            if (liveChatButton) {
                liveChatButton.setAttribute('aria-hidden', 'false');
                liveChatButton.setAttribute('tabIndex', '0');
            }
        }
    }, [isOpen]);

    useEventListener(window, KEYDOWN_EVENT, escapeKeyHandler);

    const renderIcon = () => {
        if (title === orderSummaryIntl.formatMessage({ id: 'order-summary:instore-pickup' })) {
            return <InstoreIcon aria-hidden={true} />;
        } else if (title === orderSummaryIntl.formatMessage({ id: 'order-summary:rental-protection-plan' })) {
            return <CheckmarkWavy aria-hidden={true} />;
        } else if (title === orderSummaryIntl.formatMessage({ id: 'order-summary:environment-fee' })) {
            return <></>;
        } else {
            return <Truck aria-hidden={true} />;
        }
    };

    return (
        <ReactModal
            isOpen={isOpen}
            className="popup"
            onRequestClose={handleClose}
            role="dialog"
            aria-labelledby="modal-title"
            aria-describedby="modal-content"
            data-testid="checkoutModelPopup"
            onAfterOpen={afterOpenModal}
            id="checkoutModelPopup"
            ref={modalRef}
            tabIndex={-1}
            autoFocus={true}>
            <div className={`popup__dialog ${customClass || ''}`} tabIndex={0}>
                <div className="popup__titlewrap">
                    <h6 className="popup__title" id="modal-title" ref={titleRef}>
                        {!hideIcon && renderIcon()}
                        {title}
                    </h6>
                    <Button
                        className="popup__crossicon"
                        onClick={handleClose}
                        dataTestid={'close-modal-btn'}
                        customButtonAriaLabel={orderSummaryIntl.formatMessage({
                            id: 'order-summary:common-close-dialog'
                        })}
                        tabIndex={0}
                        aria-label={orderSummaryIntl.formatMessage({ id: 'order-summary:common-close-dialog' })}>
                        <CloseIcon aria-hidden={true} />
                    </Button>
                </div>
                <div className="popup__content" id="modal-content" tabIndex={0}>
                    {content}
                </div>
            </div>
        </ReactModal>
    );
};

export default Modal;
