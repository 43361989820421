import {
    SET_IS_USER_SIGNING_IN,
    SET_SHOW_SIGNIN_MODAL,
    SET_SHOW_CHOOSE_ACCOUNT_DRAWER,
    SET_SHOW_JOB_SITE_DRAWER,
    SET_CAP_ACCOUNT_SELECTED,
    SET_IS_JOBSITE_LOADED,
    SET_ACCOUNT_FAVORITE_LIST_DATA,
    SET_JOBSITE_FAVORITE_LIST_DATA,
    SET_ALL_ACCOUNT_PROJECTS,
    SET_IS_FAVORITES_LOADING
} from '../../aem-core-components/actions/constants';
export const userAction = resetUser => {
    return (state, action) => {
        switch (action.type) {
            case 'setCartId':
                return {
                    ...state,
                    cartId: action.cartId
                };
            case 'setUserProfile':
                return {
                    ...state,
                    userProfile: action.userProfile
                };
            case 'setAccountProjects':
                return {
                    ...state,
                    accountProjects: action.accountProjects
                };
            case 'setUserType':
                return {
                    ...state,
                    userType: action.value
                };
            case 'setIsCheckoutLoading':
                return {
                    ...state,
                    isCheckoutLoading: action.value
                };
            case 'setIsUserProfileLoading':
                return {
                    ...state,
                    isUserProfileLoading: action.value
                };
            case 'wishlist':
                return {
                    ...state,
                    wishlist: action?.wishListData
                };
            case 'freqRentals':
                return {
                    ...state,
                    freqRentals: action?.freqRentalsData
                };
            case 'resetUser':
                resetUser();
                return {
                    ...state
                };
            case 'showInactivityNotification':
                return {
                    ...state,
                    showInactivityNotification: action.value
                };
            case 'setIsOverRidePCLoading':
                return {
                    ...state,
                    isOverRidePCLoading: action.value
                };
            case 'beginProjectsLoading':
                return {
                    ...state,
                    isProjectsLoading: true
                };
            case 'endProjectsLoading':
                return {
                    ...state,
                    isProjectsLoading: false
                };
            case SET_IS_JOBSITE_LOADED:
                return {
                    ...state,
                    isJobSiteLoaded: action.payload
                };
            case 'setHasAccountChanged':
                return {
                    ...state,
                    hasAccountChanged: action.value
                };
            case 'updateSkipQtyObject':
                return {
                    ...state,
                    userProfile: {
                        ...state.userProfile,
                        skip_qty: { ...action?.skipQtyObj }
                    }
                };
            case 'setIsProfileLoaded':
                return {
                    ...state,
                    isProfileLoaded: action.value
                };
            case SET_IS_USER_SIGNING_IN:
                return {
                    ...state,
                    isUserSigningIn: action?.isUserSigningIn
                };
            case SET_SHOW_SIGNIN_MODAL:
                return {
                    ...state,
                    showSignInModal: action?.showSignInModal
                };
            case SET_SHOW_CHOOSE_ACCOUNT_DRAWER:
                return {
                    ...state,
                    showChooseAccountDrawer: action.payload
                };
            case SET_SHOW_JOB_SITE_DRAWER:
                return {
                    ...state,
                    showJobSiteDrawer: action.payload
                };
            case SET_CAP_ACCOUNT_SELECTED:
                return {
                    ...state,
                    isCapAccountSelected: action.payload
                };
            case SET_ACCOUNT_FAVORITE_LIST_DATA:
                return {
                    ...state,
                    favoriteAccountListData: action.payload
                }
            case SET_ALL_ACCOUNT_PROJECTS:
                return {
                    ...state,
                    allAccountProjects: action.allAccountProjects
                };
            case SET_JOBSITE_FAVORITE_LIST_DATA:
                return {
                    ...state,
                    favoriteJobsiteListData: action.payload
                }
            case SET_IS_FAVORITES_LOADING:
                return {
                    ...state,
                    isFavoritesLoading: action.payload
                };
            default:
                return state;
        }
    };
};
