import React, { useEffect, useState } from 'react';
import { bool } from 'prop-types';
import { useIntl } from 'react-intl';
import { useCartState } from '../../contexts/cart';
import { useUserContext } from '../../aem-core-components/context/UserContext';
import { useFilterState } from '../cap';
import { useCookieValue } from '../../aem-core-components/utils/hooks';
import { useCheckAuthorityType, useCheckUser } from '../../hooks/useCheckUser';
import useUserData from '../../hooks/useUserData';
import ChooseAccountDrawer from '../global/modules/chooseAccountDrawer';
import Button from '../global/atoms/button';
import { reloadOnAccountChange } from '../global/utils/commonUtils';
import { USER_TYPE } from '../../constants/userDetailsConstants';
import { ENV_CONFIG } from '../../constants/envConfig';
import './GlobalAccountHeader.scss';
import { AUTHORITY_TYPE } from '../global/constants';
import { SET_SHOW_CHOOSE_ACCOUNT_DRAWER } from '../../aem-core-components/actions/constants';

const GlobalAccountHeader = props => {
    const { isCCHeader } = props;
    const intl = useIntl();
    const [{ userAccount }, dispatch] = useCartState();
    const [{ projectDetails }] = useFilterState();
    const userType = useCheckUser();
    const [settingsCookie] = useCookieValue('Settings');
    const [{ getProjectsFromCookie }] = useUserData();
    const [projectInfoCookies, setProjectInfoCookies] = useState({});
    const [userState, { dispatch: userDispatch }] = useUserContext();
    const { userProfile, showChooseAccountDrawer } = userState;
    const isSingleAccountCreditUser = userProfile?.accounts?.length === 1 || false;
    const accountName = userAccount?.accountName || projectInfoCookies?.CurrentWynneAccountName || '';
    const accountNumber = userAccount?.accountNumber || projectInfoCookies?.CurrentWynneAccount || '';
    const renderAccountNameNumber = accountName && accountNumber ? `${accountName}-#${accountNumber}` : '';
    const showSelAccounts =
        userType == USER_TYPE.CREDIT && (!isCCHeader || (isCCHeader && ENV_CONFIG.SHOW_AEM_ACC_SELECTOR_ON_CC));
    const authorityType = useCheckAuthorityType();

    useEffect(() => {
        let projectInfoAddr = projectInfoCookies?.SLA;
        let newSettings = getProjectsFromCookie();
        if (projectInfoAddr != newSettings?.SLA) setProjectInfoCookies(newSettings);
    }, [settingsCookie]);

    useEffect(() => {
        if (projectDetails?.isValueUpdated) {
            reloadOnAccountChange(userType);
        }
    }, [projectDetails?.isValueUpdated]);

    const formattedAddress = () => {
        if (!accountName || !accountNumber) {
            /** If it is a P2P user and account is not selected then showing Select account */
            if (authorityType === AUTHORITY_TYPE.P2P) {
                return <span className="header__account">{intl.formatMessage({ id: 'select-account' })}</span>;
            }
            return null;
        }
        return (
            <>
                <span className="header__account">{`${accountName}-#${accountNumber}`}</span>
            </>
        );
    };

    const toggleChooseAccountDrawer = () => {
        userDispatch({ type: SET_SHOW_CHOOSE_ACCOUNT_DRAWER, payload: !showChooseAccountDrawer });
    };

    const renderGlobalAccountSwitcher = () => {
        return (
            <div className="header__setaccount">
                {isSingleAccountCreditUser ? (
                    <span className="header__setaccount-span remove-underline" data-testid="single_account_test_id">
                        {formattedAddress()}
                    </span>
                ) : (
                    userProfile?.accounts?.length > 1 && (
                        <Button
                            onClick={toggleChooseAccountDrawer}
                            className="header__setaccount-button"
                            dataTestid="multiple_account_cta_test_id"
                            customButtonAriaLabel={`${renderAccountNameNumber} ${intl.formatMessage({
                                id: 'choose-account-label'
                            })}`}>
                            <span className="header__setaccount-span">{formattedAddress()}</span>
                        </Button>
                    )
                )}
            </div>
        );
    };

    return (
        <>
            {showSelAccounts && renderGlobalAccountSwitcher()}
            {showChooseAccountDrawer && <ChooseAccountDrawer />}
        </>
    );
};

GlobalAccountHeader.propTypes = {
    isCCHeader: bool
};

GlobalAccountHeader.defaultProps = {
    isCCHeader: false
};

export default React.memo(GlobalAccountHeader);
