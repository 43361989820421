import React from 'react';
import { func, object, oneOfType, string } from 'prop-types';
import { useIntl } from 'react-intl';
import moment from 'moment';
import Button from '../../../../atoms/button/button';
import ArrowRight from '../../../../../../resources/images/chevron-right-16.svg';

const DateSelector = ({ availabilityDate, selectDates, className, changeDateRef, productName, btnCustomClass }) => {
    const intl = useIntl();
    const formattedAvailableDate = moment(availabilityDate)?.format('MMM DD');

    return (
        <>
            <Button
                btnRef={changeDateRef}
                className={`button button-block producttile__dateselectore producttile__action ${className} ${btnCustomClass}`}
                data-testid="producttile__dateselectore"
                onClick={selectDates}
                buttonAriaLabel={`${intl.formatMessage({
                    id: 'product-availability-text'
                })} ${formattedAvailableDate} ${intl.formatMessage({ id: 'change-dates' })} for ${productName}`}>
                <span className="producttile__abailable">
                    {intl.formatMessage({ id: 'product-availability-text' })} <strong>{formattedAvailableDate}</strong>
                </span>

                <span className="producttile__changedates">
                    {intl.formatMessage({ id: 'change-dates' })} <ArrowRight />
                </span>
            </Button>
        </>
    );
};
export default React.memo(DateSelector);

DateSelector.defaultProps = {
    availabilityDate: '',
    selectDates: () => {},
    className: '',
    changeDateRef: () => {},
    productName: ''
};

DateSelector.propTypes = {
    availabilityDate: oneOfType([string, object]),
    selectDates: func,
    className: string,
    changeDateRef: func,
    productName: string
};
