import { bool, string } from 'prop-types';
import React from 'react';
import Toggle from './../../../../components/global/atoms/Toggle/Toggle';
import './ToggleTabs.scss';

const ToggleTabs = props => {
    const { toggle1Title, toggle2Title, isSelected, toggle1TestId, toggle2TestId, isBoxShadowRequired } = props;
    const handleToggleClick = () => {
        props.toggleHandler(!props.isSelected);
    };
    return (
        <div className={isBoxShadowRequired ? `tabs` : `tabsWithoutShadow`} role="tablist">
            <Toggle title={toggle1Title} isSelected={isSelected} onClick={handleToggleClick} testid={toggle1TestId} />
            <Toggle title={toggle2Title} isSelected={!isSelected} onClick={handleToggleClick} testid={toggle2TestId} />
        </div>
    );
};
export default ToggleTabs;

ToggleTabs.propsTypes = {
    toggle1Title: string,
    toggle2Title: string,
    isSelected: bool,
    toggle1TestId: string,
    toggle2TestId: string,
    isBoxShadowRequired: bool
};

ToggleTabs.defaultProps = {
    toggle1Title: '',
    toggle2Title: '',
    isSelected: bool,
    toggle1TestId: '',
    toggle2TestId: '',
    isBoxShadowRequired: false
};
