
import React from 'react';
import { node, shape, string } from 'prop-types';
import classes from './message.css';

const Message = props => {
    const { children, fieldState } = props;
    const { asyncError, error } = fieldState;
    const errorMessage = error || asyncError;
    const className = errorMessage ? classes.root_error : classes.root;

    return <p className={className}>{errorMessage || children}</p>;
};

Message.propTypes = {
    children: node,
    classes: shape({
        root: string,
        root_error: string
    }),
    fieldState: shape({
        asyncError: string,
        error: string
    })
};

export default Message;
