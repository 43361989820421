import { logError } from '../../../components/global/utils/logger';
import { useCookieValue } from '../../utils/hooks';

export const getPdpDetails = async payload => {
    const { pdpFavouritesQuery, ...rest } = payload;
    const [magentoTokenCookie, setMagentoTokenCookie] = useCookieValue('cif.magentoToken');
    const { accountNumber } = rest;
    try {
        if (!magentoTokenCookie) {
            return;
        }
        const { data, error } = await pdpFavouritesQuery({
            variables: {
                accountNumber
            },
            fetchPolicy: 'network-only'
        });
        if (error) {
            logError(error, false, 'getPdpDetails', [payload]);
        }
        return { wishlist: data?.getFavorites, error };
    } catch (error) {
        //prevent wires crossed on getFavorites
        logError(error, false, 'getPdpDetails', [payload]);
    }
};

export const addFavouritesFromPdp = async payload => {
    const { addToFavWishlist, accountNumber, sku } = payload;
    const [magentoTokenCookie, setMagentoTokenCookie] = useCookieValue('cif.magentoToken');

    try {
        if (!magentoTokenCookie) {
            return;
        }
        const { data, error } = await addToFavWishlist({
            variables: {
                accountNumber,
                sku
            },
            fetchPolicy: 'network-only'
        });
        if (error) {
            logError(error, false, 'addFavouritesFromPdp', [payload]);
        }
        return { favorites: data?.addFavorites, error };
    } catch (error) {
        logError(error, true, 'addFavouritesFromPdp', [payload]);
    }
};

export const removeFavouritesFromPdp = async payload => {
    const { removeFromFavWishlist, accountNumber, favoriteID } = payload;
    const [magentoTokenCookie, setMagentoTokenCookie] = useCookieValue('cif.magentoToken');

    try {
        if (!magentoTokenCookie) {
            return;
        }
        const { data, error } = await removeFromFavWishlist({
            variables: {
                accountNumber,
                favoriteID
            },
            fetchPolicy: 'network-only'
        });
        if (error) {
            logError(error, false, 'removeFavouritesFromPdp', [payload]);
        }
        return { data: data?.removeFavorites, error };
    } catch (error) {
        logError(error, true, 'removeFavouritesFromPdp', [payload]);
    }
};
