import { gql } from '@apollo/client';

const UPDATE_WISHLIST = gql`
    mutation updateWishlist(
        $name: String!,
        $wishlistId: ID!,
        $accountId: Int!
    ) {
        updateWishlist(
            name: $name
            wishlistId: $wishlistId
            accountId: $accountId
        ) {
            name
            uid
            visibility
        }
    }
`;
export default UPDATE_WISHLIST;