import React, { memo } from 'react';
import { bool, func, string } from 'prop-types';
import CheckedRadio from '../../../../resources/images/checked.svg';
import UncheckedRadio from '../../../../resources/images/unchecked.svg';
import './radiobutton.scss';

const RadioButton = props => {
    const { checked, handleRadioChange, name, label, value, id, dataTestId, customClassName, ariaLabel, key } = props;

    return (
        <>
            <label
                htmlFor={id}
                className={`radiobutton__label ${checked ? 'radiobutton__selected' : ''}  ${customClassName}`}>
                <input
                    key={key}
                    className="radiobutton__radio"
                    type="radio"
                    id={id}
                    name={name}
                    value={value}
                    aria-checked={checked ? true : false}
                    checked={checked}
                    onChange={handleRadioChange}
                    tabIndex={0}
                    aria-label={ariaLabel}
                    data-testid={dataTestId}
                />
                <span className="radiobutton__image">
                    {checked ? (
                        <CheckedRadio tabIndex={'-1'} aria-hidden={true} />
                    ) : (
                        <UncheckedRadio tabIndex={'-1'} aria-hidden={true} />
                    )}{' '}
                </span>
                {label}
            </label>
        </>
    );
};

RadioButton.defaultProps = {
    name: '',
    value: '',
    id: '',
    label: '',
    checked: false,
    dataTestId: '',
    customClassName: '',
    ariaLabel: '',
    handleRadioChange: () => {}
};

RadioButton.propTypes = {
    name: string,
    value: string,
    id: string,
    label: string,
    checked: bool,
    dataTestId: string,
    customClassName: string,
    ariaLabel: string,
    handleRadioChange: func,
};

export default memo(RadioButton);
