import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '../../../../aem-core-components';
import classes from '../projectListing/projectListing.css';
import CloseCircleIcon from '../../../../resources/images/close-circle.svg';
import SearchIcon from '../../../../resources/images/search.svg';
import Input from '../../atoms/input';
import { MIN_SEARCH_QUERY_LENGTH, SEARCH_VIEW_LENGTH } from '../../utils/commonUtils';
import { jobsiteDataLocators } from './dataLocators'
import JobsiteListView from './JobsiteListView';
import './jobsitesListingModal.scss';

const FavoriteJobsites = (props) => {
    const { isProjectsLoading, favoriteJobsiteListData,
        favoriteListData,
        setFavoriteListData, recommendedJobsites, isJobSiteLoaded,
        selectedValue, setSelectedValue, setSelectedJobsite, cardStyle, jobsiteListLabel, isModalOpen } = props;
    const intl = useIntl();
    const [searchQuery, setSearchQuery] = useState('');
    const [showProjectSearch, setShowProjectSearch] = useState(false);
    const [selectedJobsiteOnTop, setSelectedJobsiteOnTop] = useState(true);
    const searchCriteria = ['jobName', 'address1', 'address2', 'state', 'city', 'zip'];

    useEffect(() => {
        updateAccountList();
    }, [searchQuery]);

    useEffect(() => {
        if (favoriteJobsiteListData?.length >= SEARCH_VIEW_LENGTH) {
            setShowProjectSearch(true);
        } else {
            setShowProjectSearch(false);
        }
    }, [favoriteJobsiteListData, searchQuery]);

    useEffect(() => {
        if (selectedJobsiteOnTop) {
            const copyOfFavoriteJobsiteListData = [...favoriteJobsiteListData]
            const sortedFavoriteList = copyOfFavoriteJobsiteListData?.sort((a, b) => a?.jobName?.localeCompare(b?.jobName));
            const sortedJobsiteList = sortSelectedJobsites(sortedFavoriteList);
            setFavoriteListData(sortedJobsiteList);
        }
    }, [searchQuery, favoriteJobsiteListData, selectedJobsiteOnTop]);

    const updateAccountList = () => {
        if (searchQuery?.length < MIN_SEARCH_QUERY_LENGTH) {
            setFavoriteListData(favoriteJobsiteListData);
            setSelectedJobsiteOnTop(true);
        } else {
            setFavoriteListData(filterData(favoriteListData, favoriteJobsiteListData, searchQuery));
            setSelectedJobsiteOnTop(false);
        }
    };

    const sortSelectedJobsites = (jobsiteList = []) => {
        const jobNumber = selectedValue?.trim();
        const clonedAccountList = [...jobsiteList];

        return clonedAccountList.sort((a, b) => {
            const isSelectedA = jobNumber === a?.jobNumber?.trim();
            const isSelectedB = jobNumber === b?.jobNumber?.trim();

            if (isSelectedA) return -1;
            if (isSelectedB) return 1;
            return 0;
        });
    };


    const filterData = (selectedTabRecords, selectedTabAllRecords, query = '') => {
        const loweredQuery = query?.toLowerCase();
        if (loweredQuery?.length < MIN_SEARCH_QUERY_LENGTH) {
            return selectedTabRecords;
        }
        return selectedTabAllRecords?.filter(data => {
            return Object.keys(data)?.some(key => {
                return searchCriteria?.includes(key)
                    ? data[key]?.toString()?.toLowerCase()?.includes(loweredQuery)
                    : false;
            });
        });
    };

    const onCrossIconClick = e => {
        setSearchQuery('');
    };

    const onSearch = e => {
        setSearchQuery(e.target.value);
    };

    const favoriteSearch = () => {
        return (
            <div className={classes.searchRoot}>
                <div
                    className={classes.searchInputBoxWrap}
                >
                    <div className={classes.searchInputBox}>
                        <SearchIcon className={classes.searchInputIcon} />
                        <Input
                            type="text"
                            name="account_search"
                            classes={classes.searchInput}
                            placeholder={intl.formatMessage({ id: 'cap:search-jobsite' })}
                            value={searchQuery}
                            dataTestId={jobsiteDataLocators.choose_jobsite_search_input}
                            handleInputChange={onSearch}
                            inputAriaLabel={`${intl.formatMessage({
                                id: 'account:ac-common-search-text'
                            })} ${intl.formatMessage({ id: 'cap:search-jobsite' })}`}
                        />
                        {searchQuery && (
                            <Button
                                buttonAriaLabel={`${intl.formatMessage({
                                    id: 'projects:clear-search-bar'
                                })}`}
                                data-testid={jobsiteDataLocators.choose_jobsite_cross_icon}
                                className={classes.crossIcon}
                                onClick={onCrossIconClick}>
                                {<CloseCircleIcon />}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        );
    };


    return (
        <JobsiteListView
            isProjectsLoading={isProjectsLoading}
            accountProjects={favoriteJobsiteListData}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            searchedJobsite={favoriteListData}
            showProjectSearch={showProjectSearch}
            recommendedJobsites={recommendedJobsites}
            isJobSiteLoaded={isJobSiteLoaded}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            setSelectedJobsite={setSelectedJobsite}
            cardStyle={cardStyle}
            jobsiteListLabel={jobsiteListLabel}
            isModalOpen={isModalOpen}
            isFavoriteNoResult
            jobsiteSearch={favoriteSearch}
        />
    )
};

export default React.memo(FavoriteJobsites);
