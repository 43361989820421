import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { MEDIA_TYPE } from '../../../main/constants/screenConstants';
import { Button, LoadingIndicator } from '../../aem-core-components';
import { SIDE_DRAWER_CREATE_FLOW } from '../../aem-core-components/context/Modal/constants';
import { ModalContext } from '../../aem-core-components/context/ModalContext';
import { useUserContext } from '../../aem-core-components/context/UserContext';
import { ENV_CONFIG } from '../../constants/envConfig';
import { STORAGE_CONFIG } from '../../constants/storageConfig';
import useMedia from '../../hooks/useMedia';
import Plus from '../../resources/images/plus.svg';
import partialConfig from '../App/config';
import { SET_SHOW_SAVE_FAVORITE_MODAL, SET_WISHLIST_LOADING } from '../favorites/actionTypes/actionTypes';
import { MAX_WISHLIST_ITEMS, WISHLIST_MAX_PAGE_SIZE, dropdownValuesConstants } from '../favorites/constants';
import { useFavouritesState } from '../favorites/context';
import useFavourites from '../favorites/hooks/useFavorites';
import { basePathForPunchout } from '../global/utils/commonUtils';
import { logError } from '../global/utils/logger';
import './myFavourites.scss';

const MyFavourites = () => {
    const intl = useIntl();
    const mediaType = useMedia();
    const useModalState = () => useContext(ModalContext);
    const { openModal } = useModalState();
    const [{ favourites, favouritesListUpdating }, favDispatch] = useFavouritesState();
    const [{ isUserProfileLoading }] = useUserContext();
    const { getWishListListItems } = useFavourites();
    const [loading, setLoading] = useState(false);
    const [favsData, setFavsData] = useState(null);
    const [noResults, setNoResults] = useState(true);
    const { pagePaths } = partialConfig;

    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.MY_FAVORITES_DROPDOWN_DATA));
        if (data?.length) {
            setFavsData(data);
            setNoResults(false);
        } else if (!isUserProfileLoading && !favouritesListUpdating) {
            fetchAndUpdateState();
        }
    }, [isUserProfileLoading, favouritesListUpdating]);

    useEffect(() => {
        const favoriteHeader = document.querySelector('#header__menu .favorite_header');
        const myFavoriteMenu = document.querySelector('#my-favorite-menu');
        
        favoriteHeader?.addEventListener('mouseenter', () => {
            myFavoriteMenu.scrollTo(0, 0);
        });

        return () => {
            favoriteHeader?.removeEventListener('mouseenter');
        };
    }, []);

    const fetchWishlistList = async () => {
        try {
            favDispatch({ type: SET_WISHLIST_LOADING, payload: true });
            const corpAccDetails = JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.CORP_ACCOUNT_DETAILS) || '{}');
            const { data } = await getWishListListItems({
                productsSize: MAX_WISHLIST_ITEMS,
                sortBy: dropdownValuesConstants.LAST_MODIFIED,
                productSortBy: dropdownValuesConstants.ATOZ,
                wishlistPageSize: WISHLIST_MAX_PAGE_SIZE,
                accountId: corpAccDetails?.id || ''
            });
            return data;
        } catch (e) {
            logError(e, false, 'getWishListListItems');
            return null;
        } finally {
            favDispatch({ type: SET_WISHLIST_LOADING, payload: false });
        }
    };

    const fetchAndUpdateState = async () => {
        setLoading(true);
        const data = await fetchWishlistList();
        if (data?.customer?.wishlists?.length) {
            setNoResults(false);
        }
        setLoading(false);
    }

    const doCreateNewList = () => {
        // in mobile/tablet device onclick close the menu drawer
        const mobSideDrawerMenu = document.querySelector('.side__menu.active');
        const sideMenuDrawerButton = document.querySelector('.side__menu-button');
        if (mediaType !== MEDIA_TYPE.DESKTOP && mobSideDrawerMenu) {
            mobSideDrawerMenu?.classList.remove('active');
            sideMenuDrawerButton?.classList.toggle('active');
            document.querySelector('body').classList.toggle('body--overflow-hidden');
        }
        favDispatch({type: SET_SHOW_SAVE_FAVORITE_MODAL, payload: true});
        openModal(SIDE_DRAWER_CREATE_FLOW);
    };

    const getNavigationLink = (id) => {
        return window.location.origin + basePathForPunchout + pagePaths.p2pFavoritesDetailPage.replace(':id', id)
    }

    const renderMyFavDropdownList = () => {
        return !noResults ? (
            <ul className="menu__column" aria-label={'My favorites list'}>
                {(favourites || favsData)?.map(fav => (
                    <li key={fav?.id}>
                        <a
                            className="menu__column__link"
                            aria-label={fav?.name}
                            href={getNavigationLink(fav?.id)}>
                            {fav?.name}
                        </a>
                    </li>
                ))}
            </ul>
        ) : null;
    };

    const getCreateButtonLabel = () => {
        const favouriteNewListLabel = ENV_CONFIG.FAVOURITE_NEW_LIST_LABEL || intl.formatMessage({ id: 'p2p-favorites-create-new-list' })
        return favouriteNewListLabel;
    }


    return (
        <div className="my_favorites_menu header__l1__menu">
            <div id="my-favorite-menu" className="header__menu-submenu header__submenu-main">
                <Button className="mob_nav_show back_main_menu side__menu-item-top">
                    <span className="side__menu-item--back-button" tabIndex="-1" aria-hidden="true"></span>
                    <p className="back__to_text">My Favorites</p>
                </Button>
                <Button
                    className="menu__column__link menu__column__link-pinned"
                    onClick={doCreateNewList}
                    aria-labelledby="create-new-list-cta">
                    <Plus />
                    <span id="create-new-list-cta">{getCreateButtonLabel()}</span>
                </Button>
                {loading ? <LoadingIndicator /> : renderMyFavDropdownList()}
            </div>
        </div>
    );
};

export default MyFavourites;
