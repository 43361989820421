import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { bool, func, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import { ENTER_KEY, MEDIA_TYPE } from '../../../../constants/screenConstants';
import useMedia from '../../../../hooks/useMedia';
import { usePageType } from '../../../../hooks/usePageType';
import ArrowRight from '../../../../resources/images/arrow-ios-right.svg';
import { logError } from '../../utils/logger';
import Button from '../button/button';
import './autocomplete.scss';
import { autoCompleteDataLocator } from './dalaLocators';

const AutoCompleteDropdown = props => {
    const inputRef = useRef(null);
    const componentRef = useRef(null);
    const {
        onSelect,
        renderOption,
        onChange,
        isDropDownOpen,
        value,
        suggestions,
        className,
        onReset,
        onSearchIconClick,
        customParentClass,
        showResetBtn,
        placeholderText,
        showSearchIcon,
        errorMsg,
        renderSuggestion,
        customOnSearchClick,
        autoCompleteWrapperClass,
        autoCompleteInputClass,
        autoCompleteInputWrapper,
        onClearingText,
        onEnterKeyPress,
        source,
        labelContent,
        renderCustomSuggestions,
        onInputFocus,
        onOutsideClickOrFocus,
        focusInput,
        inputId,
        searchButtonLabel,
        handleEcomLocationAnalyticsOnFocus,
        isError,
        p2pClass,
        handleClick,
        isOpen,
        expandOnFocus,
        focusOnReset,
        disableSubmitOnEnter,
        selectIdFromItem,
        enableKeyboardNavigation,
        openDropdown,
        readOnly,
        ...restProps
    } = props;
    //the flag isInitialOpen is to fix the issue when dropdown remains open on page load
    const [isInitialOpen, setIsInitialOpen] = useState(false);
    const [isSuggestionsAvailable, setIsSuggestionsAvailable] = useState(false);
    const [isScrollBtnVisible, setIsScrollBtnVisible] = useState(false);
    const pageType = usePageType();
    const { sendEventsForClick } = useAnalyticsContext();
    const listRef = useRef(null);
    const intl = useIntl();
    const mediaType = useMedia();

    const onBlur = e => {
        if (e.target.value === '' && onClearingText) {
            onClearingText();
        }
        sendEvents();
    };

    const onFocus = () => {
        if (expandOnFocus) {
            inputRef?.current?.select();
            // for use current location suggestion
            setIsSuggestionsAvailable(true);
            setIsInitialOpen(true);
        }
        onInputFocus();
        if (handleEcomLocationAnalyticsOnFocus) {
            handleEcomLocationAnalyticsOnFocus();
        }
    };

    const handleOnClick = e => {
        handleClick(e);
    };

    const sendEvents = () => {
        try {
            if (!window.location.href.includes('checkout-page') && showSearchIcon) {
                sendEventsForClick(
                    VARIABLE_CONFIG.EVENT.UAEVENT,
                    VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                    VARIABLE_CONFIG.EVENT_CATEGORY.SEARCH_BAR,
                    source === VARIABLE_CONFIG.PLACEMENT.HEADER
                        ? VARIABLE_CONFIG.EVENT_ACTION.SELECT_HEADER
                        : VARIABLE_CONFIG.EVENT_ACTION.SELECT,
                    `${value} :: ${pageType}`
                );
            }
        } catch (error) {
            logError(error, false, 'sendEvents');
        }
    };
    const handleItemClick = async value => {
        onSelect(value);
    };

    useEffect(() => {
        if (focusInput && inputRef?.current) {
            inputRef?.current?.focus();
        }
    }, [focusInput]);

    useEffect(() => {
        if (!isOpen && inputRef?.current && readOnly) {
            inputRef?.current?.blur();
        }
        if (listRef?.current?.children?.length === 0 || !listRef?.current) {
            setIsSuggestionsAvailable(false);
        } else {
            setIsSuggestionsAvailable(true);
        }
    }, [value, suggestions, isOpen]);

    useEffect(() => {
        if (isOpen) {
            inputRef?.current?.select();
            setIsSuggestionsAvailable(true);
            setIsInitialOpen(true);
        }
    }, [isOpen]);

    //Track events outside scope
    const clickOutside = useCallback(
        e => {
            if (componentRef?.current && !componentRef.current?.contains(e.target)) {
                // outside click
                setIsSuggestionsAvailable(false);
                onOutsideClickOrFocus();
            }
        },
        [componentRef, setIsSuggestionsAvailable, onOutsideClickOrFocus]
    );

    const clickOutsideRef = useRef(clickOutside);
    clickOutsideRef.current = clickOutside;

    useEffect(() => {
        const handleMouseDown = e => clickOutsideRef.current(e);
        const handleFocusIn = e => clickOutsideRef.current(e);

        document.addEventListener('mousedown', handleMouseDown);
        if (mediaType === MEDIA_TYPE.DESKTOP) {
            document.addEventListener('focusin', handleFocusIn);
        }

        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
            if (mediaType === MEDIA_TYPE.DESKTOP) {
                document.removeEventListener('focusin', handleFocusIn);
            }
        };
    }, [value]);

    const onClickHandler = () => {
        onSelect(value);
        customOnSearchClick(value);
    };

    const submitOnEnter = e => {
        if (e.key === ENTER_KEY && !disableSubmitOnEnter) {
            if (onEnterKeyPress) {
                onEnterKeyPress(e?.target?.value);
            } else {
                onClickHandler();
            }
            sendEvents();
        }
    };

    const onResetClick = e => {
        onReset(e);
        focusOnReset && inputRef?.current?.focus();
    };

    const closeOnEnter = e => {
        if (e.key === ENTER_KEY) {
            onResetClick(e);
        }
    };

    const handleChange = e => {
        if (!isInitialOpen) {
            setIsInitialOpen(true);
        }
        onChange(e);
    };
    const handleKeyboardNavigation = e => {
        if (e.key === ENTER_KEY) {
            handleOnClick();
        }
        const listItems = document.querySelectorAll('.listItems');
        const listItemsIds = [...listItems]?.map(item =>
            selectIdFromItem ? item?.id : item?.querySelector('div')?.id
        );
        if (e.key === 'Enter' && e.target.value === '' && onClearingText) {
            onClearingText();
        }
        if (e.keyCode === 40 && e.target.tagName.toLowerCase() === 'input') {
            listItems[0]?.focus();
        } else {
            if ((e.keyCode === 38 || e.keyCode === 40) && (showSearchIcon || enableKeyboardNavigation)) {
                const idOfCurrentFocus = e.target?.id;
                const currentFocussedIndex = listItemsIds?.indexOf(idOfCurrentFocus);
                if (
                    (currentFocussedIndex === 0 && e.keyCode === 38) ||
                    (currentFocussedIndex === listItems?.length - 1 && e.keyCode === 40)
                ) {
                    //continue
                } else {
                    if (e.keyCode === 38) {
                        document.getElementById(listItemsIds[currentFocussedIndex - 1])?.focus();
                    } else {
                        document.getElementById(listItemsIds[currentFocussedIndex + 1])?.focus();
                    }
                }
            }
        }
    };

    const renderSuggestionItem = (suggestion, index) => {
        if (renderSuggestion) {
            return renderSuggestion(suggestion, index);
        }
        return (
            <div key={suggestion}>
                <li
                    tabIndex={-1}
                    aria-label={suggestion}
                    id={suggestion}
                    aria-selected="false"
                    role="option"
                    className="autocomplete__list-item"
                    onClick={handleItemClick}
                    onKeyDown={submitOnEnter}>
                    {suggestion}
                </li>
                <ArrowRight />
            </div>
        );
    };

    return (
        <div className={autoCompleteWrapperClass} ref={componentRef}>
            <div className={`autocomplete__input ${autoCompleteInputWrapper}`}>
                <div className={`autocomplete__input-wrap`}>
                    <input
                        id={inputId}
                        type="text"
                        className={`${autoCompleteInputClass}
                             ${value ? ` ${errorMsg ? 'error_input__border' : ''}` : ''} ${
                            isError ? 'error_input__border' : ''
                        }`}
                        value={value}
                        onChange={handleChange}
                        onFocus={onFocus}
                        onClick={handleOnClick}
                        onBlur={onBlur}
                        onKeyPress={submitOnEnter}
                        onKeyDown={handleKeyboardNavigation}
                        data-testid={autoCompleteDataLocator.searchBar_inputField}
                        autoComplete="off"
                        placeholder={placeholderText}
                        aria-placeholder={placeholderText}
                        ref={inputRef}
                        aria-required={true}
                        readOnly={readOnly}
                        {...restProps}
                        title={intl.formatMessage({ id: 'location:search-title' })}
                    />
                    {value && showResetBtn && (
                        <Button
                            tabIndex={0}
                            className="crossIcon"
                            onClick={onResetClick}
                            onKeyPress={closeOnEnter}
                            dataTestid={autoCompleteDataLocator.searchBarCross_CTA}
                            customButtonAriaLabel={intl.formatMessage({
                                id: 'pdp:search-bar-cross-icon'
                            })}>
                            <i className="align icon icon-close-circle" aria-hidden={true}></i>
                        </Button>
                    )}
                    {labelContent()}
                </div>
                {showSearchIcon && (
                    <Button
                        tabIndex={0}
                        className=" searchIconClass input-group-append"
                        onClick={onClickHandler}
                        dataTestid={autoCompleteDataLocator.searchBarIcon_CTA}
                        customButtonAriaLabel={
                            searchButtonLabel ||
                            intl.formatMessage({
                                id: 'pdp:search-bar-search-icon'
                            })
                        }>
                        <i className="icon icon-search-icon" aria-hidden={'true'}></i>
                    </Button>
                )}
            </div>
            {((value?.length > 2 && suggestions?.length > 0) ||
                (value?.length > 0 && autoCompleteWrapperClass && suggestions?.length > 0) ||
                openDropdown) &&
                isInitialOpen && (
                    <ul
                        ref={listRef}
                        className={`suggestion__list_container ${p2pClass} ${
                            isDropDownOpen ? `autocomplete__list autocomplete__dropdownList` : 'autocomplete__list'
                        } ${!isSuggestionsAvailable ? 'hide__autocomplete_list' : ''}`}
                        onKeyDown={handleKeyboardNavigation}>
                        {renderCustomSuggestions ? (
                            renderCustomSuggestions()
                        ) : (
                            <>
                                {suggestions?.map(item => renderSuggestionItem(item))}
                                {isScrollBtnVisible && (
                                    <div
                                        className={`scroll_arrow ${
                                            isSuggestionsAvailable ? '' : 'hide__autocomplete_list'
                                        }`}>
                                        <span>
                                            <ArrowRight />
                                        </span>
                                    </div>
                                )}
                            </>
                        )}
                    </ul>
                )}
        </div>
    );
};

AutoCompleteDropdown.propTypes = {
    showSearchIcon: bool,
    showResetBtn: bool,
    autoCompleteWrapperClass: string,
    autoCompleteInputClass: string,
    autoCompleteInputWrapper: string,
    onClearingText: func,
    onEnterKeyPress: func,
    onReset: func,
    labelContent: func,
    renderCustomSuggestions: func,
    onInputFocus: func,
    onOutsideClickOrFocus: func,
    handleEcomLocationAnalyticsOnFocus: func,
    focusInput: bool,
    inputId: string,
    searchButtonLabel: string,
    isError: bool,
    p2pClass: string,
    handleClick: func,
    isOpen: bool,
    expandOnFocus: bool,
    focusOnReset: bool,
    disableSubmitOnEnter: bool,
    selectIdFromItem: bool,
    enableKeyboardNavigation: bool,
    openDropdown: bool,
    readOnly: bool
};

AutoCompleteDropdown.defaultProps = {
    showSearchIcon: false,
    showResetBtn: false,
    suggestions: [],
    customOnSearchClick: () => {},
    autoCompleteWrapperClass: '',
    autoCompleteInputClass: '',
    autoCompleteInputWrapper: '',
    onClearingText: () => {},
    onEnterKeyPress: null,
    onReset: () => {},
    labelContent: () => {},
    renderCustomSuggestions: null,
    onInputFocus: () => {},
    onOutsideClickOrFocus: () => {},
    handleEcomLocationAnalyticsOnFocus: () => {},
    focusInput: false,
    inputId: '',
    searchButtonLabel: '',
    isError: false,
    p2pClass: '',
    handleClick: () => {},
    isOpen: false,
    expandOnFocus: false,
    focusOnReset: false,
    disableSubmitOnEnter: false,
    selectIdFromItem: false,
    enableKeyboardNavigation: false,
    openDropdown: false,
    readOnly: false
};
export default memo(AutoCompleteDropdown);
