import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { bool, func, instanceOf, oneOfType, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { useCartState } from '../../../../contexts/cart';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../../constants/analyticsConstants/Ecommerce';
import { useCheckAuthorityType } from '../../../../hooks/useCheckUser';
import { usePageType } from '../../../../hooks/usePageType';
import AlertCross from '../../../../resources/images/alert-cross-red.svg';
import AlertTriangle from '../../../../resources/images/alert-triangle.svg';
import Alert from '../../atoms/alert/alert';
import Button from '../../atoms/button/button';
import { AUTHORITY_TYPE } from '../../constants';
import { isCCPage, isFourHourRental } from '../../utils/commonUtils';
import './UnavailableRental.scss';

const UnavailableRental = props => {
    const { icon, handleToggle, isOpen, isEndDateEmpty, showWarning, minAvailableDate, isStartDateEarly } = props;
    const intl = useIntl();
    const chatWithUsRef = useRef(null);
    const [showWarningOnSubmit, setShowWarningOnSubmit] = useState(false);
    const { sendEventsForDatePickerAction, sendEventsForDatePickerFormReset } = useAnalyticsContext();
    const [{ endDateClick, calendarDateInteraction, cart }, dispatch] = useCartState();
    const pageType = usePageType();
    const authorityType = useCheckAuthorityType();
    const isP2P = authorityType === AUTHORITY_TYPE.P2P;
    const earlyStartDateError = isStartDateEarly && isP2P;

    useEffect(() => {
        setShowWarningOnSubmit(showWarning);
        if (showWarning) {
            chatWithUsRef?.current && chatWithUsRef?.current?.focus();
        }
    }, [showWarning]);

    const handleChatClick = () => {
        handleToggle(!isOpen);
        sendEventsForDatePickerFormReset(
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CTA_CLICKED,
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_FORM_NAME,
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_PICKER_FORM_CLOSE_CHAT,
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_PICKER_FORM_RESET_TYPE,
            pageType
        );
        LiveChatWidget.call('maximize');
    };
    const isLiveChatOpenConditionFulfilled = () => {
        if (isCCPage() || window.location.pathname.includes('/login')) {
            return false;
        } else {
            return true;
        }
    };

    const isErrorUI = () => {
        if (minAvailableDate) {
            return false;
        }
        if (isEndDateEmpty || isFourHourRental(cart) || isStartDateEarly) {
            return true;
        }
        return false;
    };

    const isEndDateFocused = () => {
        if (endDateClick || calendarDateInteraction) {
            return true;
        } else {
            return false;
        }
    };

    const handleWarningMessage = () => {
        const {
            ECOMMERCE_FORM_ERROR,
            ECOMMERCE_DATE_FORM_NAME,
            ECOMMERCE_END_DATE_FORM_FIELD,
            ECOMMERCE_START_DATE_FORM_FIELD
        } = EVENT_ECOMMERCE_NAMES_CONFIG;
        let message = '';
        let field = '';

        if (earlyStartDateError) {
            message = intl.formatMessage({ id: 'date-picker: early-start-date' });
            field = isEndDateFocused() ? ECOMMERCE_END_DATE_FORM_FIELD : ECOMMERCE_START_DATE_FORM_FIELD;
        } else if (isEndDateEmpty) {
            message = intl.formatMessage({ id: 'date-picker: empty-dates-error' });
            field = isEndDateFocused() ? ECOMMERCE_END_DATE_FORM_FIELD : ECOMMERCE_START_DATE_FORM_FIELD;
        } else if (minAvailableDate && !calendarDateInteraction) {
            message = `${intl.formatMessage({ id: 'date-picker: available-date' })}`;
            return (
                <div role="alert">
                    {message} <strong>{moment(minAvailableDate)?.format('MM/DD/YY')}.</strong>
                </div>
            );
        } else if (isFourHourRental(cart)) {
            message = intl.formatMessage({ id: 'date-picker: 4-hr-rental-error' });
            field = ECOMMERCE_END_DATE_FORM_FIELD;
        } else {
            message = intl.formatMessage({ id: 'cart:invalid-date-select-error' });
            field = isEndDateFocused() ? ECOMMERCE_END_DATE_FORM_FIELD : ECOMMERCE_START_DATE_FORM_FIELD;
        }
        if (showWarning && !showWarningOnSubmit) {
            sendEventsForDatePickerAction(ECOMMERCE_FORM_ERROR, ECOMMERCE_DATE_FORM_NAME, field, message);
        }
        if (isEndDateEmpty || isFourHourRental(cart)) {
            return message;
        }

        const chatWithUsText = earlyStartDateError
            ? intl.formatMessage({ id: 'cart:contact-support' })
            : intl.formatMessage({ id: 'cart:chat-message' });

        const errorMessageText = earlyStartDateError
            ? intl.formatMessage({ id: 'cart:invalid-date-select-error-text-2' })
            : intl.formatMessage({ id: 'cart:invalid-date-select-error-text' });
        return (
            <div>
                <span aria-hidden="true">{message} </span>
                {isLiveChatOpenConditionFulfilled() ? (
                    <>
                        <Button
                            className={
                                earlyStartDateError
                                    ? 'unavailablerental-mobile__contact_support'
                                    : 'unavailablerental-mobile__chat'
                            }
                            onClick={handleChatClick}
                            dataTestid={'chat-with-us'}
                            btnRef={chatWithUsRef}
                            buttonAriaLabel={`${chatWithUsText} as ${message}`}>
                            {chatWithUsText}
                        </Button>{' '}
                        {errorMessageText}
                    </>
                ) : (
                    <>
                        <span>{chatWithUsText}</span> {errorMessageText}
                    </>
                )}
            </div>
        );
    };

    const getAlertIcon = () => {
        if (earlyStartDateError) {
            return <AlertCross />;
        } else if (icon) {
            return icon;
        } else if (!isErrorUI()) {
            return <AlertTriangle />;
        } else {
            return '';
        }
    };

    return (
        <div
            className={
                isEndDateEmpty || earlyStartDateError
                    ? 'unavailablerental-error_box'
                    : 'unavailablerental-mobile__alert'
            }>
            <Alert
                localStyle={isErrorUI() ? '' : 'unavailablerental_warning'}
                icon={getAlertIcon()}
                type={isErrorUI() ? 'error' : 'warning'}
                message={handleWarningMessage()}
            />
        </div>
    );
};

UnavailableRental.propTypes = {
    handleToggle: func,
    isOpen: bool,
    showWarning: bool,
    minAvailableDate: oneOfType([instanceOf(Date), string])
};

UnavailableRental.defaultProps = {
    handleToggle: () => {},
    isOpen: false,
    showWarning: false,
    minAvailableDate: ''
};

export default React.memo(UnavailableRental);
