const industriesViewChange = $(".industries__viewchange");
const industriesViewchangeGrid = $(".industries__viewchange-grid");
const industriesViewchangeList = $(".industries__viewchange-list");
const industriesGrid = $(".industries__grid");
const industriesList = $(".industries__list");

window.addEventListener('load', event => {
	try {
		reportWindowSize();
		industriesViewchangeGrid.on("click", function () {
			$(this).addClass("industries__active active");
			industriesViewchangeList.removeClass("industries__active active");
			industriesGrid.addClass("active");
			industriesList.removeClass("active");
		});
		industriesViewchangeList.on("click", function () {
			$(this).addClass("industries__active active");
			industriesViewchangeGrid.removeClass("industries__active active");
			industriesGrid.removeClass("active");
			industriesList.addClass("active");
		});
	} catch (error) {
		console.error('An error occurred while initializing the jquery:', error);
	}
})

function reportWindowSize() {
	const windowInnerWidth = window.innerWidth;

	if (windowInnerWidth <= 1024.98) {
		industriesGrid.removeClass("active");
		industriesList.addClass("active");
		industriesViewchangeList.addClass("industries__active active");
		industriesViewchangeGrid.removeClass("industries__active active");
	} else {

		industriesGrid.addClass("active");
		industriesList.removeClass("active");
		industriesViewchangeList.removeClass("industries__active active");
		industriesViewchangeGrid.addClass("industries__active active");
	}
}
// window.addEventListener("resize", reportWindowSize);