import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useUserContext } from '../../../aem-core-components/context/UserContext';
import { useCheckUser } from '../../../hooks/useCheckUser';
import useCoachmark from '../../../hooks/useCoachmark';
import isObjectWithKeys from '../../../aem-core-components/utils/isObjectWithKeys';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import { COACHMARK_TYPE } from '../constants';

export const useRentalCoachmark = localSelectedPickupStore => {
    const [{ isProfileLoaded }] = useUserContext();
    const userType = useCheckUser();
    const { setCoachmarkCount, showCoachmark } = useCoachmark();
    const [locationContainerRef, isLocationVisible] = useInView();
    const [isLocationTooltipOpen, setIsLocationTooltipOpen] = useState(false);
    const [pickupstoreContainerRef, isPickupStoreContainerVisible] = useInView();
    const [isPickupstoreTooltipOpen, setIsPickupstoreTooltipOpen] = useState(false);
    const [showPickupCoachmark, setShowPickupCoachmark] = useState(false);
    const [showLocationCoachmark, setShowLocationCoachmark] = useState(false);

    useEffect(() => {
        if (isProfileLoaded) {
            setShowPickupCoachmark(showCoachmark(COACHMARK_TYPE.PICK_STORE));
            setShowLocationCoachmark(showCoachmark(COACHMARK_TYPE.LOCATION));
        }
    }, [isProfileLoaded]);

    useEffect(() => {
        if (userType === USER_TYPE.CREDIT && isLocationVisible && showLocationCoachmark) {
            setCoachmarkCount(COACHMARK_TYPE.LOCATION);
            setIsLocationTooltipOpen(true);
        } else {
            setIsLocationTooltipOpen(false);
        }
    }, [showLocationCoachmark, isLocationVisible]);

    const handlePickupstoreTooltipClose = (changeShowPickupCoachmark = true) => {
        setIsPickupstoreTooltipOpen(false);
        changeShowPickupCoachmark && setShowPickupCoachmark(false);
    };

    const handleLocationTooltipClose = () => {
        setIsLocationTooltipOpen(false);
        setShowLocationCoachmark(false);
    };

    useEffect(() => {
        if (isPickupStoreContainerVisible && showPickupCoachmark) {
            if (isObjectWithKeys(localSelectedPickupStore)) {
                setCoachmarkCount(COACHMARK_TYPE.PICK_STORE);
                setIsPickupstoreTooltipOpen(true);
            }
        } else {
            setIsPickupstoreTooltipOpen(false);
        }
    }, [showPickupCoachmark, isPickupStoreContainerVisible, localSelectedPickupStore?.pc]);

    return {
        isLocationTooltipOpen,
        isPickupstoreTooltipOpen,
        locationContainerRef,
        pickupstoreContainerRef,
        handlePickupstoreTooltipClose,
        handleLocationTooltipClose
    };
};
