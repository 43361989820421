import { useState } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import MUTATION_UPDATE_CART_ITEM from '../../../aem-core-components/queries/mutation_update_cart_item.graphql';
import CART_DETAILS_QUERY from '../../../aem-core-components/queries/query_cart_details.graphql';
import { useFilterState } from '../../cap';
import { useUserContext } from '../../../aem-core-components/context/UserContext';
import { useCartState } from '../../../contexts/cart';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import useProduct from '../../../hooks/useProduct';
import useCartOptions from '../../../hooks/useCartOptions';
import { useCapUtils } from '../../cap/hooks/useCapUtils';
import { useAwaitQuery } from '../../../aem-core-components/utils/hooks';
import useUserData from '../../../hooks/useUserData';
import useCheckLocationEmpty from '../../../hooks/useCheckLocationEmpty';
import { useCheckUser } from '../../../hooks/useCheckUser';
import useAnalytics from '../../../hooks/useAnalytics';
import useCheckoutNavigate from '../../App/hooks/useCheckoutNavigate';
import { useChronosAvailability } from '../../../hooks/useChronosAvailability';
import { useAtp } from '../../cap/hooks/useATP';
import useCartEstimate from '../../App/hooks/useCartEstimate';
import isObjectWithKeys from '../../../aem-core-components/utils/isObjectWithKeys';
import { isValidString, logError } from '../../global/utils/logger';
import { getCartDetails } from '../../../aem-core-components/actions/cart';
import { getDateDiffInHrs } from '../../cap/utils/atputils';
import { generateSKU, getRentalDuration } from '../../global/utils/commonUtils';
import config from '../../App/config';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import {
    SET_CART_AVAILABLE_UNVAILABLE_ITEMS,
    SET_CHECKOUT_ERROR_DETAILS,
    SET_IS_CART_LOADING,
    SET_RECOMPUTE_ITEM_AVAILABILITY,
    SET_SOURCES_LOADING_FLAG,
    SET_VIEW_CART_FIELDS
} from '../../../aem-core-components/actions/constants';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { TILE_STATES } from '../../global/modules/productTile/constants';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../constants/analyticsConstants/Ecommerce';
import { EMPLOYEE } from '../../checkout/constants';

/**
 * @class useCartOperations - is a custom hook that provides various utility functions to handle cart operations .
 * @param {item} props.item = cart item on which operation has to be performed @default {}
 * @returns {Object} An object containing various utility functions.
 */
export const useCartOperations = props => {
    const { item } = props || {};
    const cartIntl = useIntl();
    const { product = {} } = item || {};
    const [, { removeItem }] = useProduct({ item });
    const { handleATPCart, getItemAvailabilityATP } = useAtp();
    const [updateCartItemMutation] = useMutation(MUTATION_UPDATE_CART_ITEM);
    const cartDetailsQuery = useAwaitQuery(CART_DETAILS_QUERY);
    const [, { dispatch, updateCartFromMinicart, removeUnavailableItems }] = useCartOptions({
        updateCartItemMutation,
        cartDetailsQuery,
        handleATPCart
    });
    const [{ cart, cartId, isCreditNewAddress, showGuestCheckoutLogin, isSourcesLoading, userAccount }] =
        useCartState();
    const { sendEventsForEcommerceRemove, sendEventsForEcommerceAdd, sendEventsForEcommerceAction } =
        useAnalyticsContext();
    const [{ payloadEcommerceActionAnalytics }] = useAnalytics();
    const [{ startDate, endDate, viewCart, selectedStoreDetails, projectDetails }, filterDispatch] = useFilterState();
    const [{ userProfile }, { dispatch: userDispatch }] = useUserContext();
    const [{ updateFourHourRentals }] = useUserData();
    const { updateBsrPricingPCs } = useCapUtils();
    const { isSelectedLocationEmpty, isRentalDetailsAvailable } = useCheckLocationEmpty();
    const [{ getEstimates }] = useCartEstimate();
    const displayQuoteId = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.DISPLAY_QUOTE_ID) || '';
    const {
        getMergedSourcesATPStores,
        getAvailableProducts,
        getItemAvailabilityATPChronos,
        getStoresWithUnvailableProducts
    } = useChronosAvailability();
    const { getChronosStores, computeChronosStoreWithAVS, validateDateSlots } = useCheckoutNavigate();
    const [isQuantityUpdate, setIsQuantityUpdate] = useState(false);
    const [sortedChronosPcs, setSortedChronosPcs] = useState([]);
    const [isCheckoutDetailsValidating, setIsCheckoutDetailsValidating] = useState(false);
    const { rollbackToCi } = ENV_CONFIG.INVENTORY_CHECK_CONFIGS || {};
    const isAtpDisabled = rollbackToCi || false;
    const { rentallabels = '{}' } = ENV_CONFIG.CAP_HEADER || {};
    const userType = useCheckUser();
    const parsedRentalLabels = JSON.parse(rentallabels);
    const emptyRentalDetailsLabel =
        parsedRentalLabels?.['empty-rental-details'] ||
        cartIntl.formatMessage({ id: 'cart:product-tile-rental-details' });
    const emptyRentalLocationLabel =
        parsedRentalLabels?.['empty-rental-location'] ||
        cartIntl.formatMessage({ id: 'cart:product-tile-rental-location' });
    const emptyRentalDatesLabel =
        parsedRentalLabels?.['empty-rental-dates'] || cartIntl.formatMessage({ id: 'cart:product-tile-rental-dates' });
    const overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');

    const handleRemoveItem = async itemToRemove => {
        try {
            sendEventsForEcommerceRemove(
                VARIABLE_CONFIG.ECOMMERCE_PAGE.MINICART,
                getProductPayload(itemToRemove?.quantity)
            );
        } catch (error) {
            logError(error, false, 'handleRemoveItem');
        }
        setIsQuantityUpdate(true);
        dispatch({ type: SET_IS_CART_LOADING, isCartLoading: true });
        await removeItem(itemToRemove?.uid);
        dispatch({ type: SET_RECOMPUTE_ITEM_AVAILABILITY, recomputeItemsAvailability: true });
        dispatch({ type: SET_IS_CART_LOADING, isCartLoading: false });
        handleRemoveMergeCartError(itemToRemove);
    };

    const handleRemoveMergeCartError = item => {
        if (userProfile?.skip_qty[item?.product?.sku]) {
            const skuQtyCopy = { ...userProfile?.skip_qty };
            delete skuQtyCopy[item?.product?.sku];
            userDispatch({ type: 'updateSkipQtyObject', skipQtyObj: skuQtyCopy });
        }
    };

    const getProductPayload = quantity => {
        return [
            {
                id: item?.product?.sku,
                name: item?.product?.name,
                price: parseFloat(item?.prices?.row_total?.value)?.toFixed(2),
                categories: item?.product?.category_path || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                category:
                    item?.product?.productcategoryname ||
                    item?.product?.parent_category_name ||
                    VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                subcategory: item?.product?.category_name || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                dimension38: getRentalDuration(startDate, endDate) || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                dimension31: item?.prices?.row_total?.value,
                dimension32: item?.prices?.row_total?.value * 7,
                dimension33: item?.prices?.row_total?.value * 31,
                sku: item?.product?.sku,
                dimension41: -1,
                list: VARIABLE_CONFIG.ECOMMERCE_PAGE.MINICART,
                position: parseInt(item?.product?.position) || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                quantity: quantity
            }
        ];
    };

    const handleCartQuantityUpdation = async (newVal, item) => {
        setIsQuantityUpdate(true);
        dispatch({ type: SET_IS_CART_LOADING, isCartLoading: true });
        await updateCartFromMinicart([{ cart_item_uid: item?.uid, quantity: newVal }]);
        dispatch({ type: SET_RECOMPUTE_ITEM_AVAILABILITY, recomputeItemsAvailability: true });
        if (newVal - item?.quantity < 0) {
            try {
                sendEventsForEcommerceRemove(
                    VARIABLE_CONFIG.ECOMMERCE_PAGE.MINICART,
                    getProductPayload(Math.abs(newVal - item?.quantity))
                );
            } catch (error) {
                logError(error, false, 'handleOnChange');
            }
        } else {
            try {
                sendEventsForEcommerceAdd(
                    VARIABLE_CONFIG.ECOMMERCE_PAGE.MINICART,
                    getProductPayload(newVal - item?.quantity)
                );
                updateFourHourRentals(item?.product?.sku);
            } catch (error) {
                logError(error, false, 'handleOnChange');
            }
        }
        dispatch({ type: SET_IS_CART_LOADING, isCartLoading: false });
    };

    const initialiseSavedCart = () => {
        getCartDetails({
            cartDetailsQuery,
            dispatch,
            cartId,
            filterDispatch,
            handleATPCart,
            isRentalDetailsAvailable: isRentalDetailsAvailable()
        });
    };

    const renderEmptyRentalLabels = () => {
        if (isValidString(isAtpDisabled) && isSelectedLocationEmpty()) {
            return cartIntl.formatMessage({ id: 'cart:enter-location-for-price' });
        } else if (isSelectedLocationEmpty() && (!startDate || !endDate)) {
            return emptyRentalDetailsLabel;
        } else if (isSelectedLocationEmpty()) {
            return emptyRentalLocationLabel;
        } else if (!startDate || !endDate) {
            return emptyRentalDatesLabel;
        }
    };

    const handleRedirection = () => {
        window.location.href = product?.product_page_url;
    };

    const upateSourcePcInContext = async (pcVal, pcLat, pcLong, cartItems = cart?.items) => {
        // not calling estimates if dates are missing
        if (pcVal && startDate && endDate) {
            const cartEstimates = await getEstimates('', pcVal, pcLat, pcLong, false, cartItems);
            //to handle checkout source call if not called
            dispatch({
                type: SET_SOURCES_LOADING_FLAG,
                isSourcesLoading: false
            });
            return cartEstimates;
        } else {
            dispatch({
                type: SET_SOURCES_LOADING_FLAG,
                isSourcesLoading: false
            });
        }
    };

    /**
     * This function is for the scenario when user lands on checkout page after all the checks are performed.
     * This function will fetch cart items from storage and call estimates
     */
    const renderCheckoutWithoutValidations = async () => {
        try {
            let cartEstimates = null;
            const cartItems = getItemStatusFromStorageOrATP(cart?.items);
            const conditionalItems = cartItems?.conditionalItems || [];
            const unavailableItems = cartItems?.unavailableItems || [];
            if (viewCart?.pc && cartItems?.availableItems?.length) {
                // estimates call
                cartEstimates = await upateSourcePcInContext(
                    viewCart?.pc,
                    viewCart?.pcLat,
                    viewCart?.pcLong,
                    cartItems?.availableItems
                );
            }
            const availableItems = cartEstimates?.availableCartItems || cartItems?.availableItems || [];
            dispatch({
                type: SET_CART_AVAILABLE_UNVAILABLE_ITEMS,
                availableCartItems: availableItems,
                unavailableCartItems: [...unavailableItems, ...conditionalItems]
            });
        } catch (error) {
            logError(error, false, 'renderCheckoutWithUserDetails');
        }
    };

    /**
     * This function is for the scenario when user lands on checkout page without the valid checks.
     * This function will fetch cart items and will validate the user details api
     * @returns isValid
     */
    const renderCheckoutWithValidations = async () => {
        try {
            const { isValid } = await validateCartDetails({ isCheckout: true });
            return isValid;
        } catch (error) {
            logError(error, false, 'renderCheckoutWithoutUserDetails');
        }
    };

    const fetchCartForCheckout = async () => {
        await updateBsrPricingPCs();
        const estimatesResponse = await getCartItems();
        const unavailableItems = estimatesResponse?.unavailableCartItems;
        // removing previous unavailable items from cart for override pc case
        if (overridePC?.pc && unavailableItems?.length > 0) {
            await removeUnavailableItems(unavailableItems, false);
        }
        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.DISCARD_OLD_CART);
    };

    /**
     * This function will fetch cart items availability
     * @returns available, conditional and unavailable items
     */
    const renderCartItems = async () => {
        // resetting error
        dispatch({ type: SET_CHECKOUT_ERROR_DETAILS });
        let cartEstimates = null;
        await updateBsrPricingPCs();
        const cartDetails = await getCartItemsWithAvailability();
        const { pc, pcLat, pcLong } = viewCart || {};
        const conditionalItems = cartDetails?.conditionalItems || [];
        const unavailableItems = cartDetails?.unavailableItems || [];
        if (pc && cartDetails?.availableItems?.length) {
            // estimates call
            cartEstimates = await upateSourcePcInContext(pc, pcLat, pcLong, cartDetails?.availableItems);
        }
        const availableItems = cartEstimates?.availableCartItems || cartDetails?.availableItems || [];

        dispatch({
            type: SET_CART_AVAILABLE_UNVAILABLE_ITEMS,
            availableCartItems: availableItems,
            unavailableCartItems: [...unavailableItems, ...conditionalItems]
        });
        return {
            availableItems,
            conditionalItems,
            unavailableItems
        };
    };

    const getCartItemsWithAvailability = async () => {
        const estimatesResponse = await getCartItems();
        if (estimatesResponse?.items?.length === 0) {
            return {};
        }
        let items = estimatesResponse?.items;

        const discardOldCart =
            JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.DISCARD_OLD_CART)) || false;
        const unavailableItems = estimatesResponse?.unavailableCartItems;
        // removing previous unavailable items from cart for override pc case
        if (discardOldCart && overridePC?.pc && unavailableItems?.length > 0) {
            await removeUnavailableItems(unavailableItems, false);
            items = estimatesResponse?.availableCartItems;
        }
        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.DISCARD_OLD_CART);

        if (startDate && endDate) {
            return handleDateRange(items);
        } else {
            setIsQuantityUpdate(false);
            return handleNoDateRange(items);
        }
    };

    const getCartItems = async () => {
        return (
            cart ||
            (await getCartDetails({
                cartDetailsQuery,
                dispatch,
                cartId,
                filterDispatch,
                handleATPCart,
                isRentalDetailsAvailable: isRentalDetailsAvailable()
            }))
        );
    };

    const handleDateRange = async (items = []) => {
        if (isPickupOrDeliveryWithin72Hours(startDate)) {
            return handleWithin72Hours(items);
        } else {
            return handleDefault(items);
        }
    };

    const handleWithin72Hours = items => {
        const cartItems = getItemStatusFromStorageOrATP(items);
        saveCartItemsInStorage(cartItems);
        /** calling sources asyncronously, will use the sources data to show the modal during checkout.
         *  not calling sources if delivery is selected */
        if (!isQuantityUpdate) {
            if (cartItems?.availableItems?.length && viewCart?.isInStorePickup) {
                handlePickupChronosStores(cartItems);
            } else {
                setSortedChronosPcs([]);
            }
        }
        setIsQuantityUpdate(false);
        return { ...cartItems };
    };

    /**
     * This function will get the item availability status from storage,
     * if status is not present in storage, will fetch it from atp
     * @param {*} items
     * @returns available, conditional and unavailable items
     */
    const getItemStatusFromStorageOrATP = items => {
        const cartItemsFromStorage = JSON.parse(
            sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.CART_ITEMS) || '{}'
        );
        const availableItems = [];
        const conditionalItems = [];
        const unavailableItems = [];
        items?.map(item => {
            const catsku = item?.product?.sku;
            let tileStatus = cartItemsFromStorage[catsku];
            if (!tileStatus) {
                // if status is not in storage, will calculate it from atp
                tileStatus = handleATPCart({
                    catsku,
                    inventoryDataObj: item?.product?.ec_pc_inventory
                });
            }
            if (tileStatus === TILE_STATES.AVAILABLE) {
                availableItems.push(item);
            } else if (tileStatus === TILE_STATES.UNAVAILABLE) {
                unavailableItems.push(item);
            } else {
                conditionalItems.push({ ...item, status: tileStatus });
            }
        });
        return { availableItems, conditionalItems, unavailableItems };
    };

    /**
     * This function calls sources with available items and
     * and calls another sources with available+conditional items.
     * It then selects the stores from 1st call and decided item
     * availability from 2nd sources call.
     * @param {*} cartItems
     */
    const handlePickupChronosStores = async cartItems => {
        try {
            dispatch({
                type: SET_SOURCES_LOADING_FLAG,
                isSourcesLoading: true
            });
            const { data: stores } = await getChronosStores('', {}, cartItems?.availableItems);
            const sortedAvailableStores = getMergedSourcesATPStores(stores?.data, stores?.data);
            /**
             *  enhancements for the 2nd sources call
             *  - it is not required on checkout page as we do not show the chronos store modal there
             *  - will not call if cart has only available items as the sources will be same as this call
             *  - will call this sources if the sources does not suggest the selected store
             */
            if (
                cartItems?.conditionalItems?.length > 0 &&
                sortedAvailableStores?.length > 0 &&
                sortedAvailableStores?.[0]?.pc !== selectedStoreDetails?.pc
            ) {
                const availableItems = cartItems?.availableItems || [];
                const conditionalItems = cartItems?.conditionalItems || [];
                const { data: availableCondtionalStores } = await getChronosStores('', {}, [
                    ...availableItems,
                    ...conditionalItems
                ]);
                const mergedStores = mergeAvailableAndConditionalStores(
                    availableCondtionalStores?.data,
                    sortedAvailableStores
                );
                setSortedChronosPcs(mergedStores);
            } else {
                setSortedChronosPcs(sortedAvailableStores);
            }
            dispatch({
                type: SET_SOURCES_LOADING_FLAG,
                isSourcesLoading: false
            });
        } catch (error) {
            logError(error, false, 'handlePickupChronosStores');
        }
    };

    const mergeAvailableAndConditionalStores = (availableCondtionalStores, availableItemsStores) => {
        if (!availableCondtionalStores?.length) {
            return availableItemsStores;
        }
        const stores = [];
        availableCondtionalStores?.forEach(conditionalStore => {
            const availableStore = availableItemsStores?.find(item => item?.pc === conditionalStore?.pc);
            if (availableStore) {
                stores?.push({ ...availableStore, items: conditionalStore?.items });
            }
        });
        return stores;
    };

    const showDiscrepencyStoreSelector = availableItems => {
        if (viewCart?.isInStorePickup && sortedChronosPcs?.length > 0) {
            if (sortedChronosPcs[0]?.pc !== selectedStoreDetails?.pc) {
                return true;
            } else {
                // this is to check when the chronos suggested the selected item but
                // some available item may be unavailable from chronos
                const firstChronosPcItems = sortedChronosPcs[0]?.items;
                const isItemMissing = availableItems?.find(item => {
                    // finds available item from chronos store items
                    const matchedItem = firstChronosPcItems?.find(
                        chronosItem =>
                            generateSKU(chronosItem?.equipmentCategory, chronosItem?.equipmentClass) ===
                            item?.product?.sku
                    );
                    // returns true for item when availableQuantity is missing
                    return matchedItem?.availableQuantity <= 0;
                });
                return !!isItemMissing;
            }
        }
        return false;
    };

    const updateItemsFromSelectedChronosStore = ({ chronosStores = sortedChronosPcs, selectedChronosStore }) => {
        try {
            // getting cart items status from atp
            localStorage.setItem(
                STORAGE_CONFIG.LOCAL_STORAGE.SELECTEDSTOREDETAILS,
                JSON.stringify(selectedChronosStore)
            );
            const cartItemsWithATP = getItemAvailabilityATP(cart?.items);
            if (cartItemsWithATP?.availableItems?.length) {
                const chronosAvailabilityStatus = getAvailableProducts(chronosStores, selectedChronosStore);
                // deciding the item availabilty based on atp + chronos
                const {
                    availableItems = [],
                    conditionalItems = [],
                    unavailableItems = []
                } = getItemAvailabilityATPChronos(cart?.items, cartItemsWithATP, chronosAvailabilityStatus);
                saveCartItemsInStorage({ availableItems, conditionalItems, unavailableItems });
                if (selectedChronosStore?.pc && availableItems?.length) {
                    // estimates call
                    upateSourcePcInContext(
                        selectedChronosStore?.pc,
                        selectedChronosStore?.latitude,
                        selectedChronosStore?.longitude,
                        availableItems
                    );
                }
                dispatch({
                    type: SET_CART_AVAILABLE_UNVAILABLE_ITEMS,
                    availableCartItems: availableItems,
                    unavailableCartItems: [...unavailableItems, ...conditionalItems]
                });
                return { availableItems, conditionalItems, unavailableItems };
            } else {
                return cartItemsWithATP;
            }
        } catch (error) {
            logError(error, false, 'updateItemsFromSelectedChronosStore');
            return {};
        }
    };

    const saveCartItemsInStorage = ({ availableItems, conditionalItems, unavailableItems }) => {
        const cartItems = {};
        availableItems?.forEach(item => {
            cartItems[item?.product?.sku] = TILE_STATES.AVAILABLE;
        });
        unavailableItems?.forEach(item => {
            cartItems[item?.product?.sku] = TILE_STATES.UNAVAILABLE;
        });
        conditionalItems?.forEach(item => {
            cartItems[item?.product?.sku] = item?.status;
        });
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.CART_ITEMS, JSON.stringify(cartItems));
    };

    const handleDefault = (items = []) => {
        const pcDetails = viewCart;
        setIsQuantityUpdate(false);

        return { pcDetails, ...getItemAvailabilityATP(items) };
    };

    const handleNoDateRange = (items = []) => {
        return getItemAvailabilityATP(items);
    };

    const isPickupOrDeliveryWithin72Hours = date => {
        return getDateDiffInHrs(date, moment().format('YYYY-MM-DDTHH:mm:ss')) < 72;
    };

    const isDeliveryWithin24Hrs = date => {
        return !viewCart?.isInStorePickup && getDateDiffInHrs(date, moment().format('YYYY-MM-DDTHH:mm:ss')) < 24;
    };

    /**
     * This function adds the name of the products by fetching it from cartItems
     * @param {*} cartItems = To fetch products names
     * @param {*} storesWithUnAvailableProducts = To add product names into the object
     */
    const addItemName = (cartItems, storesWithUnAvailableProducts) => {
        const itemMap = {};
        cartItems?.forEach(item => {
            // creating itemMap lookup to store product name based on its sku
            itemMap[item?.product?.sku] = item?.product?.name;
        });

        storesWithUnAvailableProducts?.forEach(item => {
            item?.unavailableProducts?.forEach(product => {
                if (product) {
                    // adding the product name by fetching from the itemMap lookup
                    product.name = itemMap[product?.catclass];
                }
            });
        });
    };

    /**
     * This function accept cartItems array for fetching product name from catclass
     * It returns the selected store and the rest chronos stores as suggested stores
     * along with the items unavailable at each store.
     * @param {*} unavailableItemsArr
     * @param {*} cartItems
     * @returns
     */
    const getSelectedAndSuggestedStores = (unavailableItemsArr, cartItems) => {
        try {
            const storesWithUnavailableProducts = getStoresWithUnvailableProducts(sortedChronosPcs);
            addItemName(cartItems, storesWithUnavailableProducts);
            const unavailableItems =
                unavailableItemsArr?.map(item => ({
                    catclass: item?.product?.sku,
                    name: item?.product?.name
                })) || [];

            //  adding all the unavailable cart items to the stores existing unavailable products
            storesWithUnavailableProducts?.forEach(items => {
                items?.unavailableProducts?.push(...unavailableItems);
            });
            const index = storesWithUnavailableProducts?.findIndex(
                product => selectedStoreDetails?.pc === product?.store?.pc
            );

            if (index === -1) {
                /**
                 *  if the selected store is not in the sources call
                 *  then, adding all the cart items as unavailable for
                 *  the selected store and returning the chronos stores
                 *  as the suggested stores.
                 */
                return {
                    selectedStore: {
                        store: selectedStoreDetails,
                        unavailableProducts: [...cartItems.map(items => items?.product)]
                    },
                    suggestedStores: storesWithUnavailableProducts
                };
            } else {
                /**
                 *  if the selected store is in the sources call then,
                 *  sending that store from the chronos store data as the
                 *  selected store and returning the rest chronos stores
                 *  as the suggested stores.
                 */
                return {
                    selectedStore: storesWithUnavailableProducts[index],
                    suggestedStores: [
                        ...(storesWithUnavailableProducts?.slice(0, index) || []),
                        ...(storesWithUnavailableProducts?.slice(index + 1) || [])
                    ]
                };
            }
        } catch (err) {
            logError(err, false, 'getSelectedAndSuggestedStores');
            return {};
        }
    };

    const handleChange = (key, value) => {
        try {
            filterDispatch({ type: SET_VIEW_CART_FIELDS, key, value });
        } catch (error) {
            logError(error, false, 'handleChange');
        }
    };

    const getChronosStoresForValidation = localViewCart => {
        if (
            localViewCart?.isInStorePickup &&
            isObjectWithKeys(selectedStoreDetails) &&
            isPickupOrDeliveryWithin72Hours(startDate)
        ) {
            return [selectedStoreDetails];
        } else {
            return null;
        }
    };

    const onCheckoutHandler = async (localViewCart = { ...viewCart }) => {
        try {
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART, JSON.stringify(localViewCart));
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.PROJECTDETAILS, JSON.stringify(projectDetails));
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS, isCreditNewAddress);
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.SHOWGUESTCHECKOUTLOGIN, showGuestCheckoutLogin);
            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ISSOURCESLOADING, isSourcesLoading);
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.CHECKOUTREFERRER, true);
            if (userType === USER_TYPE.CREDIT && isCreditNewAddress) {
                localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREATEJOBSITE, true);
            } else {
                localStorage.removeItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREATEJOBSITE);
            }
            setIsCheckoutDetailsValidating(true);
            const { isValid, updatedCartItems } = await validateCartDetails({
                chronosStores: getChronosStoresForValidation(localViewCart)
            });
            setIsCheckoutDetailsValidating(false);
            if (isValid) {
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ISUSERDETAILSSET, true);
                window.location.href = window.location.origin + config.pagePaths.checkoutPage;
            }
            return updatedCartItems;
        } catch (error) {
            logError(error, false, 'onCheckoutHandler');
        }
    };

    const handleCartItemsStatusFromSources = chronosStores => {
        /**
         * not updating items status from sources in case of override pc
         * and in case of pickup the sources call was already done and
         * the items status was already updated, so not recomputing status again.
         * if start date is >24 hrs for delivery, we will not change the
         * item status based on sources data
         */
        if (!overridePC?.pc && isDeliveryWithin24Hrs(startDate)) {
            return updateItemsFromSelectedChronosStore({
                chronosStores,
                selectedChronosStore: chronosStores?.[0]
            });
        }
        return null;
    };

    const validateCartDetails = async ({ isCheckout, chronosStores }) => {
        const { storesData, avsAddressDetails } = await computeChronosStoreWithAVS(chronosStores);
        if (storesData?.storesError || !storesData?.data?.data) {
            return { isValid: false };
        }
        const updatedCartItems = handleCartItemsStatusFromSources(storesData?.data?.data);
        /**
         *  updatedCartItems gives the changed cart items status,
         *  it will be null if we are not changing items status from chronos
         *  which is override pc and pickup fulfillment senario.
         *  If the items status are changed and there are no available items,
         *  will return isValid as false.
         */
        if (updatedCartItems && !updatedCartItems?.availableItems?.length) {
            return { isValid: false };
        }
        const { isValid } = await validateDateSlots({
            isCheckout,
            storesData,
            availableCartItems: updatedCartItems?.availableItems || cart?.availableCartItems,
            avsAddressDetails
        });
        return {
            isValid,
            updatedCartItems
        };
    };

    const triggerCheckoutStartEvent = () => {
        try {
            sendEventsForEcommerceAction(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CHECKOUT_STARTED,
                payloadEcommerceActionAnalytics()
            );
        } catch (error) {
            logError(error, false, 'triggerCheckoutStartEvent');
        }
    };

    const showCheckoutButton = () => {
        if (userType === USER_TYPE.GUEST || userType === USER_TYPE.CASH) {
            return true;
        } else if (userProfile?.type === EMPLOYEE) {
            return false;
        } else {
            const currentAccount = userProfile?.accounts?.find(account => account?.id === userAccount?.accountNumber);
            return currentAccount?.permission?.[0]?.reservation === true;
        }
    };

    return {
        fetchCartForCheckout,
        handleRemoveItem,
        handleCartQuantityUpdation,
        renderEmptyRentalLabels,
        renderCartItems,
        handleRedirection,
        initialiseSavedCart,
        upateSourcePcInContext,
        sortedChronosPcs,
        getSelectedAndSuggestedStores,
        handleChange,
        triggerCheckoutStartEvent,
        showCheckoutButton,
        onCheckoutHandler,
        isCheckoutDetailsValidating,
        showDiscrepencyStoreSelector,
        updateItemsFromSelectedChronosStore,
        renderCheckoutWithoutValidations,
        renderCheckoutWithValidations,
        getCartItems
    };
};
