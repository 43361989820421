import { gql } from '@apollo/client';

const CREATE_OR_TRANSMIT_QUOTE = gql`
    mutation createQuote(
        $cart_id: String!
        $companyId: String
        $branchNumber: String
        $accountNumber: String
        $fulfillmentType: String!
        $deliveryInstructions: String
        $startDateTime: String!
        $endDateTime: String!
        $earliestDateTime: String!
        $optOutRPP: Boolean!
        $optOutFuelCharge: String
        $sellingChannel: String!
        $initiatingChannel: String!
        $transactionType: String!
        $type: String!
        $poNumber: String
        $ratesOverriden: Boolean
        $initiateTransmit: Boolean
        $orderedBy: QuoteRequestOrderedBy
        $jobSiteId: String!
        $jobsiteNumber: String!
        $products: [QuoteRequestProducts]
        $pickUpCharge: Float
        $deliveryCharge: Float
        $rentalSubTotal: Float
        $purchasesSubTotal: Float
        $rppFees: Float
        $otherFees: Float
        $taxes: Float
        $total: Float
        $fuelCharge: Float
        $allOtherCharge: Float
        $tcsessionId: String
        $hasSpecialityItem: Boolean
    ) {
        createQuote(
            input: {
                cart_id: $cart_id
                companyId: $companyId
                branchNumber: $branchNumber
                accountNumber: $accountNumber
                fulfillmentType: $fulfillmentType
                deliveryInstructions: $deliveryInstructions
                startDateTime: $startDateTime
                endDateTime: $endDateTime
                earliestDateTime: $earliestDateTime
                optOutRPP: $optOutRPP
                optOutFuelCharge: $optOutFuelCharge
                sellingChannel: $sellingChannel
                initiatingChannel: $initiatingChannel
                transactionType: $transactionType
                type: $type
                poNumber: $poNumber
                ratesOverriden: $ratesOverriden
                initiateTransmit: $initiateTransmit
                orderedBy: $orderedBy
                jobSiteId: $jobSiteId
                jobsiteNumber: $jobsiteNumber
                products: $products
                pickUpCharge: $pickUpCharge
                deliveryCharge: $deliveryCharge
                rentalSubTotal: $rentalSubTotal
                purchasesSubTotal: $purchasesSubTotal
                rppFees: $rppFees
                otherFees: $otherFees
                taxes: $taxes
                total: $total
                fuelCharge: $fuelCharge
                allOtherCharge: $allOtherCharge
                tcsessionId: $tcsessionId
                hasSpecialityItem: $hasSpecialityItem
            }
        ) {
            status
            message
            data {
                status
                transmitStatus
                sfGuiQuoteId
                sfQuoteId
                displayQuoteId
                recordType
            }
        }
    }
`;

export default CREATE_OR_TRANSMIT_QUOTE;
