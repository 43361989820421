import React, { createContext, useContext, useReducer } from 'react';
import {  func, object, shape } from 'prop-types';
import { dstActions} from './actions';
import { dstInitialState } from './state';

export const DstContext = createContext();

export const DstProvider = props => {
    const reducer = props.reducer || dstActions;
    const initialState = props.initialState || dstInitialState;

    return (
        <DstContext.Provider value={useReducer(reducer, initialState)}>{props.children}</DstContext.Provider>
    );
};

DstProvider.propTypes = {
    reducer: func,
    initialState: shape({
        allPCs: object,
        allTrainers: object
    })
};

export const useDstState = () => useContext(DstContext);
