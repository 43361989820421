import { gql } from '@apollo/client';

export const UPDATE_JOBSITE_FAVORITE_LIST = gql`
  mutation AddCustomerJobsiteToFavorites($jobsite_id: String!, $account_id,: String!, $company_id: Int!) {
    addCustomerJobsiteToFavorites(
        jobsite_id: $jobsite_id,
        account_id: $account_id,
        company_id: $company_id
     )
  }
`;

export default UPDATE_JOBSITE_FAVORITE_LIST;