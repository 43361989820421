import React, { useEffect, useState } from 'react';
import { ENTER_KEY } from '../../../../constants/screenConstants';
import { useCheckAuthorityType } from '../../../../hooks/useCheckUser';
import { AUTHORITY_TYPE } from '../../constants';
import AccountListView from './AccountListView';
import {
    MIN_SEARCH_QUERY_LENGTH,
    isAccountNotActive
} from '../../utils/commonUtils';
import './ChooseAccountDrawer.scss';


const FavoriteAccounts = (props) => {
    const { favoriteList, setFavoriteList, selectedValue, setSelectedValue, cardStyle, updateAccountDetailsHandler, handleTextInactive, sortSelectedAccounts, filterData, isUserProfileLoading, favoriteAccountListData } = props;
    const authorityType = useCheckAuthorityType();
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedAccountOnTop, setSelectedAccountOnTop] = useState(true);

    useEffect(() => {
        updateAccountList();
    }, [searchQuery]);

    useEffect(() => {
        if (selectedAccountOnTop) {
            const sortedFavoriteList = favoriteAccountListData?.sort((a, b) => a?.name.localeCompare(b?.name));
            const sortedAccountList = sortSelectedAccounts(selectedValue, sortedFavoriteList);
            setFavoriteList(sortedAccountList);
        }
    }, [selectedAccountOnTop, searchQuery, favoriteAccountListData]);

    const updateAccountList = () => {
        if (searchQuery?.length < MIN_SEARCH_QUERY_LENGTH) {
            setFavoriteList(favoriteAccountListData);
            setSelectedAccountOnTop(true);
        } else {
            setFavoriteList(filterData(favoriteList, favoriteAccountListData, searchQuery));
            setSelectedAccountOnTop(false);
        }
    };

    const onSearchInputChange = e => {
        setSearchQuery(e?.target?.value);
    };

    const onResetClick = () => {
        setSearchQuery('');
        setFavoriteList(favoriteAccountListData);
        setSelectedAccountOnTop(true);
    };

    const getAccountList = () => {
        if (authorityType === AUTHORITY_TYPE.P2P) {
            return favoriteList?.filter(account => !isAccountNotActive(account.accountStatus)); //filter inactive accounts
        }
        return favoriteList;
    };

    const onSearchIconClick = () => {
        updateAccountList();
    };

    const onEnterKeyPressed = e => {
        if (e.key === ENTER_KEY) {
            updateAccountList();
        }
    };

    return (
        <AccountListView
            allAccountsList={favoriteAccountListData}
            accountList={favoriteList}
            searchQuery={searchQuery}
            onSearchInputChange={onSearchInputChange}
            onEnterKeyPressed={onEnterKeyPressed}
            onResetClick={onResetClick}
            onSearchIconClick={onSearchIconClick}
            getAccountList={getAccountList}
            selectedValue={selectedValue}
            isUserProfileLoading={isUserProfileLoading}
            setSelectedValue={setSelectedValue}
            cardStyle={cardStyle}
            updateAccountDetailsHandler={updateAccountDetailsHandler}
            handleTextInactive={handleTextInactive}
        />
    )
};

export default React.memo(FavoriteAccounts);
