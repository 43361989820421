import { array, bool, func, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Button from '../../../../aem-core-components/components/Button';
import { useUserContext } from '../../../../aem-core-components/context/UserContext';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../../constants/analyticsConstants/Ecommerce';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import { SCREEN_NAME } from '../../../../constants/screenConstants';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';
import { USER_TYPE } from '../../../../constants/userDetailsConstants';
import { useCheckUser } from '../../../../hooks/useCheckUser';
import { useSunbeltLocation } from '../../../../hooks/useSunbeltLocation';
import useAnalytics from '../../../../hooks/useAnalytics';
import CrossIcon from '../../../../resources/images/closecircleblack.svg';
import SearchIcon from '../../../../resources/images/search.svg';
import Input from '../../atoms/input/Input';
import { isCheckoutPage } from '../../utils/commonUtils';
import { logError } from '../../utils/logger';
import classes from './searchBar.css';
import { useFilterState } from '../../../cap';

const SearchBar = props => {
    const intl = useIntl();

    const [{ accountProjects }] = useUserContext();
    const userType = useCheckUser();
    const sunbeltLocation = useSunbeltLocation();
    const {
        dataSet,
        placeholder,
        displaySearchResults,
        searchCriteria,
        notFoundErrorMessage,
        isSearchIconVisible,
        screenName,
        source,
        handleInputBlur,
        setSearchErrorText
    } = props;
    const [{ viewCart }, filterDispatch] = useFilterState();
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState(dataSet);
    const [errorMessage, setErrorMessage] = useState('');
    const { sendEventsForPageElementClick, sendEventsForLocationAction, sendEventsForAddressErrorAction } =
        useAnalyticsContext();
    const [{ payloadEcommerceLocationActionAnalytics }] = useAnalytics();

    useEffect(() => {
        setFilteredData(dataSet);
    }, [dataSet]);

    useEffect(() => {
        setFilteredData(filterData(searchQuery));
    }, [searchQuery]);

    useEffect(() => {
        if (filteredData?.length === 0 && searchQuery?.length > 2) {
            setSearchErrorText(searchQuery);
            setErrorMessage(notFoundErrorMessage);
        } else {
            setSearchErrorText('');
            setErrorMessage('');
        }
    }, [searchQuery, filteredData]);

    const onFocus = () => {
        if(source === VARIABLE_CONFIG.PLACEMENT.LOCATIONOVERLAY  && JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ISLOCATIONFORMNOTDIRTY)) && !isCheckoutPage()){
        sendEventsForLocationAction(
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_LOCATION_STARTED,
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_LOCATION_MODAL_FORM_NAME,
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_NAME_PROJECT_SEARCH,
            payloadEcommerceLocationActionAnalytics()
        );
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ISLOCATIONFORMNOTDIRTY, false);
        }
    }

    const onBlur = () => {
        if (source === VARIABLE_CONFIG.PLACEMENT.LOCATIONOVERLAY && filteredData?.length === 0 && searchQuery?.length > 2 && !isCheckoutPage()) {
            sendEventsForAddressErrorAction(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_FORM_ADDRESS_EVENT,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_LOCATION_MODAL_FORM_NAME,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_FORM_ACCOUNT_FIELD,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_FORM_ACCOUNT_ERROR,
                payloadEcommerceLocationActionAnalytics(true)
            );
        }
    };

    const filterData = (query = '') => {
        if (query.length < 3) {
            return dataSet;
        }

        return dataSet?.filter(data => {
            return Object.keys(data).some(key =>
                searchCriteria?.includes(key) ? data[key].toString().toLowerCase().includes(query.toLowerCase()) : false
            );
        });
    };

    const onSearch = e => {
        //track search bar interaction for project search for credit user
        setSearchQuery(e.target.value);
    };
    const handleSearchEvent = () => {
        if (handleInputBlur) {
            if (source == VARIABLE_CONFIG.PLACEMENT.LOCATIONOVERLAY && accountProjects.length > 20) {
                if (userType === USER_TYPE.CREDIT) {
                    try {
                        sendEventsForPageElementClick(
                            VARIABLE_CONFIG.TYPE.FIELD,
                            VARIABLE_CONFIG.ACTION.SEARCH,
                            VARIABLE_CONFIG.STYLE.SEARCH_BAR,
                            searchQuery,
                            VARIABLE_CONFIG.PLACEMENT.CONTENT,
                            VARIABLE_CONFIG.MODAL.NONE,
                            VARIABLE_CONFIG.SELECT_METHOD.NONE,
                            sunbeltLocation
                        );
                    } catch (error) {
                        logError(error, false, 'handleSearchEvent');
                    }
                }
            }
        }
        if (JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ISLOCATIONFORMNOTDIRTY)) && !isCheckoutPage()) {
            sendEventsForLocationAction(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_LOCATION_STARTED,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_LOCATION_MODAL_FORM_NAME,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_NAME_ACCOUNT_SEARCH,
                payloadEcommerceLocationActionAnalytics()
            );
            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ISLOCATIONFORMNOTDIRTY, false);
        }
    };
    const onClick = e => {
        setSearchQuery('');
    };
    return (
        <div className={classes.searchRoot}>
            {dataSet?.length > 20 && (
                <div
                    className={
                        screenName === SCREEN_NAME.CHECKOUT_SCREEN || screenName === SCREEN_NAME.PDP_SCREEN
                            ? ''
                            : classes.searchInputBoxWrap
                    }>
                    <div className={classes.searchInputBox}>
                        {isSearchIconVisible && (
                            <>
                                <SearchIcon className={classes.searchInputIcon} tabindex={-1} aria-hidden={true} />
                            </>
                        )}
                        <Input
                            type="text"
                            name="account_search"
                            classes={classes.searchInput}
                            placeholder={placeholder}
                            value={searchQuery}
                            dataTestId={'searchInput'}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            handleInputChange={onSearch}
                            handleInputBlur={handleSearchEvent}
                            inputAriaLabel={`${intl.formatMessage({
                                id: 'account:ac-common-search-text'
                            })} ${placeholder}`}
                        />
                        {searchQuery && (
                            <Button
                                tabindex={'0'}
                                buttonAriaLabel={`${intl.formatMessage({
                                    id: 'projects:clear-search-bar'
                                })}`}
                                data-testid={'crossIcon'}
                                className={classes.crossIcon}
                                onClick={onClick}>
                                <CrossIcon aria-hidden="true" tabindex={'-1'} />
                            </Button>
                        )}
                    </div>
                </div>
            )}
            {errorMessage && <span className={classes.notFoundError}>{errorMessage}</span>}
            {filteredData?.length > 0 && (
                <div className={`${classes.searchRoot} ${classes.searchRootMargin}`} role="radiogroup">
                    {filteredData?.map(data => displaySearchResults(data))}
                </div>
            )}
        </div>
    );
};

SearchBar.propTypes = {
    /**
     * The data received from api response
     */
    dataSet: array.isRequired,
    /**
     * A string with search bar placeholder value
     */
    placeholder: string.isRequired,
    /**
     * A function that defines style of displaying search results
     */
    displaySearchResults: func.isRequired,
    /**
     * An array having criteria for search
     */
    searchCriteria: array.isRequired,
    /**
     * A string with search results not Found Error Message value
     */
    notFoundErrorMessage: string,

    handleInputBlur: bool,

    setSearchErrorText: func,
    source: string
};

SearchBar.defaultProps = {
    screenName: '',
    handleInputBlur: false,
    setSearchErrorText: () => {},
    source: ''
};

export default SearchBar;
