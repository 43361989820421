import { func, number, oneOfType, shape, string } from 'prop-types';
import React from 'react';
import BaseProductTile from '../BaseProductTile';
import SelectRentalLocationDates from '../atoms/selectRentalLocationDates';

const EmptyLocation = ({
    tileDetails,
    handleAnchorClick,
    handleRedirectClick,
    emptyDetailsText,
    openEmptyLocationDateModal,
    accountNumber,
    ...restProps
}) => {
    const footerContent = () => {
        return (
            <SelectRentalLocationDates
                emptyDetailsText={emptyDetailsText}
                openEmptyLocationDateModal={openEmptyLocationDateModal}
            />
        );
    };
    return (
        <BaseProductTile
            productImg={tileDetails?.productimageurl}
            catClass={tileDetails?.catclass}
            productName={tileDetails?.producttitle}
            productUrl={tileDetails?.producturl}
            accountNumber={accountNumber}
            footerContent={footerContent}
            handleAnchorClick={handleAnchorClick}
            handleRedirectClick={handleRedirectClick}
            productAltText={tileDetails.productAltText}
            {...restProps}></BaseProductTile>
    );
};

export default React.memo(EmptyLocation);

EmptyLocation.defaultProps = {
    tileDetails: {
        catclass: '',
        producttitle: '',
        productimageurl: '',
        producturl: '#',
        showonlinecatalog: 'YES',
        disableaddtocartoption: 'NO',
        categoryname: '',
        catsku: '',
        catid: '',
        metaTitle: '',
        metaDescription: '',
        productAltText: ''
    },
    handleAnchorClick: () => {},
    handleRedirectClick: () => {},
    emptyDetailsText: '',
    openEmptyLocationDateModal: () => {}
};

EmptyLocation.propTypes = {
    tileDetails: shape({
        catclass: oneOfType([number, string]),
        producttitle: string,
        productimageurl: string,
        producturl: string,
        showonlinecatalog: string,
        disableaddtocartoption: string,
        categoryname: string,
        catsku: oneOfType([number, string]),
        catid: oneOfType([number, string]),
        metaDescription: string,
        metaTitle: string,
        productAltText: string
    }),
    handleAnchorClick: func,
    handleRedirectClick: func,
    emptyDetailsText: string,
    openEmptyLocationDateModal: func
};
