import { useMutation } from '@apollo/client';
import { useCartState } from '../../../contexts/cart';
import MUTATION_MERGE_CARTS_ON_EDIT_QUOTE from '../../../aem-core-components/queries/mutation_merge_cart_on_edit_quote.graphql';
import { useCookieValue } from '../../../aem-core-components/utils/hooks';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { FULFILLMENT_TYPE } from '../../cap/constants';
import { getRentablesPrice } from '../../checkout/checkoutAndOrderSummary/api/getCheckoutAPIs';
import {
    convertDateToMomentDateTime,
    convertDateToMomentNonUTCDateTime,
    extractCatAndClass,
    formatMobileNumber,
    isSBREmployee,
    removeHyphens
} from '../../global/utils/commonUtils';
import { isValidString, logError } from '../../global/utils/logger';
import { getRatesByOwnedPc } from '../../pdp/api/getRates';
import {
    QUOTE_ESTIMATES_LOADED,
    QUOTE_ORDER_ESTIMATES,
    SET_QUOTES_ERROR,
    SET_SELECTED_QUOTE_RATES
} from '../actionTypes/actionTypes';
import { QUOTE_DETAIL_PRODUCT_TYPE_CAT_CLASS, QUOTE_DETAIL_PRODUCT_TYPE_MISC_CHARGE_ITEMS } from '../constants';
import { useQuotesState } from '../context';
import { isAddonItem } from '../../global/utils/p2pUtils';
import TRANSMIT_QUOTE_DETAILS from '../mutations/mutation_transmit_quote_details';
import VIEW_QUOTE_DETAILS from '../mutations/mutation_view_quote_details';
import { calculateStoreDistance } from '../apis/filterApis';
import { KM_TEXT, MILES_TEXT } from '../../dstTrainer/constants';

const { LOCAL_STORAGE } = STORAGE_CONFIG;

const useQuoteDetails = () => {
    const [{ viewCart, projectDetails }] = useCartState();
    const [{ quoteDetailsData, selectedQuoteRates, quoteOrderEstimates, quoteSummary }, quoteDispatch] =
        useQuotesState();
    const [getQuoteDetails] = useMutation(VIEW_QUOTE_DETAILS);
    const [mergeCartMutation] = useMutation(MUTATION_MERGE_CARTS_ON_EDIT_QUOTE);
    const [transmitQuotes] = useMutation(TRANSMIT_QUOTE_DETAILS);
    const [settingsCookie, setSettingsCookie] = useCookieValue('Settings');
    const [pos] = useCookieValue('pos');

    const { cart: quoteCartData, data: quoteData } = quoteDetailsData || {};
    const { accountData, jobsiteData, products, branchData } = quoteData || {};

    /**
     * Function to filter out products that are actual like accessory and equipment and avoiding misc items
     * and addons because we are not getting actual SKU for these as those are fake SKU's.
     */
    const getActualProducts = () => {
        return products?.filter(item => item?.productData?.productType === QUOTE_DETAIL_PRODUCT_TYPE_CAT_CLASS) || [];
    };

    const getSalesItems = () => {
        const merchandisedItems = products?.filter(item => isAddonItem(item)) || [];
        const salesItems = merchandisedItems.map(item => {
            return {
                itemNumber: item?.productData?.itemNumber,
                quantity: item?.quantity,
                stockClass: item?.productData?.stockClass,
                unitPrice: item?.sellingPrice
            };
        });
        return salesItems;
    };

    // Call the rates API for quote detail products
    const loadRatesForQuotes = async () => {
        try {
            let ownedPcsItemsMap = {};
            let productArray = [];
            let ratesAndProductid = {};
            const actualProducts = getActualProducts();

            for (let obj of actualProducts) {
                const pc = Number(obj?.branchLocationNumber);
                const productId = obj?.productSKU;
                productArray.push({
                    pc,
                    productId
                });
                ownedPcsItemsMap[productId] = pc;
            }

            if (productArray.length < 1) {
                return;
            }

            const { data } = await getRatesByOwnedPc(
                accountData?.displayAccountNumber,
                jobsiteData?.jobsiteNumber,
                productArray
            );

            data?.data?.items.forEach(ratesObject => {
                const prodID = ratesObject?.productId;
                const { daily, minimum, monthly, weekly } = ratesObject?.rates?.suggestedRates;
                const rates = {
                    daily: Number(daily) || 0,
                    minimum: Number(minimum) || 0,
                    monthly: Number(monthly) || 0,
                    weekly: Number(weekly) || 0,
                    ownedPc: ownedPcsItemsMap[prodID]
                };
                ratesAndProductid[prodID] = rates;
            });

            quoteDispatch({ type: SET_SELECTED_QUOTE_RATES, payload: ratesAndProductid });
        } catch (error) {
            logError(error, false, 'handleRatesAndEstimates');
            console.error(error);
        }
    };

    // Call estimates API for quote detail on drawer
    const loadEstimatesForQuotes = async () => {
        const startDateTimeLocale = quoteData.rentalStartDate.split('T');
        const rentalStartDate = convertDateToMomentDateTime(startDateTimeLocale[0], startDateTimeLocale[1]);

        const endDateTimeLocale = quoteData?.estimatedEndDate?.split('T');
        const rentalEndDate = convertDateToMomentDateTime(endDateTimeLocale[0], endDateTimeLocale[1]);
        quoteDispatch({ type: QUOTE_ESTIMATES_LOADED, payload: false });
        try {
            const payload = {
                cartId: quoteCartData?.id,
                pc: Number(quoteData?.branchData?.branchLocationNumber),
                jobSiteAddr1: jobsiteData?.streetAddress || '',
                jobSiteAddr2: jobsiteData?.streetAddress2 || '',
                jobSiteCity: jobsiteData?.city,
                jobSiteState: jobsiteData?.state,
                jobSiteZip: jobsiteData?.zipCode,
                jobNumber: jobsiteData?.jobsiteNumber,
                dlSt: '',
                dlNumber: '',
                startDateAndTime: rentalStartDate,
                endDateAndTime: rentalEndDate,
                lat: jobsiteData?.latitude,
                long: jobsiteData?.longitude,
                distance: quoteData?.isDelivery ? await getDistance() : 0,
                timeType: quoteData?.deliveryMethod,
                lastResort: false,
                fulfillment: 1,
                pcLat: branchData?.latitude,
                pcLong: branchData?.longitude,
                projectId: jobsiteData?.jobsiteName,
                isDeliveryEstimate: quoteData?.isDelivery,
                account: Number(accountData?.displayAccountNumber),
                equipment: getProductsForEstimates(),
                salesEquipments: getSalesItems()
            };

            // this is an estimates call
            const { data } = await getRentablesPrice(payload, true);

            if (data?.estimate) {
                const charges = getCharges(data.estimate);
                const estimateResponse = {
                    estimate: {
                        ...data.estimate,
                        totals: {
                            ...data.estimate.totals,
                            fuelCharges: charges?.fuelCharges,
                            deliveryPickUpCharges: charges?.deliveryPickUpCharges,
                            allOtherCharges: charges?.allOtherCharges || 0
                        }
                    }
                };

                quoteDispatch({ type: QUOTE_ORDER_ESTIMATES, payload: estimateResponse });
                quoteDispatch({ type: QUOTE_ESTIMATES_LOADED, payload: true });
            }
        } catch (error) {
            logError(error, false, 'handleEstimates');
            console.error(error);
        }
    };

    const getCharges = estimates => {
        if (estimates) {
            const deliveryObj = estimates?.miscCharges?.find(item => item.type.indexOf('DELIVERY') > -1);
            const pickupObj = estimates?.miscCharges?.find(item => item.type.indexOf('PICKUP') > -1);
            const fuelObj = estimates?.miscCharges?.find(item => item.type.indexOf('FUEL') > -1);

            const fuelCharges = fuelObj?.charge ? fuelObj?.charge : 0;
            const deliveryCharge = deliveryObj?.charge ? deliveryObj?.charge : 0;
            const pickupCharge = pickupObj?.charge ? pickupObj?.charge : 0;
            const deliveryPickUpCharges = deliveryCharge + pickupCharge;
            const allOtherCharges = estimates?.totals?.miscCharges - deliveryPickUpCharges;

            return { fuelCharges, deliveryPickUpCharges, allOtherCharges };
        }
    };

    // Get the modified products array required for estimates call
    const getProductsForEstimates = () => {
        try {
            const actualProducts = getActualProducts();

            return actualProducts?.map(item => {
                const { catId, classId } = extractCatAndClass(item?.productSKU);
                return {
                    catId,
                    classId,
                    quantity: item?.quantity
                };
            });
        } catch (error) {
            logError(error, false, 'getProducts');
        }
    };

    // Get the distance from an API based on branch and jobsites lat and long
    const getDistance = async () => {
        try {
            const payload = {
                data: [
                    {
                        sourceLat: parseFloat(jobsiteData?.latitude), //source is jobsite
                        sourceLong: parseFloat(jobsiteData?.longitude),
                        destinationLat: parseFloat(branchData?.latitude), //destination is PC
                        destinationLong: parseFloat(branchData?.longitude),
                        distanceUnit: parseInt(localStorage.getItem('companyID')) == 1 ? MILES_TEXT : KM_TEXT
                    }
                ]
            };
            const data = await calculateStoreDistance(payload);
            const storeDistance = data?.data?.distance;
            return isValidString(storeDistance) ? storeDistance : '';
        } catch (error) {
            logError(error, false, 'getDistance');
        }
    };

    // Get the quote details based on quote id from an API
    const fetchQuoteDetails = async payload => {
        try {
            const { data, error } = await getQuoteDetails({
                variables: payload || {},
                fetchPolicy: 'network-only'
            });

            return { data, error };
        } catch (error) {
            logError(error, false, 'fetchQuoteDetails');
            quoteDispatch({ type: SET_QUOTES_ERROR, payload: error });
            return { data: null, error };
        }
    };

    const mergeCartOnEditQuote = async payload => {
        try {
            const { data, error } = await mergeCartMutation({
                variables: payload || {},
                fetchPolicy: 'network-only'
            });

            return { data, error };
        } catch (error) {
            quoteDispatch({ type: SET_QUOTES_ERROR, payload: error });
            logError(error, false, 'mergeCartOnEditQuote');
            return { data: null, error };
        }
    };

    /**
     *  Function to set checkout steps data on storage
     */
    const setCheckoutStepsDataOnEditQuote = () => {
        // setting delivery method
        localStorage.setItem(
            LOCAL_STORAGE.ISINSTOREPICKUP,
            quoteData?.deliveryMethod == FULFILLMENT_TYPE.DELIVERY ? false : true
        );

        // setting start and end date
        localStorage.setItem(LOCAL_STORAGE.STARTDATE, new Date(quoteData?.rentalStartDate));
        localStorage.setItem(LOCAL_STORAGE.ENDDATE, new Date(quoteData?.estimatedEndDate));

        // setting viewcart data
        const localViewCart = {
            ...viewCart,
            startDate: new Date(quoteData?.rentalStartDate),
            endDate: new Date(quoteData?.estimatedEndDate),
            pc: Number(quoteData?.branchLocationNumber),
            pcLat: Number(quoteData?.branchData?.latitude),
            pcLong: Number(quoteData?.branchData?.longitude),
            isInStorePickup: quoteData?.deliveryMethod == FULFILLMENT_TYPE.DELIVERY ? false : true
        };
        localStorage.setItem(LOCAL_STORAGE.VIEWCART, JSON.stringify(localViewCart));

        // setting project details data
        const localProjectDetails = {
            ...projectDetails,
            locationPC: Number(quoteData?.branchLocationNumber),
            projectName: quoteData?.jobsiteData?.jobsiteName,
            projectAddress1: quoteData?.jobsiteData?.streetAddress,
            projectAddress2: quoteData?.jobsiteData?.streetAddress2,
            selectedProjectJobId: quoteData?.jobsiteData?.jobsiteName,
            selectedProjectLatititude: Number(quoteData?.jobsiteData?.latitude),
            selectedProjectLongitude: Number(quoteData?.jobsiteData?.longitude),
            selectedProjectState: quoteData?.jobsiteData?.state,
            selectedProjectCity: quoteData?.jobsiteData?.city,
            selectedProjectZip: quoteData?.jobsiteData?.zipCode,
            primaryContactName: quoteData?.siteContactData?.name,
            phoneNumber: formatMobileNumber(quoteData?.siteContactData?.phone),
            poNumber: quoteData?.poNumber
        };
        localStorage.setItem(LOCAL_STORAGE.PROJECTDETAILS, JSON.stringify(localProjectDetails));
    };

    /**
     * Function to set settings cookie for edit quote scenario
     */
    const setSettingsCookieOnEditQuote = () => {
        return new Promise((resolve, reject) => {
            try {
                const ccCookies = [
                    'SLLat',
                    'SLLong',
                    'SLA',
                    'SLC',
                    'SLS',
                    'SLZ',
                    'CurrentJobSite',
                    'locationPC',
                    'projectName',
                    'projectAddress2',
                    'primaryContactName',
                    'phoneNumber',
                    'accessNotes',
                    'poNumber',
                    'RMJobsiteId'
                ];
                let settingsKey = [];
                let settingData = (settingsCookie && settingsCookie.split('&')) || [];
                let settingCookieData = '';
                for (let i in settingData) {
                    let cookieKey = settingData[i].split('=');
                    if (ccCookies.indexOf(cookieKey[0]) === -1) {
                        settingsKey.push(settingData[i]);
                    }
                }

                settingsKey.push(`IsCorpLink=${encodeURIComponent(quoteData?.accountData?.isCorpLinkAccount ?? true)}`);
                settingsKey.push(
                    `CurrentWynneAccount=${encodeURIComponent(quoteData?.accountData?.displayAccountNumber)}`
                );
                //sync localStorage selectedAccountId with quote displayAccountNumber
                localStorage.setItem(LOCAL_STORAGE.SELECTED_ACCOUNT_ID, quoteData?.accountData?.displayAccountNumber);

                settingsKey.push(`CurrentWynneAccountName=${encodeURIComponent(quoteData?.accountData?.accountName)}`);

                const receivedAccountStatus = quoteData?.accountData?.accountStatus;
                settingsKey.push(
                    `CurrentWynneAccountStatus=${encodeURIComponent(
                        `${receivedAccountStatus?.toLowerCase() == 'active' ? 'A' : receivedAccountStatus}`
                    )}`
                ); //TODO:- Need to revisit when other account status will come in future.

                settingsKey.push(`CurrentWynneAccountCID=${encodeURIComponent(parseInt(quoteData?.companyId))}`);
                settingsKey.push(`CurrentJobSite=${encodeURIComponent(quoteData?.jobsiteData?.jobsiteNumber)}`);
                settingsKey.push(`locationPC=${encodeURIComponent(quoteData?.branchLocationNumber)}`);
                settingsKey.push(`projectName=${encodeURIComponent(quoteData?.jobsiteData?.jobsiteName)}`);
                settingsKey.push(`projectAddress2=${encodeURIComponent(quoteData?.jobsiteData?.streetAddress2)}`);
                settingsKey.push(
                    `primaryContactName=${encodeURIComponent(
                        `${quoteData?.orderedByData?.firstName || ''} ${quoteData?.orderedByData?.lastName || ''}`
                    )}`
                );
                settingsKey.push(`phoneNumber=${encodeURIComponent(quoteData?.orderedByData?.phone)}`);
                settingsKey.push(
                    `accessNotes=${encodeURIComponent(
                        (quoteData?.jobsiteData?.accessNotes1 ?? '') + (quoteData?.jobsiteData?.accessNotes2 ?? '')
                    )}`
                ); // TODO:- Meanwhile we are getting 2 access notes only from API as SF confirmed they have only 2 notes.
                settingsKey.push(`poNumber=${encodeURIComponent(quoteData?.poNumber)}`);
                settingsKey.push(`SLLat=${encodeURIComponent(quoteData?.jobsiteData?.latitude)}`);
                settingsKey.push(`SLLong=${encodeURIComponent(quoteData?.jobsiteData?.longitude)}`);
                settingsKey.push(`SLA=${encodeURIComponent(quoteData?.jobsiteData?.streetAddress)}`);
                settingsKey.push(`SLC=${encodeURIComponent(quoteData?.jobsiteData?.city)}`);
                settingsKey.push(`SLS=${encodeURIComponent(quoteData?.jobsiteData?.state)}`);
                settingsKey.push(`SLZ=${encodeURIComponent(quoteData?.jobsiteData?.zipCode)}`);
                settingsKey.push(`RMJobsiteId=${encodeURIComponent(quoteData?.jobsiteData?.rmJobsiteId)}`);
                settingCookieData = settingsKey.join('&');
                setSettingsCookie(settingCookieData);
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };

    const transmitQuoteDetails = async () => {
        try {
            const payload = {
                sfGuiQuoteId: quoteDetailsData?.data?.sfGuiQuoteId,
                tcsessionId: pos // for local testing use "Vi657c7557400bd"
            };

            const { data, error } = await transmitQuotes({
                variables: payload || {},
                fetchPolicy: 'network-only'
            });
            return { data, error };
        } catch (error) {
            logError(error, true, 'transmitQuote API failed to load data');
        }
    };

    return {
        fetchQuoteDetails,
        mergeCartOnEditQuote,
        loadRatesForQuotes,
        loadEstimatesForQuotes,
        transmitQuoteDetails,
        setCheckoutStepsDataOnEditQuote,
        setSettingsCookieOnEditQuote
    };
};

export default useQuoteDetails;
