import { number, string } from 'prop-types';
import React from 'react';
import Error from '../../../../resources/images/failure-reservation.svg';
import Success from '../../../../resources/images/success-checkmark.svg';
import Warning from '../../../../resources/images/warning-reservation.svg';
import { checkoutDatalocator } from '../../../checkout/checkoutAndOrderSummary/dataLocators';
import './confirmationStatusScreen.scss';

export default function ConfirmationStatusScreen(props) {
    const { successMessage, warningMessage, errorMessage, children, statusBottom, confirmationStatus, customClass } =
        props;

    const renderReservationStatus = () => {
        switch (confirmationStatus) {
            case 1:
                return (
                    <>
                        <Success
                            data-testid={checkoutDatalocator?.reservation_result_icon_testid}
                            className="modal-status-success"
                        />
                        <h4
                            data-testid={checkoutDatalocator?.reservation_resultLabel_testid}
                            id={checkoutDatalocator?.reservation_resultLabel_testid}
                            className="modal-status-title"
                            tabIndex={0}>
                            {successMessage}
                        </h4>
                    </>
                );
            case 2:
                return (
                    <>
                        <Warning data-testid={checkoutDatalocator?.reservation_result_icon_testid} />
                        <h4
                            data-testid={checkoutDatalocator?.reservation_resultLabel_testid}
                            id={checkoutDatalocator?.reservation_resultLabel_testid}
                            tabIndex={0}>
                            {warningMessage}
                        </h4>
                    </>
                );
            case 3:
                return (
                    <>
                        <Error data-testid={checkoutDatalocator?.reservation_result_icon_testid} />
                        <h4
                            data-testid={checkoutDatalocator?.reservation_resultLabel_testid}
                            id={checkoutDatalocator?.reservation_resultLabel_testid}
                            tabIndex={0}>
                            {errorMessage}
                        </h4>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <div className={`confirmation-screen-container ${customClass}`}>
                <div className="status-confirmation-header">
                    {renderReservationStatus()}
                    {children}
                </div>

                <div className="status-confirmation-bottom">{statusBottom}</div>
            </div>
        </>
    );
}

ConfirmationStatusScreen.propsType = {
    successMessage: string,
    warningMessage: string,
    errorMessage: string,
    confirmationStatus: number
};

ConfirmationStatusScreen.defaultProps = {
    successMessage: '',
    warningMessage: '',
    errorMessage: '',
    confirmationStatus: 0
};
